import {
  Button,
  DatePicker,
  Image,
  Input,
  Select,
  Space,
  Spin,
  Table,
  Tag,
} from "antd";
import { Pagination } from "components/Pagination";
import { useEffect, useRef } from "react";
import { formatVND, getTitle } from "utils";
import usePaymentOrder from "hooks/usePaymentOrder";
import { debounce } from "lodash";
import {
  PaymentOrder,
  PaymentOrderStatus,
  PaymentOrderStatusTrans,
} from "types/paymentOrder";
import { HistoryCheckoutDrawer } from "./components/HistoryCheckoutDrawer";
import settings from "settings";
import { unixToFullTime } from "utils/formatDate";
import { Avatar } from "antd/lib";
import HistoryCheckoutExportExcel from "./components/HistoryCheckoutExportExcel";
import usePaymentType from "hooks/usePaymentType";
import { EPaymentTypeTrans } from "types/paymentType";
import dayjs from "dayjs";
import useMerchant from "hooks/useMerchant";

const { Column } = Table;
interface IProps {
  title?: string;
  description?: string;
  merchantId?: number;
}

export const HistoryCheckoutPage = ({
  title = "",
  description = "",
  merchantId,
}: IProps) => {
  const { fetchPaymentType, isFetchPaymentTypeLoading, paymentTypes } =
    usePaymentType({
      initialQuery: {
        page: 1,
        limit: 20,
      },
    });
  const {
    fetchPaymentOrder,
    isFetchPaymentOrderLoading,
    queryPaymentOrder,
    totalPaymentOrder,
    paymentOrders,
  } = usePaymentOrder({
    initialQuery: {
      page: 1,
      limit: 10,
    },
  });
  const {
    query: queryMerchant,
    fetchData: fetchMerchant,
    loading: loadingMerchant,
    data: dataMerchants,
  } = useMerchant({
    initialQuery: {
      page: 1,
      limit: 20,
    },
  });
  // const modalRef = useRef<PaymentOrderModal>(null);
  const PaymentOrderDrawerRef = useRef<any>(null);
  useEffect(() => {
    fetchMerchant();
  }, []);
  useEffect(() => {
    document.title = getTitle(title);
    queryPaymentOrder.merchantId = merchantId;
    fetchPaymentOrder();
    fetchPaymentType();
  }, [merchantId]);

  const searchDebounce = debounce((search) => {
    queryPaymentOrder.search = search;
    fetchPaymentOrder();
  }, 300);

  const onChangeRangePicker = (date: any, dateFormat: string[]) => {
    if (date) {
      queryPaymentOrder.toAt = dayjs(date[1]).endOf("day").unix();
      queryPaymentOrder.fromAt = dayjs(date[0]).startOf("day").unix();
    } else {
      queryPaymentOrder.toAt = undefined;
      queryPaymentOrder.fromAt = undefined;
    }
    fetchPaymentOrder();
  };
  const debounceFetchMerchant = debounce((value) => {
    queryMerchant.search = value;
    fetchMerchant();
  }, 300);
  return (
    <section className="box">
      <div className="flex flex-col gap-1 mb-5">
        <h1 className="text-xl m-0 ">{title}</h1>
        <span className="font-medium">{description}</span>
      </div>
      <div className="filter-container flex items-center gap-3 flex-wrap">
        <Space wrap>
          <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <br />
            <Input
              allowClear={true}
              placeholder="Tìm kiếm theo mã"
              className=" !w-[170px] focus-within:!border-primary  hover:!border-primary focus-within:!shadow-none"
              onChange={(e) => {
                let text = e.target.value;
                searchDebounce(text);
              }}
              size="middle"
            />
          </div>
          <div className="filter-item w-full sm:w-fit">
            <label htmlFor="">Cửa hàng</label>
            <br />
            <Select
              filterOption={false}
              showSearch
              size="middle"
              className="custom-filter focus-within:!border-primary hover:!border-primary focus-within:!shadow-none"
              style={{ width: 250 }}
              placeholder="Chọn cửa hàng"
              allowClear
              loading={loadingMerchant}
              onSearch={(value) => debounceFetchMerchant(value)}
              onChange={(value) => {
                if (value) {
                  queryPaymentOrder.merchantId = value;
                } else {
                  queryPaymentOrder.merchantId = undefined;
                  queryMerchant.search = "";
                  fetchMerchant();
                }
                fetchPaymentOrder();
              }}
            >
              <Select.Option value={undefined}>Tất cả</Select.Option>
              {dataMerchants?.map((item) => (
                <Select.Option value={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </div>
          <div className="filter-item w-full max-w-[300px] sm:w-fit">
            <label htmlFor="">Khoảng thời gian</label>
            <DatePicker.RangePicker
              presets={[
                { label: "Hôm nay", value: [dayjs(), dayjs().add(1, "day")] },
                {
                  label: "Tuần này",
                  value: [dayjs().startOf("week"), dayjs().endOf("week")],
                },
                {
                  label: "Tháng này",
                  value: [dayjs().startOf("month"), dayjs().endOf("month")],
                },
                {
                  label: "Tháng trước",
                  value: [
                    dayjs().subtract(1, "month").startOf("month"),
                    dayjs().subtract(1, "month").endOf("month"),
                  ],
                },
              ]}
              onChange={onChangeRangePicker}
              className="custom-filter w-[300px] max-h-[32px] !sm:w-[300px]"
              size="small"
              format={settings.dateFormat}
            />
          </div>
          <div className="filter-item ">
            <label htmlFor="">Trạng thái</label>
            <br />
            <Select
              size="middle"
              className="custom-filter focus-within:!border-primary hover:!border-primary focus-within:!shadow-none"
              placeholder="Chọn trạng thái"
              style={{ width: 200 }}
              allowClear
              onChange={(value) => {
                queryPaymentOrder.status = value;
                fetchPaymentOrder();
              }}
            >
              {Object.values(PaymentOrderStatus).map((item) => (
                <Select.Option value={item}>
                  {PaymentOrderStatusTrans[item]?.label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="filter-item w-full sm:w-fit">
            <label htmlFor="">Hình thức thanh toán</label>
            <br />
            <Select
              size="middle"
              className="custom-filter focus-within:!border-primary hover:!border-primary focus-within:!shadow-none"
              style={{ width: 250 }}
              placeholder="Chọn hình thức thanh toán"
              allowClear
              loading={isFetchPaymentTypeLoading}
              onChange={(value) => {
                if (value) {
                  queryPaymentOrder.paymentTypeId = value;
                } else {
                  queryPaymentOrder.paymentTypeId = undefined;
                }
                // if(value)

                fetchPaymentOrder();
              }}
            >
              <Select.Option value={undefined}>Tất cả</Select.Option>
              {paymentTypes?.map((item) => (
                <Select.Option value={item.id}>
                  {EPaymentTypeTrans[item.type]}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="filter-item btn mb-1.5">
            <HistoryCheckoutExportExcel
              summaries={paymentOrders}
              queryPaymentOrder={queryPaymentOrder}
            />
          </div>
        </Space>
      </div>

      <Spin spinning={isFetchPaymentOrderLoading}>
        <Table
          pagination={false}
          rowKey="id"
          dataSource={paymentOrders}
          scroll={{ x: "max-content", y: "calc(100vh - 280px)" }}
        >
          <Column
            align="center"
            width={70}
            title="Mã"
            key="point"
            render={(text, record: PaymentOrder) => (
              <div className="flex flex-col gap-1">
                <span
                  className="font-semibold text-primary cursor-pointer"
                  onClick={() =>
                    PaymentOrderDrawerRef.current?.handleOpen(record)
                  }
                >
                  #{record?.code}
                </span>
              </div>
            )}
          />
          <Column
            width={250}
            title="Tên khách hàng"
            key="name"
            render={(text, record: PaymentOrder) => (
              <div className="flex gap-3 items-center">
                <Avatar
                  src={record.customer?.avatar}
                  icon={
                    <Image
                      src={settings?.avatarDefault}
                      preview={false}
                    ></Image>
                  }
                  shape="circle"
                />
                <span className="text-sm font-semibold">
                  {record?.customer?.fullName || "Khách hàng ẩn danh"}
                </span>
              </div>
            )}
          />

          <Column
            align="center"
            width={120}
            title="Số điện thoại"
            key="point"
            render={(text, record: PaymentOrder) => (
              <div className="flex flex-col gap-1">
                <span className="font-medium">{record?.customer?.phone}</span>
              </div>
            )}
          />

          <Column
            align="center"
            width={150}
            title="Tổng giá (VNĐ)"
            key="point"
            render={(text, record: PaymentOrder) => (
              <>
                <div className="flex flex-col gap-1">
                  <span className="font-medium">
                    {formatVND(record?.amount)}
                  </span>
                </div>
              </>
            )}
          />

          <Column
            align="center"
            width={250}
            title="Cửa hàng"
            key="name"
            render={(text, record: PaymentOrder) => (
              <div className="flex gap-3 items-center justify-around ">
                <span>
                  {record?.paymentOrderDetails?.find((item) => item.merchant)
                    ?.merchant
                    ? record?.paymentOrderDetails?.find((item) => item.merchant)
                        ?.merchant.name
                    : "Joy Bookie"}
                </span>
              </div>
            )}
          />
          <Column
            align="center"
            width={200}
            title="Hình thức thanh toán"
            key="point"
            render={(text, record: PaymentOrder) => (
              <>
                <div className="flex flex-col gap-1">
                  <span className="font-medium">
                    {EPaymentTypeTrans[record?.paymentType?.type]}
                  </span>
                </div>
              </>
            )}
          />

          <Column
            align="center"
            width={120}
            title="Trạng thái"
            key="point"
            render={(text, record: PaymentOrder) => (
              <div className="flex flex-col gap-1">
                <span className="font-medium">
                  <Tag color={PaymentOrderStatusTrans[record.status].color}>
                    {PaymentOrderStatusTrans[record.status].label}
                  </Tag>
                </span>
              </div>
            )}
          />
          <Column
            align="center"
            width={140}
            title="Ngày tạo"
            key="point"
            render={(text, record: PaymentOrder) => (
              <>
                <div className="flex flex-col gap-1">
                  <span className="font-medium">
                    {unixToFullTime(record?.createdAt)}
                  </span>
                </div>
              </>
            )}
          />

          <Column
            width={100}
            title="Thao tác"
            render={(text, record: PaymentOrder) => (
              <Space>
                <Button
                  onClick={() =>
                    PaymentOrderDrawerRef.current?.handleOpen(record)
                  }
                >
                  Chi tiết
                </Button>
              </Space>
            )}
          ></Column>
        </Table>

        <Pagination
          currentPage={queryPaymentOrder.page}
          total={totalPaymentOrder}
          // pageSize={queryPaymentOrder.limit}
          onChange={({ limit, page }) => {
            queryPaymentOrder.page = page;
            queryPaymentOrder.limit = limit;
            fetchPaymentOrder();
          }}
        />
      </Spin>

      <HistoryCheckoutDrawer
        ref={PaymentOrderDrawerRef}
        // paymentOrder={paymentOrders}
      />
    </section>
  );
};
