import { createContext, useContext, useRef, useState } from "react";
import ROneSignal from "react-onesignal";
import { oneSignalApi } from "../api/oneSignal.api";
import { getToken } from "../utils/auth";
import { Button, Space, notification } from "antd";
import { OneSignal } from "utils/oneSignal";

interface IOneSignalContext {
  isInitialized: boolean;
  isSubscribed: boolean;
  loading: boolean;
  isBlocked: boolean;
  subscribe?: (sub: boolean, notification?: boolean) => void;
  runOneSignal?: () => void;
  setNotificationLoading?: (notification: boolean) => void;
  getListCallback?: () => OneSignalCallbackData[];
  addListCallback?: (type: OneSignalDataType, cb: Function) => number;
  removeListCallback?: (index: number | undefined) => void;
}

interface Props {
  children: React.ReactNode;
}
const OneSignalContext = createContext<IOneSignalContext>({
  isInitialized: false,
  isSubscribed: false,
  loading: false,
  isBlocked: false,
});

export const useOneSignalContext = () => useContext(OneSignalContext);

export enum OneSignalDataType {
  NEW_MERCHANT = "NEW_MERCHANT",
  LEAD = "LEAD",
}
export interface OneSignalCallbackData {
  type: OneSignalDataType;
  cb: Function;
}

const OneSignalProvider = ({ children }: Props) => {
  const [isInit, setIsInit] = useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);

  const listCallback = useRef<OneSignalCallbackData[]>([]);

  const addListCallback = (type: OneSignalDataType, cb: Function) => {
    const index = listCallback.current.push({ type, cb });
    return index - 1;
  };

  const getListCallback = () => {
    return listCallback.current;
  };

  const removeListCallback = (index: number | undefined) => {
    if (index !== undefined) listCallback.current.splice(index, 1);
  };

  const runOneSignal = () => {
    if (!isInit) {
      OneSignal.init(() => {
        ROneSignal.on("subscriptionChange", () => {
          const token = getToken();
          if (!token) return;
          OneSignal.addEventSubChange(async (isSub) => {
            // const oneSignalId = localStorage.getItem("oneSignalId");
            try {
              setLoading(true);
              const oneSignalId = await ROneSignal.getUserId();
              if (isSub) {
                await oneSignalApi.sub({
                  oneSignalId: oneSignalId,
                });
              } else {
                await oneSignalApi.unSub({ oneSignalId });
              }
              setIsBlocked(false);
              setIsSubscribed(isSub);
              OneSignal.getId();
            } catch (error) {
            } finally {
              setLoading(false);
            }
          });
        });
        ROneSignal.getSubscription(async (sub) => {
          try {
            await OneSignal.notifyPermission(false);
            if (sub) {
              const oneSignalId = await ROneSignal.getUserId();
              if (oneSignalId) {
                await oneSignalApi.sub({
                  oneSignalId: oneSignalId,
                });
              }
            }
            setIsSubscribed(sub);
          } catch (error) {
            setIsBlocked(true);
          }
        });
        ROneSignal.on(
          "notificationPermissionChange",
          async function (permissionChange) {
            const currentPermission = permissionChange.to;

            //Gọi api xóa userId nếu reset quyền thông báo hoặc tắt quyền
            if (currentPermission !== "granted") {
              window.location.reload();
            }
          }
        );
        addListCallback(OneSignalDataType.LEAD, (data: any) => {
          notification.info({
            message: "Có liên hệ mới!",
            btn: (
              <Space>
                <Button
                  type="primary"
                  onClick={() => (window.location.href = data.url)}
                >
                  Đến trang này
                </Button>
              </Space>
            ),
          });
        });
        addListCallback(OneSignalDataType.NEW_MERCHANT, (data: any) => {
          notification.info({
            message: "Có có Spa mới cần được duyệt!",
            btn: (
              <Space>
                <Button
                  type="primary"
                  onClick={() => (window.location.href = data.url)}
                >
                  Đến trang này
                </Button>
              </Space>
            ),
          });
        });

        OneSignal.notificationDisplay((oneSignalData) => {
          console.log(oneSignalData);

          const type = oneSignalData?.data?.type;
          const list = getListCallback();

          list
            .filter((it) => it.type == type)
            .forEach((it) => it.cb(oneSignalData));
        });
        setIsInit(true);
      });
    } else {
      //Case user logout rồi login lại
      ROneSignal.getSubscription(async (sub) => {
        try {
          const oneSignalId = await ROneSignal.getUserId();
          if (sub) {
            await oneSignalApi.sub({
              oneSignalId: oneSignalId,
            });
          }
          await OneSignal.notifyPermission(false);
          setIsSubscribed(sub);
        } catch (error) {
          setIsBlocked(true);
        }
      });
    }
  };

  return (
    <OneSignalContext.Provider
      value={{
        isInitialized: isInit,
        runOneSignal,
        isSubscribed,
        loading,
        isBlocked,
        setNotificationLoading: setLoading,
        getListCallback,
        addListCallback,
        removeListCallback,
      }}
    >
      {children}
    </OneSignalContext.Provider>
  );
};

export default OneSignalProvider;
