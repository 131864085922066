import { CloseOutlined } from "@ant-design/icons";
import { Badge, Card, Col, Divider, Drawer, Popover, Row, Tag } from "antd";
import React, { useImperativeHandle, useState } from "react";
import { QueryParam } from "types/query";
import { Service } from "types/service";
import { formatVND } from "utils/formatVND";
//@ts-ignore
import {
  ServicePackOrder,
  ServicePackOrderDetail,
  ServicePackOrderStatusTrans,
} from "types/servicePackOrder";
import { formattedDate } from "utils/formatDate";
import { servicePackOrderApi } from "api/servicePackOrder.api";
import { BiPackage } from "react-icons/bi";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { ServicePackDetail } from "types/servicePack";

export interface ServicePackOrderDrawer {
  handleOpen: (servicePackOrder: ServicePackOrder) => void;
}

interface ExtendedService extends Service {
  serviceEditSelected: number;
}

interface ServicePackOrderDrawerProps {
  // onClose: () => void;
  // selectedInvoices: Checkout;
}

export const ServicePackOrderDrawer = React.forwardRef(
  ({}: ServicePackOrderDrawerProps, ref) => {
    const [query, setQuery] = useState<QueryParam>({ page: 1, limit: 50 });
    const [selectedServicePackOrder, setSelectedServicePackOrder] =
      useState<ServicePackOrder>();
    const [visible, setVisible] = useState(false);
    useImperativeHandle<any, ServicePackOrderDrawer>(
      ref,
      () => ({
        handleOpen: async (servicePackOrder: ServicePackOrder) => {
          setVisible(true);
          const res = (await servicePackOrderApi.findOne(servicePackOrder.id))
            .data as ServicePackOrder;
          setSelectedServicePackOrder({
            ...res,
            servicePackOrderDetails: res?.servicePackOrderDetails?.map(
              (item) =>
                ({
                  ...item,
                  serviceOptions: JSON.parse(item.serviceOptionJson || "[]"),
                } as ServicePackOrderDetail)
            ),
          });
        },
      }),
      []
    );

    const onCloseModal = () => {
      setVisible(false);
    };

    return (
      <Drawer
        bodyStyle={{ padding: 0 }}
        closable={false}
        width={500}
        onClose={onCloseModal}
        open={visible}
      >
        <div
          className={`cursor-pointer  absolute-left-[50px] bg-white top-[20px]
            w-10 h-10 rounded-full flex items-center justify-center`}
          onClick={onCloseModal}
        >
          <CloseOutlined className={`text-center ${"!text-black"}`} />
        </div>
        {selectedServicePackOrder && (
          <>
            <div className="flex pb-12 flex-col h-full gap-4 p-4 bg-[#f2f2f7]">
              <h1 className="text-xl font-semibold !mb-6">Chi tiết đơn hàng</h1>
              <div className="rounded-lg border border-solid border-[#d3d3d3] p-6 bg-white">
                <div className="flex flex-col gap-1 items-center">
                  <h1 className="text-lg font-semibold">
                    Đơn hàng #{selectedServicePackOrder?.code}
                  </h1>
                  <span className="text-[#757676]">
                    {formattedDate(selectedServicePackOrder.createdAt)}
                  </span>
                  <span className="text-[#757676]">
                    Hóa đơn cho{" "}
                    <span className="text-primary">
                      {selectedServicePackOrder.customer?.fullName ||
                        "Khách hàng ẩn danh"}
                    </span>
                  </span>
                  <Tag
                    color={
                      ServicePackOrderStatusTrans[
                        selectedServicePackOrder?.status
                      ]?.color
                    }
                  >
                    {
                      ServicePackOrderStatusTrans[
                        selectedServicePackOrder?.status
                      ]?.label
                    }
                  </Tag>
                </div>
              </div>
              <div className="rounded-lg border border-solid border-[#d3d3d3] p-6 bg-white">
                <div className="title font-bold text-xl flex flex-col gap-2">
                  <div className="flex justify-between ">
                    <span className="text-base">
                      <div className="flex items-center">
                        <BiPackage className="text-2xl mr-2"></BiPackage>
                        Gói dịch vụ
                      </div>
                      <div className="font-normal text-sm text-gray-400 mt-1">
                        Danh sách dịch vụ trong gói
                      </div>
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <div>
                      {/* <span className="mr-1">{item.quantity || 1}x</span> */}
                      {selectedServicePackOrder.servicePack?.name}
                    </div>
                    <Popover
                      style={{ width: 30 }}
                      content={
                        <Row
                          gutter={12}
                          style={{ width: 450 }}
                          justify={"center"}
                          align={"middle"}
                        >
                          <Col span={16} className="font-bold">
                            Dịch vụ
                          </Col>
                          <Col span={4} className="font-bold">
                            Số lượng
                          </Col>
                          <Col span={4} className="font-bold">
                            Đã dùng
                          </Col>
                          <Col span={24}>
                            <Divider className="!my-2" />
                          </Col>
                          {selectedServicePackOrder.servicePackOrderDetails.map(
                            (detail) => (
                              <>
                                <Col span={16} className="font-semibold">
                                  {detail?.service?.name}
                                </Col>
                                <Col
                                  span={4}
                                  className="text-center font-medium"
                                >
                                  {detail.quantity}
                                </Col>
                                <Col
                                  span={4}
                                  className="text-center font-medium"
                                >
                                  {detail.used}
                                </Col>
                              </>
                            )
                          )}
                        </Row>
                      }
                    >
                      <span className="whitespace-nowrap text-sm font-semibold pt-1 hover:cursor-help">
                        Đã dùng{" "}
                        <IoMdInformationCircleOutline className="translate-y-0.5" />
                      </span>
                    </Popover>
                  </div>
                  <Divider className="!my-3" />

                  <div>
                    {selectedServicePackOrder.servicePackOrderDetails?.map(
                      (detail) => (
                        <div className="flex flex-col">
                          <div className="item flex justify-between">
                            <div className="flex gap-2 items-center">
                              <span className="text-xs text-primary">
                                {detail.quantity || 1}
                              </span>
                              <div className="text-sm leading-normal font-semibold !mb-0">
                                {detail?.service?.name}
                              </div>
                              {/* <Badge
                                count={detail.quantity || 1}
                                className="-translate-x-2 -translate-y-2"
                                size="default"
                              ></Badge> */}
                            </div>

                            <div className="flex items-center gap-2">
                              <span className="font-medium text-[15px]">
                                {formatVND(detail?.service?.price || 0)}{" "}
                              </span>
                              <span className="text-xs text-gray-500">
                                x{detail.quantity || 1}
                              </span>
                            </div>
                          </div>
                          {detail?.serviceOptions?.length !== 0 &&
                            detail?.serviceOptions?.map((item) => (
                              <div className="item flex justify-between italic">
                                <div className="flex gap-2 items-center">
                                  <div className="text-sm leading-normal font-medium !mb-0">
                                    + {item.pricingName} (dịch vụ kèm)
                                  </div>
                                </div>

                                <div className="flex items-center gap-2">
                                  <span className="font-medium text-[15px]">
                                    {formatVND(item.price)}
                                  </span>
                                  <span className="text-xs text-gray-500">
                                    x{detail.quantity || 1}
                                  </span>
                                </div>
                              </div>
                            ))}
                          <Divider className="!my-2" />
                        </div>
                      )
                    )}
                  </div>

                  {/* <Divider className="!my-2"></Divider> */}
                  <div className="flex justify-between w-full ">
                    <span className=" text-sm leading-normal !text-[16px] font-semibold">
                      Giá gói
                    </span>
                    <span className="font-medium line-through text-[16px] text-slate-400">
                      {formatVND(
                        selectedServicePackOrder.servicePack.originPrice
                      )}
                    </span>
                  </div>
                  <div className="flex  justify-between w-full">
                    <span className="text-sm leading-normal text-[20px]">
                      Thành tiền
                    </span>
                    <span className="font-semibold text-[18px] ">
                      {formatVND(
                        selectedServicePackOrder.servicePack.finalPrice
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <Card className="!border-slate-300 !border !rounded-lg mb-6">
                <div className="flex justify-between font-bold text-[20px]">
                  <div className="">Tổng:</div>
                  <div>
                    {formatVND(selectedServicePackOrder?.moneyTotal || 0) + "đ"}
                  </div>
                </div>
              </Card>
            </div>
          </>
        )}
      </Drawer>
    );
  }
);
