import { MenuOutlined } from "@ant-design/icons";
import { Avatar, message, Modal, Table } from "antd";
import { Rule } from "antd/lib/form";
import { arrayMoveImmutable } from "array-move";
import { Column } from "rc-table";
import React, { useImperativeHandle, useState } from "react";
import {
  SortableHandle,
  SortableElement,
  SortableContainer,
  SortEnd,
  SortableContainerProps,
} from "react-sortable-hoc";
import { PaymentType } from "types/paymentType";

export interface PaymentTypeDndModal {
  handleOpen: (paymentTypeListInput: PaymentType[]) => void;
}
interface PaymentTypeDndModalProps {
  onSubmitOk: () => void;
  changePosition: (newPosition: any) => void;
}

interface IPositionPayload {
  id: number;
  position: number;
}

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", padding: "20px", color: "#999" }} />
));

const SortableItem = SortableElement(
  (props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />
);
const SortableBody = SortableContainer(
  (props: React.HTMLAttributes<HTMLTableSectionElement>) => (
    <tbody {...props} className="ant-table-tbody" />
  )
);

export const PaymentTypeDndModal = React.forwardRef(
  ({ onSubmitOk, changePosition }: PaymentTypeDndModalProps, ref) => {
    const [isChangePositionloading, setIsChangePositionLoading] =
      useState(false);
    const [visible, setVisible] = useState(false);
    const [paymentTypeList, setPaymentTypeList] = useState<PaymentType[]>([]);
    const [payloadPosition, setPayloadPosition] = useState<IPositionPayload[]>(
      []
    );

    useImperativeHandle<any, PaymentTypeDndModal>(
      ref,
      () => ({
        handleOpen(paymentTypeListInput) {
          setPaymentTypeList(paymentTypeListInput);
          handleUpdatePosition(paymentTypeListInput);
          setVisible(true);
        },
      }),
      []
    );

    const updatePositionAsync = async () => {
      setIsChangePositionLoading(true);
      try {
        await changePosition(payloadPosition);
        message.success("Cập nhật vị trí thành công!");
        onSubmitOk();
        onCloseModal();
      } finally {
        setIsChangePositionLoading(false);
      }
    };

    const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
      if (oldIndex !== newIndex && paymentTypeList) {
        const newData = arrayMoveImmutable(
          paymentTypeList.slice(),
          oldIndex,
          newIndex
        ).filter((el: PaymentType) => !!el);
        handleUpdatePosition(newData);
        setPaymentTypeList(newData);
      }
    };

    //* Xử lý update vị trí cho paymentType
    const handleUpdatePosition = async (newData: PaymentType[]) => {
      const newPosition: {
        id: number;
        position: number;
      }[] = [];

      //* Tạo payload với id của paymentType và vị trí mới dựa vào index của paymentType trong list
      newData.forEach(async (data, index) => {
        data.position = index + 1;
        newPosition.push({
          id: data.id,
          position: index + 1,
        });
      });
      setPaymentTypeList(newData);
      setPayloadPosition(newPosition);
    };

    const DraggableContainer = (props: SortableContainerProps) => {
      console.log(props);
      return (
        <SortableBody
          useDragHandle
          disableAutoscroll
          helperClass="row-dragging"
          onSortEnd={onSortEnd}
          {...props}
        />
      );
    };

    const DraggableBodyRow: React.FC<any> = ({
      className,
      style,
      ...restProps
    }) => {
      // function findIndex base on Table rowKey props and should always be a right array index
      const index = paymentTypeList?.findIndex(
        (x) => x.id === restProps["data-row-key"]
      );
      return <SortableItem index={index} {...restProps} />;
    };

    const onCloseModal = () => {
      setPaymentTypeList([]);
      setVisible(false);
    };

    return (
      <Modal
        onCancel={onCloseModal}
        visible={visible}
        title={"Cập nhật vị trí"}
        style={{ top: 20 }}
        width={700}
        confirmLoading={isChangePositionloading}
        onOk={updatePositionAsync}
        destroyOnClose
      >
        <Table
          pagination={false}
          rowKey="id"
          style={{ marginTop: 20 }}
          dataSource={paymentTypeList}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        >
          <Column
            title="Kéo thả"
            align="center"
            width={60}
            className="drag-visible drag-icon"
            dataIndex={"no"}
            render={(text) => (
              <>
                <DragHandle />
              </>
            )}
          />
          <Column
            width={60}
            align="center"
            title="Ảnh"
            dataIndex="icon"
            key="icon"
            render={(text, record: PaymentType) => (
              <Avatar src={text || "/logo.png"} size={40} shape="square" />
            )}
          />
          <Column
            title="Hình thức thanh toán"
            dataIndex="name"
            width={300}
            key="name"
            render={(text, record: PaymentType) => {
              return (
                <span
                  //   onClick={() => {
                  //     modalRef.current?.handleUpdate(record);
                  //   }}
                  className="text-secondary cursor-pointer hover:underline font-medium"
                >
                  {text}
                </span>
              );
            }}
          />
          <Column
            title="Vị trí hiển thị"
            dataIndex="position"
            width={300}
            key="position"
            render={(text, record: PaymentType) => {
              return <span>{text}</span>;
            }}
          />
        </Table>
      </Modal>
    );
  }
);
