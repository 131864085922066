import { AxiosPromise } from "axios";
import { request } from "utils/request";

export const newsApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/news",
      params,
    }),
  create: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/news",
      data,
      method: "post",
    }),
  update: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/news/${id}`,
      method: "patch",
      data,
    }),
  delete: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/news/${id}`,
      method: "delete",
    }),
  uploadUrl: process.env.REACT_APP_API_URL + "/v1/admin/news/upload",
};
