import { Merchant } from "./merchant";

export interface Banner {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  name: string;
  image: string;
  isVisible: boolean;
  layout: BannerLayout;
  merchant: Merchant;
}

export enum BannerLayout {
  Layout1 = "LAYOUT1",
  Layout2 = "LAYOUT2",
  Layout3 = "LAYOUT3",
  Layout4 = "LAYOUT4",
  Layout5 = "LAYOUT5",
}
