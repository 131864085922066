import { Col, Form, Input, message, Modal, Row } from "antd";
import { Rule } from "antd/lib/form";
import { configurationApi } from "api/configuration.api";
import { InputNumberVN } from "components/Input/InputNumberVN";
import { useEffect, useState } from "react";
import { Configuration, configurationParamsObject } from "types/configuration";
import { ModalStatus } from "types/modal";

const rules: Rule[] = [{ required: true, message: "Bắt buộc nhập!" }];

export const ConfigurationModal = ({
  visible,
  status,
  configuration,
  onClose,
  onSubmitOk,
}: {
  visible: boolean;
  status: ModalStatus;
  configuration: Partial<Configuration>;
  onClose: () => void;
  onSubmitOk: () => void;
}) => {
  const [form] = Form.useForm<Configuration>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({ ...configuration });
    return () => {};
  }, [configuration, visible]);

  const createData = async () => {
    const valid = await form.validateFields();
    const data = { staff: form.getFieldsValue() };

    setLoading(true);
    try {
      const res = await configurationApi.create(data);
      message.success("Thao tác thành công");
      onClose();
      onSubmitOk();
    } finally {
      setLoading(false);
    }
  };

  const updateData = async () => {
    const valid = await form.validateFields();
    const data = { configuration: form.getFieldsValue() };
    setLoading(true);
    try {
      const res = await configurationApi.update(configuration?.id || 0, data);
      message.success("Thao tác thành công");
      onClose();
      onSubmitOk();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      maskClosable={false}
      onCancel={onClose}
      visible={visible}
      title={status == "create" ? "Thêm cấu hình" : "Cập nhật cấu hình"}
      style={{ top: 20 }}
      width={500}
      confirmLoading={loading}
      onOk={() => {
        status == "create" ? createData() : updateData();
      }}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Tiêu đề" rules={rules}>
              <Input
                placeholder=""
                disabled
                value={configurationParamsObject[configuration.param!]?.label}
              />
            </Form.Item>

            {configuration.dataType == "NUMBER" ? (
              <Form.Item label="Giá trị" name="value" rules={rules}>
                <InputNumberVN style={{ width: "100%" }} />
              </Form.Item>
            ) : (
              <Form.Item label="Giá trị" name="value" rules={rules}>
                <Input />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
