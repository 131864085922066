import { Col, Form, Input, message, Modal, Row, Select, Switch } from "antd";
import { bannerApi } from "api/banner.api";
import { InputNumberVN } from "components/Input/InputNumberVN";
import MerchantSelector from "components/Selector/MerchantSelector";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import { RECOMMENDED_SIZE } from "constant/size";
import React, { useImperativeHandle, useState } from "react";
import { Banner, BannerLayout } from "types/banner";
import { ModalStatus } from "types/modal";
import {
  betweenValueRule,
  positiveIntegersRule,
  requiredRule,
} from "utils/validate";

export interface BannerModal {
  handleCreate: () => void;
  handleUpdate: (Banner: Banner) => void;
}
interface BannerModalProps {
  onClose: () => void;
  onSubmitOk: () => void;
}

export const BannerModal = React.forwardRef(
  ({ onClose, onSubmitOk }: BannerModalProps, ref) => {
    const [form] = Form.useForm<Banner & { merchantId: number }>();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<ModalStatus>("create");
    const [bannerSelected, setBannerSelected] = useState<Banner>();

    useImperativeHandle<any, BannerModal>(
      ref,
      () => ({
        handleCreate() {
          form.resetFields();
          setVisible(true);
          setStatus("create");
        },
        handleUpdate(Banner: Banner) {
          setBannerSelected(Banner);
          form.setFieldsValue({ ...Banner, merchantId: Banner.merchant?.id });
          setVisible(true);
          setStatus("update");
        },
      }),
      []
    );

    const getFormData = () => {
      const { id, merchantId, ...data } = form.getFieldsValue();
      return {
        id,
        banner: data,
        merchantId,
      };
    };

    const onSubmit = async () => {
      await form.validateFields();
      setLoading(true);
      try {
        const { id, ...banner } = getFormData();
        if (id) {
          await bannerApi.update(id || 0, banner);
        } else {
          await bannerApi.create(banner);
        }
        message.success("Thao tác thành công!");
        onClose();
        onSubmitOk();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        onCancel={() => {
          form.resetFields();
          onClose?.();
          setVisible(false);
        }}
        visible={visible}
        title={
          <h1 className="text-secondary text-lg font-bold">
            {status === "create"
              ? "Thêm mới banner"
              : `Cập nhật banner - ${bannerSelected?.name || ""}`}
          </h1>
        }
        style={{ top: 20 }}
        width={600}
        confirmLoading={loading}
        onOk={onSubmit}
      >
        <Form layout="vertical" form={form}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Row gutter={32}>
            <Col span={24}>
              <Form.Item noStyle shouldUpdate={true}>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      className="text-center"
                      rules={[requiredRule]}
                      label="Hình ảnh"
                      name="image"
                      style={{ marginBottom: 32 }}
                    >
                      <SingleImageUpload
                        recommendedSize={
                          RECOMMENDED_SIZE[
                            getFieldValue("layout") as BannerLayout
                          ]
                        }
                        uploadUrl={bannerApi.uploadUrl}
                        onUploadOk={(path: string) => {
                          form.setFieldsValue({
                            image: path,
                          });
                        }}
                        imageUrl={form.getFieldValue("image")}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item label="Tên banner" name="name" rules={[requiredRule]}>
                <Input placeholder="Nhập tên banner" />
              </Form.Item>
            </Col>

            <Col span={12} className="text-center">
              <Form.Item label="Layout" name="layout" rules={[requiredRule]}>
                <Select
                  placeholder={"Chọn Layout"}
                  options={Object.values(BannerLayout).map((item) => ({
                    value: item,
                    label: item,
                  }))}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={12} className="text-center">
              <Form.Item
                label="Vị trí"
                name="position"
                rules={[positiveIntegersRule]}
              >
                <InputNumberVN placeholder="Nhập vị trí"></InputNumberVN>
              </Form.Item>
            </Col>
            <Col span={24} className="text-center">
              <Form.Item label="Link cửa hàng" name="link">
                <Input placeholder="Nhập vào link cửa hàng" />
              </Form.Item>
              {/* <Form.Item label="Cửa hàng" name="merchantId">
                <MerchantSelector></MerchantSelector>
              </Form.Item> */}
            </Col>
            <Form.Item
              initialValue={true}
              valuePropName="checked"
              label="Trạng thái"
              name={"isVisible"}
            >
              <Switch checkedChildren="Hiện" unCheckedChildren="Ẩn"></Switch>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    );
  }
);
