import { Col, Form, Input, message, Modal, Row } from "antd";
import { Rule } from "antd/lib/form";
import { employeeApi } from "api/employee.api";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import { useEffect, useState } from "react";
import { Merchant } from "types/merchant";
import { ModalStatus } from "types/modal";
import { Staff } from "types/staff";
import {
  emailRule,
  minRule,
  minRuleCustom,
  phoneValidate,
  requiredRule,
  wordAndNumberOnlyRule,
} from "utils/validate";

export const EmployeeModal = ({
  visible,
  status,
  staff,
  onClose,
  onSubmitOk,
  merchant,
}: {
  visible: boolean;
  status: ModalStatus;
  staff: Partial<Staff>;
  merchant: Merchant | undefined;
  onClose: () => void;
  onSubmitOk: () => void;
}) => {
  const [form] = Form.useForm<Staff>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (status == "create" && visible) {
      form.resetFields();
    }
  }, [visible, status]);

  useEffect(() => {
    form.setFieldsValue({ ...staff });
  }, [staff]);

  const getFormData = () => {
    const { username, ...data } = form.getFieldsValue();
    return {
      employee: {
        username: [merchant?.namespace, username].join("-"),
        ...data,
      },
      merchantId: merchant?.id,
    };
  };

  const createData = async () => {
    await form.validateFields();
    const data = getFormData();

    setLoading(true);
    try {
      await employeeApi.createEmployee(data);
      message.success("Thao tác thành công");
      onClose();
      onSubmitOk();
    } finally {
      setLoading(false);
    }
  };

  const updateData = async () => {
    await form.validateFields();
    const { merchantId, ...data } = getFormData();

    setLoading(true);
    try {
      await employeeApi.updateEmployee(staff?.id || 0, data);
      message.success("Thao tác thành công");
      onClose();
      onSubmitOk();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      onCancel={onClose}
      visible={visible}
      title={status == "create" ? "Thêm nhân viên" : "Cập nhật nhân viên"}
      style={{ top: 20 }}
      width={700}
      confirmLoading={loading}
      onOk={() => {
        status == "create" ? createData() : updateData();
      }}
      okText={"Lưu"}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={24}>
            <div className="w-full m-auto">
              <Form.Item shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item
                      label="Ảnh đại diện"
                      name="avatar"
                      className="text-center"
                    >
                      <SingleImageUpload
                        uploadUrl={employeeApi.uploadUrl}
                        onUploadOk={(path: string) => {
                          form.setFieldsValue({
                            avatar: path,
                          });
                        }}
                        imageUrl={form.getFieldValue("avatar")}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </div>
          </Col>
          <Col span={12}>
            <Form.Item
              tooltip="Mã tự sinh nếu để trống"
              label="Mã nhân viên"
              name="code"
            >
              <Input
                placeholder="Nhập tên đăng nhập"
                disabled={status === "update"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Tên đăng nhập"
              name="username"
              rules={
                status == "update" ? [] : [requiredRule, wordAndNumberOnlyRule]
              }
            >
              <Input
                classNames={{
                  prefix: "!mr-0",
                }}
                prefix={
                  status == "update" ? undefined : merchant?.namespace + "-"
                }
                placeholder="Nhập tên đăng nhập"
                disabled={status === "update"}
              />
            </Form.Item>
          </Col>

          {status === "update" ? null : (
            <Col span={24}>
              <Form.Item
                label="Mật khẩu"
                name="password"
                rules={[requiredRule]}
              >
                <Input placeholder="Nhập mật khẩu" type={"password"} />
              </Form.Item>
            </Col>
          )}

          <Col span={24}>
            <Form.Item label="Tên" name="name" rules={[requiredRule]}>
              <Input placeholder="Nhập họ và tên" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Điện thoại"
              name="phone"
              rules={[requiredRule, ...phoneValidate]}
            >
              <Input placeholder="Nhập số điện thoại" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Email" name="email" rules={[emailRule]}>
              <Input placeholder="Nhập email" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
