import * as ExcelJS from "exceljs";
import saveAs from "file-saver";
import moment from "moment";
import { ServiceOptionCustom } from "types/appointment";
import { Checkout, CheckoutStatusTrans } from "types/checkout";

import { MyExcelColumn, CellStyle } from "utils/MyExcel";
import { formatVND } from "utils/formatVND";

//* Từng dòng xuất excel
interface IRowExport {
  code: string;
  customerName: string;
  employeeName: string;
  serviceName: string;
  moneyTotal: string;
  status: string;
  createdAt: string;
  price: string;
  note: string;
}

export const HandleExportCheckoutExcel = async (
  data: Checkout[],
  columns: MyExcelColumn<Checkout>[],
  fileName: string,
  sheetName: string
) => {
  const workbook = new ExcelJS.Workbook();
  //Khai bao file
  const sheet = workbook.addWorksheet(sheetName, {
    pageSetup: { fitToPage: true, fitToHeight: 5, fitToWidth: 7 },
  });

  sheet.columns = columns as ExcelJS.Column[];

  const headerRow = sheet.getRow(1);

  headerRow.eachCell((c, index) => {
    c.style = columns[index - 1]?.headingStyle as CellStyle;
  });

  for (const item of data) {
    let rows: IRowExport[] = [];
    let currIndex = sheet.rowCount + 1;
    item.checkoutDetails.forEach((detail) => {
      const hasSingleServiceOption = item.checkoutDetails?.find(
        (detail) => !detail.servicePackOrderDetail
      );
      const isServicePack = detail?.servicePackOrderDetail;
      rows.push({
        code: item.code,
        customerName: item?.customerProfile?.fullName || "Khách ẩn danh",
        employeeName: item?.employee?.name || "Không có",
        price: isServicePack ? "0" : formatVND(detail.service.price),
        status: CheckoutStatusTrans[item.status].label,
        moneyTotal: formatVND(item?.moneyTotal),
        createdAt: item?.createdAt
          ? moment.unix(item.createdAt).format("HH:mm DD/MM/YYYY")
          : "--",
        serviceName: detail?.service?.name,
        note: isServicePack ? "Thanh toán gói" : "",
      });
      const serviceOptions: ServiceOptionCustom[] = JSON.parse(
        detail.serviceOptionJson || "[]"
      );
      const checkedServiceOptions: ServiceOptionCustom[] =
        serviceOptions?.filter((item) => item.isChecked);
      if (checkedServiceOptions.length !== 0) {
        checkedServiceOptions.forEach((option) => {
          rows.push({
            code: item.code,
            customerName: item?.customerProfile?.fullName || "Khách ẩn danh",
            employeeName: item?.employee?.name || "Không có",

            status: CheckoutStatusTrans[item.status].label,
            createdAt: item?.createdAt
              ? moment.unix(item.createdAt).format("HH:mm DD/MM/YYYY")
              : "--",
            serviceName: `${option?.pricingName} (Dịch vụ kèm)`,
            moneyTotal:
              item?.moneyTotal == 0
                ? "Thanh toán gói"
                : formatVND(item?.moneyTotal),
            price: isServicePack ? "0" : formatVND(option.price),
            note: isServicePack ? "Thanh toán gói" : "",
          });
        });
      }
    });
    sheet.addRows(rows);
    // console.log(sheet);
    // debugger;

    // appointment.appointmentDetails.forEach((detail) => {
    //   const hasSingleServiceOption = appointment.appointmentDetails?.find(
    //     (item) => !item.servicePackOrderDetail
    //   );
    //   sheet.addRow({
    //     code: appointment.code,
    //     customerName: appointment?.customerProfile?.fullName || "Khách ẩn danh",
    //     contactPhone: appointment?.customerProfile?.contactPhone || "Không có",

    //     totalDuration: formatDuration(
    //       appointment.appointmentDetails.reduce(
    //         (acc, cur) => acc + cur.duration,
    //         0
    //       )
    //     ),
    //     totalPrice: !hasSingleServiceOption
    //       ? "Thanh toán gói"
    //       : appointment?.moneyTotal,
    //     merchantLocationName: appointment?.merchantLocation?.name,
    //     status: AppointmentStatusTrans[appointment.status].label,
    //     createdAt: appointment?.createdAt
    //       ? moment.unix(appointment.createdAt).format("HH:mm DD/MM/YYYY")
    //       : "--",
    //     type: detail?.servicePackOrderDetail ? "Gói" : "Đơn lẻ",
    //     serviceName: detail?.service?.name,
    //     price: formatVND(detail?.service?.price),
    //     duration: formatDuration(detail?.service?.duration),
    //   });
    // });

    if (rows.length > 1) {
      const lastRow = sheet.rowCount;
      sheet.mergeCells(currIndex, 1, lastRow, 1);
      sheet.mergeCells(currIndex, 2, lastRow, 2);
      sheet.mergeCells(currIndex, 3, lastRow, 3);
      // sheet.mergeCells(currIndex, 7, lastRow, 7);
      sheet.mergeCells(currIndex, 6, lastRow, 6);
      sheet.mergeCells(currIndex, 7, lastRow, 7);
      sheet.mergeCells(currIndex, 8, lastRow, 8);
    }
  }
  workbook.xlsx.writeBuffer().then(function (buffer: any) {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `${fileName}.xlsx`
    );
  });
};
