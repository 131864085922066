import { ImportOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Flex, Spin, Table, message } from "antd";
import { roleApi } from "api/role.api";
import useRole from "hooks/useRole";
import { useEffect, useState } from "react";
import { adminRoutes } from "router";
import settings from "settings";
import { ModalStatus } from "types/modal";
import { Permission, Role } from "types/role";
import { getTitle } from "utils";
import { RoleModal } from "./components/RoleModal";

const { ColumnGroup, Column } = Table;

export const RolePage = ({ title = "" }) => {
  const {
    fetchData,
    data: roles,
    loading,
  } = useRole({
    initialQuery: { page: 1, limit: 20 },
  });
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState<Partial<Role>>({});
  const [modalStatus, setModalStatus] = useState<ModalStatus>("create");
  const [loadingImport, setLoadingImport] = useState(false);
  const [adminPermissions, setAdminPermissions] = useState<Permission[]>([]);

  useEffect(() => {
    document.title = getTitle(title);
    fetchPermissions();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchPermissions = async () => {
    const { data } = await roleApi.getPermission();
    setAdminPermissions(data);
  };
console.log({roles})
  const handleImportAdminRoutes = async () => {
    try {
      const permissions: Permission[] = [];

      for (const route of adminRoutes) {
        if (route.children) {
          for (const childRoute of route.children) {
            const path = route.path + "/" + childRoute.path;

            const find = adminPermissions.find((e) => e.path == path);
            const id = find?.id;
            permissions.push({
              path,
              id,
              name: find?.name || childRoute.name || "",
            });
          }
        } else {
          const find = adminPermissions.find((e) => e.path == route.path);
          const path = route.path;
          const id = find?.id;

          permissions.push({
            path: path as string,
            id,
            name: find?.name || route.name || "",
          });
        }
      }

      setLoadingImport(true);

      await roleApi.importPermission({
        permissions,
      });
      message.success("Thao tác thành công");
      fetchData();
      // fetchPermissions();
      setLoadingImport(false);
    } catch (error) {
    } finally {
      setLoadingImport(false);
    }
  };

  return (
    <div>
      <section className="box">
        <Flex gap={12} className="mb-4">
          {settings.isDev && (
            <Button
              onClick={handleImportAdminRoutes}
              type="primary"
              icon={<ImportOutlined />}
              loading={loadingImport}
              size="middle"
            >
              Import routes
            </Button>
          )}
          <Button
            key={2}
            onClick={() => {
              setVisibleModal(true);
              setModalStatus("create");
              setSelectedRole({});
            }}
            type="primary"
            size="middle"
            icon={<PlusOutlined />}
          >
            Thêm mới
          </Button>
        </Flex>
        <div className="b-container">
          <Spin spinning={loading}>
            <Table
              className="table-striped-rows"
              pagination={false}
              rowKey="id"
              dataSource={roles}
            >
              <Column
                title="Level"
                // dataIndex="name"
                render={(text, record: Role) => <>{record.name}</>}
              />
              <Column title="Mô tả" dataIndex="description" />

              <Column
                width={150}
                align="center"
                title="Thao tác"
                key="action"
                render={(text, record: Role) => (
                  <span>
                    {/* {rolesCheck.update && ( */}
                    <Button
                      type="primary"
                      onClick={() => {
                        setSelectedRole(record);
                        setVisibleModal(true);
                        setModalStatus("update");
                      }}
                    >
                      Cập nhật
                    </Button>
                    {/* )} */}
                  </span>
                )}
              />
            </Table>
          </Spin>
        </div>
      </section>

      <RoleModal
        onSubmitOk={fetchData}
        onClose={() => {
          setVisibleModal(false);
        }}
        open={visibleModal}
        role={selectedRole}
        status={modalStatus}
      />
    </div>
  );
};

export { RolePage as default };
