import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Popconfirm,
  Popover,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import { Pagination } from "components/Pagination";
import useFaq from "hooks/useFaq";
import { useEffect, useRef } from "react";
import { FAQTypeTrans, Faq } from "types/faq";
import { getTitle } from "utils";
import { FaqModal } from "./components/FaqModal";

const { Column } = Table;

export const FaqPage = ({ title = "" }) => {
  const {
    fetchFaq,
    faqs,
    isFetchFaqLoading,
    totalFaq,
    queryFaq,
    filterFaq,
    deleteFaq,
  } = useFaq({
    initialQuery: {
      page: 1,
      limit: 20,
    },
  });
  const modalRef = useRef<FaqModal>(null);

  useEffect(() => {
    document.title = getTitle(title);
    fetchFaq();
  }, []);
  return (
    <section className="box">
      <div className="filter-container">
        <Space>
          <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              size="middle"
              onChange={(ev) => {
                filterFaq({ search: ev.currentTarget.value, page: 1 });
              }}
              placeholder="Tìm câu hỏi"
            />
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => fetchFaq()}
              type="primary"
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => {
                modalRef.current?.handleCreate();
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              Thêm mới
            </Button>
          </div>
        </Space>
      </div>

      <Spin spinning={isFetchFaqLoading}>
        <Table pagination={false} rowKey="id" dataSource={faqs}>
          <Column
            title="STT"
            dataIndex={"position"}
            align="center"
            width={50}
            render={(position) => {
              return <span className="font-medium">{position}</span>;
            }}
          />
          <Column
            title="Câu hỏi"
            dataIndex="title"
            width={400}
            key="title"
            render={(text, record: Faq) => {
              return (
                <span
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                  className="text-secondary cursor-pointer hover:underline font-medium"
                >
                  {text}
                </span>
              );
            }}
          />
          <Column
            title="Loại câu hỏi"
            width={200}
            render={(text, record: Faq) => {
              return <span>{FAQTypeTrans[record?.type]}</span>;
            }}
          />
          <Column
            title="Nội dung"
            dataIndex="content"
            key="content"
            render={(text, record: Faq) => {
              return (
                <Tooltip
                  title={<div dangerouslySetInnerHTML={{ __html: text }}></div>}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: text }}
                    className="line-clamp-1"
                  ></div>
                </Tooltip>
              );
            }}
          />

          <Column
            width={150}
            title="Hành động"
            key="action"
            render={(text, record: Faq) => (
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                >
                  Cập nhật
                </Button>
                <Popconfirm
                  title="Bạn có chắc chắn muốn thực hiện thao tác?"
                  onConfirm={async () => {
                    await deleteFaq(record.id);
                    message.success("Xóa thành công!");
                  }}
                >
                  <Button type="primary" danger>
                    Xóa
                  </Button>
                </Popconfirm>
              </Space>
            )}
          />
        </Table>

        <Pagination
          currentPage={queryFaq.page}
          total={totalFaq}
          pageSize={queryFaq.limit}
          onChange={({ limit, page }) => {
            queryFaq.page = page;
            queryFaq.limit = limit;
            fetchFaq();
          }}
        />
      </Spin>

      <FaqModal
        FaqList={faqs}
        onSubmitOk={fetchFaq}
        onClose={() => {}}
        ref={modalRef}
      />
    </section>
  );
};
