import {
  BellOutlined,
  CaretDownOutlined,
  CloseOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Dropdown, Menu, Switch, Tooltip, message } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { Header } from "antd/lib/layout/layout";
import { useOneSignalContext } from "context/OneSignalContext";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { adminRoutes } from "router";
import settings from "settings";
import { userStore } from "store/userStore";
import { setToken } from "utils/auth";
import { iOS } from "utils/devide";
import { OneSignal } from "utils/oneSignal";
import { $url } from "utils/url";

const menu = (
  <Menu>
    <Menu.Item>
      <Link to="staff/profile">Cá nhân</Link>
    </Menu.Item>

    <Menu.Item>
      <Link to={"/login"} onClick={() => setToken("")}>
        Đăng xuất
      </Link>
    </Menu.Item>
  </Menu>
);

export const Navbar = observer(
  ({ collapsed, toggle }: { collapsed: boolean; toggle: () => void }) => {
    const location = useLocation();
    const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);

    const [isVisibleNotiError, setIsVisibleNotiError] = useState<boolean>(true);

    const errorMessage = iOS()
      ? "Không hỗ trợ trên ios"
      : "Vui lòng cấp quyền cho phép nhận thông báo trên trình duyệt bạn đang sử dụng";

    const { isSubscribed, loading, isBlocked, setNotificationLoading } =
      useOneSignalContext();

    useEffect(() => {
      // set breadcrumbs

      const find = adminRoutes
        .flatMap((v) => (v.children?.length ? v.children : v))
        .find(
          (e) =>
            e.name ==
            location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
        );

      console.log(find);

      if (find) {
        if (find.breadcrumb) {
          setBreadcrumbs(find.breadcrumb.split("/"));
        } else if (find.title) {
          setBreadcrumbs([find.title]);
        }
      }
    }, [location]);

    //   const menu = React.useMemo(()=> <Menu>
    //   <Menu.Item>
    //     <Link to="/profile">Cá nhân</Link>
    //   </Menu.Item>

    //   <Menu.Item>
    //     <Link to={""}>Đăng xuất</Link>
    //   </Menu.Item>
    // </Menu>,)

    const handleChangePushNotification = useCallback(
      async (checked: boolean) => {
        try {
          setNotificationLoading?.(true);

          await OneSignal.subscribe(checked);
        } catch (error) {
          message.error(errorMessage);
        } finally {
          setNotificationLoading?.(false);
        }
      },
      []
    );

    return (
      <Header
        className={`site-layout-background ${collapsed ? "collapsed" : ""}`}
        style={{ padding: 0 }}
      >
        <div>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              style: { color: "#000" },
              onClick: toggle,
            }
          )}
          <Breadcrumb style={{ display: "inline-block" }}>
            {breadcrumbs.map((item, i) => (
              <Breadcrumb.Item key={i}>{item}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>

        <div className="flex items-center gap-3 font-semibold">
          <div className="flex items-center">
            <Switch
              className="custom-switch"
              loading={loading}
              checked={isSubscribed}
              onChange={handleChangePushNotification}
            ></Switch>
            <span
              className="hidden md:block"
              style={{
                fontWeight: "600",
                marginLeft: 10,
              }}
            >
              Bật thông báo đẩy
            </span>
            <Tooltip title="Bật thông báo đẩy">
              <BellOutlined className="md:hidden ml-2 text-white text-xl" />
            </Tooltip>
            {isBlocked && (
              <Tooltip
                overlayClassName="notification-tooltip"
                trigger={"click"}
                visible={isVisibleNotiError}
                placement="bottom"
                overlayInnerStyle={{
                  backgroundColor: "white",
                  color: "black",
                }}
                title={
                  <div
                    style={{
                      position: "relative",
                      padding: 10,
                    }}
                  >
                    {errorMessage}
                    <div
                      onClick={() => {
                        setIsVisibleNotiError(false);
                      }}
                      className="close-btn"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                      }}
                    >
                      <CloseOutlined></CloseOutlined>
                    </div>
                  </div>
                }
              ></Tooltip>
            )}
          </div>
          {<div>v{settings.version}</div>}
          <div>
            <Dropdown trigger={["click"]} overlay={menu}>
              <div style={{ cursor: "pointer" }}>
                <Avatar
                  size={"large"}
                  src={$url(userStore.info.avatar)}
                  style={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                  }}
                >
                  {userStore.info.name?.[0]}
                </Avatar>
                <CaretDownOutlined style={{ color: "#fff", marginLeft: 4 }} />
              </div>
            </Dropdown>
          </div>
          {/* {userStore.info.avatar ? (
            <Image
              width={40}
              src={$url(userStore.info.avatar)}
              style={{ borderRadius: "50%" }}
              fallback={require("assets/images/user.png")}
            />
          ) : (
            <img
              width={40}
              alt=""
              style={{ borderRadius: "50%" }}
              src={require("assets/images/user.png")}
            />
          )} */}
        </div>
      </Header>
    );
  }
);
