import { debounce } from "lodash";
import { useState } from "react";
import { QueryParam } from "types/query";

interface Props<S> {
  initQuery: S;
  featureName?: string;
  queryFunc: (
    query: S,
    api?: any
  ) => Promise<{ data: any; total: number; [key: string]: any }>;
  deleteFunc?: (id: number) => Promise<any>;
  createFunc?: (data: any) => Promise<any>;
  editFunc?: (id: number, data: any) => Promise<any>;
}

export function useFetchTableData<T = any, S = QueryParam>({
  initQuery,
  queryFunc,
  deleteFunc,
  createFunc,
  editFunc,
}: Props<S>) {
  const [query, setQuery] = useState<S>(initQuery);
  const [data, setData] = useState<T[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const filterData = debounce((newQuery: Partial<S>) => {
    //@ts-ignore
    Object.assign(query, { page: 1, ...newQuery });
    fetchData();
  }, 200);

  const fetchData = async (newQuery?: Partial<S>, api?: any) => {
    try {
      setLoading(true);
      const queryParams = { ...query, ...newQuery };
      const res = await queryFunc(queryParams, api);
      setData(res.data);
      setTotal(res.total);

      return res.data;
    } finally {
      setLoading(false);
    }
  };

  const deleteData = async (id: number) => {
    try {
      setLoading(true);
      await deleteFunc?.(id);
    } finally {
      fetchData();
      setLoading(false);
    }
  };

  const createData = async (dataCreate?: any) => {
    try {
      setLoading(true);
      await createFunc?.(dataCreate);
    } finally {
      setLoading(false);
    }
  };

  const editData = async (id: number, dataEdit?: any) => {
    try {
      setLoading(true);
      await editFunc?.(id, dataEdit);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    total,
    loading,
    setLoading,
    setQuery,
    query,
    fetchData,
    deleteData,
    createData,
    editData,
    setData,
    filterData,
  };
}
