import { Layout } from "antd";
import { useResponsive } from "hooks/useResponsive";
import { AuthProvider } from "provider/AuthProvider";
import { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Navbar } from "./components/Navbar";
import { Sidebar } from "./components/Sidebar";
import "./styles/AdminLayout.scss";

const { Content } = Layout;

const deviceWidth = window.innerWidth;

export const AdminLayout = () => {
  console.log("admin layout");

  const state = useResponsive();
  const [collapsed, setCollapsed] = useState(() => deviceWidth <= 768);
  const [siteLayoutMarginLeft, setSiteLayoutMarginLeft] = useState(250);

  useEffect(() => {
    if (state.md) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }

    return () => {};
  }, [state]);

  const toggle = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  useEffect(() => {
    if (collapsed) {
      setSiteLayoutMarginLeft(80);
    } else {
      setSiteLayoutMarginLeft(250);
    }
  }, [collapsed]);

  return (
    <AuthProvider>
      <Sidebar collapsed={collapsed} toggle={() => setCollapsed(!collapsed)} />

      <Layout
        className="site-layout"
        style={{ marginLeft: siteLayoutMarginLeft }}
      >
        {/* navbar */}
        <Navbar collapsed={collapsed} toggle={toggle} />

        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 12,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </AuthProvider>
  );
};
