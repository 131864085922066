import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Space, Spin, Switch, Table } from "antd";
import { Pagination } from "components/Pagination";
import useTreatment from "hooks/useTreatment";
import { useEffect, useRef } from "react";
import { getTitle } from "utils";
import { CityModal } from "./components/CityModal";
import { City } from "types/address";
import useCity from "hooks/useCity";
import { FaCity } from "react-icons/fa";
import { PreviewImage } from "components/PreviewImage/PreviewImage";

const { Column } = Table;

export const CityPage = ({ title = "" }) => {
  const {
    fetchData,
    query,
    data,
    loading,
    filterData,
    total,
    deleteData,
    toggleHighlight,
  } = useCity({
    initialQuery: {
      page: 1,
      limit: 20,
    },
  });
  const modalRef = useRef<CityModal>(null);

  useEffect(() => {
    document.title = getTitle(title);
    fetchData();
  }, []);
  console.log(data);
  return (
    <section className="box">
      <div className="filter-container">
        <Space>
          <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              size="middle"
              onChange={(ev) => {
                filterData({ search: ev.currentTarget.value, page: 1 });
              }}
              placeholder="Tìm kiếm"
            />
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => fetchData()}
              type="primary"
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </div>

          {/* <div className="filter-item btn">
            <Button
              onClick={() => {
                modalRef.current?.handleCreate();
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              Thêm mới
            </Button>
          </div> */}
        </Space>
      </div>

      <Spin spinning={loading}>
        <Table pagination={false} rowKey="id" dataSource={data}>
          <Column
            width={150}
            align="center"
            title="Hình"
            dataIndex="image"
            key="image"
            render={(text) => (
              <div className="flex text-center justify-center">
                {text ? (
                  <PreviewImage image={text} />
                ) : (
                  // <img
                  //   src={text}
                  //   className="object-cover inline-block"
                  //   width={100}
                  //   height={50}
                  //   alt=""
                  // />
                  <FaCity fontSize={28} />
                )}
              </div>
            )}
          />
          <Column
            title="Tên"
            dataIndex="name"
            width={300}
            key="name"
            render={(text, record: City) => {
              return (
                <span
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                  className="text-secondary cursor-pointer hover:underline font-medium"
                >
                  {text}
                </span>
              );
            }}
          />
          <Column
            width={150}
            title="Thứ tự hiển thị"
            dataIndex="priority"
            key="priority"
            render={(text, record: City) => text}
          />
          <Column
            title="Nổi bật"
            align="center"
            width={150}
            dataIndex="isHighlight"
            key="isHighlight"
            render={(text, record: City) => (
              <span>
                <Popconfirm
                  onConfirm={async () => {
                    await toggleHighlight(record.id, !record.isHighlight);
                  }}
                  title="Bạn có chắc chắn muốn thực hiện thao tác này?"
                >
                  <Switch
                    checked={record.isHighlight}
                    checkedChildren="Nổi bật"
                    unCheckedChildren="Nổi bật"
                  ></Switch>
                </Popconfirm>
                {/* <Tag color={text ? "green" : "red"}>
                    {text ? "Bật" : "Tắt"}
                  </Tag> */}
              </span>
            )}
          />

          <Column
            width={150}
            title="Hành động"
            key="action"
            render={(text, record: City) => (
              <Space>
                {/* <Popconfirm
                  title="Bạn có chắc chắn muốn thực hiện thao tác?"
                  onConfirm={async () => {
                    await deleteData(record.id);
                  }}
                >
                  <Button type="primary" danger>
                    Xóa
                  </Button>
                </Popconfirm> */}
                <Button
                  type="primary"
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                >
                  Cập nhật
                </Button>
              </Space>
            )}
          />
        </Table>

        <Pagination
          currentPage={query.page}
          total={total}
          pageSize={query.limit}
          onChange={({ limit, page }) => {
            filterData({ limit, page });
          }}
        />
      </Spin>

      <CityModal onSubmitOk={fetchData} onClose={() => {}} ref={modalRef} />
    </section>
  );
};
