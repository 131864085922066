import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const appointmentApi = {
  findOne: (id?: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/appointment/${id}`,
      // params
    }),
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/appointment",
      params,
    }),
  cancel: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/appointment/${id}/cancel`,
      method: "delete",
      data,
    }),
};
