import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { News } from "types/news";
import { newsApi } from "api/news.api";
import { message } from "antd";
interface NewsQuery extends QueryParam {
  tagIds?: number[];
  isVisible?: boolean;
}

type Props = {
  initialQuery: NewsQuery;
};

const useNews = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<News, NewsQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await newsApi.findAll(query).then(({ data }) => data);
      return {
        data: data.newses,
        total: data.total,
      };
    },
    deleteFunc: async (id) => {
      await newsApi.delete(id);
    },
    editFunc: async (id, data) => {
      await newsApi.update(id, data);
    },
    createFunc: async (data) => {
      await newsApi.create(data);
    },
  });

  const toggleHighlight = async (id: number, isHighlight?: boolean) => {
    await newsApi.update(id, { news: { isHighlight } });
    message.success("Thao tác thành công");
    fetch.fetchData();
  };
  const toggleVisible = async (id: number, isVisible?: boolean) => {
    await newsApi.update(id, { news: { isVisible } });
    message.success("Thao tác thành công");
    fetch.fetchData();
  };

  return { toggleHighlight, toggleVisible, ...fetch };
};

export default useNews;
