import { Locale } from "antd/es/locale";
import viVN from "antd/es/locale/vi_VN";

export const daysName = ["", "T.2", "T.3", "T.4", "T.5", "T.6", "T.7", "CN"];
export const viVNLocale: Locale = {
  ...viVN,
  Calendar: {
    ...viVN.Calendar,
    lang: {
      ...(viVN.Calendar?.lang ?? {}),
      placeholder: "Chọn thời gian",
      yearPlaceholder: "Chọn năm",
      quarterPlaceholder: "Chọn quý",
      monthPlaceholder: "Chọn tháng",
      weekPlaceholder: "Chọn tuần",
      rangePlaceholder: ["Ngày bắt đầu", "Ngày kết thúc"],
      rangeYearPlaceholder: ["Năm bắt đầu", "Năm kết thúc"],
      rangeQuarterPlaceholder: ["Quý bắt đầu", "Quý kết thúc"],
      rangeMonthPlaceholder: ["Tháng bắt đầu", "Tháng kết thúc"],
      rangeWeekPlaceholder: ["Tuần bắt đầu", "Tuần kết thúc"],
      shortWeekDays: ["CN", "Hai", "Ba", "Tư", "Năm", "Sáu", "Bảy"],
      shortMonths: [
        "Tháng 1",
        "Tháng 2",
        "Tháng 3",
        "Tháng 4",
        "Tháng 5",
        "Tháng 6",
        "Tháng 7",
        "Tháng 8",
        "Tháng 9",
        "Tháng 10",
        "Tháng 11",
        "Tháng 12",
      ],
    },
  },
  DatePicker: {
    ...viVN.DatePicker,
    // @ts-ignore
    lang: {
      ...(viVN.DatePicker?.lang ?? {}),
      placeholder: "Chọn thời điểm",
      yearPlaceholder: "Chọn năm",
      quarterPlaceholder: "Chọn quý",
      monthPlaceholder: "Chọn tháng",
      weekPlaceholder: "Chọn tuần",
      rangePlaceholder: ["Ngày bắt đầu", "Ngày kết thúc"],
      rangeYearPlaceholder: ["Năm bắt đầu", "Năm kết thúc"],
      rangeQuarterPlaceholder: ["Quý bắt đầu", "Quý kết thúc"],
      rangeMonthPlaceholder: ["Tháng bắt đầu", "Tháng kết thúc"],
      rangeWeekPlaceholder: ["Tuần bắt đầu", "Tuần kết thúc"],
      shortWeekDays: ["CN", "Hai", "Ba", "Tư", "Năm", "Sáu", "Bảy"],
      shortMonths: [
        "Th1",
        "Th2",
        "Th3",
        "Th4",
        "Th5",
        "Th6",
        "Th7",
        "Th8",
        "Th9",
        "Th10",
        "Th11",
        "Th12",
      ],
    },
  },
};
