import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const leadApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/lead",
      params,
    }),
  resolve: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/lead/${id}/resolve`,
      method: "patch",
      data,
    }),
  delete: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/lead/${id}`,
      method: "delete",
    }),
    summary: (params?: any): AxiosPromise<any> =>
      request({
        url: "/v1/admin/lead/summary/status",
        params,
      }),
      countNew: (params?: any): AxiosPromise<any> =>
        request({
          url: "/v1/admin/lead/count/new",
          params,
        }),
};
