import {
  Avatar,
  Col,
  Form,
  Image,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Tabs,
} from "antd";
import { DatePicker } from "antd";
import TextArea from "antd/es/input/TextArea";
import { RadioChangeEvent } from "antd/lib";
import { customerApi } from "api/customer.api";
import { recordSaleApi } from "api/recordSale.api";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import dayjs from "dayjs";
import moment from "moment";
import React, { useImperativeHandle, useState } from "react";
import { Customer, GenderType, genderTypeObject } from "types/customer";
import { ModalStatus } from "types/modal";
import {
  RecordSale,
  RecordSalePaymentType,
  RecordSalePaymentTypeTrans,
} from "types/recordSale";
import { formatVND } from "utils";
import { disableFuture } from "utils/date-picker";
import { getCustomerAddress, getMerchantAddress } from "utils/url";
import { emailRule, requiredRule } from "utils/validate";
import { CustomerTransactionPage } from "views/CustomerTransaction/CustomerTransaction";

export interface IDebtModal {
  handleCreate: () => void;
  handleOpen: (recordSale: RecordSale) => void;
}
interface DebtModalProps {
  onClose: () => void;
  onSubmitOk: () => void;
}

export const DebtModal = React.forwardRef(
  ({ onClose, onSubmitOk }: DebtModalProps, ref) => {
    const [form] = Form.useForm<Customer>();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<ModalStatus>("create");
    const [recordSaleSelected, setRecordSaleSelected] = useState<RecordSale>();

    const [recordSalePaymentTypeSelected, setRecordSalePaymentTypeSelected] =
      useState<RecordSalePaymentType>(RecordSalePaymentType.Point);

    const onChange = (e: RadioChangeEvent) => {
      console.log("radio checked", e.target.value);
      setRecordSalePaymentTypeSelected(e.target.value);
    };

    useImperativeHandle<any, IDebtModal>(
      ref,
      () => ({
        handleCreate() {
          form.resetFields();
          setVisible(true);
          setStatus("create");
        },
        handleOpen(recordSale: RecordSale) {
          setRecordSaleSelected(recordSale);
          setVisible(true);
          setStatus("update");
        },
      }),
      []
    );

    const getFormData = () => {
      const data = form.getFieldsValue();
      return data;
    };

    const onSubmit = async () => {
      await form.validateFields();
      const data = getFormData();
      setLoading(true);
      try {
        const payload = {
          ...data,
        };
        await recordSaleApi.debt(recordSaleSelected?.id || 0, payload);
        message.success("Đánh dấu ghi nợ thành công!");
        onSubmitOk();
        form.resetFields();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    console.log(recordSaleSelected);

    return (
      <Modal
        onCancel={() => {
          form.resetFields();
          setVisible(false);
        }}
        visible={visible}
        title={
          <h1 className="text-secondary text-lg font-bold">
            {status === "create" ? "" : `Đánh dấu là ghi nợ`}
          </h1>
        }
        style={{ top: 20 }}
        width={500}
        confirmLoading={loading}
        destroyOnClose
        onOk={onSubmit}
        okText="Xác nhận"
      >
        <div>
          <div className="mb-10">
            <div className="text-center">
              <Image
                src={recordSaleSelected?.merchant.avatar}
                className="!w-[100px] !h-[100px] rounded-full object-contain shadow-md"
              ></Image>
              <h1 className="font-medium text-lg text-secondary">
                {recordSaleSelected?.merchant.name}
              </h1>
            </div>
          </div>
        </div>

        <Form layout="vertical" form={form}>
          <Form.Item label="Ghi chú nợ" name="debtNote" rules={[requiredRule]}>
            <TextArea placeholder="Nhập vào ghi chú nợ" />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);
