import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const paymentOrderApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/paymentOrder",
      params,
    }),
  //   create: (data:any):AxiosPromise<any> => request({
  //     url: '/v1/merchant/paymentOrder',
  //     data,
  //     method: 'post'
  //   }),
  //   update: (id:number, data:any):AxiosPromise<any> => request({
  //     url: `/v1/merchant/paymentOrder/${id}`,
  //     method: 'patch',
  //     data
  //   }),
  //   delete: (id:number):AxiosPromise<any> => request({
  //     url: `/v1/merchant/paymentOrder/${id}`,
  //     method: 'delete'
  //   }),
};
