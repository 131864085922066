import {
  Avatar,
  Button,
  Menu,
  message,
  Popconfirm,
  Popover,
  Space,
  Table,
  Tag,
} from "antd";
import Column from "antd/lib/table/Column";
import React, { useRef, useState } from "react";
import { formattedFullDate, unixToFullTime } from "utils/formatDate";
import {
  Appointment,
  AppointmentDetail,
  AppointmentStatus,
  AppointmentStatusTrans,
} from "types/appointment";
import { formatVND } from "utils/formatVND";
import { CustomerProfile } from "types/customerProfile";
import settings from "settings";
import { secondToMinuteString } from "utils/generateDuration";
import { DeleteOutlined, DownOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

interface PropsType {
  dataSource: Appointment[];
  loading: boolean;
  refreshData: () => void;
  onDelete?: (employeeId: number) => void;
  onSelect?: (appointment: Appointment) => void;
  onCancel?: (appointmentId: number, resson: string) => void;
  onViewCustomer?: (customer: CustomerProfile) => void;
  fetchAppointment: () => void;
  selectedMerchantLocationId?: number | null;
}
export const AppointmentList = ({
  dataSource,
  loading,
  onSelect,
  onCancel,
}: PropsType) => {
  const [reason, setReason] = useState("");
  return (
    <div>
      <Table
        sticky
        dataSource={dataSource}
        pagination={false}
        loading={loading}
        size="small"
        scroll={{ x: "max-content", y: "calc(100vh - 280px)" }}
      >
        <Column
          align="center"
          width={100}
          title="Mã"
          key="point"
          render={(text, record: Appointment) => (
            <div className="flex flex-col gap-1">
              <span className="font-semibold text-primary cursor-pointer">
                #{record?.code}
              </span>
            </div>
          )}
        />
        <Column
          width={200}
          title="Tên khách hàng"
          key="name"
          render={(text, record: Appointment) => (
            <div className="flex gap-3 items-center">
              <Avatar
                className="!w-[40px] !h-[40px]"
                src={record.customerProfile?.avatar || settings.avatarDefault}
              />
              <span className="text-sm font-semibold cursor-pointer hover:underline">
                {record?.customerProfile?.fullName || "Khách hàng ẩn danh"}
              </span>
            </div>
          )}
        />

        <Column
          align="center"
          width={120}
          title="Số điện thoại"
          key="point"
          render={(text, record: Appointment) => (
            <div className="flex flex-col gap-1">
              <span className="font-medium">
                {record?.customerProfile?.contactPhone}
              </span>
            </div>
          )}
        />

        <Column
          width={200}
          title="Dịch vụ"
          key="point"
          render={(text, record: Appointment) => (
            <div>
              {record.appointmentDetails
                .slice(0, 1)
                .map((item: AppointmentDetail, index: number) => (
                  <div key={index}>
                    <span> {item?.service.name}</span>
                  </div>
                ))}

              {record.appointmentDetails.length > 1 && (
                <Popover
                  content={
                    <div className="list flex flex-col gap-3 min-w-[300px]">
                      {record?.appointmentDetails.map((detail) => (
                        <div className="item">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span
                              // style={{  }}
                              className="service-name font-semibold max-w-[200px]"
                            >
                              {/* {detail?.serviceOption?.pricingName ||
                                  detail.service?.name} */}
                            </span>
                            <span style={{ fontWeight: 500, fontSize: 14 }}>
                              {/* {detail.servicePackOrderDetail
                                  ? "Thanh toán gói"
                                  : `${formatVND(detail.price)}`} */}
                            </span>
                          </div>
                          <div>
                            {secondToMinuteString(detail.service.duration)}
                            {detail?.employee?.name
                              ? ` bởi ${detail?.employee?.name}`
                              : ""}
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                  title={
                    <h1 className="text-lg font-semibold">Danh sách dịch vụ</h1>
                  }
                  trigger="hover"
                >
                  <span
                    className="text-primary cursor-pointer"
                    style={{ marginBottom: 0 }}
                  >
                    +{record.appointmentDetails.length - 1} dịch vụ khác
                    {/* Trỏ để xem */}
                  </span>
                </Popover>
              )}
            </div>
          )}
        />
        <Column
          align="center"
          width={170}
          title="Cửa hàng"
          key="point"
          render={(text, record: Appointment) => (
            <>
              <div className="flex flex-col gap-1">
                <span className="font-medium">{record?.merchant?.name}</span>
              </div>
            </>
          )}
        />
        <Column
          align="center"
          width={170}
          title="Chi nhánh"
          key="point"
          render={(text, record: Appointment) => (
            <>
              <div className="flex flex-col gap-1">
                <span className="font-medium">
                  {record?.merchantLocation?.name}
                </span>
              </div>
            </>
          )}
        />
        <Column
          align="right"
          width={150}
          title="Tổng giá (VNĐ)"
          key="point"
          render={(text, record: Appointment) => {
            const hasSingleServiceOption = record.appointmentDetails.find(
              (item) => !item.servicePackOrderDetail
            );
            return (
              <>
                <div className="flex flex-col gap-1">
                  <span className="font-medium">
                    {!hasSingleServiceOption
                      ? "Thanh toán gói"
                      : `${formatVND(record?.moneyTotal)}`}
                  </span>
                </div>
              </>
            );
          }}
        />
        <Column
          align="center"
          width={120}
          title="Trạng thái"
          key="point"
          render={(text, record: Appointment) => (
            <div className="flex flex-col gap-1">
              <span className="font-medium">
                {record?.isConfirmed &&
                record?.status == AppointmentStatus.New ? (
                  <Tag color={"orange"}>Đã xác nhận</Tag>
                ) : (
                  <Tag color={AppointmentStatusTrans[record.status].color}>
                    {AppointmentStatusTrans[record.status].label}
                  </Tag>
                )}
              </span>
            </div>
          )}
        />
        <Column
          align="center"
          width={130}
          title="Lí do hủy lịch"
          key="point"
          render={(text, record: Appointment) => (
            <>
              <div className="flex flex-col gap-1">
                <span className="font-medium">{record?.reasonCancel}</span>
              </div>
            </>
          )}
        />
        <Column
          align="center"
          width={140}
          title="Ngày tạo"
          key="point"
          render={(text, record: Appointment) => (
            <>
              <div className="flex flex-col gap-1">
                <span className="font-medium">
                  {unixToFullTime(record?.createdAt)}
                </span>
              </div>
            </>
          )}
        />
        <Column
          fixed="right"
          width={100}
          title="Thao tác"
          render={(text, record: Appointment) => (
            <Space>
              <Popconfirm
                placement="top"
                // title={`Bạn xác nhận muốn hủy lịch đặt?`}
                title={
                  <div>
                    <h1 className="text-sm font-semibold m-0 mb-2">
                      Bạn xác nhận muốn hủy lịch đặt?
                    </h1>
                    <TextArea
                      placeholder="Nhập vào lý do hủy lịch"
                      rows={4}
                      onChange={(e) => setReason(e.target.value)}
                      className="!rounded-lg"
                    />
                  </div>
                }
                onConfirm={() => {
                  if (reason) {
                    onCancel?.(record.id, reason);
                  } else {
                    message.error("vui lòng nhập lý do hủy lịch đặt");
                  }
                }}
                okText="Xác nhận"
                cancelText="Đóng"
                overlayClassName="custom-popconfirm"
                showArrow={false}
              >
                {record?.status == AppointmentStatus.Completed ||
                record?.status == AppointmentStatus.Cancel ? (
                  <></>
                ) : (
                  <Button style={{ borderRadius: 9999 }} type="primary" danger>
                    <Space>Hủy lịch đặt</Space>
                  </Button>
                )}
              </Popconfirm>
            </Space>
          )}
        ></Column>
        {onSelect && (
          <Column
            fixed="right"
            align="center"
            width={140}
            title="Thao tác"
            key="action"
            render={(text, record: Appointment) => (
              <>
                <Button onClick={() => onSelect(record)} type="primary">
                  Chọn
                </Button>
              </>
            )}
          />
        )}
      </Table>
    </div>
  );
};
