import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Image,
  Input,
  Popconfirm,
  Popover,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import { Pagination } from "components/Pagination";
import TagSelector from "components/Selector/TagSelector";
import useNews from "hooks/useNews";
import moment from "moment";
import { useEffect, useState } from "react";
import { News } from "types/news";
import { QueryObject } from "types/query";
import { getTitle } from "utils";
import { NewsModal } from "./components/NewsModal";
import { PreviewImage } from "components/PreviewImage/PreviewImage";
import { ModalStatus } from "types/modal";

const { Column } = Table;
const { Option } = Select;
export const NewsPage = ({ title = "", description="" }) => {
  const [newSelected, setNewSelected] = useState<News>();
  const [visibleNewsModal, setVisibleNewsModal] = useState(false);
  const [status, setStatus] = useState<ModalStatus>("create");
  const {
    fetchData,
    filterData,
    data,
    loading,
    query,
    deleteData,
    toggleHighlight,
    toggleVisible,
    total,
  } = useNews({
    initialQuery: {
      page: 1,
      limit: 20,
    },
  });

  const handleTableChange = (
    newPagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    let queryObject: QueryObject[] = [];
    console.log(sorter);
    if (sorter.order) {
      queryObject.push({
        type: "sort",
        field: `${sorter.columnKey}`,
        value: sorter.order === "ascend" ? "ASC" : "DESC",
      });
    }

    query.queryObject = JSON.stringify(queryObject);
    fetchData();
  };

  useEffect(() => {
    document.title = getTitle(title);
    fetchData();
  }, []);

  const handleViewNews = (news: News) => {
    setNewSelected(news);
    setVisibleNewsModal(true);
    setStatus("update");
  };

  return (
    <div>
      <section className="box">
      <div className="flex flex-col gap-1 mb-5">
        <h1 className="text-xl m-0 ">{title}</h1>
        <span className="font-medium">{description}</span>
      </div>
        <div className="filter-container">
          <Space wrap>
            <div className="filter-item">
              <label htmlFor="">Tìm kiếm</label>
              <br />
              <Input
                size="middle"
                onChange={(ev) => {
                  filterData({
                    search: ev.currentTarget.value,
                    page: 1,
                  });
                }}
                placeholder="Tìm kiếm theo tiêu đề"
              />
            </div>
            <div className="filter-item">
              <label htmlFor="">Tag</label>
              <br />
              <TagSelector
                onChange={(value) => {
                  filterData({ tagIds: value });
                }}
                mode="multiple"
                style={{
                  minWidth: 200,
                }}
                placeholder={"Chọn tag"}
              ></TagSelector>
            </div>
            <div className="filter-item">
              <label htmlFor="">Trạng thái</label>
              <div>
                <Select
                  placeholder="Chọn trạng thái"
                  allowClear
                  className="!h-auto"
                  onChange={(val) => {
                    filterData({ isVisible: val, page: 1 });
                  }}
                  style={{ width: 150 }}
                >
                  <Option value={true}>Hiển thị</Option>
                  <Option value={false}>Ẩn</Option>
                </Select>
              </div>
            </div>

            <div className="filter-item btn">
              <Button
                onClick={() => fetchData(query)}
                type="primary"
                icon={<SearchOutlined />}
              >
                Tìm kiếm
              </Button>
            </div>

            <div className="filter-item btn">
              <Button
                onClick={() => {
                  setNewSelected(undefined);
                  setVisibleNewsModal(true);
                  setStatus("create");
                }}
                type="primary"
                icon={<PlusOutlined />}
              >
                Thêm mới
              </Button>
            </div>
          </Space>
        </div>

        <Spin spinning={loading}>
          <Table
            onChange={handleTableChange}
            pagination={false}
            rowKey="id"
            scroll={{ x: "max-content" }}
            dataSource={data}
          >
            <Column
              title="Tiêu đề"
              dataIndex="title"
              width={350}
              key="title"
              render={(text, record: News) => (
                <div className="flex items-center gap-2 cursor-pointer">
                  <Image
                    className="!w-[60px]  flex-shrink-0 object-cover max-h-[30px]"
                    src={record.thumbnail}
                    alt=""
                  />
                  <Typography.Paragraph
                    onClick={() => {
                      handleViewNews(record);
                    }}
                    className="text-secondary flex-1 font-medium hover:underline"
                    title={text}
                    ellipsis={{ rows: 2 }}
                  >
                    {text}
                  </Typography.Paragraph>
                </div>
              )}
            />

            <Column
              title="Nội dung"
              width={200}
              dataIndex="shortContent"
              key="shortContent"
              render={(text, record: News) => (
                <Popover
                  trigger={"hover"}
                  title="Nội dung "
                  content={
                    <Typography.Paragraph style={{ width: 700 }}>
                      {text}
                    </Typography.Paragraph>
                  }
                >
                  <Typography.Paragraph
                    ellipsis={{ expandable: false, rows: 2 }}
                    style={{ width: 300, marginBottom: 0 }}
                  >
                    {text}
                  </Typography.Paragraph>
                </Popover>
              )}
            />
            <Column
              title="Tag"
              dataIndex="tags"
              align="center"
              width={150}
              key="tags"
              render={(text, record: News) => (
                <Space size={2} wrap className="max-h-[100px] overflow-y-auto">
                  {record.tags.map((tag) => (
                    <Tag color="blue" key={tag.id}>
                      {tag.name}
                    </Tag>
                  ))}
                </Space>
              )}
            />
            <Column
              title="Nổi bật"
              align="center"
              dataIndex="isHighlight"
              key="isHighlight"
              render={(text, record: News) => (
                <span>
                  <Popconfirm
                    onConfirm={async () => {
                      await toggleHighlight(record.id, !record.isHighlight);
                    }}
                    title="Bạn có chắc chắn muốn thực hiện thao tác này?"
                  >
                    <Switch
                      checked={record.isHighlight}
                      checkedChildren="Nổi bật"
                      unCheckedChildren="Nổi bật"
                    ></Switch>
                  </Popconfirm>
                  {/* <Tag color={text ? "green" : "red"}>
                    {text ? "Bật" : "Tắt"}
                  </Tag> */}
                </span>
              )}
            />
            <Column
              width={150}
              title="Trạng thái"
              dataIndex="isVisible"
              key="isVisible"
              render={(text, record: News) => (
                <span style={{ color: "black" }}>
                  <Popconfirm
                    onConfirm={async () => {
                      await toggleVisible(record.id, !record.isVisible);
                    }}
                    title="Bạn có chắc chắn muốn thực hiện thao tác này?"
                  >
                    <Switch
                      checked={record.isVisible}
                      // style={{ backgroundColor: "#7bed9f", color: "black" }}
                      className="new-visible-btn"
                      checkedChildren="Hiển thị"
                      unCheckedChildren="Ẩn"
                    ></Switch>
                  </Popconfirm>
                  {/* <Tag color={text ? "green" : "red"}>
                    {text ? "Hiển thị" : "Bị ẩn"}
                  </Tag> */}
                </span>
              )}
            />
            <Column
              width={150}
              title="Thời gian tạo"
              dataIndex="createdAt"
              sorter
              key="news.createdAt"
              render={(text, record: News) =>
                moment.unix(text).format("DD/MM/YYYY")
              }
            />

            <Column
              width={200}
              align="center"
              title="Thao tác"
              key="action"
              render={(text, record: News) => (
                <span>
                  <Space>
                    <Button
                      type="primary"
                      onClick={() => {
                        handleViewNews(record);
                      }}
                    >
                      Cập nhật
                    </Button>
                    <Popconfirm
                      placement="topLeft"
                      title={`Xác nhận xóa tin tức này?`}
                      onConfirm={async () => {
                        await deleteData(record.id);
                        message.success("Thao tác thành công");
                      }}
                      okText="Ok"
                      cancelText="Không"
                    >
                      <Button danger>Xóa</Button>
                    </Popconfirm>
                  </Space>
                </span>
              )}
            />
          </Table>

          <Pagination
            pageSize={query.limit}
            currentPage={query.page}
            total={total}
            onChange={({ page, limit }) => {
              filterData({ page, limit });
            }}
          />
        </Spin>
      </section>

      <NewsModal
        visible={visibleNewsModal}
        news={newSelected}
        onSubmitOk={fetchData}
        onClose={function (): void {
          setNewSelected(undefined);
          setVisibleNewsModal(false);
        }}
        status={status}
      />
    </div>
  );
};
