import { AppLoading } from "components/App/AppLoading";
import { useRouter } from "hooks/useRouter";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRoutes } from "router";
import { permissionStore } from "store/permissionStore";
import { userStore } from "store/userStore";
import { getToken } from "utils/auth";

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigation = useNavigate();
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  useRouter(isLoaded);

  useEffect(() => {
    fetchPermission();
    return () => {};
  }, []);

  const fetchPermission = async () => {
    const token = getToken();
    const path = location.pathname;
    const searchParam = window.location.search;
    if (!token) {
      navigation(`/login?redirect=${path}${searchParam}`);
      return;
    }

    try {
      await userStore.getProfile();

      permissionStore.accessRoutes = [...adminRoutes];

      if (userStore.info.role) {
        await permissionStore.fetchPermissions(userStore.info.role.id);
        await permissionStore.setAccessRoutes();
      } else {
      }
    } catch (error) {
      userStore.logout();
      navigation("/login");
    } finally {
      setIsLoaded(true);
    }
  };

  if (!isLoaded) {
    return <AppLoading />;
  }

  return <>{children}</>;
};
