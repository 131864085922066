import "styles/AntDesign.scss";
import "styles/App.scss";
// import "antd/dist/antd.less";
import { ConfigProvider } from "antd";
import { useRoutes } from "react-router-dom";
import { routes } from "router";
import "./styles/menu.scss";
import "utils/custom";

import { viVNLocale } from "utils/locale";
import { useEffect } from "react";
import { userStore } from "store/userStore";
import settings from "settings";
import { useOneSignalContext } from "context/OneSignalContext";
import { conversationStore } from "store/conversationStore";
import { observer } from "mobx-react";
import { debounce } from "lodash";

function App() {
  const element = useRoutes(routes);

  const { runOneSignal } = useOneSignalContext();

  // const [isLoaded, setIsLoaded] = useState(false);

  // useEffect(() => {
  //   handleInit();
  // }, []);

  // const handleInit = async () => {
  //   try {
  //     if (userStore.token) {
  //       await userStore.getProfile();
  //     }
  //   } catch (error) {
  //     userStore.logout();
  //   } finally {
  //     setIsLoaded(true);
  //   }
  // };
  useEffect(() => {
    let intervalId: any = 0;
    if (userStore.isLoggedIn) {
      if (!settings.isDev) {
        runOneSignal?.();
      }
      conversationStore.countNewConversation();
      intervalId = setInterval(() => {
        conversationStore.countNewConversation();
      }, 60000);
    }
    return () => clearInterval(intervalId);
  }, [userStore.isLoggedIn]);

  return (
    <ConfigProvider
      theme={{
        components: {},
        token: {
          colorPrimary: "#abca74",
        },
      }}
      locale={viVNLocale}
    >
      <div className="App">{element}</div>
    </ConfigProvider>
  );
}

export default observer(App);
