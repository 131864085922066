import { Lead } from "./lead";

export enum DictionaryType {
  LeadTopic = "LEAD_TOPIC",
  ReviewSample = "REVIEW_SAMPLE", //Mẫu nội dung review
  SearchContent = "SEARCH_CONTENT", //nội dung tìm kiểm ở trang home (client)
}

export const DictionaryTypeTrans = {
  [DictionaryType.LeadTopic]: "Chủ đề liên lạc",
  [DictionaryType.ReviewSample]: "Mẫu nội dung review",
  [DictionaryType.SearchContent]: "Nội dung tìm kiểm ở trang home (client)",
};

export interface Dictionary {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  name: string;
  type: DictionaryType;
  leads: Lead[];
}
