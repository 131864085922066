import { employeeApi } from "api/employee.api";
import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { Employee } from "types/employee";
interface EmployeeQuery extends QueryParam {
  merchantId?: number;
}
type Props = {
  initialQuery: EmployeeQuery;
};

const useEmployee = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<Employee, EmployeeQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await employeeApi
        .findAllEmployee(query)
        .then(({ data }) => data);
      return {
        data: data.employees,
        total: data.total,
      };
    },
    deleteFunc: async (id) => {
      await employeeApi.delete(id);
    },
    editFunc: async (id, data) => {
      await employeeApi.updateEmployee(id, data);
    },
    createFunc: async (data) => {
      await employeeApi.createEmployee(data);
    },
  });

  return { ...fetch };
};

export default useEmployee;
