import { News } from "./news";

export interface Tag {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  name: string;
  type: TagType;
  newses: News[];
}

export enum TagType {
  News = "NEWS",
}
export const tagTypeObject = {
  [TagType.News]: {
    value: TagType.News,
    label: "Tin tức",
  },
};
