import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { Merchant, MerchantStatus } from "types/merchant";
import { merchantApi } from "api/merchant.api";
import { message } from "antd";
export interface MerchantQuery extends QueryParam {
  isExpired?: boolean;
  status?: MerchantStatus;
}
type Props = {
  initialQuery: MerchantQuery;
};

const useMerchant = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<Merchant, MerchantQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await merchantApi.findAll(query).then(({ data }) => data);
      return {
        data: data.merchants,
        total: data.total,
      };
    },
    deleteFunc: async (id) => {
      await merchantApi.delete(id);
    },
    editFunc: async (id, data) => {
      await merchantApi.update(id, data);
    },
    createFunc: async (data) => {
      await merchantApi.create(data);
    },
  });

  const toggleBlock = async (id: number, isBlock?: boolean) => {
    await fetch.editData(id, { merchant: { isBlock } });
    fetch.fetchData();
    message.success("Thao tác thành công");
  };
  const toggleVisible = async (id: number, isVisible?: boolean) => {
    await fetch.editData(id, { merchant: { isVisible } });
    fetch.fetchData();
    message.success("Thao tác thành công");
  };

  return { toggleBlock, toggleVisible, ...fetch };
};

export default useMerchant;
