import { InputNumber } from "antd";
import { InputNumberProps } from "antd/lib";
import React from "react";

export const FormInputNumber = (props: InputNumberProps) => {
  return (
    <InputNumber
      {...props}
      className="custom-input-number"
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    />
  );
};
