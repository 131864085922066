import { SearchOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Input,
  Popconfirm,
  Space,
  Spin,
  Table,
  Image,
} from "antd";
import { Pagination } from "components/Pagination";
import useCustomer from "hooks/useCustomer";
import { useEffect, useRef } from "react";
import { Customer } from "types/customer";
import { formatVND, getTitle } from "utils";
import { CustomerModal } from "./components/CustomerModal";
import { PreviewImage } from "components/PreviewImage/PreviewImage";
import { unixToDate } from "utils/dateFormat";
import CustomerExportExcel from "./components/CustomerExportExcel";

const { Column } = Table;

export const CustomerPage = ({ title = "", description="" }) => {
  const { fetchData, query, data, loading, filterData, total, deleteData } =
    useCustomer({
      initialQuery: {
        page: 1,
        limit: 20,
      },
    });
  const modalRef = useRef<CustomerModal>(null);

  useEffect(() => {
    document.title = getTitle(title);
    fetchData();
  }, []);

  return (
    <section className="box">
      <div className="flex flex-col gap-1 mb-5">
        <h1 className="text-xl m-0 ">{title}</h1>
        <span className="font-medium">{description}</span>
      </div>
      <div className="filter-container">
        <Space>
          <div className="filter-item btn">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              size="middle"
              onChange={(ev) => {
                filterData({ search: ev.currentTarget.value, page: 1 });
              }}
              placeholder="Tìm kiếm theo tên, số điện thoại"
            />
          </div>

          <div className="filter-item btn ">
            <Button
              onClick={() => fetchData()}
              type="primary"
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </div>
          <div className="filter-item btn ">
            <CustomerExportExcel customers={data} queryCustomer={query} />
          </div>
        </Space>
      </div>

      <Spin spinning={loading}>
        <Table
          pagination={false}
          rowKey="id"
          dataSource={data}
          // size={"small"}

          scroll={{
            x: "max-content",
          }}
        >
          <Column
            fixed
            width={250}
            title="Tên khách hàng"
            dataIndex="fullName"
            key="fullName"
            render={(text, record: Customer) => (
              <div className="flex items-center gap-3">
                <Image
                  src={record.avatar || "/logo.png"}
                  preview={false}
                  className="rounded-full shadow-md !w-[40px] !h-[40px] object-cover"
                />
                <span
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                  className="text-secondary font-medium cursor-pointer hover:underline"
                >
                  {text}
                </span>
              </div>
            )}
          />

          <Column
            width={200}
            title="Thứ hạng"
            dataIndex="createdAt"
            key="createdAt"
            render={(text, record: Customer) => (
              <span>{record?.rank?.name || "Thành viên mới"}</span>
            )}
          ></Column>
          <Column
            align="center"
            width={200}
            title="Ngày tham gia/đăng ký"
            dataIndex="createdAt"
            key="createdAt"
            render={(text, record: Customer) => (
              <span>{unixToDate(record.createdAt)}</span>
            )}
          ></Column>
          <Column
            width={120}
            title="Số điện thoại"
            dataIndex="phone"
            key="phone"
          />

          <Column width={120} title="Email" dataIndex="email" key="email" />

          <Column
            width={120}
            align="center"
            title="Điểm"
            dataIndex="point"
            key="point"
            render={(text) => (
              <span className="text-secondary font-medium">
                {formatVND(text)}
              </span>
            )}
          />
          <Column
            fixed="right"
            width={100}
            align="center"
            title="Hành động"
            key="action"
            render={(text, record: Customer) => (
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                  // size="small"
                >
                  Cập nhật
                </Button>
                <Popconfirm
                  title="Bạn có chắc chắn muốn thực hiện thao tác?"
                  onConfirm={async () => {
                    await deleteData(record.id);
                  }}
                >
                  <Button type="primary" danger>
                    Xóa
                  </Button>
                </Popconfirm>
              </Space>
            )}
          />
        </Table>

        <Pagination
          currentPage={query.page}
          total={total}
          pageSize={query.limit}
          onChange={({ limit, page }) => {
            filterData({ limit, page });
          }}
        />
      </Spin>

      <CustomerModal onSubmitOk={fetchData} onClose={() => {}} ref={modalRef} />
    </section>
  );
};
