import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { Faq } from "types/faq";
import { faqApi } from "api/faq.api";
interface FaqQuery extends QueryParam {
  FaqTopicId?: number;
}
type Props = {
  initialQuery: FaqQuery;
};

const useFaq = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<Faq, FaqQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await faqApi.findAll(query).then(({ data }) => data);
      return {
        data: data.faqs,
        total: data.total,
      };
    },
    editFunc: async (id, data) => {
      await faqApi.update(id, data);
    },
    deleteFunc: async (id) => {
      await faqApi.delete(id);
    },
  });
  const {
    query: queryFaq,
    fetchData: fetchFaq,
    total: totalFaq,
    loading: isFetchFaqLoading,
    data: faqs,
    deleteData: deleteFaq,
    editData: resolveFaq,
    filterData: filterFaq,
  } = fetch;

  return {
    fetchFaq,
    totalFaq,
    queryFaq,
    isFetchFaqLoading,
    faqs,
    deleteFaq,
    resolveFaq,
    filterFaq,
  };
};

export default useFaq;
