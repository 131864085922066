Array.prototype.findLast = function (cb) {
  let find;
  for (let index = this.length - 1; index >= 0; index--) {
    const finded = cb(this[index]);
    if (finded) {
      find = this[index];
      break;
    }
  }
  return find;
};
