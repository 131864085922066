import { appointmentApi } from "api/appointment.api";
import { useRef, useState } from "react";
import { Appointment, AppointmentStatus } from "types/appointment";
import { QueryParam } from "types/query";

export interface AppointmentQuery extends QueryParam {
  status?: AppointmentStatus;
  isConfirmed?: boolean;
  from?: number;
  to?: number;
  employeeId?: number;
}
export interface QueryParams {
    page?: number;
    limit?: number;
    search?: string;
    [params: string]: any;
  }

interface AppointmentProps {
  initQuery: AppointmentQuery;
}

export const useFetchAppointment = ({ initQuery }: AppointmentProps) => {
  const [data, setData] = useState<Appointment[]>([]);
  const query = useRef<AppointmentQuery>(initQuery);
  const [loading, setLoading] = useState<boolean>(false);

  const [total, setTotal] = useState(0);

  const fetchAppointment = async (newQuery?: AppointmentQuery) => {
    try {
      setLoading(true);
      const { data } = await appointmentApi.findAll({
        ...query.current,
        ...newQuery,
      });
      setTotal(data.total);
      setData(data.appointments);
      setLoading(false);
    } catch (error) {}
  };

  return {
    appointments: data,
    totalAppointments: total,
    fetchAppointment,
    queryAppointments: query.current,
    loadingAppointments: loading,
  };
};
