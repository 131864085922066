import { Col, Form, Input, message, Modal, Row, Select } from "antd";
import { Rule } from "antd/lib/form";
import { tagApi } from "api/tag.api";
import React, { useImperativeHandle, useState } from "react";
import { ModalStatus } from "types/modal";
import { Tag, tagTypeObject } from "types/tag";
import { requiredRule } from "utils/validate";

export interface TagModal {
  handleCreate: () => void;
  handleUpdate: (Tag: Tag) => void;
}
interface TagModalProps {
  onClose: () => void;
  onSubmitOk: () => void;
}

export const TagModal = React.forwardRef(
  ({ onClose, onSubmitOk }: TagModalProps, ref) => {
    const [form] = Form.useForm<Tag>();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<ModalStatus>("create");

    useImperativeHandle<any, TagModal>(
      ref,
      () => ({
        handleCreate() {
          form.resetFields();
          setVisible(true);
          setStatus("create");
        },
        handleUpdate(Tag: Tag) {
          form.setFieldsValue({ ...Tag });
          setVisible(true);
          setStatus("update");
        },
      }),
      []
    );

    const getFormData = () => {
      const { id, ...data } = form.getFieldsValue();
      return {
        id,
        tag: data,
      };
    };

    const onSubmit = async () => {
      await form.validateFields();
      setLoading(true);
      try {
        const { id, ...tag } = getFormData();
        if (id) {
          await tagApi.update(id || 0, tag);
        } else {
          await tagApi.create(tag);
        }
        message.success("Thao tác thành công!");
        onClose();
        onSubmitOk();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        onCancel={() => {
          form.resetFields();
          onClose?.();
          setVisible(false);
        }}
        visible={visible}
        title={status == "create" ? "Tạo dữ liệu" : "Chỉnh sửa dữ liệu"}
        style={{ top: 20 }}
        width={700}
        confirmLoading={loading}
        onOk={onSubmit}
      >
        <Form layout="vertical" form={form}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Tên" name="name" rules={[requiredRule]}>
                <Input placeholder="Nhập tên" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Loại" name="type" rules={[requiredRule]}>
                <Select
                  options={Object.values(tagTypeObject)}
                  placeholder="Chọn loại thẻ"
                ></Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
);
