import { Badge } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { conversationStore } from "store/conversationStore";

interface IProps {
  title: string;
}

export const ConversationBadge = observer(({ title }: IProps) => {
  return (
    <div className="flex justify-between items-center !w-full">
      <span>{title}</span>
      <Badge
        count={conversationStore.totalNewConversation}
        style={{ boxShadow: "none", marginLeft: 20 }}
      />
    </div>
  );
});
