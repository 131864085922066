import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Popconfirm,
  Space,
  Table,
  Image,
  Tag,
  message,
  Select,
  DatePicker,
} from "antd";
import { Pagination } from "components/Pagination";
import { useEffect, useState } from "react";
import { formatVND, getTitle } from "utils";
import useWithdraw from "hooks/useWithdraw";
import { Withdraw, WithdrawStatus, WithdrawStatusTrans } from "types/withdraw";
import { unixToFullDate } from "utils/dateFormat";
import { withdrawApi } from "api/withdraw.api";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import settings from "settings";
import dayjs from "dayjs";
import WithdrawExportExcel from "./components/WithdrawExportExcel";
import { debounce } from "lodash";
import useMerchant from "hooks/useMerchant";

const { Column } = Table;

interface IProps {
  merchantId?: number;
  title?: string;
  description?: string;
}

export const WithdrawPage = ({
  title = "",
  description = "",
  merchantId,
}: IProps) => {
  const {
    fetchData: fetchWithdraw,
    query: queryWithdraw,
    data: withdraws,
    loading: loadingWithdraw,
    total: totalWithdraw,
  } = useWithdraw({
    initialQuery: {
      page: 1,
      limit: 10,
    },
  });
  const [note, setNote] = useState<string>();
  const [noteAccept, setNoteAccept] = useState<string>();

  const [loadingConfirmWithdraw, setLoadingConfirmWithdraw] = useState(false);
  const [loadingCancelWithdraw, setLoadingCancelWithdraw] = useState(false);

  useEffect(() => {
    document.title = getTitle(title);
    // fetchWithdraw();
  }, []);

  useEffect(() => {
    fetchWithdraw();
    fetchMerchant();
  }, []);

  const handleConfirmWithdraw = async (withdrawId: number, note: string) => {
    try {
      setLoadingConfirmWithdraw(true);
      const res = await withdrawApi.complete(withdrawId, { note });
      message.success("Xác nhận lệnh rút thành công!");
      fetchWithdraw();
    } catch (error) {
    } finally {
      setLoadingConfirmWithdraw(false);
    }
  };

  const handleCancelWithdraw = async (withdrawId: number, note: string) => {
    if (!note) {
      message.error("Vui lòng nhập vào ghi chú");
      return;
    }
    try {
      setLoadingCancelWithdraw(true);
      const res = await withdrawApi.cancel(withdrawId, {
        note,
      });
      message.success("Từ chối lệnh rút thành công!");
      fetchWithdraw();
    } catch (error) {
    } finally {
      setLoadingCancelWithdraw(false);
    }
  };

  const onChangeRangePicker = (date: any, dateFormat: string[]) => {
    if (date) {
      queryWithdraw.toAt = dayjs(date[1]).endOf("day").unix();
      queryWithdraw.fromAt = dayjs(date[0]).startOf("day").unix();
    } else {
      queryWithdraw.toAt = undefined;
      queryWithdraw.fromAt = undefined;
    }
    fetchWithdraw();
  };
  const {
    query: queryMerchant,
    fetchData: fetchMerchant,
    loading: loadingMerchant,
    data: dataMerchants,
  } = useMerchant({
    initialQuery: {
      page: 1,
      limit: 20,
    },
  });
  const debounceFetchMerchant = debounce((value) => {
    queryMerchant.search = value;
    fetchMerchant();
  }, 300);
  return (
    <section className="box">
      <div className="flex flex-col gap-1 mb-5">
        <h1 className="text-xl m-0 ">{title}</h1>
        <span className="font-medium">{description}</span>
      </div>
      <div className="filter-container">
        <Space>
          <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              size="middle"
              onChange={(ev) => {
                fetchWithdraw({ search: ev.currentTarget.value, page: 1 });
              }}
              placeholder="Tìm kiếm theo mã"
            />
          </div>
          <div className="filter-item w-full sm:w-fit">
            <label htmlFor="">Cửa hàng</label>
            <br />
            <Select
              filterOption={false}
              showSearch
              size="middle"
              className="custom-filter focus-within:!border-primary hover:!border-primary focus-within:!shadow-none"
              style={{ width: 250 }}
              placeholder="Chọn cửa hàng"
              allowClear
              loading={loadingMerchant}
              onSearch={(value) => debounceFetchMerchant(value)}
              onChange={(value) => {
                if (value) {
                  queryWithdraw.merchantId = value;
                } else {
                  queryWithdraw.merchantId = undefined;
                  queryMerchant.search = "";
                  fetchMerchant();
                }
                fetchWithdraw();
              }}
            >
              <Select.Option value={undefined}>Tất cả</Select.Option>
              {dataMerchants?.map((item) => (
                <Select.Option value={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </div>
          <div className="filter-item w-full  sm:w-fit">
            <label htmlFor="">Khoảng thời gian</label>
            <DatePicker.RangePicker
              presets={[
                { label: "Hôm nay", value: [dayjs(), dayjs().add(1, "day")] },
                {
                  label: "Tuần này",
                  value: [dayjs().startOf("week"), dayjs().endOf("week")],
                },
                {
                  label: "Tháng này",
                  value: [dayjs().startOf("month"), dayjs().endOf("month")],
                },
                {
                  label: "Tháng trước",
                  value: [
                    dayjs().subtract(1, "month").startOf("month"),
                    dayjs().subtract(1, "month").endOf("month"),
                  ],
                },
              ]}
              onChange={onChangeRangePicker}
              className="custom-filter !w-full max-h-[32px] !sm:w-[300px]"
              size="small"
              format={settings.dateFormat}
            />
          </div>
          <div className="filter-item w-full !max-w-[200px] sm:w-fit ">
            <label htmlFor="">Trạng thái rút</label>
            <Select
              size="middle"
              className="custom-filter !max-w-[300px] focus-within:!border-primary hover:!border-primary focus-within:!shadow-none"
              style={{ width: 200 }}
              placeholder="Chọn trạng thái"
              allowClear
              onChange={(value) => {
                queryWithdraw.status = value;
                fetchWithdraw();
              }}
            >
              <Select.Option value={undefined}>Tất cả</Select.Option>
              {Object.values(WithdrawStatus)?.map((item) => (
                <Select.Option value={item}>
                  {WithdrawStatusTrans[item]?.label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="filter-item btn mt-1">
            <WithdrawExportExcel
              queryWithdraw={queryWithdraw}
              summaries={withdraws}
            />
          </div>

          <div className="filter-item btn mt-1">
            <Button
              onClick={() => fetchWithdraw()}
              type="primary"
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </div>
        </Space>
      </div>

      <Table
        loading={loadingWithdraw}
        pagination={false}
        rowKey="id"
        dataSource={withdraws}
        // size={"small"}

        scroll={{
          x: "max-content",
        }}
      >
        <Column
          width={70}
          title="Mã"
          render={(text, record: Withdraw) => (
            <span className="text-secondary font-medium">{record.code}</span>
          )}
        />
        <Column
          width={100}
          title="Cửa hàng"
          render={(text, record: Withdraw) => (
            <div className="flex items-center gap-2">
              <Image
                src={record.merchant.avatar}
                preview={false}
                className="rounded-full shadow-md !w-[40px] !h-[40px] object-cover"
              />
              <div className="flex flex-col flex-1">
                <span className="font-medium">{record.merchant.name}</span>

                <span className="text-[#8f8b8b]">
                  {record.merchant.address}
                </span>
              </div>
            </div>
          )}
        />
        <Column
          width={200}
          title="Liên hệ"
          render={(text, record: Withdraw) => (
            <div className="flex items-center gap-2">
              <div className="flex flex-col">
                <span className="text-[#8f8b8b]">{record.merchant.email}</span>
                <span className="">{record.merchant.phone}</span>
              </div>
            </div>
          )}
        />

        <Column
          width={150}
          title="Số tiền rút"
          render={(text, record: Withdraw) => (
            <span className="font-medium">{formatVND(record.amount)}</span>
          )}
        />
        <Column
          width={150}
          title="Ghi chú"
          render={(text, record: Withdraw) => (
            <span className="font-medium">{record.note}</span>
          )}
        />
        <Column
          align="center"
          width={150}
          title="Trạng thái rút"
          render={(text, record: Withdraw) => (
            <Tag
              color={WithdrawStatusTrans[record.status].color}
              className="font-medium"
            >
              {WithdrawStatusTrans[record.status].label}
            </Tag>
          )}
        />

        <Column
          width={150}
          title="Ngày thực hiện"
          render={(text, record: Withdraw) => (
            <span className="">{unixToFullDate(record.createdAt)}</span>
          )}
        />

        <Column
          width={200}
          align="center"
          title="Hành động"
          key="action"
          render={(text, record: Withdraw) => (
            <Space>
              {record?.status == WithdrawStatus.Pending && (
                <>
                  <Popconfirm
                    placement="topLeft"
                    title={
                      <>
                        <h2>Xác nhận duyệt lệnh rút này?</h2>
                        <TextArea
                          placeholder="Nhập vào ghi chú"
                          onChange={(e) => setNoteAccept(e.target.value)}
                        ></TextArea>
                      </>
                    }
                    onConfirm={() =>
                      handleConfirmWithdraw(record.id, noteAccept as string)
                    }
                    okText="Xác nhận"
                    cancelText="Không"
                  >
                    <Button type="primary">Duyệt yêu cầu</Button>
                  </Popconfirm>
                  <Popconfirm
                    placement="topLeft"
                    title={
                      <>
                        <h2>Xác nhận từ chối lệnh rút này?</h2>
                        <TextArea
                          placeholder="Nhập vào ghi chú"
                          onChange={(e) => setNote(e.target.value)}
                        ></TextArea>
                      </>
                    }
                    onConfirm={() =>
                      handleCancelWithdraw(record.id, note as string)
                    }
                    okText="Xác nhận"
                    cancelText="Không"
                  >
                    <Button type="primary" danger>
                      Từ chối
                    </Button>
                  </Popconfirm>
                </>
              )}
            </Space>
          )}
        />
      </Table>

      <Pagination
        total={totalWithdraw}
        currentPage={queryWithdraw.page}
        onChange={({ limit, page }) => {
          queryWithdraw.page = page;
          queryWithdraw.limit = limit;
          fetchWithdraw();
        }}
      />
    </section>
  );
};
