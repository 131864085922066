import { DatePicker, Table } from "antd";
import useRecordSale, { RecordSaleQuery } from "hooks/useRecordSale";
import { useEffect, useRef } from "react";

import Column from "antd/es/table/Column";
import dayjs from "dayjs";
import { SumaryRecordSale } from "types/dashboard";
import { formatVND } from "utils";
import { RecordSaleModal } from "views/Dashboard/components/RecordSaleModal";

interface IProps {
  querySummaryCommission?: RecordSaleQuery;
}
const { RangePicker } = DatePicker;

const CommissionList = () => {
  const currDay = dayjs();
  const initialQuery = {
    fromAt: currDay.startOf("year"),
    toAt: currDay.endOf("year"),
  };

  const {
    fetchSummary,
    query: queryRecordSale,
    setQuery: setQueryRecordSale,
    summary: recordSales,
    loading: loadingRecordSales,
    total: totalRecordSales,
  } = useRecordSale({
    initialQuery: {
      page: 1,
      limit: 50,
      fromAt: initialQuery.fromAt.unix(),
      toAt: initialQuery.toAt.unix(),
    },
  });

  useEffect(() => {
    fetchSummary();
    return () => {};
  }, []);

  const onChangeRangePicker = (date: any, dateFormat: string[]) => {
    if (date) {
      queryRecordSale.toAt = dayjs(date[1]).endOf("year").unix();
      queryRecordSale.fromAt = dayjs(date[0]).startOf("year").unix();
    } else {
      queryRecordSale.toAt = undefined;
      queryRecordSale.fromAt = undefined;
    }
    fetchSummary();
  };

  const recordSaleModal = useRef<RecordSaleModal>();

  return (
    <div className="m-auto">
      <div className="filter-container">
        <div className="flex flex-col filter-item mb-3 gap-1">
          <label htmlFor="">Khoảng thời gian</label>
          <RangePicker
            value={[
              dayjs.unix(queryRecordSale?.fromAt),
              dayjs.unix(queryRecordSale?.toAt),
            ]}
            onChange={onChangeRangePicker}
            picker="year"
            className="custom-filter w-[300px]"
            size="large"
            allowClear={false}
          />
        </div>
      </div>
      <Table
        dataSource={recordSales}
        size="small"
        scroll={{ x: "700px", y: "300px" }}
        pagination={false}
        summary={(pageData) => {
          let totalMoneyRenewNumber = 0;
          let totalMoneyCommissionNumber = 0;
          let totalAmount = 0;
          let totalmoneyVoucher = 0;
          let totalmoney = 0;
          pageData.forEach(
            ({ amount, moneyRenew, moneyCommission, moneyVoucher }) => {
              totalMoneyRenewNumber += moneyRenew;
              totalMoneyCommissionNumber += +moneyCommission;
              totalAmount += amount;
              totalmoneyVoucher += moneyVoucher;
              totalmoney += moneyVoucher + amount;
              console.log("totalmoneyVoucher", totalmoneyVoucher);
            }
          );
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <span className="text-base font-semibold">Tổng cộng</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <span className="text-primary block font-bold cursor-pointer text-base">
                    {formatVND(totalMoneyCommissionNumber)}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <span className="text-primary block font-bold cursor-pointer text-base">
                    {formatVND(totalMoneyRenewNumber)}
                  </span>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={3}>
                  <span className="text-primary block font-bold cursor-pointer text-base">
                    {formatVND(totalAmount)}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <span className="text-primary block font-bold cursor-pointer text-base">
                    {formatVND(totalmoneyVoucher)}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  <span className="text-primary block font-bold cursor-pointer text-base">
                    {formatVND(totalmoney)}
                  </span>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      >
        <Column
          width={50}
          title="Tháng"
          dataIndex="month"
          key="month"
          render={(text, record: SumaryRecordSale) => (
            <div
              className="flex flex-col gap-1"
              onClick={() => recordSaleModal?.current?.handleCreate()}
            >
              {record?.month}
            </div>
          )}
        />
        <Column
          width={50}
          align="left"
          title="Tổng tiền hoa hồng"
          dataIndex="moneyCommission"
          key="moneyCommission"
          render={(text, record: SumaryRecordSale) => (
            <div className="flex flex-col gap-1">
              <span className="text-secondary font-medium cursor-pointer">
                {formatVND(record?.moneyCommission)}
              </span>
            </div>
          )}
        />
        <Column
          width={50}
          align="left"
          title="Tổng tiền trả theo gói"
          dataIndex="moneyRenew"
          key="moneyRenew"
          render={(text, record: SumaryRecordSale) => (
            <div className="flex flex-col gap-1">
              <span className="text-secondary font-medium cursor-pointer">
                {formatVND(record?.moneyRenew)}
              </span>
            </div>
          )}
        />

        <Column
          width={40}
          align="left"
          title="Tổng doanh thu hoa hồng"
          dataIndex="amount"
          key="amount"
          render={(text, record: SumaryRecordSale) => (
            <div className="flex flex-col gap-1">
              <span className="text-secondary font-medium cursor-pointer">
                {formatVND(record?.amount)}
              </span>
            </div>
          )}
        />
        <Column
          width={40}
          align="left"
          title="Tổng doanh thu voucher"
          dataIndex="moneyRenew"
          key="moneyRenew"
          render={(text, record: SumaryRecordSale) => (
            <div className="flex flex-col gap-1">
              <span className="text-secondary font-medium cursor-pointer">
                {formatVND(record?.moneyVoucher)}
              </span>
            </div>
          )}
        />
        <Column
          width={40}
          align="left"
          title="Tổng doanh thu"
          dataIndex="amount"
          key="amount"
          render={(text, record: SumaryRecordSale) => (
            <div className="flex flex-col gap-1">
              <span className="text-secondary font-medium cursor-pointer">
                {formatVND(record?.amount + record?.moneyVoucher)}
              </span>
            </div>
          )}
        />
      </Table>
      <RecordSaleModal ref={recordSaleModal} />
    </div>
  );
};

export default CommissionList;
