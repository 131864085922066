import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const servicePackOrderApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/servicePackOrder",
      params,
    }),
  findOne: (id?: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/servicePackOrder/${id}`,
      // params,
    }),
  paid: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/servicePackOrder/${id}/paid`,
      method: "patch",
    }),
  cancel: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/servicePackOrder/${id}/cancel`,
      method: "delete",
    }),
  //   create: (data: any): AxiosPromise<any> =>
  //     request({
  //       url: "/v1/merchant/servicePackOrder",
  //       data,
  //       method: "post",
  //     }),
  //   update: (id: number, data: any): AxiosPromise<any> =>
  //     request({
  //       url: `/v1/merchant/servicePackOrder/${id}`,
  //       method: "patch",
  //       data,
  //     }),
  //   delete: (id: number): AxiosPromise<any> =>
  //     request({
  //       url: `/v1/merchant/servicePackOrder/${id}`,
  //       method: "delete",
  //     }),
};
