import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { paymentTypeApi } from "api/paymentType.api";
import { message } from "antd";
import { PaymentType } from "types/paymentType";
interface paymentTypeQuery extends QueryParam {}
type Props = {
  initialQuery: paymentTypeQuery;
};

const usePaymentType = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<PaymentType, paymentTypeQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await paymentTypeApi.findAll(query).then(({ data }) => data);
      return {
        data: (data.paymentTypes as PaymentType[]).sort(
          (a, b) => a.position - b.position
        ),
        total: data.total,
      };
    },
    editFunc: async (id, data) => {
      await paymentTypeApi.update(id, data);
    },
  });
  const toggleEnable = async (id: number, isEnabled?: boolean) => {
    await paymentTypeApi.update(id, { paymentType: { isEnabled } });
    message.success("Thao tác thành công");
    fetch.fetchData();
  };
  const toggleDefault = async (id: number, isDefault?: boolean) => {
    await paymentTypeApi.update(id, { paymentType: { isDefault } });
    message.success("Thao tác thành công");
    fetch.fetchData();
  };
  const changePosition = async (
    paymentTypes: { id: number; position: number }[]
  ) => {
    await paymentTypeApi.batch({ paymentTypes });
    fetch.fetchData();
  };

  const {
    query: queryPaymentType,
    fetchData: fetchPaymentType,
    total: totalPaymentType,
    loading: isFetchPaymentTypeLoading,
    data: paymentTypes,
  } = fetch;

  return {
    fetchPaymentType,
    totalPaymentType,
    queryPaymentType,
    isFetchPaymentTypeLoading,
    paymentTypes,
    toggleEnable,
    toggleDefault,
    changePosition,
  };
};

export default usePaymentType;
