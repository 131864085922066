import { forwardRef, useMemo } from "react";
import dayjs from "dayjs";
import {
  Checkout,
  CheckoutPaymentType,
  CheckoutPaymentTypeTrans,
} from "types/checkout";
import { formattedFullDate } from "utils/formatDate";
import { Button, Col, Divider, Image, Popover, Row, Typography } from "antd";
import { formatMoneyWithMinusSymbol, formatVND } from "utils/formatVND";
import { GoQuestion } from "react-icons/go";
import { userStore } from "store/userStore";
import { arrToStr } from "utils/url";
import { Appointment, AppointmentDetail } from "types/appointment";
import { CheckoutDetail } from "types/checkoutDetail";
import { ServicePackOrder } from "types/servicePackOrder";
import { formatPhoneNumber } from "utils/formatPhoneNumber";
import settings from "settings";
interface PropsType {
  data: Checkout;
  checkoutDetailWithSingleServiceOptions: CheckoutDetail[];
  servicePackOrderList: ServicePackOrder[];
  hasSelectOnlyServicePackOrder: boolean;
  appointment?: Appointment;
}

const PrintExample = forwardRef(
  (
    {
      checkoutDetailWithSingleServiceOptions,
      hasSelectOnlyServicePackOrder,
      servicePackOrderList,
      data,
      appointment,
    }: PropsType,
    ref: any
  ) => {
    console.log({ servicePackOrderList });
    return (
      <div style={{ display: "none" }} className="wrapper-printer">
        <style type="text/css" media="print">
          {` @page { size: A5 portrait;
          margin: 0.5cm;
         }
          td,th{
              padding: 2px 4px;
          }
          `}
        </style>
        <div className="print-example" ref={ref}>
          {/* <PrintPage data={props.data} /> */}

          <div className="px-5">
            <div className="flex">
              <div className=""></div>
              {appointment && (
                <div className="text-center items-center  flex-1 mt-0">
                  <h1 className="font-bold">{data.merchant.name}</h1>
                  <span className="font-medium">
                    {arrToStr([appointment?.merchantLocation?.address])}
                  </span>
                  <p className="font-medium mb-0">
                    {formatPhoneNumber(
                      appointment.merchantLocation.contactPhone
                    )}
                  </p>
                </div>
              )}
            </div>

            <div className="text-center">-----------------</div>

            <div className="mt-2 text-center mb-2">
              <h1 className="font-bold text-base">ĐƠN HÀNG #{data.code}</h1>
              <span className="font-medium">
                {formattedFullDate(data.createdAt)}
              </span>
            </div>

            <div>
              <div className="flex items-center gap-2">
                <span className="text-base font-bold w-[100px]">
                  Khách hàng:
                </span>
                <span className="text-base font-medium">
                  {data.customer.fullName}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-base font-bold w-[100px]">
                  Điện thoại:
                </span>
                <span className="text-base font-medium">
                  {data.customer.phone}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-base font-bold ">
                  Thời gian dùng dịch vụ:
                </span>
                <span className="text-base font-medium">
                  {formattedFullDate(appointment?.serviceStartAt)}
                </span>
              </div>
            </div>

            <Divider style={{ margin: "5px 0" }} />

            <div className="mt-5">
              {/* <div className="flex justify-between mb-1">
          <span className="text-base font-bold">Dịch vụ</span>
          <span className="text-base font-bold">Số lượng</span>
        </div> */}

              {/* //* Dịch vụ gói */}
              <div className="flex flex-col gap-2">
                {servicePackOrderList?.map((item) => {
                  return (
                    <>
                      <h1 className="text-base font-bold underline">
                        Gói: {item.servicePack?.name}
                      </h1>
                      {/* <Divider style={{ margin: "5px 0" }} /> */}
                      {item?.servicePackOrderDetails?.map((detail) => {
                        return (
                          <div className="flex flex-col">
                            <div className="item flex justify-between">
                              <div className="flex gap-2 items-center">
                                {/* <span className="text-xs text-gray-500">
                                        1 x
                                      </span> */}
                                <div className="text-sm leading-normal font-semibold !mb-0">
                                  {detail.service.name}
                                </div>
                              </div>

                              <div className="flex items-center gap-2">
                                <span className="font-medium text-[15px]">
                                  Thanh toán gói
                                </span>
                              </div>
                            </div>
                            {detail?.serviceOptions?.length !== 0 &&
                              detail?.serviceOptions?.map((item) => (
                                <div className="item flex justify-between italic">
                                  <div className="flex gap-2 items-center">
                                    <div className="text-sm leading-normal font-medium !mb-0">
                                      + {item.pricingName} (dịch vụ kèm)
                                    </div>
                                  </div>

                                  {/* <div className="flex items-center gap-2">
                                    <span className="font-medium text-[15px]">
                                      Thanh toán gói
                                    </span>
                                  </div> */}
                                </div>
                              ))}
                          </div>
                        );
                      })}
                      <Divider style={{ margin: "5px 0" }} />
                    </>
                  );
                })}

                {/* //* Dịch vụ đơn lẻ */}
                {checkoutDetailWithSingleServiceOptions.length !== 0 && (
                  <>
                    <h1 className="text-base font-bold underline">
                      Dịch vụ đơn lẻ:
                    </h1>
                    {/* <Divider style={{ margin: "5px 0" }} /> */}

                    {checkoutDetailWithSingleServiceOptions?.map((detail) => {
                      return (
                        <div className="flex flex-col">
                          <div className="item flex justify-between">
                            <div className="flex gap-2 items-center">
                              <div className="text-sm leading-normal font-semibold !mb-0">
                                {detail.service.name}
                              </div>
                            </div>

                            <div className="flex items-center gap-2">
                              <span className="font-medium text-[15px]">
                                {formatVND(detail?.service.price)}
                              </span>
                              <span className="text-xs text-gray-500">
                                x{detail.quantity || 1}
                              </span>
                            </div>
                          </div>
                          {detail?.serviceOptions?.length !== 0 &&
                            detail?.serviceOptions?.map((item) => (
                              <div className="item flex justify-between italic">
                                <div className="flex gap-2 items-center">
                                  <div className="text-sm leading-normal font-medium !mb-0">
                                    + {item.pricingName} (dịch vụ kèm)
                                  </div>
                                </div>

                                <div className="flex items-center gap-2">
                                  <span className="font-medium text-[15px]">
                                    {formatVND(item.price)}
                                  </span>
                                  <span className="text-xs text-gray-500">
                                    x{item.quantity || 1}
                                  </span>
                                </div>
                              </div>
                            ))}
                        </div>
                      );
                    })}
                  </>
                )}

                <div>
                  {!hasSelectOnlyServicePackOrder && (
                    <>
                      <Divider style={{ margin: "5px 0" }} />
                      <div className="flex justify-between items-center mb-2">
                        <h1 className="text-base font-semibold">Tổng tiền</h1>
                        <span className="font-semibold">
                          {formatVND(data?.moneyTotal)}
                        </span>
                      </div>

                      {data?.moneyVoucher !== 0 && (
                        <div className="flex justify-between items-center mb-2">
                          <h1 className="text-base font-semibold">Voucher</h1>
                          <span className="font-medium">
                            {formatMoneyWithMinusSymbol(data?.moneyVoucher)}
                          </span>
                        </div>
                      )}
                      {data?.moneyCoupon !== 0 && (
                        <div className="flex justify-between items-center mb-2">
                          <h1 className="text-base font-semibold">Coupon</h1>
                          <span className="font-medium">
                            {formatMoneyWithMinusSymbol(data?.moneyCoupon)}
                          </span>
                        </div>
                      )}
                      {data?.customerPaid !== 0 && (
                        <div className="flex justify-between items-center mb-2">
                          <h1 className="text-base font-semibold">
                            Thanh toán trước
                          </h1>
                          <span className="font-medium">
                            {formatMoneyWithMinusSymbol(data?.customerPaid)}
                          </span>
                        </div>
                      )}
                      {/* {data.checkoutPayments?.[0]?.paymentType ===
                        CheckoutPaymentType.Point && (
                        <div className="flex justify-between items-center mb-2">
                          {data.checkoutPayments?.[0].amount > 0 && (
                            <>
                              <h1 className="text-base font-semibold">
                                Khách thanh toán online
                              </h1>
                              <span
                                className={`font-medium ${
                                  data?.customer ? "text-red-500" : ""
                                } `}
                              >
                                {formatMoneyWithMinusSymbol(
                                  data.checkoutPayments?.[0].amount
                                )}
                              </span>
                            </>
                          )}
                          {data.checkoutPayments?.[0].amount < 0 && (
                            <>
                              <h1 className="text-base font-semibold">
                                Hoàn tiền
                              </h1>
                              <span
                                className={`font-medium ${
                                  data?.customer ? "text-red-500" : ""
                                } `}
                              >
                                {formatVND(data.checkoutPayments?.[0].amount)
                                  .toString()
                                  .replace("-", "")}
                              </span>
                            </>
                          )}
                        </div>
                      )} */}

                      {data.checkoutPayments.length > 0 && (
                        <div className="flex justify-between items-center mb-2">
                          <h1 className="text-base font-semibold">
                            Phương thức thanh toán
                          </h1>
                          <span className="font-semibold">
                            {data.checkoutPayments.map((item) => {
                              return (
                                <span>
                                  {
                                    //@ts-ignore
                                    CheckoutPaymentTypeTrans[item.paymentType]
                                      .label
                                  }
                                </span>
                              );
                            })}
                          </span>
                        </div>
                      )}
                      <Divider style={{ margin: "5px 0" }} />
                    </>
                  )}

                  <div className="flex justify-between items-center mb-2">
                    <h1 className="text-base font-semibold">
                      {data?.checkoutPayments?.[0]?.amount < 0
                        ? "Tiền hoàn lại"
                        : "Tổng thanh toán"}
                    </h1>
                    <div>
                      <span className="font-bold">
                        {hasSelectOnlyServicePackOrder
                          ? "Đã thanh toán gói"
                          : `${formatVND(data?.checkoutPayments?.[0]?.amount)
                              .toString()
                              .replace("-", "")}`}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="text-center mt-10">
                  <h1 className="text-base">
                    Được in vào lúc {formattedFullDate(dayjs().unix())}
                  </h1>
                  <span className="font-medium text-base">
                    bởi {userStore.info.name}
                  </span>
                </div>
                <div className="font-bold w-full text-center mt-5">
                  <Image src={settings.logoDefault} height={20} /> Powered by{" "}
                  <span className="!text-[#abca74]">JoyBookie</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

// export const PrintPage = ({ data }: { data: Checkout }) => {
//   const appointmentDetailsResult = useMemo(() => {
//     // Gom nhóm dữ liệu theo serviceOption id và service id
//     const groupedDetails: any = {};
//     [...data.checkoutDetails, ...data.checkoutDetails].forEach((detail) => {
//       const key = detail.serviceOption.id;
//       if (!groupedDetails[key]) {
//         groupedDetails[key] = [];
//       }
//       groupedDetails[key].push(detail);
//     });

//     // Merge dữ liệu trong từng nhóm
//     const mergedDetails: CheckoutDetail[] = [];
//     data.checkoutDetails.forEach((checkout) => {
//       const correspondingAppointment = data.checkoutDetails.find(
//         (appointment) =>
//           appointment.serviceOption.id === checkout.serviceOption.id &&
//           appointment.service.id === checkout.service.id
//       );
//       if (correspondingAppointment) {
//         // Clone appointment detail để không ảnh hưởng đến dữ liệu gốc
//         const mergedDetail = { ...correspondingAppointment };
//         // Giữ lại quantity từ checkoutDetail
//         mergedDetail.quantity = checkout.quantity;
//         mergedDetails.push(mergedDetail);
//       }
//     });

//     return mergedDetails;
//   }, [data]);

//   //* Thể hiện ds chọn dịch vụ chỉ có gói dùng hiển thị/ ẩn content giá tiền
//   const hasSelectOnlyServicePackOrder = useMemo(() => {
//     if (!data) return true;
//     const hasSingleServiceOption = data.checkoutDetails.find(
//       (item) => !item.servicePackOrderDetail
//     );
//     return hasSingleServiceOption ? false : true;
//   }, [data]);

//   console.log(appointmentDetailsResult);

//   let previousEndTime = 0;

//   return (
//     <div className="px-5">
//       <div className="flex">
//         <div className=""></div>
//         <div className="text-center items-center  flex-1 mt-3">
//           <h1 className="font-bold">{data.merchant.name}</h1>
//           <span className="font-medium">
//             {arrToStr([
//               userStore.info.merchant?.address,
//               userStore.info.merchant?.ward.nameWithType,
//               userStore.info.merchant?.district.nameWithType,
//               userStore.info.merchant?.city.nameWithType,
//             ])}
//           </span>
//           <p className="font-medium mb-0">
//             {formatPhoneNumber(data.merchant.phone)}
//           </p>
//         </div>
//       </div>

//       <div className="text-center">-----------------</div>

//       <div className="mt-2 text-center mb-2">
//         <h1 className="font-bold text-base">ĐƠN HÀNG #{data.code}</h1>
//         <span className="font-medium">{formattedFullDate(data.createdAt)}</span>
//       </div>

//       <div>
//         <div className="flex items-center gap-2">
//           <span className="text-base font-bold w-[100px]">Khách hàng:</span>
//           <span className="text-base font-medium">
//             {data.customer.fullName}
//           </span>
//         </div>
//         <div className="flex items-center gap-2">
//           <span className="text-base font-bold w-[100px]">Điện thoại:</span>
//           <span className="text-base font-medium">{data.customer.phone}</span>
//         </div>

//       </div>

//       <Divider style={{ margin: "5px 0" }} />

//       <div className="mt-5">
//         {/* <div className="flex justify-between mb-1">
//           <span className="text-base font-bold">Dịch vụ</span>
//           <span className="text-base font-bold">Số lượng</span>
//         </div> */}

//         <div className="flex flex-col gap-2">
//           {appointmentDetailsResult.map((item) => {
//             // const startTime = previousEndTime ? previousEndTime : item.start; // Thời gian bắt đầu của mục hiện tại
//             // const endTime = startTime + item.duration; // Thời gian kết thúc của mục hiện tại
//             // previousEndTime = endTime;
//             return (
//               <>
//                 <div className="item flex justify-between">
//                   <div>
//                     <h1 className="text-base font-semibold">
//                       {item.serviceOption.pricingName}
//                     </h1>
//                     {/* <span>{formattedFullDate(previousEndTime)}</span> */}
//                   </div>

//                   <div className="flex items-center gap-2">
//                     {hasSelectOnlyServicePackOrder ? (
//                       "Thanh toán gói"
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-500">
//                           x{item.quantity}
//                         </span>
//                         <span className="font-medium">
//                           {formatVND(item.price)}
//                         </span>
//                       </>
//                     )}
//                   </div>
//                 </div>
//               </>
//             );
//           })}

//           <div>
//             {!hasSelectOnlyServicePackOrder && (
//               <>
//                 <Divider style={{ margin: "5px 0" }} />
//                 <div className="flex justify-between items-center mb-2">
//                   <h1 className="text-base font-semibold">Tổng tiền</h1>
//                   <span className="font-semibold">
//                     {formatVND(data?.moneyTotal)}đ
//                   </span>
//                 </div>

//                 {data?.moneyVoucher !== 0 && (
//                   <div className="flex justify-between items-center mb-2">
//                     <h1 className="text-base font-semibold">Voucher</h1>
//                     <span className="font-medium">
//                       {formatMoneyWithMinusSymbol(data?.moneyVoucher)}
//                     </span>
//                   </div>
//                 )}
//                 {data?.moneyCoupon !== 0 && (
//                   <div className="flex justify-between items-center mb-2">
//                     <h1 className="text-base font-semibold">Coupon</h1>
//                     <span className="font-medium">
//                       {formatMoneyWithMinusSymbol(data?.moneyCoupon)}
//                     </span>
//                   </div>
//                 )}
//                 <div className="flex justify-between items-center mb-2">
//                   <h1 className="text-base font-semibold">
//                     Khách thanh toán online
//                   </h1>
//                   <span className={`font-medium`}>
//                     {formatMoneyWithMinusSymbol(
//                       data?.appointment?.customerPaid
//                     )}
//                   </span>
//                 </div>

//                 {data.checkoutPayments.length > 0 && (
//                   <div className="flex justify-between items-center mb-2">
//                     <h1 className="text-base font-semibold">
//                       Phương thức thanh toán
//                     </h1>
//                     <span className="font-semibold">
//                       {data.checkoutPayments.map((item) => {
//                         return (
//                           <span>
//                             {
//                               //@ts-ignore
//                               CheckoutPaymentTypeTrans[item.paymentType].label
//                             }
//                           </span>
//                         );
//                       })}
//                     </span>
//                   </div>
//                 )}
//               </>
//             )}

//             <Divider style={{ margin: "5px 0" }} />
//             <div className="flex justify-between items-center mb-2">
//               <h1 className="text-base font-semibold">
//                 {data?.checkoutPayments?.[0]?.amount < 0
//                   ? "Tiền hoàn lại"
//                   : "Tổng thanh toán"}
//               </h1>
//               <div>
//                 <span className="font-bold">
//                   {hasSelectOnlyServicePackOrder
//                     ? "Đã thanh toán"
//                     : `${formatVND(data?.checkoutPayments?.[0]?.amount)}`}
//                 </span>
//               </div>
//             </div>
//           </div>

//           <div className="text-center mt-10">
//             <h1 className="text-base">
//               Được in vào lúc {formattedFullDate(dayjs().unix())}
//             </h1>
//             <span className="font-medium text-base">
//               bởi {userStore.info.name}
//             </span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default PrintExample;
