import { AxiosPromise } from "axios";
import settings from "settings";
import { request } from "utils/request";

export const bannerApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/banner",
      params,
    }),
  create: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/banner",
      data,
      method: "post",
    }),
  update: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/banner/${id}`,
      method: "patch",
      data,
    }),
  delete: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/banner/${id}`,
      method: "delete",
    }),
  uploadUrl: settings.apiUrl + "/v1/admin/banner/upload",
};
