import React from "react";
// @ts-ignore
import AutoComplete from "react-google-autocomplete";

export interface CoordAddress {
  lat: number;
  lng: number;
  address: string;
}

interface IGoogleMapAutoComplete {
  onPlaceSelected: (coordAddress: CoordAddress) => void;
  defaultBounds?: any;
}

export const GoogleMapAutoComplete = ({
  onPlaceSelected,
  defaultBounds,
}: IGoogleMapAutoComplete) => {
  return (
    <div>
      <AutoComplete
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        options={{
          bounds: defaultBounds,
          types: ["address"],
          language: "vi",
        }}
        placeholder="Chọn địa chỉ của bạn"
        style={{ width: "100%" }}
        language="vi"
        onPlaceSelected={(place: any) => {
          console.log("onPlaceSelected:", place);
          if (place.geometry && place.geometry.location) {
            onPlaceSelected({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
              address: place.formatted_address || "",
            });
          }
        }}
      />
    </div>
  );
};
