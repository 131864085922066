import { Button, Col, Form, Input, Row, Switch, Image } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { AddressSelect } from "components/AddressSelect/AddressSelect";
import {
  RecommendedSize,
  SingleImageUpload,
} from "components/Upload/SingleImageUpload";
import { useEffect, useMemo, useRef, useState } from "react";
import { Merchant, MerchantStatus } from "types/merchant";
import { ModalStatus } from "types/modal";
import {
  phoneValidate,
  requiredRule,
  wordAndNumberOnlyRule,
} from "utils/validate";
import { AddressModal } from "../AddressModal";
import settings from "settings";
import { merchantApi } from "api/merchant.api";
import useCategory from "hooks/useCategory";
import { Select } from "antd/lib";
import { GenerateObjectUrl } from "components/GenerateObjectUrl/GenerateObjectUrl";
import { RECOMMENDED_SIZE } from "constant/size";
import { RenewPackage } from "types/renewPackage";
import useEmployee from "hooks/useEmployee";
import { MerchantLocation } from "types/merchantLocation";

const style = {};

export const InfoTab = ({
  merchant,
  status,
  renewPackages,
}: {
  merchant?: Merchant;
  status: ModalStatus;
  renewPackages: RenewPackage[];
}) => {
  const form = Form.useFormInstance();
  const [addressVisible, setAddressVisible] = useState(false);
  const addressSelectRef = useRef<any>();
  const [isActive, setIsActive] = useState(false); //* Src cũ là isBlock nhưng theo usecase là cần "Hoạt động" chứ ko phải "Khóa"
  const {
    fetchCategory,
    categories,
    deleteCategory,
    filterCategory,
    isFetchCategoryLoading,
    queryCategory,
    totalCategory,
  } = useCategory({
    initialQuery: {
      page: 1,
      limit: 20,
    },
  });

  const { fetchData, data, total, query, filterData, loading } = useEmployee({
    initialQuery: {
      page: 1,
      limit: 20,
      merchantId: merchant?.id,
    },
  });

  useEffect(() => {
    fetchCategory();
    query.merchantId = merchant?.id;
    fetchData();
  }, [merchant?.id]);

  useEffect(() => {
    if (merchant) {
      addressSelectRef.current.setValue(merchant);
      setIsActive(!merchant.isBlock);
    }
    return () => {
      setIsActive(false);
      addressSelectRef.current.setValue(undefined);
      form.setFieldsValue({});
    };
  }, [merchant]);
  console.log({ merchant });

  const merchantMainLocation = useMemo(() => {
    return (
      merchant?.merchantLocations?.find((item) => item.isMain) ||
      ({} as MerchantLocation)
    );
  }, [merchant]);

  console.log(merchantMainLocation);
  return (
    <>
      <Form layout="vertical" form={form}>
        <Row gutter={32}>
          {!!merchant?.signupImages.length && (
            <Col span={24}>
              <div className="text-[14px] font-semibold mb-2">
                Hình ảnh đăng ký
              </div>
              <Row justify={"center"} gutter={[24, 24]}>
                {merchant?.signupImages.map((image) => (
                  <Col span={4}>
                    <div className="border border-dashed border-[#d9d9d9] rounded-lg h-[100px]">
                      <Image
                        height={"100%"}
                        className="object-contain"
                        width={"100%"}
                        src={image.url}
                      ></Image>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          )}
          <Col span={24}>
            <div className="text-[14px] font-semibold mb-2">
              {" "}
              <span style={{ color: "red" }}>* </span>Hình ảnh
            </div>
            <div className="flex items-center justify-center gap-10">
              <div>
                <div className="text-center">
                  <Form.Item noStyle shouldUpdate={true}>
                    {() => {
                      return (
                        <Form.Item
                          // label=""
                          name="avatar"
                          rules={[requiredRule]}
                        >
                          <SingleImageUpload
                            uploadUrl={merchantApi.uploadUrl}
                            onUploadOk={(path: string) => {
                              form.setFieldsValue({
                                avatar: path,
                              });
                            }}
                            // uploadTitleBtn="Ảnh đại diện"
                            imageUrl={form.getFieldValue("avatar")}
                          />
                          <div className="text-center font-semibold mt-1">
                            Ảnh đại diện
                          </div>
                          <RecommendedSize
                            className="text-xs"
                            iconClassName="text-xs"
                            size={RECOMMENDED_SIZE.merchantAvatar}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </div>
              </div>
              <div>
                <div className="text-center">
                  <Form.Item noStyle shouldUpdate={true}>
                    {() => {
                      return (
                        <Form.Item
                          // label="Ảnh giới thiệu"
                          name="bioImage"
                          rules={[requiredRule]}
                        >
                          <SingleImageUpload
                            onUploadOk={(path: string) => {
                              form.setFieldsValue({
                                bioImage: path,
                              });
                            }}
                            // uploadTitleBtn="Ảnh giới thiệu"
                            imageUrl={form.getFieldValue("bioImage")}
                          />
                          <div className="text-center font-semibold mt-1">
                            Ảnh giới thiệu
                          </div>
                          <RecommendedSize
                            className="text-xs"
                            iconClassName="text-xs"
                            size={RECOMMENDED_SIZE.bioImage}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </div>
              </div>
              <div>
                <div className="text-center">
                  <Form.Item noStyle shouldUpdate={true}>
                    {() => {
                      return (
                        <Form.Item
                          // label="Ảnh bìa"
                          name="coverImage"
                          rules={[requiredRule]}
                        >
                          <SingleImageUpload
                            onUploadOk={(path: string) => {
                              form.setFieldsValue({
                                coverImage: path,
                              });
                            }}
                            // uploadTitleBtn="Ảnh bìa"
                            imageUrl={form.getFieldValue("coverImage")}
                          />
                          <div className="text-center font-semibold mt-1">
                            Ảnh bìa
                          </div>
                          <RecommendedSize
                            className="text-xs"
                            iconClassName="text-xs"
                            size={RECOMMENDED_SIZE.coverImage}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </div>
              </div>
            </div>
          </Col>
          {/* <Col span={24}>
            <div className="text-center">
              <Form.Item noStyle shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item
                      label="Ảnh đại diện"
                      name="avatar"
                      rules={[requiredRule]}
                    >
                      <SingleImageUpload
                        recommendedSize={RECOMMENDED_SIZE.merchantAvatar}
                        uploadUrl={merchantApi.uploadUrl}
                        onUploadOk={(path: string) => {
                          form.setFieldsValue({
                            avatar: path,
                          });
                        }}
                        imageUrl={form.getFieldValue("avatar")}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </div>
          </Col> */}
          <Col span={12}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  extra={
                    merchant?.status == MerchantStatus.Approve ? (
                      <GenerateObjectUrl
                        link={`${getFieldValue(
                          "namespace"
                        )}?merchantLocationId=${merchantMainLocation?.id || 1}`}
                      />
                    ) : null
                  }
                  label="Namespace"
                  name="namespace"
                  rules={[requiredRule, wordAndNumberOnlyRule]}
                >
                  <Input
                    placeholder="Nhập Namespace"
                    disabled={
                      status === "update" &&
                      merchant?.status !== MerchantStatus.Reject
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item label="Tên cửa hàng" name="name" rules={[requiredRule]}>
              <Input placeholder="Nhập tên" />
            </Form.Item>
            {status == "create" && (
              <Form.Item
                label="Mật khẩu"
                name="password"
                rules={[requiredRule]}
              >
                <Input.Password placeholder="Nhập mật khẩu" />
              </Form.Item>
            )}
            <Form.Item
              style={style}
              label="Số điện thoại"
              name="phone"
              rules={[requiredRule, ...phoneValidate]}
            >
              <Input placeholder="Nhập số điện thoại" />
            </Form.Item>
            <Form.Item style={style} label="Email" name="email">
              <Input placeholder="Nhập email" />
            </Form.Item>
            <Form.Item
              name="bankAccountNumber"
              label={
                <h1 className="text-base font-semibold mb-0">
                  Số tài khoản ngân hàng
                </h1>
              }
              rules={[requiredRule]}
            >
              <Input
                placeholder="Nhập số tài khoản ngân hàng"
                size="large"
                className="!rounded-lg"
              ></Input>
            </Form.Item>
            <Form.Item
              name="bankAccountName"
              label={
                <h1 className="text-base font-semibold mb-0">
                  Tên chủ tài khoản ngân hàng
                </h1>
              }
              rules={[requiredRule]}
            >
              <Input
                placeholder="Nhập vào tên chủ tài khoản ngân hàng"
                size="large"
                className="!rounded-lg"
              ></Input>
            </Form.Item>
            <Form.Item
              name="bankName"
              label={
                <h1 className="text-base font-semibold mb-0">Tên ngân hàng</h1>
              }
              rules={[requiredRule]}
            >
              <Input
                placeholder="Nhập vào tên ngân hàng"
                size="large"
                className="!rounded-lg"
              ></Input>
            </Form.Item>
            <Form.Item
              name={"categoryId"}
              label="Ngành dịch vụ"
              rules={[requiredRule]}
            >
              <Select
                showSearch
                placeholder="Chọn ngành dịch vụ"
                style={{ width: "100%" }}
                // optionFilterProp="children"
                // mode="multiple"
                // onChange={onChange}
                // onSearch={onSearch}
                // filterOption={(input, option: any) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                //   0
                // }
                loading={isFetchCategoryLoading}
              >
                {categories.map((category) => (
                  <Select.Option key={category.id} value={category.id}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[requiredRule]}
              required
              label="Gói sử dụng"
              name={"renewPackageId"}
            >
              <Select
                // onChange={handleChangeCity}
                style={{ width: "100%" }}
                // onClear={() => {
                //   clearDistrict();
                //   clearWard();
                // }}
                allowClear
                placeholder="Gói sử dụng"
                showSearch
                options={renewPackages.map((item) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                })}
              ></Select>
            </Form.Item>
            {status === "update" && (
              <Form.Item label="Nhân viên phụ trách" name={"saleStaffId"}>
                <Select
                  // onChange={handleChangeCity}
                  style={{ width: "100%" }}
                  // onClear={() => {
                  //   clearDistrict();
                  //   clearWard();
                  // }}
                  allowClear
                  placeholder="Chọn nhân viên phụ trách"
                  showSearch
                  options={data.map((item) => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  })}
                ></Select>
              </Form.Item>
            )}
            <Form.Item name={"isBlock"} hidden></Form.Item>

            <Form.Item hidden style={style} name="long" rules={[requiredRule]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={"shortDesc"} label="Giới thiệu ngắn">
              <Input.TextArea
                placeholder="Nhập nội dung giới thiệu ngắn về tiệm"
                className="!h-[56px]"
              ></Input.TextArea>
            </Form.Item>

            <AddressSelect
              ref={addressSelectRef}
              form={form}
              onChange={function (data: any): void {}}
            ></AddressSelect>

            <Form.Item
              style={style}
              label="Tên đường, số nhà"
              name="address"
              rules={[requiredRule]}
            >
              <Input placeholder="Nhập tên đường, số nhà"></Input>
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <FormItem
                  label={<label className="!h-auto">Vị trí</label>}
                  className="custom-link"
                  rules={[requiredRule]}
                  name={"lat"}
                >
                  <div></div>
                  <Input
                    className="custom-input"
                    size="small"
                    suffix={
                      getFieldValue("lat") ? (
                        // eslint-disable-next-line jsx-a11y/alt-text
                        <img
                          width={20}
                          className="object-cover"
                          height={20}
                          src={`/icon/${
                            getFieldValue("lat") ? "success.png" : "error.png"
                          }`}
                        />
                      ) : (
                        <div className="w-[20px] h-[20px]"></div>
                      )
                    }
                    // status={getFieldValue("lat") ? undefined : "error"}
                    value={
                      getFieldValue("lat")
                        ? "Đã chọn vị trí"
                        : "Chưa chọn vị trí"
                    }
                    readOnly
                    style={{ height: "100%" }}
                    onChange={(e) => {
                      return false;
                    }}
                    addonAfter={
                      <Button
                        className="location-btn"
                        style={{ height: "42px !important" }}
                        type="primary"
                        onClick={() => setAddressVisible(true)}
                      >
                        {merchant?.address ? "Update" : "Chọn vị trí"}
                      </Button>
                    }
                  />
                </FormItem>
              )}
            </Form.Item>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  style={style}
                  label="Ẩn/Hiện"
                  name="isVisible"
                  initialValue={true}
                  valuePropName="checked"
                >
                  <Switch
                    checkedChildren="Hiện"
                    unCheckedChildren="Ẩn"
                  ></Switch>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  style={style}
                  label="Trạng thái"
                  // name="isBlock"
                  rules={[requiredRule]}
                  valuePropName="checked"
                >
                  <Switch
                    value={isActive}
                    checkedChildren="Hoạt động"
                    unCheckedChildren="Đã khóa"
                    onChange={(value) => {
                      // console.log(value);
                      setIsActive(value);
                      form.setFieldsValue({
                        isBlock: !value,
                      });
                    }}
                  ></Switch>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* <Col span={12}>
            <div className="text-center">
              <Form.Item noStyle shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item
                      label="Ảnh bìa"
                      name="coverImage"
                      rules={[requiredRule]}
                    >
                      <SingleImageUpload
                        recommendedSize={RECOMMENDED_SIZE.coverImage}
                        onUploadOk={(path: string) => {
                          form.setFieldsValue({
                            coverImage: path,
                          });
                        }}
                        imageUrl={form.getFieldValue("coverImage")}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </div>
          </Col>
          <Col span={12}>
            <div className="text-center">
              <Form.Item noStyle shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item
                      label="Ảnh giới thiệu"
                      name="bioImage"
                      rules={[requiredRule]}
                    >
                      <SingleImageUpload
                        recommendedSize={RECOMMENDED_SIZE.bioImage}
                        onUploadOk={(path: string) => {
                          form.setFieldsValue({
                            bioImage: path,
                          });
                        }}
                        imageUrl={form.getFieldValue("bioImage")}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </div>
          </Col>
          {!!merchant?.signupImages?.length && (
            <Col span={24}>
              <div className="text-[14px] font-semibold mb-2">
                Hình ảnh đăng ký
              </div>
              <Row justify={"center"} gutter={[24, 24]}>
                {merchant?.signupImages.map((image) => (
                  <Col span={4}>
                    <Image
                      height={"100px"}
                      className="object-contain"
                      width={"100%"}
                      src={image.url}
                    ></Image>
                  </Col>
                ))}
              </Row>
            </Col>
          )} */}
          <Col span={24}>
            <Form.Item name={"bio"} label="Nội dung giới thiệu">
              <Input.TextArea
                rows={6}
                placeholder="Nhập nội dung giới thiệu về tiệm"
              ></Input.TextArea>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {addressVisible && (
        <AddressModal
          merchant={merchant}
          visible={addressVisible}
          onClose={() => setAddressVisible(false)}
          merchantId={merchant?.id || 0}
          onSubmitOk={(address, lat, long) => {
            form.setFieldsValue({ position: address, lat, long });
          }}
        />
      )}
    </>
  );
};
