import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

export const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Xin lỗi, bạn không có quyền truy cập đường dẫn này hoặc đường dẫn không tồn tại."
      extra={
        <Button
          onClick={() => {
            navigate("/");
          }}
          type="primary"
        >
          Về trang chủ
        </Button>
      }
    />
  );
};
