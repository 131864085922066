import { Pagination as AntPagination, PaginationProps } from "antd";

interface IPagination extends Omit<PaginationProps, "onChange"> {
  total: number;
  onChange: ({ page, limit }: { page: number; limit: number }) => void;
  currentPage: number;
}

export const Pagination = ({
  total,
  onChange,
  currentPage,
  ...props
}: IPagination) => {
  return (
    <AntPagination
      current={currentPage}
      style={{ marginTop: 12 }}
      total={total}
      showSizeChanger
      onChange={(page, limit) => {
        onChange({
          page,
          limit,
        });
      }}
      showTotal={(total) => `Tổng ${total} dữ liệu`}
      {...props}
    />
  );
};
