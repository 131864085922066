import { ExportOutlined } from "@ant-design/icons";
import { Popconfirm, Button } from "antd";
import { recordSaleApi } from "api/recordSale.api";
import dayjs from "dayjs";
import { RecordSaleQuery } from "hooks/useRecordSale";
import moment from "moment";
import { useState, useMemo, useEffect } from "react";
import {
  RecordSale,
  RecordSalePaymentStatusTrans,
  RecordSalePaymentTypeTrans,
} from "types/recordSale";
import { MyExcelColumn, handleExport } from "utils/MyExcel";
import { formatVND } from "utils/formatVND";

interface PropsType {
  summaries: RecordSale[];
  queryRecordSale: RecordSaleQuery;
}

const RecordSaleExportExcel = ({ summaries, queryRecordSale }: PropsType) => {
  const [percentExportComplete, setPercentExportComplete] = useState<number>(0);

  const [visible, setVisible] = useState<boolean>(false);

  const exportColumns: MyExcelColumn<RecordSale>[] = useMemo(() => {
    return [
      {
        width: 30,
        header: "Tháng",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "month",
        render: (record: RecordSale) => {
          return dayjs(record.month).format("MM-YYYY");
        },
      },
      {
        width: 30,
        header: "Mã thanh toán",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "code",
        render: (record: RecordSale) => {
          return record?.code;
        },
      },
      {
        width: 30,
        header: "Cửa hàng",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "name",
        render: (record: RecordSale) => {
          return record?.merchant?.name;
        },
      },
      {
        width: 50,
        header: "Email",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "email",
        render: (record: RecordSale) => {
          return record?.merchant?.email;
        },
      },
      {
        width: 30,
        header: "Số điện thoại",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "phone",
        render: (record: RecordSale) => {
          return record?.merchant?.phone;
        },
      },
      {
        width: 30,
        header: "Điểm",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "balance",
        render: (record: RecordSale) => {
          return formatVND(record?.merchant?.balance);
        },
      },
      {
        width: 30,
        header: "Tổng lịch đặt",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "totalAppointment",
        render: (record: RecordSale) => {
          return record?.totalAppointment;
        },
      },
      {
        width: 40,
        header: "Tổng lịch đặt xác nhận trễ",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "totalInvalidConfirmAppointment",
        render: (record: RecordSale) => {
          return record?.totalInvalidConfirmAppointment;
        },
      },
      {
        width: 20,
        header: "Tổng đánh giá tốt",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "totalGoodReview",
        render: (record: RecordSale) => {
          return record?.totalGoodReview;
        },
      },
      {
        width: 20,
        header: "Tổng đánh giá xấu",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "totalBadReview",
        render: (record: RecordSale) => {
          return record?.totalBadReview;
        },
      },
      {
        width: 40,
        header: "Phương thức thanh toán",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "paymentType",
        render: (record: RecordSale) => {
          return record?.paymentType
            ? RecordSalePaymentTypeTrans[record?.paymentType].label
            : "--";
        },
      },
      // {
      //   width: 20,
      //   header: "Doanh thu lịch đặt",
      //   headingStyle: {
      //     font: {
      //       bold: true,
      //     },
      //   },
      //   style: {
      //     alignment: {
      //       vertical: "middle",
      //     },
      //   },
      //   key: "moneyAppointment",
      //   render: (record: RecordSale) => {
      //     return formatVND(record.moneyAppointment);
      //   },
      // },
      // {
      //   width: 70,
      //   header: "Doanh thu voucher",
      //   headingStyle: {
      //     font: {
      //       bold: true,
      //     },
      //   },
      //   style: {
      //     alignment: {
      //       wrapText: true,
      //       vertical: "middle",
      //     },
      //   },
      //   key: "moneyVoucher",
      //   render: (record: RecordSale) => {
      //     return formatVND(record.moneyVoucher);
      //   },
      // },
      // {
      //   width: 50,
      //   header: "Doanh thu gói dịch vụ",
      //   headingStyle: {
      //     font: {
      //       bold: true,
      //     },
      //   },
      //   style: {
      //     alignment: {
      //       vertical: "middle",
      //     },
      //   },
      //   key: "moneyServicePack",
      //   render: (record: RecordSale) => {
      //     return formatVND(record.moneyServicePack);
      //   },
      // },
      {
        width: 40,
        header: "Tiền hoa hồng",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "moneyCommission",
        render: (record: RecordSale) => {
          return `${formatVND(record.moneyCommission)} ( ${
            record?.commissionPercent
          }%)`;
        },
      },
      {
        width: 40,
        header: "Tiền trả theo gói",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "moneyRenew",
        render: (record: RecordSale) => {
          return formatVND(record.moneyRenew);
        },
      },
      {
        width: 40,
        header: "Trạng thái thanh toán",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "paymentStatus",
        render: (record: RecordSale) => {
          return RecordSalePaymentStatusTrans[record.paymentStatus].label;
        },
      },
      // {
      //   width: 40,
      //   header: "Thanh toán",
      //   headingStyle: {
      //     font: {
      //       bold: true,
      //     },
      //   },
      //   style: {
      //     alignment: {
      //       vertical: "middle",
      //     },
      //   },
      //   key: "amount",
      //   render: (record: RecordSale) => {
      //     return formatVND(record.amount);
      //   },
      // },

      {
        width: 30,
        header: "Thời gian thanh toán",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "updatedAt",
        render: (record: RecordSale) => {
          return record.updatedAt
            ? moment.unix(record.updatedAt).format("HH:mm DD/MM/YYYY")
            : "--";
        },
      },
    ];
  }, []);

  useEffect(() => {
    if (percentExportComplete >= 100) {
      setTimeout(() => {
        setVisible(false);
      }, 500);
    }
  }, [percentExportComplete]);
  const handleExportExcel = async () => {
    setVisible(true);
    setPercentExportComplete(0);
    try {
      await handleExport({
        onProgress(percent) {
          setPercentExportComplete(percent);
        },
        exportColumns: exportColumns,
        fileType: "xlsx",
        dataField: "recordSales",
        query: queryRecordSale,
        api: recordSaleApi.findAll,
        fileName: "Danh sách thanh toán tiền cho Admin",
        sheetName: "Danh sách thanh toán tiền cho Admin",
        isGetFullData: false,
      });
    } finally {
      setPercentExportComplete(0);
      setVisible(false);
    }
  };

  return (
    <div>
      <Popconfirm
        title={`Bạn có muốn xuất file excel`}
        onConfirm={handleExportExcel}
        okText={"Xuất excel"}
        cancelText={"Huỷ"}
      >
        <Button
          type="primary"
          loading={false}
          icon={<ExportOutlined />}
          size="middle"
          disabled={!summaries.length}
        >
          Xuất file excel
        </Button>
      </Popconfirm>
    </div>
  );
};

export default RecordSaleExportExcel;
