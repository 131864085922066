import { withdrawApi } from "api/withdraw.api";
import { useRef, useState } from "react";
import { Withdraw } from "types/withdraw";
import { QueryParam } from "types/query";
import { dashboardApi } from "api/dashboard.api";
import { SummarySale } from "types/dashboard";
import { SummaryData } from "views/Merchant/components/TabPane/StatsSalePanelGroup";
import moment from "moment";

export interface DashboardQuery {
  fromAt?: number;
  toAt?: number;
  merchantId?: number;
  month?: number;
  page?: number;
  limit?: number
}

interface UseDashboardProps {
  initQuery: DashboardQuery;
}

export const useDashboard = ({ initQuery }: UseDashboardProps) => {
  const [summarySale, setSummarySale] = useState<SummarySale[]>([]);
  const [summaryRecordSale, setSummaryRecordSale] = useState<number>(0);
  const [summary, setSummary] = useState<SummarySale[]>([]);
  const [summaryData, setSummaryData] = useState<SummaryData>({
    appointments: 0,
    servicePack: 0,
    voucher: 0,
  });
  const [totalSummary, setTotalSummary] = useState(0);
  const [totalSummarySale, setTotalSummarySale] = useState(0);
  // const [query, setQuery] = useState<DashboardQuery>(initQuery);
  const query = useRef<DashboardQuery>(initQuery);
  const [loading, setLoading] = useState(false);
  const [loadingSale, setLoadingSale] = useState(false);
  const [loadingRecordSale, setLoadingRecordSale] = useState(false);

  const fetchSummarySale = async () => {
    setLoadingSale(true);
    try {
      const data = (await dashboardApi.summarySale(query.current))
        .data as SummarySale[];

      setSummarySale(data);
      const calSummary = calSummaryData(data);
      setSummaryData({
        appointments: calSummary.appointments,
        servicePack: calSummary.servicePack,
        voucher: calSummary.voucher,
      });
      // setTotalSummarySale(data.total);
    } finally {
      setLoadingSale(false);
    }
  };

  const fetchSummary = async () => {
    setLoading(true);
    try {
      const { data } = await dashboardApi.summary(query.current);

      setSummary(data);
      setTotalSummary(data.total);
    } finally {
      setLoading(false);
    }
  };
  const fetchSummaryRecordSale = async () => {
    setLoadingRecordSale(true);
    try {
      const { data } = await dashboardApi.summaryRecordSale({
        month: moment.unix(query.current.fromAt || 0).format("YYYY-MM"),
        merchantId: query.current?.merchantId,
      });

      setSummaryRecordSale(data.amount);
      // setTotalSummary(data.total);
    } finally {
      setLoadingRecordSale(false);
    }
  };

  const calSummaryData = (data: SummarySale[]) => {
    let initOutput: SummaryData = {
      appointments: 0,
      servicePack: 0,
      voucher: 0,
    };
    data?.forEach((item) => {
      initOutput = {
        appointments: initOutput.appointments + item.appointmentTotalSale,
        voucher: initOutput.voucher + item.voucherTotalSale,
        servicePack: initOutput.servicePack + item.servicePackTotalSale,
      };
    });
    return initOutput;
  };

  return {
    summarySale,
    summary,
    totalSummary,
    totalSummarySale,
    fetchSummarySale,
    fetchSummary,
    loading,
    query: query.current,
    summaryData,
    fetchSummaryRecordSale,
    summaryRecordSale,
    loadingRecordSale,
    loadingSale,
  };
};
