import { request } from "utils/request";
import { AxiosPromise } from "axios";
import settings from "settings";

export const cityApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/city",
      params,
    }),
  update: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/city/${id}`,
      method: "patch",
      data,
    }),
  uploadUrl: settings.apiUrl + "/v1/admin/city/upload",
};
