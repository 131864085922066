import { Col, Form, Input, InputNumber, message, Modal, Row } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { commissionConfigApi } from "api/commissionConfig.api";
import { renewPackageApi } from "api/renewPackage.api";
import { FormInputNumber } from "components/Input/FormInputNumber";
import React, { useImperativeHandle, useState } from "react";
import { CommissionConfig } from "types/commissionConfig";
import { ModalStatus } from "types/modal";
import { RenewPackage } from "types/renewPackage";
import { requiredRule } from "utils/validate";

export interface RenewPackageModal {
  handleCreate: () => void;
  handleUpdate: (renewPackage: RenewPackage) => void;
}
interface RenewPackageModalProps {
  onClose: () => void;
  onSubmitOk: () => void;
}

export const RenewPackageModal = React.forwardRef(
  ({ onClose, onSubmitOk }: RenewPackageModalProps, ref) => {
    const [form] = useForm<{
      id: number;
      createdAt: number;
      updatedAt: number;
      isDeleted: boolean;
      deletedAt: number;
      deletedBy: string;
      name: string;
      days: number; //số ngày giá hạn
      price: number;
      type: string;
    }>();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [type, setType] = useState("");
    const [status, setStatus] = useState<ModalStatus>("create");
    useImperativeHandle<any, RenewPackageModal>(
      ref,
      () => ({
        handleCreate() {
          form.resetFields();
          setVisible(true);
          setStatus("create");
        },
        handleUpdate(renewPackage: RenewPackage) {
          form.setFieldsValue({ ...renewPackage });

          console.log("type", renewPackage.type);
          setType(renewPackage.type);
          setVisible(true);
          setStatus("update");
        },
      }),
      []
    );

    const getFormData = () => {
      const { id, ...data } = form.getFieldsValue();
      return {
        id,
        renewPackage: data,
      };
    };

    const onSubmit = async () => {
      await form.validateFields();
      setLoading(true);
      try {
        const { id, ...renewPackage } = getFormData();
        if (id) {
          await renewPackageApi.update(id || 0, renewPackage);
        } else {
          await renewPackageApi.create(renewPackage);
        }
        message.success("Thao tác thành công!");
        onClose();
        onSubmitOk();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        onCancel={() => {
          form.resetFields();
          onClose?.();
          setVisible(false);
        }}
        visible={visible}
        title={status == "create" ? "Tạo dữ liệu" : "Chỉnh sửa dữ liệu"}
        style={{ top: 20 }}
        width={700}
        confirmLoading={loading}
        onOk={onSubmit}
      >
        <Form layout="vertical" form={form}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Tên gói" name="name" rules={[requiredRule]}>
                <Input placeholder="Nhập tên gói" />
              </Form.Item>
              <Form.Item label="Giá tiền" name="price" rules={[requiredRule]}>
                {type === "TIER1" ? (
                  <>
                    <FormInputNumber
                      disabled
                      placeholder="Nhập giá tiền"
                      addonAfter="đ"
                    />
                  </>
                ) : (
                  <FormInputNumber placeholder="Nhập giá tiền" addonAfter="đ" />
                )}
                {/* <Input placeholder="Nhập giá tiền" /> */}
              </Form.Item>
              <Form.Item label="Mô tả" name="desc">
                <TextArea rows={5} placeholder="Nhập mô tả" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
);
