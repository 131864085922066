import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const checkoutApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/checkout",
      params,
    }),
  detail: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/checkout/${id}`,
      method: "get",
    }),
};
