import moment from "moment";
import { format } from "date-fns";
import viLocale from "date-fns/locale/vi";
export const unixToDate = (unixValue: number) => {
  const dateFormat = moment
    .unix(unixValue)
    .format(process.env.REACT_APP_DATE_FORMAT);
  return dateFormat;
};

export const unixToTime = (unixValue: number) => {
  const dateFormat = moment
    .unix(unixValue)
    .format(process.env.REACT_APP_TIME_FORMAT);
  return dateFormat;
};

export const unixToFullTime = (unixValue: number) => {
  const dateFormat = moment
    .unix(unixValue)
    .locale("vi")
    .format("HH:mm, DD/MM/YYYY");
  return dateFormat;
};

export const formattedDate = (createdAt?: number) => {
  return format(moment.unix(createdAt || 0).toDate(), "eeee, dd MMMM yyyy", {
    //@ts-ignore
    locale: viLocale,
  });
};

export const formattedFullDate = (createdAt?: number) => {
  return format(moment.unix(createdAt || 0).toDate(), "eeee, dd MMMM yyyy", {
    //@ts-ignore
    locale: viLocale,
  });
};
