import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const authApi = {
  login: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/auth/login",
      data,
      method: "post",
    }),

  passwordUpdate: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/auth/password/update",
      data,
      method: "post",
    }),

  profile: (): AxiosPromise<any> =>
    request({
      url: "/v1/admin/auth/profile",
    }),
};
