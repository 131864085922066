import { Button, Form, Input, message, Modal, Tree } from "antd";
import { Rule } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import { roleApi } from "api/role.api";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { adminRoutes } from "router";
import { userStore } from "store/userStore";
import { ModalStatus } from "types/modal";
import { Permission, Role } from "types/role";
import { Staff } from "types/staff";

const rules: Rule[] = [{ required: true, message: "Bắt buộc" }];

interface ITreeData {
  title: string;
  key: string;
  children?: ITreeData[];
}

export const RoleModal = observer(
  ({
    open,
    status,
    role,
    onClose,
    onSubmitOk,
  }: {
    open: boolean;
    status: ModalStatus;
    role: Partial<Role>;
    onClose: () => void;
    onSubmitOk: () => void;
  }) => {
    const [form] = Form.useForm<Staff>();
    const [loading, setLoading] = useState(false);
    const [treeData, setTreeData] = useState<ITreeData[]>([]);
    const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
    const [permissions, setPermissions] = useState<Permission[]>([]);

    useEffect(() => {
      if (status == "create" && open) {
        form.resetFields();
      }
    }, [open, status]);

    useEffect(() => {
      form.setFieldsValue({ ...role });
      generateSelectedKeys();
      return () => {};
    }, [role]);

    useEffect(() => {
      if (open) {
        generateTreeData();
        fetchPermissions();
      }
    }, [open]);

    const fetchPermissions = async () => {
      const res = await roleApi.getPermission();
      setPermissions(res.data);
    };

    const generateSelectedKeys = async () => {
      if (role.id) {
        const res = await roleApi.findOne(role.id);
        const permissions: Permission[] = res.data.permissions;

        setCheckedKeys(permissions.map((e) => e.path));
      }
    };

    const generateTreeData = () => {
      if (treeData.length) {
        return;
      }

      for (const route of adminRoutes) {
        const data: ITreeData = {
          title: route.title || "",
          key: route.path || "",
        };

        treeData.push(data);
        if (route.children) {
          data.children = [];
          for (const childRoute of route.children) {
            data.children.push({
              title: childRoute.title || "",
              key: route.path + "/" + childRoute.path || "",
            });
          }
        }
      }

      setTreeData([...treeData]);
    };

    const createData = async (data: any) => {
      setLoading(true);
      try {
        await roleApi.create(data);
        message.success("Tạo mới thành công");
        onClose();
        onSubmitOk();
      } finally {
        setLoading(false);
      }
    };

    const updateData = async (data: any) => {
      setLoading(true);
      try {
        await roleApi.update(role?.id || 0, data);
        message.success("Cập nhật thành công");
        onClose();
        onSubmitOk();
      } finally {
        setLoading(false);
      }
    };

    const handleSubmit = async () => {
      await form.validateFields();

      const data = {
        info: form.getFieldsValue(),
        permissionIds: checkedKeys
          .map((e) => {
            const find = permissions.find((p) => p.path == e);
            return find?.id;
          })
          .filter((e) => e),
      };
      if (status == "create") {
        createData({ role: data.info });
      } else {
        updateData(data);
      }
    };

    const onSelect = (selectedKeys: any, info: any) => {};

    const onCheck = (checkedKeys: any, info: any) => {
      setCheckedKeys(checkedKeys);
    };

    const renderFooter = () => {
      const arr = [];

      const cancelBtn = (
        <Button onClick={onClose} type="default">
          Đóng
        </Button>
      );
      const okBtn = (
        <Button
          type="primary"
          onClick={() => {
            handleSubmit();
          }}
        >
          Lưu
        </Button>
      );
      if (status == "create" || role?.id != 1) {
        arr.push(cancelBtn);
        arr.push(okBtn);
      }

      return arr.length ? arr : false;
    };

    return (
      <Modal
        onCancel={onClose}
        visible={open}
        title={status == "create" ? "Tạo mới quyền" : "Cập nhật quyền"}
        style={{ top: 20 }}
        footer={renderFooter()}
        width={700}
        confirmLoading={loading}
      >
        <Form layout="vertical" form={form}>
          <Form.Item label="Level" name="name" rules={rules}>
            <Input placeholder="" />
          </Form.Item>

          <Form.Item label="Mô tả" name="description">
            <TextArea rows={3} />
          </Form.Item>
        </Form>
        {status == "update" && (
          <Tree
            checkable
            onSelect={onSelect}
            onCheck={onCheck}
            treeData={treeData}
            checkedKeys={checkedKeys}
          ></Tree>
        )}
      </Modal>
    );
  }
);
