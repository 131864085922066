import { Input, Space, Spin, Table, Tag } from "antd";
import Column from "antd/lib/table/Column";
import { Pagination } from "components/Pagination";
import useCustomerTransaction from "hooks/useCustomerTransaction";
import moment from "moment";
import { useEffect } from "react";
import {
  CustomerTransaction,
  CustomerTransactionType,
  customerTransactionTypeTrans,
} from "types/customerTransaction";
import { formatVND, getTitle } from "utils";

interface CustomerTransactionProps {
  initialQuery?: Record<string, any>;
  title?: string;
}

export const CustomerTransactionPage = ({
  initialQuery,
  title = "",
}: CustomerTransactionProps) => {
  const { data, fetchData, loading, query, filterData, total } =
    useCustomerTransaction({
      initialQuery: {
        page: 1,
        limit: 20,
        ...initialQuery,
      },
    });

  useEffect(() => {
    document.title = getTitle(title);
    fetchData();
  }, []);

  return (
    <div>
      <div className="filter-container">
        <Space>
          <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              size="middle"
              onChange={(ev) => {
                filterData({
                  search: ev.target.value,
                });
              }}
              placeholder="Tìm kiếm theo mã giao dịch"
            />
          </div>
        </Space>
      </div>

      <Spin spinning={loading}>
        <Table
          scroll={{
            x: 800,
            y: 600,
          }}
          pagination={false}
          rowKey="id"
          dataSource={data}
        >
          <Column
            align="center"
            width={60}
            title="STT"
            dataIndex="stt"
            key="stt"
            render={(text, record: CustomerTransaction, index) => index + 1}
          />
          <Column
            title="Mã giao dịch"
            dataIndex="code"
            key="code"
            render={(text, record: CustomerTransaction) => text}
          />
          <Column
            title="Loại"
            dataIndex="type"
            key="type"
            align="center"
            render={(
              text: CustomerTransactionType,
              record: CustomerTransaction
            ) => (
              <Tag color={customerTransactionTypeTrans[text]?.color}>
                {customerTransactionTypeTrans[text]?.label}
              </Tag>
            )}
          />
          <Column
            title="Giá trị"
            align="right"
            dataIndex="amount"
            key="amount"
            render={(text, record: CustomerTransactionType) => {
              const isIncrease = text > 0;

              return (
                <span
                  style={{
                    fontWeight: 500,
                    color: isIncrease ? "green" : "red",
                  }}
                >
                  {isIncrease && "+"}
                  {formatVND(text)}
                  <sup>đ</sup>
                </span>
              );
            }}
          />

          <Column
            title="Số dư ban đầu"
            align="right"
            dataIndex="beforeChange"
            key="beforeChange"
            render={(text, record: CustomerTransactionType) => (
              <>
                {formatVND(text)}
                <sup>đ</sup>
              </>
            )}
          />
          <Column
            width={200}
            title="Số dư sau khi thay đổi"
            align="right"
            dataIndex="afterChange"
            key="afterChange"
            render={(text, record: CustomerTransactionType) => (
              <>
                {formatVND(text)}
                <sup>đ</sup>
              </>
            )}
          />

          <Column
            title="Ngày tạo"
            dataIndex="createdAt"
            key="createdAt"
            render={(text, record: CustomerTransactionType) =>
              text ? moment.unix(text).format("HH:mm:ss DD/MM/YYYY") : ""
            }
          />
        </Table>

        <Pagination
          total={total || 0}
          currentPage={query.page || 1}
          onChange={({ page, limit }) => {
            filterData({ page, limit });
          }}
          defaultPageSize={query.limit}
        />
      </Spin>
    </div>
  );
};
