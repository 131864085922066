import { Col, Form, message, Modal, Row, Select } from "antd";
import { Rule } from "antd/lib/form";
import Input from "antd/lib/input/Input";
import { contentDefineApi } from "api/contentDefine.api";
import { RichTextEditor } from "components/Editor/RichTextEditor";
import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import {
  ContentDefine,
  ContentDefineType,
  ContentDefineTypeTrans,
} from "types/contentDefine";
import { ModalStatus } from "types/modal";

const rules: Rule[] = [{ required: true }];
const { Option } = Select;

export const ContentDefineModal = observer(
  ({
    visible,
    status,
    contentDefine,
    onClose,
    onSubmitOk,
  }: {
    visible: boolean;
    status: ModalStatus;
    contentDefine: Partial<ContentDefine>;
    onClose: () => void;
    onSubmitOk: () => void;
  }) => {
    const [form] = Form.useForm<ContentDefine>();
    const [, setForceUpdate] = useState({});
    const [loading, setLoading] = useState(false);
    // const [content, setContent] = useState("");
    const editorRef = useRef<{ setContent: (content: string) => void }>(null);
    const isEditorInit = useRef(false);

    useEffect(() => {
      if (status == "create" && visible) {
        form.resetFields();
      } else {
      }
      // setContent(contentDefine.body || "");
    }, [visible, status]);

    useEffect(() => {
      form.setFieldsValue({ ...contentDefine });
      if (isEditorInit.current) {
        editorRef.current?.setContent(contentDefine.body || "");
      }
      setForceUpdate({});
    }, [contentDefine]);

    const createData = async () => {
      const valid = await form.validateFields();
      const data = {
        contentDefine: {
          ...form.getFieldsValue(),
          // body: content,
        },
      };

      setLoading(true);
      try {
        const res = await contentDefineApi.create(data);
        message.success("Tạo mới thành công!");
        onClose();
        onSubmitOk();
      } finally {
        setLoading(false);
      }
    };

    const updateData = async () => {
      const valid = await form.validateFields();

      const data = {
        contentDefine: {
          ...form.getFieldsValue(),
          // body:form.getFieldValue('body') ||  content,
        },
      };

      setLoading(true);
      try {
        const res = await contentDefineApi.update(contentDefine?.id || 0, data);
        message.success("Cập nhật thành công");
        onClose();
        onSubmitOk();
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        maskClosable={false}
        onCancel={onClose}
        visible={visible}
        title={
          <h1 className="text-secondary text-lg font-bold">
            {status === "create"
              ? "Tạo mới nội dung"
              : `Cập nhật nội dung - ${
                  //@ts-ignore
                  ContentDefineTypeTrans[contentDefine?.type] || ""
                }`}
          </h1>
        }
        style={{ top: 20 }}
        width={1200}
        cancelText="Đóng"
        okText="Lưu"
        confirmLoading={loading}
        onOk={() => {
          status == "create" ? createData() : updateData();
        }}
      >
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Tên nội dung" name="title" rules={rules}>
                <Input />
              </Form.Item>
              {/* <Form.Item label="Loại" name="type" rules={rules}>
                <Select disabled={status == "update"} style={{ width: "100%" }}>
                  {Object.values(ContentDefineType).map((e) => (
                    <Option key={e} value={e}>
                      {ContentDefineTypeTrans[e]}
                    </Option>
                  ))}
                </Select>
              </Form.Item> */}
            </Col>
            <Col span={24}>
              <Form.Item shouldUpdate noStyle>
                {() => (
                  <Form.Item required label="Nội dung" name="body">
                    <RichTextEditor
                      ref={editorRef}
                      onInit={() => {
                        editorRef.current?.setContent(
                          form.getFieldValue("body")
                        );
                        isEditorInit.current = true;
                      }}
                      onChange={(val) => {
                        form.setFieldsValue({
                          body: val,
                        });
                      }}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
);
