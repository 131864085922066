import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Space, Spin, Table } from "antd";
import { Pagination } from "components/Pagination";
import { useEffect, useRef } from "react";
import { getTitle } from "utils";
import useDictionary from "hooks/useDictionary";
import { Dictionary, DictionaryType } from "types/dictionary";
import { ReviewSampleModal } from "./components/ReviewSampleModal";

const { Column } = Table;

export const ReviewSamplePage = ({ title = "", description = "" }) => {
  const {
    fetchDictionary,
    isFetchDictionaryLoading,
    dictionaries,
    queryDictionary,
    totalDictionary,
    filterDictionary,
    deleteDictionary,
  } = useDictionary({
    initialQuery: {
      page: 1,
      limit: 20,
      type: DictionaryType.ReviewSample,
    },
  });
  const modalRef = useRef<ReviewSampleModal>(null);

  useEffect(() => {
    document.title = getTitle(title);
    fetchDictionary();
  }, []);
  return (
    <section className="box">
      <div className="flex flex-col gap-1 mb-5">
        <h1 className="text-xl m-0 ">{title}</h1>
        <span className="font-medium">{description}</span>
      </div>
      <div className="filter-container">
        <Space>
          <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              size="middle"
              onChange={(ev) => {
                filterDictionary({ search: ev.currentTarget.value, page: 1 });
              }}
              placeholder="Tìm tên đánh giá mẫu"
            />
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => fetchDictionary()}
              type="primary"
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => {
                modalRef.current?.handleCreate();
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              Thêm mới
            </Button>
          </div>
        </Space>
      </div>

      <Spin spinning={isFetchDictionaryLoading}>
        <Table pagination={false} rowKey="id" dataSource={dictionaries}>
          <Column
            title="Đánh giá mẫu"
            dataIndex="name"
            key="name"
            render={(text, record: Dictionary) => {
              return (
                <span
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                  className="text-secondary cursor-pointer hover:underline font-medium"
                >
                  {record?.name}
                </span>
              );
            }}
          />

          <Column
            width={150}
            title="Hành động"
            key="action"
            render={(text, record: Dictionary) => (
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                >
                  Cập nhật
                </Button>
                <Popconfirm
                  title="Bạn có chắc chắn muốn thực hiện thao tác?"
                  onConfirm={async () => {
                    await deleteDictionary(record.id);
                  }}
                >
                  <Button type="primary" danger>
                    Xóa
                  </Button>
                </Popconfirm>
              </Space>
            )}
          />
        </Table>

        <Pagination
          currentPage={queryDictionary.page}
          total={totalDictionary}
          pageSize={queryDictionary.limit}
          onChange={({ limit, page }) => {
            queryDictionary.page = page;
            queryDictionary.limit = limit;
            fetchDictionary();
          }}
        />
      </Spin>

      <ReviewSampleModal
        dictionaryList={dictionaries}
        onSubmitOk={fetchDictionary}
        onClose={() => {}}
        ref={modalRef}
      />
    </section>
  );
};
