import { AxiosPromise } from "axios";
import { request } from "utils/request";

export const dashboardApi = {
  summarySale: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/dashboard/summary/sale",
      params,
    }),
  summaryRecordSale: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/dashboard/summary/recordSale",
      params,
    }),
  summary: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/dashboard/summary",
      params,
    }),
};
