import { AxiosPromise } from "axios";
import { request } from "utils/request";

export const roleApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/role",
      params,
    }),
  findOne: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/role/${id}`,
    }),
  getPermission: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/role/permissions",
      params,
    }),
  create: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/role",
      data,
      method: "post",
    }),
  importPermission: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/role/permissions/import",
      data,
      method: "post",
    }),

  update: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/role/${id}/update`,
      method: "post",
      data,
    }),
  delete: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/role/${id}`,
      method: "delete",
    }),
};
