export interface CommissionConfig {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  name: string;
  percent: number;
  type: CommissionConfigType;
}

export enum CommissionConfigType {
  StandardBooking = "STANDARD_BOOKING", // tiêu chuẩn
  Tier1Booking = "TIER1_BOOKING", // 50 booking/tháng
  Tier2Booking = "TIER2_BOOKING", // 100 booking/tháng
  Guarantee = "GUARANTEE", //đảm bảo cơ sở vật chất
  HighReview = "HIGH_REVIEW", //có lượt đánh giá cao
  LowReview = "LOW_REVIEW", //có lượt đánh giá thấp
}

export const commissionConfigTypeObject = {
  [CommissionConfigType.StandardBooking]: {
    label: "Tiêu chuẩn",
    value: CommissionConfigType.StandardBooking,
  },
  [CommissionConfigType.Tier1Booking]: {
    label: "Có 50 booking 1 tháng trở lên",
    value: CommissionConfigType.Tier1Booking,
  },
  [CommissionConfigType.Tier2Booking]: {
    label: "Có 100 booking 1 tháng trở lên",
    value: CommissionConfigType.Tier2Booking,
  },
  [CommissionConfigType.Guarantee]: {
    label: "Cơ sở dịch vụ đảm bảo 100% các quy định về xác nhận booking",
    value: CommissionConfigType.Guarantee,
  },
  [CommissionConfigType.HighReview]: {
    label:
      "Trong tháng có từ 10 review đánh giá 5 sao, không có review nào từ 3 sao trở xuống",
    value: CommissionConfigType.HighReview,
  },
  [CommissionConfigType.LowReview]: {
    label: "Có từ 5 đánh giá từ 1 đến 2 sao trong 1 tháng",
    value: CommissionConfigType.LowReview,
  },
};
