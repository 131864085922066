import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Popconfirm,
  Popover,
  Space,
  Spin,
  Switch,
  Table,
  Tabs,
  TabsProps,
  Typography,
  message,
} from "antd";
import { Pagination } from "components/Pagination";
import useCouponCampaign from "hooks/useCouponCampaign";
import { useEffect, useRef, useState } from "react";
import {
  CouponCampaignTypeTrans,
  CouponCampaign,
  CouponDiscountTypeTrans,
  CouponDiscountType,
  CouponCampaignType,
} from "types/couponCampaign";
import { formatVND, getTitle } from "utils";
import { CouponCampaignModal } from "./components/CouponCampaignModal";

const { Column } = Table;

export const CouponCampaignPage = ({ title = "", description="" }) => {
  const {
    couponCampaigns,
    isFetchCouponCampaignLoading,
    queryCouponCampaign,
    fetchCouponCampaign,
    filterCouponCampaign,
    toggleEnable,
    deleteCouponCampaign,
  } = useCouponCampaign({
    initialQuery: {
      page: 1,
      limit: 20,
      type: CouponCampaignType.NewMemberShip,
    },
  });
  const modalRef = useRef<CouponCampaignModal>(null);

  const [currTab, setCurrTab] = useState<CouponCampaignType>(
    CouponCampaignType.NewMemberShip
  );

  const items: TabsProps["items"] = [
    {
      key: CouponCampaignType.NewMemberShip,
      label: CouponCampaignTypeTrans[CouponCampaignType.NewMemberShip],
    },
    {
      key: CouponCampaignType.DOB,
      label: CouponCampaignTypeTrans[CouponCampaignType.DOB],
    },
    {
      key: CouponCampaignType.FirstRegister,
      label: CouponCampaignTypeTrans[CouponCampaignType.FirstRegister],
    },
  ];

  useEffect(() => {
    document.title = getTitle(title);
    fetchCouponCampaign();
  }, []);

  const onTabChange = (activeKey: string) => {
    setCurrTab(activeKey as CouponCampaignType);
    queryCouponCampaign.type = activeKey;
    fetchCouponCampaign();
  };

  return (
    <section className="box">
      <div className="flex flex-col gap-1 mb-5">
        <h1 className="text-xl m-0 ">{title}</h1>
        <span className="font-medium">{description}</span>
      </div>
      {/* <div className="filter-container">
        <Space>
          <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              size="middle"
              onChange={(ev) => {
                filterCouponCampaign({
                  search: ev.currentTarget.value,
                  page: 1,
                });
              }}
              placeholder="Tìm câu hỏi"
            />
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => fetchCouponCampaign()}
              type="primary"
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </div>

        </Space>
      </div> */}
      <Tabs items={items} activeKey={currTab} onChange={onTabChange} />

      {/* {currTab === CouponCampaignType.NewMemberShip && (
        <div className="filter-container">
          <Space>
            <div className="filter-item">
              <label htmlFor="">Tìm kiếm</label>
              <Input
                size="middle"
                onChange={(ev) => {
                  filterCouponCampaign({
                    search: ev.currentTarget.value,
                    page: 1,
                  });
                }}
                placeholder="Tìm kiếm"
              />
            </div>

            <div className="filter-item btn">
              <Button
                onClick={() => fetchCouponCampaign()}
                type="primary"
                icon={<SearchOutlined />}
              >
                Tìm kiếm
              </Button>
            </div>

            <div className="filter-item btn">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  modalRef.current?.handleCreate();
                }}
              >
                Thêm mới
              </Button>
            </div>
          </Space>
        </div>
      )} */}

      <Spin spinning={isFetchCouponCampaignLoading}>
        <Table
          pagination={false}
          rowKey="id"
          dataSource={couponCampaigns}
          scroll={{ x: "max-content" }}
        >
          <Column
            title="Tên chiến dịch"
            dataIndex="name"
            width={200}
            key="name"
            render={(text, record: CouponCampaign) => {
              return (
                <span
                  className="font-medium text-secondary cursor-pointer"
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                >
                  {text}
                </span>
              );
            }}
          />
          <Column
            title="Loại"
            dataIndex="type"
            width={200}
            key="type"
            render={(text, record: CouponCampaign) => {
              return <span>{CouponCampaignTypeTrans[record?.type]}</span>;
            }}
          />
          <Column
            align="end"
            title="Giá trị giảm"
            dataIndex="discountValue"
            width={150}
            key="discountValue"
            render={(text, record: CouponCampaign) => {
              return (
                <span className="font-medium text-secondary">
                  {formatVND(text)}
                </span>
              );
            }}
          />
          <Column
            align="center"
            title="Giảm theo"
            dataIndex="discountType"
            width={150}
            key="discountType"
            render={(text, record: CouponCampaign) => {
              return (
                <span>{CouponDiscountTypeTrans[record?.discountType]}</span>
              );
            }}
          />
          <Column
            align="end"
            title="Số điểm cần có để nhận"
            dataIndex="minBalance"
            width={350}
            key="minBalance"
            render={(text) => {
              return (
                <span className="font-medium text-secondary">
                  {formatVND(text)}
                </span>
              );
            }}
          />
          <Column
            align="end"
            title="Giá trị áp dụng tối thiểu (VNĐ)"
            dataIndex="conditionValue"
            width={300}
            key="conditionValue"
            render={(text, record: CouponCampaign) => {
              return (
                <span className="font-medium text-secondary">
                  {formatVND(text)}
                </span>
              );
            }}
          />
          <Column
          align="end"
            title="Giảm tối đa (VNĐ)"
            dataIndex="discountMaxValue"
            width={200}
            key="discountMaxValue"
            render={(text, record: CouponCampaign) => {
              return (
                <span className="font-medium text-secondary">
                  {record.discountType === CouponDiscountType.Percent &&
                    formatVND(text)}
                </span>
              );
            }}
          />

          <Column
            title="Hoạt động"
            align="center"
            width={200}
            dataIndex="isEnabled"
            key="isEnabled"
            render={(text, record: CouponCampaign) => (
              <span>
                <Popconfirm
                  onConfirm={async () => {
                    await toggleEnable(record.id, !record.isEnabled);
                  }}
                  title="Bạn có chắc chắn muốn thực hiện thao tác này?"
                >
                  <Switch
                    checked={record.isEnabled}
                    checkedChildren="Hoạt động"
                    unCheckedChildren="Khóa"
                  ></Switch>
                </Popconfirm>
                {/* <Tag color={text ? "green" : "red"}>
                    {text ? "Bật" : "Tắt"}
                  </Tag> */}
              </span>
            )}
          />
          <Column
            title="Có giá trị trong (ngày)"
            align="center"
            width={250}
            dataIndex="expiredInDay"
            key="expiredInDay"
            render={(text, record: CouponCampaign) => <span>{text}</span>}
          />

          <Column
            width={150}
            title="Hành động"
            key="action"
            render={(text, record: CouponCampaign) => (
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                >
                  Cập nhật
                </Button>
              </Space>
            )}
          />
        </Table>

        {/* <Pagination
          currentPage={queryCouponCampaign.page}
          total={totalCouponCampaign}
          pageSize={queryCouponCampaign.limit}
          onChange={({ limit, page }) => {
            // filterData({ limit, page });
          }}
        /> */}
      </Spin>

      <CouponCampaignModal
        // CouponCampaignList={couponCampaigns}
        onSubmitOk={fetchCouponCampaign}
        onClose={() => {}}
        ref={modalRef}
      />
    </section>
  );
};
