import { PrinterOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useRef, useState } from "react";
import PrintExample from "./PrintExample";
import { Checkout, CheckoutDetail } from "types/checkout";
import { ServicePackOrder } from "types/servicePackOrder";
import { Appointment } from "types/appointment";
import ReactToPrint from "react-to-print";

export const printStyle = `
@media print {
  @page { 
    size: A5 portrait
  }
 
}
table, th, td {
  border: 1px solid;
}
table {
  width: 100%;
  border-collapse: collapse;
}
`;

interface IProps {
  data: Checkout;
  checkoutDetailWithSingleServiceOptions: CheckoutDetail[];
  servicePackOrderList: ServicePackOrder[];
  hasSelectOnlyServicePackOrder: boolean;
  appointment?: Appointment;
}
const PrintBillBtn = ({
  data,
  checkoutDetailWithSingleServiceOptions,
  hasSelectOnlyServicePackOrder,
  servicePackOrderList,
  appointment,
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(null);

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <div className="flex justify-between">
            <h1 className="text-base">In hóa đơn</h1>
            <PrinterOutlined className="text-base"></PrinterOutlined>
          </div>
        )}
        pageStyle={printStyle}
        content={() => componentRef.current}
      />
      <PrintExample
        data={data}
        ref={componentRef}
        checkoutDetailWithSingleServiceOptions={
          checkoutDetailWithSingleServiceOptions
        }
        hasSelectOnlyServicePackOrder={hasSelectOnlyServicePackOrder}
        servicePackOrderList={servicePackOrderList}
        appointment={appointment}
      />
    </>
  );
};

export default React.memo(PrintBillBtn);
