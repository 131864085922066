import { Appointment } from "./appointment";
import { Customer } from "./customer";
import { Employee } from "./employee";
import { Merchant } from "./merchant";
import { Promotion } from "./promotion";

export enum CustomerTransactionType {
  Deposit = "DEPOSIT", //(+) nạp tiền vào ví
  Payment = "PAYMENT", //(-) thanh toán dịch vụ
  CancelBooking = "CANCEL_BOOKING", //(+) hoàn tiền đặt lịch
  Review = "REVIEW", // (+) đánh giá service, spa
  RefundCheckout = "REFUND_CHECKOUT", //(+) số điểm hoàn lại khi checkout
}

export const customerTransactionTypeTrans = {
  [CustomerTransactionType.Deposit]: {
    value: CustomerTransactionType.Deposit,
    label: "Nạp tiền vào ví",
    color: "green",
  },
  [CustomerTransactionType.Payment]: {
    value: CustomerTransactionType.Payment,
    label: "Thanh toán dịch vụ",
    color: "blue",
  },
  [CustomerTransactionType.CancelBooking]: {
    value: CustomerTransactionType.CancelBooking,
    label: "Hoàn tiền đặt lịch",
    color: "cyan",
  },
  [CustomerTransactionType.Review]: {
    value: CustomerTransactionType.Review,
    label: "Đánh giá Service & Spa",
    color: "yellow",
  },
  [CustomerTransactionType.RefundCheckout]: {
    value: CustomerTransactionType.RefundCheckout,
    label: "Hoàn điểm",
    color: "purple",
  },
};

export interface CustomerTransaction {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  code: string;
  note: string;
  amount: number;
  beforeChange: number;
  afterChange: number;
  type: CustomerTransactionType;
  appointment: Appointment;
  promotion: Promotion;
  employee: Employee;
  customer: Customer;
  merchant: Merchant; //sử dụng dịch vụ tại spa nào?
  // paymentOrder: PaymentOrder;
  // rate: Rate;
}
