import { ExportOutlined } from "@ant-design/icons";
import { Popconfirm, Button, message } from "antd";
import { checkoutApi } from "api/checkout.api";
import { CheckoutQuery } from "hooks/useFetchCheckout";
import { useState, useMemo, useEffect } from "react";
import { Checkout, CheckoutStatusTrans } from "types/checkout";
import { MyExcelColumn, getExportData } from "utils/MyExcel";
import { unixToFullTime } from "utils/formatDate";
import { formatVND } from "utils/formatVND";
import { HandleExportCheckoutExcel } from "./HandleExportCheckout";

interface PropsType {
  summaries: Checkout[];
  queryCheckout: CheckoutQuery;
}

const CheckoutExportExcel = ({ summaries, queryCheckout }: PropsType) => {
  const [percentExportComplete, setPercentExportComplete] = useState<number>(0);

  const [visible, setVisible] = useState<boolean>(false);

  const exportColumns: MyExcelColumn<Checkout>[] = useMemo(() => {
    return [
      {
        width: 30,
        header: "Mã",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "code",
        render: (record: Checkout) => {
          return `#${record?.code}`;
        },
      },
      {
        width: 30,
        header: "Tên khách hàng",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "customerName",
        render: (record: Checkout) => {
          return record?.customerProfile?.fullName || "Khách hàng ẩn danh";
        },
      },
      // {
      //   width: 30,
      //   header: "Cửa hàng",
      //   headingStyle: {
      //     font: {
      //       bold: true,
      //     },
      //   },
      //   style: {
      //     alignment: {
      //       vertical: "middle",
      //     },
      //   },

      //   key: "name",
      //   render: (record: Checkout) => {
      //     return record?.merchant?.name;
      //   },
      // },
      // {
      //   width: 15,
      //   header: "Liên hệ",
      //   headingStyle: {
      //     font: {
      //       bold: true,
      //     },
      //   },
      //   style: {
      //     alignment: {
      //       vertical: "middle",
      //     },
      //   },
      //   key: "phone",
      //   render: (record: Checkout) => {
      //     return `${record?.merchant?.email} - ${record?.merchant?.phone}`;
      //   },
      // },
      {
        width: 30,
        header: "Tên nhân viên",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "employeeName",
        render: (record: Checkout) => {
          return record?.employee?.name;
        },
      },
      {
        width: 40,
        header: "Dịch vụ",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "serviceName",
        // render: (record: Checkout) => {
        //   return record?.servicePack?.name;
        // },
      },
      {
        width: 20,
        header: "Giá (VNĐ)",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "price",
        // render: (record: Checkout) => {
        //   return formatVND(record?.);
        // },
      },
      // {
      //   width: 20,
      //   header: "Ghi chú",
      //   headingStyle: {
      //     font: {
      //       bold: true,
      //     },
      //   },
      //   style: {
      //     alignment: {
      //       vertical: "middle",
      //     },
      //   },
      //   key: "note",
      // render: (record: Checkout) => {
      //   return formatVND(record?.moneyTotal);
      // },
      // },

      {
        width: 20,
        header: "Tổng giá (VNĐ)",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "moneyTotal",
        render: (record: Checkout) => {
          return formatVND(record?.moneyTotal);
        },
      },
      {
        width: 20,
        header: "Trạng thái",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "status",
        render: (record: Checkout) => {
          return CheckoutStatusTrans[record.status].label;
        },
      },
      {
        width: 70,
        header: "Ngày tạo",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            wrapText: true,
            vertical: "middle",
          },
        },
        key: "createdAt",
        render: (record: Checkout) => {
          return unixToFullTime(record?.createdAt);
        },
      },
    ];
  }, []);

  useEffect(() => {
    if (percentExportComplete >= 100) {
      setTimeout(() => {
        setVisible(false);
      }, 500);
    }
  }, [percentExportComplete]);
  const handleExportExcel = async () => {
    try {
      // debugger;
      const data = await getExportData<Checkout>({
        onProgress(percent) {
          setPercentExportComplete(percent);
        },
        limit: 100,
        dataField: "checkouts",
        api: checkoutApi.findAll,
        query: { ...queryCheckout, isMapCode: true },
        isGetFullData: true,
      });
      await HandleExportCheckoutExcel(
        data,
        exportColumns,
        "Danh sách checkout",
        "Danh sách checkout"
      );
    } catch (error) {
      message.error("Có lỗi xảy ra, vui lòng thử lại!");
      console.log("error ne ", error);
    }
  };

  return (
    <div>
      <Popconfirm
        title={`Bạn có muốn xuất file excel`}
        onConfirm={handleExportExcel}
        okText={"Xuất excel"}
        cancelText={"Huỷ"}
      >
        <Button
          type="primary"
          loading={false}
          icon={<ExportOutlined />}
          size="middle"
          disabled={!summaries.length}
        >
          Xuất file excel
        </Button>
      </Popconfirm>
    </div>
  );
};

export default CheckoutExportExcel;
