import { dashboardApi } from "api/dashboard.api";
import { serviceApi } from "api/service.api";
import { useRef, useState } from "react";
import { QueryParam } from "types/query";
import { Service } from "types/treatment";

export interface ServicesQuery extends QueryParam {}

export interface MerchantStats {
  appointmentTotalSale: number;
  servicePackTotalSale: number;
  voucherTotalSale: number;
  date: string;
}

interface UseServicesProps {
  initQuery: ServicesQuery;
}

export const useServices = ({ initQuery }: UseServicesProps) => {
  const [query, setQuery] = useState<ServicesQuery>(initQuery);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Service[]>([]);

  const fetchServices = async () => {
    setLoading(true);
    try {
      const data = await serviceApi
        .findAll(query)
        .then(({ data }) => data.services);
      setData(data);
    } finally {
      setLoading(false);
    }
  };

  return {
    services: data,
    fetchServices,
    loadingService: loading,
    queryService: query,
  };
};
