import { Col, Form, Input, Modal, Row, Switch, message } from "antd";
import { Rule } from "antd/lib/form";
import { useForm, useWatch } from "antd/lib/form/Form";
import { newsApi } from "api/news.api";
import {
  IRichTextEditorRef,
  RichTextEditor,
} from "components/Editor/RichTextEditor";
import { GenerateObjectUrl } from "components/GenerateObjectUrl/GenerateObjectUrl";
import MerchantSelector from "components/Selector/MerchantSelector";
import TagSelector from "components/Selector/TagSelector";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import { RECOMMENDED_SIZE } from "constant/size";
import { useEffect, useRef, useState } from "react";
import { ModalStatus } from "types/modal";
import { News } from "types/news";
import { slugify } from "utils/url";
import { requiredRule } from "utils/validate";

export const NewsModal = ({
  visible,
  news,
  onClose,
  onSubmitOk,
  status,
}: {
  visible: boolean;
  news?: Partial<News>;
  onClose: () => void;
  onSubmitOk: () => void;
  status: ModalStatus;
}) => {
  const [form] = useForm<News & { tagIds: number[]; merchantId: number }>();
  const newId = useWatch("id", form);
  const title = useWatch("title", form);
  const [loading, setLoading] = useState(false);
  const editorRef = useRef<IRichTextEditorRef>();

  useEffect(() => {
    if (visible) {
      if (!news) {
        form.resetFields();
        form.setFieldsValue({ isVisible: true, isHighlight: true });
      } else {
        form.setFieldsValue({
          ...news,
          merchantId: news.merchant?.id,
          tagIds: news.tags
            ?.filter((item) => !item.isDeleted)
            .map((item) => item.id),
        });
        editorRef.current?.setContent(news.content || "");
      }
    }

    return () => {};
  }, [news, status, visible]);

  const getFormData = () => {
    const { id, tagIds, merchantId, ...data } = form.getFieldsValue();
    return {
      id,
      news: data,
      tagIds,
      merchantId,
    };
  };

  const onSubmit = async () => {
    await form.validateFields();
    setLoading(true);
    try {
      const { id, ...news } = getFormData();

      if (id) {
        await newsApi.update(id || 0, news);
      } else {
        await newsApi.create(news);
      }
      message.success("Thao tác thành công!");
      onClose();
      onSubmitOk();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      title={
        <h1 className="text-secondary text-lg font-bold">
          {status === "create"
            ? "Tạo mới tin tức"
            : `Cập nhật tin tức - ${news?.title || ""}`}
        </h1>
      }
      style={{ top: 20 }}
      width={"100%"}
      onCancel={onClose}
      confirmLoading={loading}
      onOk={onSubmit}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{ isVisible: false, isHighlight: false }}
      >
        <Form.Item hidden name={"id"}>
          <Input></Input>
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Col span={24} className="text-center">
              <Form.Item noStyle shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item
                      rules={[requiredRule]}
                      label="Hình ảnh"
                      name="thumbnail"
                    >
                      <SingleImageUpload
                        recommendedSize={RECOMMENDED_SIZE.newsImage}
                        uploadUrl={newsApi.uploadUrl}
                        // recommendSize={{ width: 133, height: 100 }}
                        onUploadOk={(path: string) => {
                          form.setFieldsValue({
                            thumbnail: path,
                          });
                        }}
                        imageUrl={form.getFieldValue("thumbnail")}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Tiêu đề"
                extra={
                  newId && (
                    <GenerateObjectUrl
                      link={slugify(title + `- ${form.getFieldsValue().id}`)}
                      subLink="news"
                    />
                  )
                }
                name="title"
                rules={[requiredRule]}
              >
                <Input placeholder="Nhập tiêu đề" />
              </Form.Item>
              <Form.Item label="Tiệm Spa" name="merchantId">
                <MerchantSelector
                  originData={news?.merchant}
                  showSearch
                  filterOption={false}
                  allowClear
                ></MerchantSelector>
              </Form.Item>
              <Form.Item label="Từ khóa" name="tagIds">
                <TagSelector
                  originData={news?.tags}
                  mode="multiple"
                  allowClear
                ></TagSelector>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                initialValue={true}
                label="Trạng thái"
                name="isVisible"
                valuePropName="checked"
              >
                <Switch checkedChildren="Hiển thị" unCheckedChildren="Ẩn" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                initialValue={true}
                label="Nổi bật"
                name="isHighlight"
                valuePropName="checked"
              >
                <Switch checkedChildren="Nổi bật" unCheckedChildren="Nổi bật" />
              </Form.Item>
            </Col>
            <Form.Item label="Tóm tắt" name="shortContent">
              <Input.TextArea
                placeholder="Nhập nội dung tóm tắt"
                rows={4}
              ></Input.TextArea>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Col span={24}>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item required label="Nội dung" name="content">
                      <RichTextEditor
                        uploadUrl={newsApi.uploadUrl}
                        inputHeight={760}
                        ref={editorRef}
                        onInit={() => {
                          // editorRef.current?.setContent(content);
                        }}
                        onChange={(content) => {
                          getFieldValue("content");
                        }}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
