import {
  Avatar,
  Col,
  Form,
  Image,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Tabs,
} from "antd";
import { DatePicker } from "antd";
import { RadioChangeEvent } from "antd/lib";
import { customerApi } from "api/customer.api";
import { recordSaleApi } from "api/recordSale.api";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import dayjs from "dayjs";
import moment from "moment";
import React, { useImperativeHandle, useState } from "react";
import { Customer, GenderType, genderTypeObject } from "types/customer";
import { ModalStatus } from "types/modal";
import {
  RecordSale,
  RecordSalePaymentType,
  RecordSalePaymentTypeTrans,
} from "types/recordSale";
import { formatVND } from "utils";
import { disableFuture } from "utils/date-picker";
import { getCustomerAddress, getMerchantAddress } from "utils/url";
import { emailRule, requiredRule } from "utils/validate";
import { CustomerTransactionPage } from "views/CustomerTransaction/CustomerTransaction";

export interface IPaymentConfirmModal {
  handleCreate: () => void;
  handleOpen: (recordSale: RecordSale) => void;
}
interface PaymentConfirmModalProps {
  onClose: () => void;
  onSubmitOk: () => void;
}

export const PaymentConfirmModal = React.forwardRef(
  ({ onClose, onSubmitOk }: PaymentConfirmModalProps, ref) => {
    const [form] = Form.useForm<Customer>();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<ModalStatus>("create");
    const [recordSaleSelected, setRecordSaleSelected] = useState<RecordSale>();

    const [recordSalePaymentTypeSelected, setRecordSalePaymentTypeSelected] =
      useState<RecordSalePaymentType>(RecordSalePaymentType.Point);

    const onChange = (e: RadioChangeEvent) => {
      console.log("radio checked", e.target.value);
      setRecordSalePaymentTypeSelected(e.target.value);
    };

    useImperativeHandle<any, IPaymentConfirmModal>(
      ref,
      () => ({
        handleCreate() {
          form.resetFields();
          setVisible(true);
          setStatus("create");
        },
        handleOpen(recordSale: RecordSale) {
          setRecordSaleSelected(recordSale);
          setVisible(true);
          setStatus("update");
        },
      }),
      []
    );

    const getFormData = () => {
      const data = form.getFieldsValue();
      return data;
    };

    const onSubmit = async () => {
      await form.validateFields();
      const data = getFormData();
      setLoading(true);
      try {
        const payload = {
          ...data,
          paymentType: recordSalePaymentTypeSelected,
        };
        await recordSaleApi.paid(recordSaleSelected?.id || 0, payload);
        message.success("Thanh toán tiền hoa hồng thành công!");
        onSubmitOk();
        form.resetFields();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    console.log(recordSaleSelected);

    return (
      <Modal
        onCancel={() => {
          form.resetFields();
          setVisible(false);
          setRecordSalePaymentTypeSelected(RecordSalePaymentType.Point);
        }}
        visible={visible}
        title={
          <h1 className="text-secondary text-lg font-bold">
            {status === "create" ? "" : `Thanh toán tiền hoa hồng`}
          </h1>
        }
        style={{ top: 20 }}
        width={500}
        confirmLoading={loading}
        destroyOnClose
        onOk={onSubmit}
        okText="Xác nhận"
      >
        <div>
          <div className="mb-10">
            <div className="text-center">
              <Image
                src={recordSaleSelected?.merchant.avatar}
                className="!w-[100px] !h-[100px] rounded-full object-contain shadow-md"
              ></Image>
              <h1 className="font-medium text-lg text-secondary">
                {recordSaleSelected?.merchant.name}
              </h1>
              <h1 className="font-medium text-base text-secondary">
                Số điểm hiện có:{" "}
                {formatVND(recordSaleSelected?.merchant?.balance)} điểm
              </h1>
            </div>
          </div>
          <h1 className="font-medium">Chọn hình thức thanh toán</h1>
          <div className="mt-2 mb-5">
            <Radio.Group
              onChange={onChange}
              value={recordSalePaymentTypeSelected}
            >
              {Object.values(RecordSalePaymentTypeTrans).map((item) => (
                <Radio value={item.value}>{item.label}</Radio>
              ))}
            </Radio.Group>
          </div>
          <div>
            {recordSalePaymentTypeSelected === RecordSalePaymentType.Point ? (
              <p>
                Xác nhận thanh toán bằng điểm: Bạn đang chọn cách thanh toán
                bằng điểm. Hành động này sẽ trừ{" "}
                <span className="font-medium text-secondary">
                  {" "}
                  {formatVND(recordSaleSelected?.amount)}
                </span>{" "}
                điểm từ tài khoản của{" "}
                <span className="font-medium text-secondary">
                  {recordSaleSelected?.merchant.name}
                </span>
              </p>
            ) : (
              <Form layout="vertical" form={form}>
                <Form.Item
                  label="Số giao dịch"
                  name="transNo"
                  rules={[requiredRule]}
                >
                  <Input placeholder="Nhập số giao dịch" />
                </Form.Item>
                <Form.Item
                  label="Số tài khoản"
                  name="bankAccountNumber"
                  rules={[requiredRule]}
                >
                  <Input placeholder="Nhập số tài khoản" />
                </Form.Item>
                <Form.Item
                  label="Tên chủ tài khoản"
                  name="bankAccountName"
                  rules={[requiredRule]}
                >
                  <Input placeholder="Nhập tên chủ tài khoản" />
                </Form.Item>
                <Form.Item
                  label="Tên ngân hàng"
                  name="bankName"
                  rules={[requiredRule]}
                >
                  <Input placeholder="Nhập tên ngân hàng" />
                </Form.Item>
              </Form>
            )}
          </div>
        </div>
      </Modal>
    );
  }
);
