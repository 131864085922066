import { ExportOutlined } from "@ant-design/icons";
import { Button, Modal, Popconfirm, Progress, message } from "antd";

import { dashboardApi } from "api/dashboard.api";
import dayjs from "dayjs";
import { DashboardQuery } from "hooks/useDashboard";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { SummarySale } from "types/dashboard";

import { MyExcelColumn, getExportData, handleExport } from "utils/MyExcel";
import { formatVND } from "utils/formatVND";
// import { productTypeList } from "../storePage";

interface PropsType {
  summarySales: SummarySale[];
  queryDashboard: DashboardQuery;
  commission: number;
}

const SummarySaleExportExcel = ({
  summarySales,
  queryDashboard,
  commission,
}: PropsType) => {
  const [percentExportComplete, setPercentExportComplete] = useState<number>(0);

  const [visible, setVisible] = useState<boolean>(false);

  const exportColumns: MyExcelColumn<SummarySale>[] = useMemo(() => {
    return [
      {
        width: 20,
        header: "Ngày",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "createdAt",
        render: (record: SummarySale) => {
          return dayjs(record?.date).format("DD/MM/YYYY");
        },
      },
      {
        width: 30,
        header: "Doanh thu dịch vụ (VNĐ)",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "appointmentTotalSale",
        render: (record: SummarySale) => {
          return formatVND(record.appointmentTotalSale);
        },
      },
      {
        width: 30,
        header: "Doanh thu gói dịch vụ (VNĐ)",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "servicePackTotalSale",
        render: (record: SummarySale) => {
          return formatVND(record.servicePackTotalSale);
        },
      },
      {
        width: 30,
        header: "Doanh thu voucher (VNĐ)",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "voucherTotalSale",
        render: (record: SummarySale) => {
          return formatVND(record.voucherTotalSale);
        },
      },
      // {
      //   width: 30,
      //   header: "Doanh thu hoa hồng (VNĐ)",
      //   headingStyle: {
      //     font: {
      //       bold: true,
      //     },
      //   },
      //   style: {
      //     alignment: {
      //       vertical: "middle",
      //     },
      //   },
      //   key: "voucherTotalSale",
      //   render: (record: SummarySale) => {
      //     return formatVND(commission);
      //   },
      // },
    ];
  }, [summarySales, commission]);

  useEffect(() => {
    if (percentExportComplete >= 100) {
      setTimeout(() => {
        setVisible(false);
      }, 500);
    }
  }, [percentExportComplete]);
  const handleExportExcel = async () => {
    setVisible(true);
    setPercentExportComplete(0);
    // console.log(moment.unix(queryDashboard.toAt!).month());
    const fileName = `Doanh thu ${
      queryDashboard.fromAt
        ? `tháng ${moment.unix(queryDashboard.fromAt).month() + 1}`
        : "tất cả các tháng"
    }`;
    try {
      await handleExport({
        onProgress(percent) {
          setPercentExportComplete(percent);
        },
        exportColumns: exportColumns,
        fileType: "xlsx",
        dataField: undefined,
        query: queryDashboard,
        api: dashboardApi.summarySale,
        fileName,
        sheetName: fileName,
        isGetFullData: false,
      });
    } finally {
      setPercentExportComplete(0);
      setVisible(false);
    }
  };

  return (
    <div>
      <Popconfirm
        title={`Bạn có muốn xuất file excel`}
        onConfirm={handleExportExcel}
        okText={"Xuất excel"}
        cancelText={"Huỷ"}
      >
        <Button
          type="primary"
          loading={false}
          icon={<ExportOutlined />}
          size="middle"
          disabled={!summarySales.length}
        >
          Xuất file excel
        </Button>
      </Popconfirm>
    </div>
  );
};

export default SummarySaleExportExcel;
