import { Dictionary } from "./dictionary";
import { Staff } from "./staff";

export enum LeadStatus {
  New = "NEW", //mới
  Resolved = "RESOLVED", //đã xử lý
}

export const LeadStatusTrans = {
  [LeadStatus.New]: {
    color: "orange",
    value: LeadStatus.New,
    label: "Mới",
  },
  [LeadStatus.Resolved]: {
    color: "green",
    value: LeadStatus.Resolved,
    label: "Đã xử lý",
  },
};

export interface Lead {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  fullName: string; //họ và tên
  phone: string;
  title: string; //chủ đề
  email: string;
  content: string; //nội dung
  status: LeadStatus;
  resolvedAt: number;
  staff: Staff; //nv nào xử lý
  leadTopic: Dictionary;
}
