import { Button, Form, Input } from "antd";
import "./styles/LoginPage.scss";
import React, { useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { userStore } from "store/userStore";
import { useNavigate } from "react-router-dom";
import { requiredRule } from "utils/validate";

const { Item: FormItem } = Form;

export const LoginPage = () => {
  const [form] = Form.useForm<{ username: string; password: string }>();
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();

  const handleSubmit = async () => {
    await form.validateFields();
    console.log("handleSubmit:");
    setLoading(true);
    const { username, password } = form.getFieldsValue();
    try {
      await userStore.login(username, password);
      await userStore.getProfile();
      navigation("/");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-page">
      <div style={{ paddingTop: 120 }}>
        <div className="login-container">
          <div className="logo text-center">
            <img
              className="inline-block w-[150px]"
              src={require("assets/images/logo.png")}
              style={{ marginBottom: "1em" }}
              alt=""
            />
          </div>

          <Form onFinish={handleSubmit} form={form} layout={"vertical"}>
            <FormItem
              rules={[requiredRule]}
              label="Tên đăng nhập"
              name="username"
            >
              <Input
                className="h-[42px] p-0 pl-3"
                placeholder="Nhập tên đăng nhập"
                prefix={<UserOutlined />}
              />
            </FormItem>

            <FormItem rules={[requiredRule]} label="Mật khẩu" name="password">
              <Input.Password
                placeholder="Nhập mật khẩu"
                prefix={<LockOutlined />}
                size="large"
              />
            </FormItem>

            <FormItem>
              <Button
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
                className="btn-login"
                type="primary"
              >
                Đăng nhập
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
  );
};
