import {
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Popover,
  Row,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import { merchantApi } from "api/merchant.api";
import AppModal, { AppModalAction } from "components/Modal/AppModal";
import { Pagination } from "components/Pagination";
import useMerchant, { MerchantQuery } from "hooks/useMerchant";
import { useEffect, useRef, useState } from "react";
import { Merchant, MerchantStatus, merchantStatusTrans } from "types/merchant";
import { ModalStatus } from "types/modal";
import { getMerchantAddress } from "utils/url";
import { MerchantModal } from "./components/MerchantModal";
import { requiredRule, wordAndNumberOnlyRule } from "utils/validate";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import { GenerateObjectUrl } from "components/GenerateObjectUrl/GenerateObjectUrl";
import { AddressSelect } from "components/AddressSelect/AddressSelect";
import { ApproveMerchantModal } from "./components/ApproveMerchantModal";
import useRenewPackage from "hooks/useRenewPackage";
import MerchantExportExcel from "./components/MerchantExportExcel";
import { formatVND } from "utils";

const { Column } = Table;

export const MerchantTab = ({
  initialQuery,
  lastUpdated,
  onStatusChanged,
}: {
  initialQuery?: {
    [P in keyof MerchantQuery]?: MerchantQuery[P];
  };
  lastUpdated: number;
  onStatusChanged?: () => void;
}) => {
  const {
    query,
    fetchData,
    loading,
    data,
    total,
    deleteData,
    toggleBlock,
    toggleVisible,
    filterData,
  } = useMerchant({
    initialQuery: {
      page: 1,
      limit: 20,
      ...initialQuery,
    },
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalStatus, setModalStatus] = useState<ModalStatus>("create");
  const [selectedMerchant, setSelectedMerchant] = useState<Merchant>();
  const rejectMerchantModal = useRef<AppModalAction>();
  const approveMerchantModal = useRef<ApproveMerchantModal>();

  useEffect(() => {
    fetchData();
  }, [lastUpdated]);

  const deleteMerchant = async (idMerchant: number) => {
    await deleteData(idMerchant);
    message.success("Thao tác thành công");
  };

  const confirm = (merchant: Merchant) => {
    Modal.confirm({
      title: "Xác nhận thao tác",
      icon: <ExclamationCircleOutlined />,
      content: `Bạn có muốn xóa ${merchant.name}?`,
      okText: "Đồng ý",
      cancelText: "Hủy",
      onOk: () => deleteMerchant(merchant.id),
    });
  };
  const confirmAction = (cb: any) => {
    Modal.confirm({
      title: "Xác nhận thao tác",
      icon: <ExclamationCircleOutlined />,
      content: `Bạn có muốn tiếp tục thao tác?`,
      okText: "Tiếp tục",
      cancelText: "Hủy",
      onOk: cb,
    });
  };

  const afterConfirmActionSuccess = () => {
    message.success("Thao tác thành công");
    fetchData();
    onStatusChanged?.();
  };

  const { data: renewPackages, fetchData: fetchRenewPackage } = useRenewPackage(
    {
      initialQuery: { page: 1, limit: 50 },
    }
  );
  useEffect(() => {
    fetchData();
    fetchRenewPackage();
    return () => {};
  }, []);

  return (
    <section>
      <div className="filter-container">
        <Space>
          <div className="filter-item btn">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              onChange={(e) => {
                let text = e.target.value;
                filterData({ search: text, page: 1 });
              }}
              size="middle"
              // style={{ width: 350 }}
              placeholder="Tên cửa hàng, Số điện thoại"
            />
          </div>

          <div className="filter-item btn mt-1">
            <Button
              onClick={() => fetchData(query)}
              type="primary"
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </div>
          <div className="filter-item btn mt-1">
            <Button
              onClick={() => {
                setSelectedMerchant(undefined);
                setModalStatus("create");
                setIsModalVisible(true);
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              Thêm mới
            </Button>
          </div>
          <div className="filter-item btn mt-1">
            <MerchantExportExcel Merchants={data} queryMerchant={query} />
          </div>
        </Space>
      </div>

      <div className="merchant-table">
        <Spin spinning={loading}>
          <Table
            onRow={(record: Merchant) => ({
              style: {
                background: record.isBlock ? "#FFF1F0" : "",
              },
            })}
            rowKey="id"
            dataSource={data}
            pagination={false}
            scroll={{ x: "max-content" }}
          >
            <Column
              fixed
              title="Tên cửa hàng"
              key="name"
              width={250}
              render={(text, record: Merchant) => (
                <div className="flex items-center gap-3">
                  <Image
                    src={record.avatar || "/logo.png"}
                    preview={false}
                    className="rounded-full shadow-md !w-[40px] !h-[40px] object-cover"
                  />
                  <span
                    onClick={
                      () => {
                        setIsModalVisible(true);
                        setModalStatus("update");
                        setSelectedMerchant(record);
                      }
                      // : () => message.info("Vui lòng duyệt tài khoản trước")
                    }
                    className="text-secondary font-medium cursor-pointer hover:underline"
                  >
                    {record.name}
                  </span>
                </div>
              )}
            />

            {/* <Column
              width={100}
              align="center"
              title="Trạng thái"
              key="status"
              render={(text, record: Merchant) => (
                <Tag color={merchantStatusTrans[record.status].color}>
                  {merchantStatusTrans[record.status].label}
                </Tag>
              )}
            /> */}

            {/* <Column
              width={500}
              title="Email"
              key="address"
              render={(text, record: Merchant) => (
                <>
                  <p>
                    <b>Địa chỉ: </b>
                    {getMerchantAddress(record)}
                  </p>
                  <p>
                    <b>Số điện thoại: </b>
                    {record.phone || "Không có thông tin"}
                  </p>
                  <p>
                    <b>Email: </b>
                    {record.email || "Không có thông tin"}
                  </p>
                </>
              )}
            /> */}

            <Column
              width={150}
              title="Email"
              key="address"
              render={(text, record: Merchant) => (
                <>
                  <p>{record.email || "Không có thông tin"}</p>
                </>
              )}
            />
            <Column
              width={150}
              title="Số diện thoại"
              key="address"
              render={(text, record: Merchant) => (
                <>
                  <p>{record.phone || "Không có thông tin"}</p>
                </>
              )}
            />
            <Column
              width={110}
              title="Điểm"
              align="center"
              dataIndex={"balance"}
              render={(text) => (
                <span className="text-secondary font-medium">
                  {formatVND(text)}
                </span>
              )}
            />
            <Column
              width={300}
              title="Địa chỉ"
              key="address"
              render={(text, record: Merchant) => (
                <>
                  <Tooltip title={record.address}>
                    <span className="line-clamp-2"> {record?.address}</span>
                  </Tooltip>
                </>
              )}
            />

            {initialQuery?.status == MerchantStatus.Approve && (
              <>
                <Column
                  width={150}
                  align="center"
                  title="Hiển thị"
                  render={(text, record: Merchant) => (
                    <div style={{ textAlign: "center" }}>
                      <Switch
                        onClick={() =>
                          confirmAction(async () => {
                            await toggleVisible(record.id, !record.isVisible);
                          })
                        }
                        checked={record.isVisible}
                        checkedChildren="Hiển thị"
                        unCheckedChildren="Ẩn"
                      ></Switch>
                    </div>
                  )}
                />
                <Column
                  width={200}
                  align="center"
                  title="Kích hoạt"
                  render={(text, record: Merchant) => (
                    <div style={{ textAlign: "center" }}>
                      <Switch
                        onClick={() => {
                          confirmAction(async () => {
                            await toggleBlock(record.id, !record.isBlock);
                          });
                        }}
                        checked={!record.isBlock}
                        checkedChildren="Hoạt động"
                        unCheckedChildren="Đã khóa"
                      ></Switch>
                    </div>
                  )}
                />
              </>
            )}

            {initialQuery?.status == MerchantStatus.Reject && (
              <Column
                width={200}
                title="Lý do"
                key="rejectReason"
                dataIndex={"rejectReason"}
              />
            )}

            <Column
              fixed="right"
              align="center"
              width={200}
              title="Hành động"
              key="action"
              render={(text, record: Merchant) => {
                return (
                  <span>
                    {record.status == MerchantStatus.Approve && (
                      <Space>
                        <Button
                          type="primary"
                          onClick={() => {
                            setIsModalVisible(true);
                            setModalStatus("update");
                            setSelectedMerchant(record);
                          }}
                        >
                          Cập nhật
                        </Button>

                        <Button danger onClick={() => confirm(record)}>
                          Xóa
                        </Button>
                      </Space>
                    )}
                    {(record.status == MerchantStatus.Pending ||
                      record.status == MerchantStatus.Reject) && (
                      <Space>
                        <Button
                          type="primary"
                          onClick={() => {
                            approveMerchantModal.current?.open(record);
                          }}
                        >
                          {record.status == MerchantStatus.Reject
                            ? "Duyệt lại"
                            : "Duyệt"}
                        </Button>

                        {record.status == MerchantStatus.Pending && (
                          <Button
                            danger
                            onClick={() => {
                              rejectMerchantModal.current?.open(record);
                            }}
                          >
                            Từ chối
                          </Button>
                        )}
                      </Space>
                    )}
                  </span>
                );
              }}
            />
          </Table>
          <Pagination
            total={total}
            defaultPageSize={query.limit}
            currentPage={query.page}
            onChange={({ limit, page }) => {
              filterData({ page, limit });
            }}
          />
        </Spin>

        <AppModal
          classNames={{
            body: "min-h-[150px] ",
          }}
          onOk={async ({ form, close }) => {
            const { id, reason } = form.getFieldsValue();
            await merchantApi.reject(id, { reason: reason || "" });
            afterConfirmActionSuccess();
            close();
          }}
          ref={rejectMerchantModal}
          destroyOnClose
          afterClose={({ form }) => {
            form.resetFields();
          }}
          title="Từ chối yêu cầu"
        >
          {({ data, form }) =>
            data ? (
              <section className="reject-merchant-modal">
                <Form
                  initialValues={{
                    id: data.id,
                  }}
                  layout="vertical"
                  form={form}
                >
                  <Form.Item hidden name={"id"}>
                    <Input></Input>
                  </Form.Item>
                  <Form.Item name={"reason"} label="Lý do từ chối yêu cầu">
                    <Input.TextArea
                      rows={4}
                      placeholder="Nhập lý do từ chối"
                    ></Input.TextArea>
                  </Form.Item>
                </Form>
              </section>
            ) : (
              <></>
            )
          }
        </AppModal>
        <ApproveMerchantModal
          renewPackages={renewPackages}
          ref={approveMerchantModal}
          onSubmitOk={() => {
            afterConfirmActionSuccess();
          }}
        />

        <MerchantModal
          renewPackages={renewPackages}
          isModalVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onSubmitOk={() => {
            fetchData();
            onStatusChanged?.();
          }}
          status={modalStatus}
          merchant={selectedMerchant}
        />
      </div>
    </section>
  );
};
