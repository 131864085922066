import {
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Switch,
} from "antd";
import { Rule } from "antd/lib/form";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import React, { useImperativeHandle, useState } from "react";
import { PaymentType } from "types/paymentType";
import { ModalStatus } from "types/modal";
// import { requiredRule } from "utils/validateRule";
import { paymentTypeApi } from "api/paymentType.api";
import { requiredRule } from "utils/validate";
import TextArea from "antd/lib/input/TextArea";

export interface PaymentTypeModal {
  // handleCreate: () => void;
  handleUpdate: (PaymentType: PaymentType) => void;
}
interface PaymentTypeModalProps {
  onClose: () => void;
  onSubmitOk: () => void;
}

export const PaymentTypeModal = React.forwardRef(
  ({ onClose, onSubmitOk }: PaymentTypeModalProps, ref) => {
    const [form] = Form.useForm<PaymentType>();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<ModalStatus>("create");

    useImperativeHandle<any, PaymentTypeModal>(
      ref,
      () => ({
        // handleCreate() {
        //   form.resetFields();
        //   setVisible(true);
        //   setStatus("create");
        // },
        handleUpdate(PaymentType: PaymentType) {
          form.setFieldsValue({ ...PaymentType });
          setVisible(true);
          setStatus("update");
        },
      }),
      []
    );

    const getFormData = () => {
      const { id, ...data } = form.getFieldsValue();
      return {
        id,
        paymentType: data,
      };
    };

    const onSubmit = async () => {
      await form.validateFields();
      setLoading(true);
      try {
        const { id, ...paymentType } = getFormData();
        await paymentTypeApi.update(id || 0, paymentType);

        message.success("Thao tác thành công!");
        onClose();
        onSubmitOk();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        onCancel={() => {
          form.resetFields();
          onClose?.();
          setVisible(false);
        }}
        visible={visible}
        title={status == "create" ? "Tạo dữ liệu" : "Chỉnh sửa dữ liệu"}
        style={{ top: 20 }}
        width={700}
        confirmLoading={loading}
        onOk={onSubmit}
      >
        <Form layout="vertical" form={form}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>

          <Col span={24} className="text-center">
            <Form.Item noStyle shouldUpdate={true}>
              {() => {
                return (
                  <Form.Item
                    className="text-center"
                    rules={[requiredRule]}
                    label="Hình đại diện"
                    name="icon"
                  >
                    <SingleImageUpload
                      // uploadUrl={LeadTopicApi.uploadUrl}
                      onUploadOk={(path: string) => {
                        form.setFieldsValue({
                          icon: path,
                        });
                      }}
                      imageUrl={form.getFieldValue("icon")}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="name" label="Hình thức thanh toán">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="desc" label="Mô tả">
                <TextArea rows={5} showCount maxLength={500} />
              </Form.Item>
            </Col>

            {/* <Col span={24}>
              <Form.Item name="position" label="Vị trí hiển thị">
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col> */}

            <Col span={12}>
              <Form.Item
                initialValue={true}
                label="Mặc định"
                name="isDefault"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren="Mặc định"
                  unCheckedChildren="Mặc định"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                initialValue={true}
                label="Hoạt động"
                name="isEnabled"
                valuePropName="checked"
              >
                <Switch checkedChildren="Bật" unCheckedChildren="Tắt" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
);
