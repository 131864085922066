import {
  Avatar,
  Button,
  message,
  Popconfirm,
  Space,
  Spin,
  Switch,
  Table,
} from "antd";
import { useEffect, useRef } from "react";
import { getTitle } from "utils";
import usePaymentSouce from "hooks/usePaymentSouce";
import { PaymentSource } from "types/paymentSource";
import { PaymentSourceModal } from "./components/PaymentSourceModal";
import { PlusOutlined } from "@ant-design/icons";
import { paymentSourceApi } from "api/paymentSource.api";
const { Column } = Table;

export const PaymentSourcePage = ({ title = "" }) => {
  const modalRef = useRef<PaymentSourceModal>(null);
  const { fetchPaymentSouce, isFetchPaymentSouceLoading, paymentSouces } =
    usePaymentSouce({
      initialQuery: {
        page: 1,
        limit: 20,
      },
    });

  useEffect(() => {
    document.title = getTitle(title);
    fetchPaymentSouce();
  }, []);

  return (
    <section className="box">
      <div className="filter-item btn mb-2">
        <Button
          onClick={() => {
            modalRef.current?.handleCreate();
          }}
          type="primary"
          icon={<PlusOutlined />}
        >
          Thêm mới
        </Button>
      </div>
      <Spin spinning={isFetchPaymentSouceLoading}>
        <Table pagination={false} rowKey="id" dataSource={paymentSouces}>
          <Column
            width={60}
            align="center"
            title="Ảnh"
            dataIndex="icon"
            key="icon"
            render={(text) => (
              <Avatar
                src={text || "/logo.png"}
                size={40}
                shape="square"
              />
            )}
          />
          <Column
            title="Nguồn thanh toán"
            dataIndex="name"
            width={300}
            key="name"
            render={(text, record: PaymentSource) => {
              return (
                <span className="text-secondary cursor-pointer font-medium">
                  {text}
                </span>
              );
            }}
          />
          <Column
            width={150}
            title="Hành động"
            key="action"
            render={(text, record: PaymentSource) => (
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                >
                  Cập nhật
                </Button>
                <Popconfirm
                  title="Bạn có chắc chắn muốn thực hiện thao tác?"
                  onConfirm={async () => {
                    await paymentSourceApi.delete(record.id);
                    fetchPaymentSouce()
                    message.success("Xóa thành công!");
                  }}
                >
                  <Button type="primary" danger>
                    Xóa
                  </Button>
                </Popconfirm>
              </Space>
            )}
          />
        </Table>
      </Spin>
      <PaymentSourceModal
        onSubmitOk={fetchPaymentSouce}
        onClose={() => {}}
        ref={modalRef}
      />
    </section>
  );
};
