import {
  CheckOutlined,
  CloseOutlined,
  FolderOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Dropdown,
  Popover,
  Row,
  Spin,
  Tag,
} from "antd";
import React, { useImperativeHandle, useState } from "react";
import { CheckoutPaymentTypeTrans, PaymentType } from "types/checkout";
import { QueryParam } from "types/query";
import { Service } from "types/service";
import { formatMoneyWithMinusSymbol, formatVND } from "utils/formatVND";
//@ts-ignore
import dayjs from "dayjs";
import { BiPackage } from "react-icons/bi";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { LuTicket } from "react-icons/lu";
import { useNavigate } from "react-router";
import { Appointment, ServiceOptionCustom } from "types/appointment";
import {
  PaymentOrder,
  PaymentOrderDetail,
  PaymentOrderStatusTrans,
} from "types/paymentOrder";
import { ServicePackOrderStatus } from "types/servicePackOrder";
import { formattedDate } from "utils/formatDate";
// import { findOneAppointment } from "views/Appointment/AppointmentPage";
// import { findOrderPayment } from "views/Appointment/components/ReviewOrderPaymentModal";
import { renderExpiredDate } from "utils/voucher";

export interface PaymentOrderDrawer {
  handleOpen: (PaymentOrder: PaymentOrder) => void;
}

interface ExtendedService extends Service {
  serviceEditSelected: number;
}

interface PaymentOrderDrawerProps {
  // onClose: () => void;
  // selectedInvoices: Checkout;
}

export const PaymentOrderDrawer = React.forwardRef(
  ({}: PaymentOrderDrawerProps, ref) => {
    const navigate = useNavigate();
    const [query, setQuery] = useState<QueryParam>({ page: 1, limit: 50 });
    const [loading, setLoading] = useState(false);
    const [orderDetailAppointment, setOrderDetailAppointment] =
      useState<PaymentOrderDetail>();
    const [orderDetailServicePack, setOrderDetailServicePack] =
      useState<PaymentOrderDetail[]>();
    const [orderDetailVoucherTemplate, setOrderDetailVoucherTemplate] =
      useState<PaymentOrderDetail[]>();
    const [selectedPaymentOrder, setSelectedPaymentOrder] =
      useState<PaymentOrder>();
    const [visible, setVisible] = useState(false);
    const [isFetchServicePackOrderDetail, setIsFetchServicePackOrderDetail] =
      useState(false);

    useImperativeHandle<any, PaymentOrderDrawer>(
      ref,
      () => ({
        handleOpen: async (paymentOrder: PaymentOrder) => {
          setLoading(true);
          setVisible(true);
          try {
            // const paymentOrder = await findOrderPayment(
            //   selectedPaymentOrder.publicCode
            // );
            // debugger;
            setSelectedPaymentOrder(paymentOrder);
            const findAppointment = paymentOrder.paymentOrderDetails.find(
              (detail) => detail.appointment !== null
            );
            const findServicePack = paymentOrder.paymentOrderDetails
              .filter((detail) => detail.servicePackOrder !== null)
              ?.map((item) => ({
                ...item,
                servicePackOrder: {
                  ...item.servicePackOrder,
                  servicePackOrderDetails:
                    item.servicePackOrder.servicePackOrderDetails.map(
                      (orderDetail) => {
                        {
                          const serviceOptions = JSON.parse(
                            orderDetail?.serviceOptionJson || "[]"
                          );
                          return { ...orderDetail, serviceOptions };
                        }
                      }
                    ),
                },
              }));
            const findVoucherTemplate = paymentOrder.paymentOrderDetails.filter(
              (detail) => detail.voucherTemplate !== null
            );
            setOrderDetailAppointment(findAppointment);
            setOrderDetailServicePack(findServicePack);
            setOrderDetailVoucherTemplate(findVoucherTemplate);
          } catch (error) {
            setVisible(false);
          } finally {
            setLoading(false);
          }
          // await fetchVoucher({ customerId: appointment?.customerProfile.id });
        },
        // handleUpdate(CheckoutDetailDrawer: CheckoutDetailDrawer) {
        //   form.setFieldsValue({ ...CheckoutDetailDrawer});
        //   setVisible(true);
        //   setStatus("update");
        // },
      }),
      []
    );

    const onCloseModal = () => {
      setVisible(false);
    };

    return (
      <Drawer
        bodyStyle={{ padding: 0, position: "relative" }}
        closable={false}
        width={500}
        onClose={onCloseModal}
        open={visible}
      >
        {loading && (
          <div className="h-dvh w-full flex items-center bg-[rgba(0,0,0,0.1)] justify-center absolute">
            <Spin spinning={loading}></Spin>
          </div>
        )}

        <div
          className={`cursor-pointer  absolute w-10 h-10 rounded-full flex items-center justify-center`}
          onClick={onCloseModal}
        >
          <CloseOutlined className={`text-center ${"!text-black"}`} />
        </div>
        {selectedPaymentOrder && (
          <PaymentOrderService
            selectedPaymentOrder={selectedPaymentOrder}
            orderDetailAppointment={orderDetailAppointment}
            orderDetailServicePack={orderDetailServicePack || []}
            orderDetailVoucherTemplate={orderDetailVoucherTemplate || []}
          />
        )}
      </Drawer>
    );
  }
);

export const StatsItem = ({
  amount,
  title,
  className,
  danger,
}: {
  amount: number;
  title: string;
  className?: string;
  danger?: boolean;
}) => {
  return (
    <>
      <div className="flex justify-between">
        <div className={className}>{title}:</div>
        <div className={[className, danger ? "text-red-500" : ""].join(" ")}>
          {formatVND(amount || 0) + "đ"}
        </div>
      </div>
    </>
  );
};

export const PaymentOrderService = ({
  orderDetailAppointment,
  orderDetailServicePack,
  orderDetailVoucherTemplate,
  selectedPaymentOrder,
  onViewDetail,
  onApplyVoucher,
  onUsePackage,
  showDetail = false,
}: {
  orderDetailAppointment?: PaymentOrderDetail;
  orderDetailServicePack?: PaymentOrderDetail[];
  orderDetailVoucherTemplate?: PaymentOrderDetail[];
  selectedPaymentOrder: PaymentOrder;
  onViewDetail?: () => void;
  onApplyVoucher?: (voucher: PaymentOrderDetail) => void;
  onUsePackage?: (
    packageService: PaymentOrderDetail,
    type: "create" | "update"
  ) => void;
  showDetail?: boolean;
}) => {
  const navigate = useNavigate();

  const amount = (
    <>
      {selectedPaymentOrder?.paymentType && (
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">Hình thức thanh toán</span>
          <span className={`text-base font-medium `}>
            {selectedPaymentOrder?.paymentType.name}
          </span>
        </div>
      )}
      {/* <Divider className="!my-1"></Divider> */}
      <div className="flex justify-between font-bold text-[20px]">
        <div className="">Tổng:</div>
        <div>{formatVND(selectedPaymentOrder?.amount || 0) + "đ"}</div>
      </div>
    </>
  );
  console.log({ orderDetailServicePack });

  return (
    <>
      <div className="flex flex-col gap-4 p-4 min-h-full bg-[#f2f2f7]">
        <h1 className="text-xl font-semibold !mb-6 ml-4">Chi tiết đơn hàng</h1>

        <div className="rounded-lg border border-solid border-[#d3d3d3] p-6 bg-white">
          <div className="flex flex-col gap-1 items-center">
            <h1 className="text-lg font-semibold">
              Đơn hàng #{selectedPaymentOrder?.code}
            </h1>
            <span className="text-[#757676]">
              {formattedDate(selectedPaymentOrder?.createdAt)}
            </span>
            <span className="text-[#757676]">
              Hóa đơn cho{" "}
              <span className="text-primary">
                {selectedPaymentOrder.customer?.fullName ||
                  "Khách hàng ẩn danh"}
              </span>
            </span>
            {/* {!!orderDetailServicePack?.length ? (
              <Tag color="blue">
                {
                  // @ts-ignore
                  CheckoutPaymentTypeTrans[
                    selectedPaymentOrder.paymentType?.type
                  ].label
                }
              </Tag>
            ) : (
              <Tag
                color={
                  PaymentOrderStatusTrans[selectedPaymentOrder?.status]?.color
                }
              >
                {PaymentOrderStatusTrans[selectedPaymentOrder?.status]?.label}
              </Tag>
            )} */}
          </div>
        </div>
        {orderDetailAppointment && (
          <div className="rounded-lg border border-solid border-[#d3d3d3] p-6 bg-white">
            <div className="title font-bold text-xl">
              <div className="flex justify-between ">
                <span className="text-base">
                  <FolderOutlined className="text-xl mr-2"></FolderOutlined>
                  Lịch đặt
                  <div className="font-normal text-sm text-gray-400 mt-1">
                    Danh sách dịch vụ khách đã đặt
                  </div>
                </span>
                {/* <span
                  onClick={() => {
                    if (onViewDetail) {
                      onViewDetail();
                      return;
                    }
                    navigate(
                      `/appointment/calendar?id=${orderDetailAppointment.appointment.id}`
                    );
                    window.location.reload();
                  }}
                  className="text-sm text-primary hover:underline cursor-pointer"
                >
                  Xem chi tiết
                </span> */}
              </div>
              <Divider className="!my-4"></Divider>
              <AppointmentDetailList
                selectedAppointment={orderDetailAppointment.appointment}
                paymentType={selectedPaymentOrder?.paymentType}
              />
            </div>
          </div>
        )}

        {orderDetailServicePack?.length !== 0 && (
          <>
            <div className="rounded-lg border border-solid border-[#d3d3d3] p-6 bg-white">
              <div className="title font-bold text-xl">
                <div className="flex justify-between ">
                  <span className="text-base">
                    <div className="flex items-center">
                      <BiPackage className="text-2xl mr-2"></BiPackage>
                      Gói dịch vụ
                    </div>
                    <div className="font-normal text-sm text-gray-400 mt-1">
                      Danh sách gói dịch vụ khách hàng đã mua
                    </div>
                  </span>

                  {/* <span className="text-sm text-primary hover:underline cursor-pointer">
                      Xem chi tiết
                    </span> */}
                </div>
                <div className="flex flex-col gap-2">
                  {orderDetailServicePack?.map((item, index) => {
                    const hasRemainUsage =
                      item.servicePackOrder.servicePackOrderDetails.find(
                        (detail) => {
                          return detail.quantity - detail.used !== 0
                            ? true
                            : false;
                        }
                      );
                    console.log({ hasRemainUsage });
                    return (
                      <div>
                        {index !== orderDetailServicePack.length && (
                          <Divider className="!my-4"></Divider>
                        )}

                        <div className="w-full flex flex-col gap-2">
                          <Tag
                            color={
                              PaymentOrderStatusTrans[
                                item.servicePackOrder?.status
                              ]?.color
                            }
                            className="w-fit"
                          >
                            {
                              PaymentOrderStatusTrans[
                                item.servicePackOrder?.status
                              ]?.label
                            }
                          </Tag>

                          <div className="flex justify-between">
                            <div className="text-sm  text-gray-600 ">
                              #{item.servicePackOrder.code}
                            </div>
                            <Popover
                              style={{ width: 30 }}
                              content={
                                <Row
                                  gutter={12}
                                  style={{ width: 450 }}
                                  justify={"center"}
                                  align={"middle"}
                                >
                                  <Col span={16} className="font-bold">
                                    Dịch vụ
                                  </Col>
                                  <Col span={4} className="font-bold">
                                    Số lượng
                                  </Col>
                                  <Col span={4} className="font-bold">
                                    Đã dùng
                                  </Col>
                                  <Col span={24}>
                                    <Divider className="!my-2" />
                                  </Col>
                                  {item.servicePackOrder.servicePackOrderDetails.map(
                                    (detail) => (
                                      <>
                                        <Col
                                          span={16}
                                          className="font-semibold"
                                        >
                                          {detail.service.name}
                                        </Col>
                                        <Col
                                          span={4}
                                          className="text-center font-medium"
                                        >
                                          {detail.quantity}
                                        </Col>
                                        <Col
                                          span={4}
                                          className="text-center font-medium"
                                        >
                                          {detail.used}
                                        </Col>
                                      </>
                                    )
                                  )}
                                </Row>
                              }
                            >
                              <div className="text-sm font-semibold flex   hover:cursor-help">
                                <span> Đã dùng </span>
                                <IoMdInformationCircleOutline className="ml-2 translate-y-0.5" />
                              </div>
                            </Popover>
                          </div>
                          <div>
                            {/* <span className="mr-1">{item.quantity || 1}x</span> */}
                            {item.servicePackOrder.servicePack?.name}
                          </div>
                        </div>
                        <Divider className="!my-3" />
                        <div>
                          {item.servicePackOrder.servicePackOrderDetails?.map(
                            (detail) => (
                              <div className="flex flex-col">
                                <div className="item flex justify-between">
                                  <div className="flex gap-2 items-center">
                                    <span className="text-xs text-primary">
                                      {detail.quantity || 1}
                                    </span>
                                    <div className="text-sm leading-normal font-semibold !mb-0">
                                      {detail?.service?.name}
                                    </div>
                                  </div>

                                  <div className="flex items-center gap-2">
                                    <span className="font-medium text-[15px]">
                                      {formatVND(detail?.service?.price || 0)}{" "}
                                    </span>
                                    <span className="text-xs text-gray-500">
                                      x{detail.quantity || 1}
                                    </span>
                                  </div>
                                </div>
                                {detail?.serviceOptions?.length !== 0 &&
                                  detail?.serviceOptions?.map((item, index) => (
                                    <div className="item flex justify-between italic">
                                      <div className="flex gap-2 items-center">
                                        <div className="text-sm leading-normal font-medium !mb-0">
                                          + {item.pricingName} (dịch vụ kèm)
                                        </div>
                                      </div>

                                      <div className="flex items-center gap-2">
                                        <span className="font-medium text-[15px]">
                                          {formatVND(item.price)}
                                        </span>
                                        <span className="text-xs text-gray-500">
                                          x{detail.quantity || 1}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                {index ==
                                  detail!.serviceOptions!.length - 1 && (
                                  <Divider className="!my-2" />
                                )}
                              </div>
                            )
                          )}
                        </div>

                        {/* <div className="flex justify-between w-full ">
                        <span className=" text-sm leading-normal text-[16px]">

                        </span>
                        <span className="font-medium text-[17px]">
                          {formatVND(
                            item.servicePackOrder.servicePack.finalPrice
                          )}
                        </span>
                      </div> */}
                        <Divider className="!my-2"></Divider>
                        <div className="flex justify-between w-full ">
                          <span className=" text-sm leading-normal !text-[16px] font-semibold">
                            Giá gói
                          </span>
                          <span className="font-medium line-through text-[16px] text-slate-400">
                            {formatVND(
                              item.servicePackOrder.servicePack.originPrice
                            )}
                          </span>
                        </div>
                        <div className="flex  justify-between w-full">
                          <span className="text-sm leading-normal text-[20px]">
                            Thành tiền
                          </span>
                          <span className="font-semibold text-[18px] ">
                            {formatVND(
                              item.servicePackOrder.servicePack.finalPrice
                            )}
                          </span>
                        </div>
                        {onUsePackage &&
                          item.servicePackOrder.status ==
                            ServicePackOrderStatus.Complete && (
                            <Dropdown
                              overlayClassName="[&>.ant-dropdown-menu]:!bg-transparent [&>.ant-dropdown-menu]:!shadow-none"
                              className="bg-transparent"
                              //@ts-ignore
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                              disabled={!hasRemainUsage}
                              menu={{
                                items: [
                                  {
                                    className: "!p-0  !mb-2",
                                    label: (
                                      <Button
                                        onClick={() => {
                                          onUsePackage?.(item, "create");
                                        }}
                                        icon={<PlusOutlined />}
                                        size="large"
                                        type="primary"
                                        block
                                      >
                                        Tạo mới
                                      </Button>
                                    ),
                                    key: "create",
                                  },
                                  {
                                    className: "!p-0 ",
                                    label: (
                                      <Button
                                        onClick={() => {
                                          onUsePackage?.(item, "update");
                                        }}
                                        icon={<CheckOutlined />}
                                        size="large"
                                        type="primary"
                                        block
                                      >
                                        Chọn từ lịch sẵn
                                      </Button>
                                    ),
                                    key: "create",
                                  },
                                ],

                                onClick: () => {},
                              }}
                              trigger={["click"]}
                            >
                              {!hasRemainUsage ? (
                                <Button
                                  // onClick={() => onUsePackage?.(item)}
                                  block
                                  // type="primary"
                                  className="my-4"
                                  size="large"
                                  disabled={true}
                                >
                                  Đã dùng hết
                                </Button>
                              ) : (
                                <Button
                                  // onClick={() => onUsePackage?.(item)}
                                  block
                                  type="primary"
                                  className="my-4"
                                  size="large"
                                >
                                  Sử dụng
                                </Button>
                              )}
                            </Dropdown>
                          )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <Card className="!border-slate-300 !border !rounded-lg">
              {amount}
            </Card>
          </>
        )}
        {!!orderDetailVoucherTemplate?.length && (
          <div className="rounded-lg border border-solid border-[#d3d3d3] p-6 bg-white">
            <div className="font-bold text-xl flex justify-between ">
              <span className="text-base">
                <div className="flex items-center">
                  <LuTicket className="text-xl mr-2"></LuTicket>
                  Voucher
                </div>
                <div className="font-normal text-sm text-gray-400 mt-1">
                  Voucher khách hàng đã mua
                </div>
              </span>
            </div>

            {orderDetailVoucherTemplate.map((orderDetailVoucherTemplate) => {
              const percentDiscount = orderDetailVoucherTemplate
                ? Math.floor(
                    ((orderDetailVoucherTemplate.voucherTemplate.value -
                      orderDetailVoucherTemplate.voucherTemplate.price) *
                      100) /
                      orderDetailVoucherTemplate.voucherTemplate.value
                  )
                : 0;
              return (
                <>
                  <Divider className="!my-4"></Divider>

                  <div
                    style={{
                      borderColor:
                        orderDetailVoucherTemplate.voucherTemplate.color,
                    }}
                    className={
                      "relative bg-transparent flex  flex-col justify-between gap-2 rounded-[10px] border-[1px] border-dashed p-[20px]"
                    }
                  >
                    <div
                      className="absolute left-0 top-0  size-full rounded-[10px] opacity-20"
                      style={{
                        backgroundColor:
                          orderDetailVoucherTemplate.voucherTemplate.color,
                      }}
                    ></div>

                    <div className="flex max-w-[300px] flex-col text-lg">
                      <span
                        className="font-bold"
                        style={{
                          color:
                            orderDetailVoucherTemplate.voucherTemplate.color,
                        }}
                      >
                        <span className="text-lg">
                          {orderDetailVoucherTemplate.quantity}x{" "}
                        </span>
                        {orderDetailVoucherTemplate.voucherTemplate.name}
                      </span>
                      <span className="text-[14px] font-bold">
                        {orderDetailVoucherTemplate.voucherTemplate.description}
                      </span>
                      <div className="">
                        <span className="text-[14px] font-medium text-text">
                          {"Giá trị Voucher: "}
                        </span>
                        <span
                          // style={{ backgroundColor: voucher.color }}
                          className="rounded-[5px] px-1 text-[14px] font-bold text-dark-green"
                        >
                          {formatVND(
                            orderDetailVoucherTemplate?.voucherTemplate?.value
                          )}
                          đ
                        </span>
                      </div>
                      <div className="">
                        <span className="text-[14px] font-medium text-text">
                          {"Giá bán: "}
                        </span>
                        <span
                          // style={{ backgroundColor: voucher.color }}
                          className="rounded-[5px] px-1 text-[14px] font-bold text-dark-green"
                        >
                          {formatVND(
                            orderDetailVoucherTemplate?.voucherTemplate?.price
                          )}
                          đ
                        </span>
                      </div>
                      {showDetail && (
                        <>
                          <div className="">
                            <span className="text-[14px] font-medium text-text">
                              {"Đã sử dụng: "}
                            </span>
                            <span
                              // style={{ backgroundColor: voucher.color }}
                              className="rounded-[5px] px-1 text-[14px] font-bold text-red-500"
                            >
                              {formatVND(
                                orderDetailVoucherTemplate?.voucher?.redeemed
                              )}
                              đ
                            </span>
                          </div>
                          <div className="">
                            <span className="text-[14px] font-medium text-text">
                              {"Giá trị còn lại: "}
                            </span>
                            <span
                              // style={{ backgroundColor: voucher.color }}
                              className="rounded-[5px] px-1 text-[14px] font-bold text-dark-green"
                            >
                              {formatVND(
                                orderDetailVoucherTemplate?.voucher?.amount -
                                  orderDetailVoucherTemplate?.voucher?.redeemed
                              )}
                              đ
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="">
                      <span className="text-[14px] font-medium text-text">
                        {"Hạn dùng: "}
                      </span>
                      <span className="text-[14px] font-bold leading-3">
                        {/* {renderExpiredDate({
                          expiredAt:
                            orderDetailVoucherTemplate?.voucherTemplate
                              ?.expiredAt,
                        })} */}
                        {
                          orderDetailVoucherTemplate?.voucherTemplate
                            ?.expirationPeriod
                        }{" "}
                        ngày
                      </span>
                    </div>
                    {percentDiscount > 0 && (
                      <div
                        className="absolute -top-[1px] right-[10px] flex flex-col items-center rounded-b-[10px] px-1 py-1"
                        style={{
                          backgroundColor:
                            orderDetailVoucherTemplate.voucherTemplate.color,
                        }}
                      >
                        <span className="text-[10px] font-medium text-white">
                          {"Giảm"}
                        </span>
                        <span className="text-[16px] font-bold text-white">
                          -{percentDiscount}%
                        </span>
                      </div>
                    )}
                    {onApplyVoucher && (
                      <Button
                        onClick={() =>
                          onApplyVoucher(orderDetailVoucherTemplate)
                        }
                        block
                        className={`mt-4`}
                        type="primary"
                        size="large"
                      >
                        Sử dụng
                      </Button>
                    )}
                  </div>
                </>
              );
            })}

            <div className="mt-4 text-xl font-bold">{amount}</div>
          </div>
        )}
      </div>
    </>
  );
};

export const AppointmentDetailList = ({
  selectedAppointment,
  paymentType,
}: {
  selectedAppointment: Appointment;
  paymentType?: PaymentType;
}) => {
  const [loading, setLoading] = useState(false);
  // const [appointment, setAppointment] = useState<Appointment>();

  // useEffect(() => {
  //   (async () => {
  //     setLoading(true);
  //     try {
  //       const data = await findOneAppointment(selectedAppointment.id);
  //       setAppointment(data);
  //     } catch (error) {
  //     } finally {
  //       setLoading(false);
  //     }
  //   })();

  //   return () => {};
  // }, [selectedAppointment]);

  // console.log(appointment?.appointmentDetails);
  return (
    <Spin spinning={loading}>
      <div className="flex flex-col gap-2">
        {selectedAppointment?.appointmentDetails?.map((item) => {
          const serviceOptions: ServiceOptionCustom[] = (
            JSON.parse(item.serviceOptionJson) as ServiceOptionCustom[]
          )?.filter((item) => item.isChecked);
          return (
            <div className="flex flex-col">
              <div className="item flex justify-between">
                <div className="flex gap-2 items-center">
                  <div className="text-sm leading-normal font-semibold !mb-0">
                    {item.service.name}
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <span className="font-medium text-[15px]">
                    {formatVND(item?.service.price)}
                  </span>
                </div>
              </div>
              {serviceOptions?.length !== 0 &&
                serviceOptions?.map((option) => (
                  <div className="item flex justify-between italic">
                    <div className="flex gap-2 items-center">
                      <div className="text-sm leading-normal font-medium !mb-0">
                        + {option.pricingName} (dịch vụ kèm)
                      </div>
                    </div>

                    <div className="flex items-center gap-2">
                      <span className="font-medium text-[15px]">
                        {formatVND(option.price)}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          );
        })}
      </div>
      <Divider className="!my-4"></Divider>
      <div className=" flex flex-col gap-3 ">
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">Tổng tiền</span>
          <span className="text-base font-medium ">
            {formatVND(selectedAppointment?.moneyTotal)}đ
          </span>
        </div>

        {selectedAppointment?.moneyVoucher !== 0 && (
          <div className="flex justify-between items-center">
            <span className="text-base font-semibold">Voucher</span>
            <span className="text-base font-medium text-red-500">
              {formatMoneyWithMinusSymbol(
                selectedAppointment?.moneyVoucher,
                true
              )}
            </span>
          </div>
        )}

        {selectedAppointment?.moneyCoupon !== 0 && (
          <div className="flex justify-between items-center">
            <span className="text-base font-semibold">Coupon</span>
            <span className="text-base font-medium text-red-500">
              {formatMoneyWithMinusSymbol(
                selectedAppointment?.moneyCoupon,
                true
              )}
            </span>
          </div>
        )}

        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">Đã thanh toán</span>
          <span
            className={`text-base font-medium ${
              selectedAppointment?.customerPaid !== 0 && "text-red-500"
            }`}
          >
            {formatMoneyWithMinusSymbol(
              selectedAppointment?.customerPaid,
              true
            )}
          </span>
        </div>
        {paymentType && (
          <div className="flex justify-between items-center">
            <span className="text-base font-semibold">
              Hình thức thanh toán
            </span>
            <span className={`text-base font-medium `}>{paymentType.name}</span>
          </div>
        )}
        {selectedAppointment && (
          <div className="flex justify-between items-center mb-2">
            <h1 className="text-base font-semibold">
              {selectedAppointment?.moneyFinal < 0
                ? "Tiền hoàn lại"
                : "Tổng thanh toán"}
            </h1>
            <div>
              <span className="font-bold">
                {formatVND(selectedAppointment?.moneyFinal)
                  .toString()
                  .replace("-", "")}
              </span>
            </div>
          </div>
        )}
      </div>
    </Spin>
  );
};

export const ServiceAppointmentList = ({ data }: { data: Appointment }) => {
  return (
    <div className="flex flex-col gap-2">
      {data?.appointmentDetails?.map((item) => {
        return (
          <div className="item flex justify-between items-end">
            <div className="text-sm font-semibold ">
              <div className="text-xs text-gray-500 font-medium">
                {[
                  dayjs.unix(item.start).format("HH:mm"),
                  dayjs
                    .unix(item.end || item.start + item.duration)
                    .format("HH:mm"),
                ].join(" - ")}
              </div>
              <div className="mt-[2px]">{item.service?.name}</div>
            </div>

            <div className="flex items-center gap-2">
              <span className="text-xs text-gray-500">
                {item.quantity || 1} x
              </span>
              <span className="font-medium">{formatVND(item.price || 0)}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
