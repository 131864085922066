import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { Banner } from "types/banner";
import { message } from "antd";
import { VoucherTemplate } from "types/voucher";
import { voucherTemplateApi } from "api/voucherTemplate.api";
interface VoucherTemplateQuery extends QueryParam {}
type Props = {
  initialQuery: VoucherTemplateQuery;
};

const useVoucherTemplate = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<VoucherTemplate, VoucherTemplateQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await voucherTemplateApi
        .findAll(query)
        .then(({ data }) => data);
      return {
        data: data.voucherTemplates,
        total: data.total,
      };
    },
    deleteFunc: async (id) => {
      await voucherTemplateApi.delete(id);
      message.success("Thao tác thành công!");
    },
    editFunc: async (id, data) => {
      await voucherTemplateApi.update(id, data);
    },
    createFunc: async (data) => {
      await voucherTemplateApi.create(data);
    },
  });

  const toggleEnabled = async (id: number, isEnabled?: boolean) => {
    try {
      fetch.setLoading(true);
      await voucherTemplateApi.enabled(id, { isEnabled });
      message.success("Thao tác thành công");
      fetch.fetchData();
    } catch (error) {
      console.log("enabled failed", error);
    } finally {
      fetch.setLoading(false);
    }
  };

  return { ...fetch, toggleEnabled };
};

export default useVoucherTemplate;
