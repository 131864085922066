import { ServiceOptionCustom } from "./appointment";
import { CheckoutDetail, PaymentType } from "./checkout";
import { Customer } from "./customer";
import { CustomerProfile } from "./customerProfile";
import { Merchant } from "./merchant";
import { PaymentOrderDetail } from "./paymentOrder";
import { Service, ServiceOption } from "./service";
import { ServicePack } from "./servicePack";

export enum ServicePackOrderStatus {
  Pending = "PENDING", //chờ thanh toán
  Complete = "COMPLETE", //đã thanh toán xog
  Cancel = "CANCEL",
}

export const ServicePackOrderStatusTrans: any = {
  [ServicePackOrderStatus.Cancel]: {
    value: ServicePackOrderStatus.Cancel,
    label: "Hủy thanh toán",
    color: "red",
  },
  [ServicePackOrderStatus.Complete]: {
    value: ServicePackOrderStatus.Complete,
    label: "Đã thanh toán",
    color: "green",
  },
  [ServicePackOrderStatus.Pending]: {
    value: ServicePackOrderStatus.Pending,
    label: "Chờ thanh toán",
    color: "blue",
  },
};

export interface ServicePackOrderDetail {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  no: number; //stt
  quantity: number;
  price: number;
  service: Service;
  serviceOption: ServiceOption;
  servicePackOrder: ServicePackOrder;
  checkoutDetails: CheckoutDetail[];
  used: number;
  serviceOptionJson: string;
  serviceOptions?: ServiceOptionCustom[];
}

export interface ServicePackOrder {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  code: string;
  status: ServicePackOrderStatus;
  cancelReason: string;
  moneyTotal: number;
  moneyTax: number;
  moneyFinal: number;
  totalSession: number;
  customer: Customer;
  customerProfile: CustomerProfile;
  merchant: Merchant;
  servicePackOrderDetails: ServicePackOrderDetail[];
  paymentType: PaymentType;
  servicePack: ServicePack;
  used: number;
  paymentOrderDetails: PaymentOrderDetail[];
}
