import { staffApi } from "api/staff.api";
import { Staff } from "types/staff";
import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
interface StaffQuery extends QueryParam {}
type Props = {
  initialQuery: StaffQuery;
};

const useStaff = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<Staff, StaffQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await staffApi.findAll(query).then(({ data }) => data);
      return {
        data: data.data,
        total: data.total,
      };
    },
    deleteFunc: async (id) => {
      await staffApi.delete(id);
    },
    editFunc: async (id, data) => {
      await staffApi.update(id, data);
    },
    createFunc: async (data) => {},
  });

  return { ...fetch };
};

export default useStaff;
