import {
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Switch,
} from "antd";
import { Rule } from "antd/lib/form";
import { dictionaryApi } from "api/dictionary.api";
import { leadApi } from "api/lead.api";
import { RichTextEditorV2 } from "components/Editor/components/RichTextEditorV2";
// import { ReviewSampleApi } from "api/lea";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import React, { useImperativeHandle, useState } from "react";
import { Dictionary, DictionaryType } from "types/dictionary";
import { ModalStatus } from "types/modal";
// import { ReviewSample } from "types/address";
import { requiredRule } from "utils/validate";

export interface DictionaryModal {
  handleCreate: () => void;
  handleUpdate: (dictionary: Dictionary) => void;
}
interface DictionaryModalProps {
  onClose: () => void;
  onSubmitOk: () => void;
  dictionaryList: Dictionary[];
}

interface IForm {
  name?: string;
  type?: DictionaryType;
  id?: number;
}

export const DictionaryModal = React.forwardRef(
  ({ onClose, onSubmitOk, dictionaryList }: DictionaryModalProps, ref) => {
    const [form] = Form.useForm<IForm>();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<ModalStatus>("create");
    // const [topicId, setTopicId] = useState<number>()

    useImperativeHandle<any, DictionaryModal>(
      ref,
      () => ({
        handleCreate() {
          form.resetFields();
          setVisible(true);
          setStatus("create");
        },
        handleUpdate(dictionary: Dictionary) {
          form.setFieldsValue({ ...dictionary });
          setVisible(true);
          setStatus("update");
          // setTopicId(dictionary.id)
        },
      }),
      []
    );

    // const getFormData = () => {
    //   const { id, ...data } = form.getFieldsValue();
    //   return {
    //     id,
    //     ReviewSample: data,
    //   };
    // };

    const onSubmit = async () => {
      console.log(form.getFieldsValue());
      await form.validateFields();
      const { id, ...formData } = form.getFieldsValue();
      setLoading(true);
      try {
        if (status === "create") {
          await dictionaryApi.create({
            dictionary: formData,
          });
        } else {
          await dictionaryApi.update(id || 0, {
            dictionary: formData,
          });
        }
        message.success("Thao tác thành công!");
        onClose();
        onSubmitOk();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        onCancel={() => {
          form.resetFields();
          onClose?.();
          setVisible(false);
        }}
        visible={visible}
        title={status == "create" ? "Tạo dữ liệu" : "Chỉnh sửa dữ liệu"}
        style={{ top: 20 }}
        width={700}
        confirmLoading={loading}
        onOk={onSubmit}
      >
        <Form layout="vertical" form={form}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>

          <Row gutter={16}>
            <Col span={24} className="text-center">
              {/* <Form.Item noStyle shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item
                      className="text-center"
                      rules={[requiredRule]}
                      label="Icon"
                      name="icon"
                    >
                      <SingleImageUpload
                        uploadUrl={ReviewSampleApi.uploadUrl}
                        onUploadOk={(path: string) => {
                          form.setFieldsValue({
                            icon: path,
                          });
                        }}
                        imageUrl={form.getFieldValue("icon")}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item> */}
            </Col>
            <Col span={24}>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item required label="Nội dung" name="name">
                      <RichTextEditorV2
                        onChange={(name) => {
                          form.setFieldsValue({ name });
                        }}
                        content={form.getFieldValue("name")}
                        maxHeight={300}
                        minHeight={300}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item label="Loại" name="priority" rules={[requiredRule]}>
                <Select
                  // onChange={handleChangeDistrict}
                  style={{ width: "100%" }}
                  allowClear
                  placeholder="Chọn chủ đề"
                  showSearch
                  options={dictionaryList}
                  fieldNames={{
                    label: "name",
                    value: "id",
                  }}
                ></Select>
              </Form.Item>
            </Col> */}
            <Form.Item
              name="type"
              initialValue={DictionaryType.SearchContent}
              hidden
            ></Form.Item>
          </Row>
        </Form>
      </Modal>
    );
  }
);
