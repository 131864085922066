import { Input } from "antd";
import NumberFormat, { NumberFormatProps } from "react-number-format";

interface InputNumber2 extends NumberFormatProps {
  onChange?: (number: any) => void;
  onChangeOk?: (val: number | null | undefined) => void;
  value?: number;
}

//Format number 10000.5 => 10.000,5
export const InputNumberVN = ({
  value,
  onChangeOk,
  onChange,
  ...props
}: InputNumber2) => {
  return (
    <NumberFormat
      displayType={"input"}
      thousandSeparator={"."}
      decimalSeparator={","}
      //@ts-ignore
      customInput={Input}
      onValueChange={(val, source) => {
        if (source.source == "event" && val) {
          if (val.floatValue == undefined) {
            onChangeOk?.(undefined);
            onChange?.(undefined);
          } else {
            onChangeOk?.(isNaN(+val.floatValue) ? null : val.floatValue);
            onChange?.(isNaN(+val.floatValue) ? null : val.floatValue);
          }
        }
      }}
      value={value?.toString().replace(".", ",")}
      {...props}
    />
  );
};
