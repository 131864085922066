import moment from "moment";
import { AddressData } from "./address";
import { Merchant } from "./merchant";
import { Rank } from "./rank";

export enum GenderType {
  Male = "MALE",
  Female = "FEMALE",
  Unknown = "UNKNOWN",
}

export const genderTypeObject = {
  [GenderType.Male]: {
    value: GenderType.Male,
    label: "Nam",
  },
  [GenderType.Female]: {
    value: GenderType.Female,
    label: "Nữ",
  },
  [GenderType.Unknown]: {
    value: GenderType.Unknown,
    label: "Không xác định",
  },
};
export interface Customer extends AddressData {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  code: string;
  gender: GenderType;
  email: string;
  address: string;
  password: string;
  point: number;
  totalPoint: number; //tổng điểm đã tích lũy
  avatar: string;
  googleId: string;
  facebookId: string;
  phone: string;
  phoneCountryCode: string; //default VN: 84
  fullName: string;
  // gender: GenderType;
  isBlocked: boolean;
  customerGroupDetails: CustomerGroupDetail[];
  merchant: Merchant;
  rank: Rank;
  dob: any;
}

export interface CustomerGroupDetail {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  customerGroup: CustomerGroup;
  customer: Customer;
}

export interface CustomerGroup {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  name: string;
  merchant: Merchant;
  customerGroupDetails: CustomerGroupDetail[];
}
