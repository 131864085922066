import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import {
  CustomerTransaction,
  CustomerTransactionType,
} from "types/customerTransaction";
import { customerTransactionApi } from "api/customerTransaction.api";
import { message } from "antd";
interface CustomerTransactionQuery extends QueryParam {
  type?: CustomerTransactionType;
  customerId?: number;
}
type Props = {
  initialQuery: CustomerTransactionQuery;
};

const useCustomerTransaction = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<
    CustomerTransaction,
    CustomerTransactionQuery
  >({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await customerTransactionApi
        .findAll(query)
        .then(({ data }) => data);
      return {
        data: data.customerTransactions,
        total: data.total,
      };
    },
    deleteFunc: async (id) => {
      await customerTransactionApi.delete(id);
      message.success("Thao tác thành công!");
    },
    editFunc: async (id, data) => {
      await customerTransactionApi.update(id, data);
    },
    createFunc: async (data) => {
      await customerTransactionApi.create(data);
    },
  });

  return { ...fetch };
};

export default useCustomerTransaction;
