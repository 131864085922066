import { Col, Form, Input, message, Modal, Row } from "antd";
import { Rule } from "antd/lib/form";
import { staffApi } from "api/staff.api";
import RoleSelector from "components/Selector/RoleSelector";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import React, { useEffect, useState } from "react";
import { ModalStatus } from "types/modal";
import { Staff } from "types/staff";
import { emailRule, phoneValidate, requiredRule } from "utils/validate";

export const StaffModal = ({
  visible,
  status,
  staff,
  onClose,
  onSubmitOk,
}: {
  visible: boolean;
  status: ModalStatus;
  staff: Partial<Staff>;
  onClose: () => void;
  onSubmitOk: () => void;
}) => {
  const [form] = Form.useForm<
    Staff & {
      roleId: number;
    }
  >();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      if (status == "create") {
        form.resetFields();
      } else {
        form.setFieldsValue({
          ...staff,
          roleId: staff.role?.id,
        });
      }
    }
  }, [visible, status, staff]);

  const getFormData = () => {
    const { roleId, ...staff } = form.getFieldsValue();
    return {
      staff,
      roleId,
    };
  };

  const createData = async () => {
    await form.validateFields();

    setLoading(true);
    try {
      const data = getFormData();
      await staffApi.create(data);
      message.success("Thao tác thành công");
      onClose();
      onSubmitOk();
    } finally {
      setLoading(false);
    }
  };

  const updateData = async () => {
    await form.validateFields();
    setLoading(true);
    try {
      const data = getFormData();
      await staffApi.update(staff?.id || 0, data);
      message.success("Thao tác thành công");
      onClose();
      onSubmitOk();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      onCancel={onClose}
      visible={visible}
      title={status == "create" ? "Thêm nhân viên" : "Cập nhật nhân viên"}
      style={{ top: 20 }}
      width={700}
      confirmLoading={loading}
      onOk={() => {
        status == "create" ? createData() : updateData();
      }}
      okText={"Lưu"}
      cancelText={"Hủy"}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={24} className="text-center">
            <Form.Item noStyle shouldUpdate={true}>
              {() => {
                return (
                  <Form.Item label="Ảnh đại diện" name="avatar">
                    <SingleImageUpload
                      uploadUrl={staffApi.uploadUrl}
                      onUploadOk={(path: string) => {
                        form.setFieldsValue({
                          avatar: path,
                        });
                      }}
                      imageUrl={form.getFieldValue("avatar")}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Tên đăng nhập"
              name="username"
              rules={[requiredRule]}
            >
              <Input
                disabled={status == "update"}
                placeholder="Nhập tên đăng nhập"
              />
            </Form.Item>
          </Col>

          {status == "create" && (
            <Col span={12}>
              <Form.Item
                label="Mật khẩu"
                name="password"
                rules={[requiredRule]}
              >
                <Input placeholder="Nhập mật khẩu" />
              </Form.Item>
            </Col>
          )}

          <Col span={12}>
            <Form.Item label="Tên" name="name" rules={[requiredRule]}>
              <Input placeholder="Nhập tên" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Số điện thoại"
              name="phone"
              rules={[requiredRule, ...phoneValidate]}
            >
              <Input placeholder="Nhập số điện thoại" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Email" rules={[emailRule]} name="email">
              <Input placeholder="Nhập Email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item rules={[requiredRule]} label="Quyền" name="roleId">
              <RoleSelector />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
