import { Button, Select, Space, Spin, Table } from "antd";

import { Pagination } from "components/Pagination";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { ContentDefine, ContentDefineTypeTrans } from "types/contentDefine";
import { ModalStatus } from "types/modal";
import { QueryParam } from "types/query";
import { getTitle } from "utils";
import { ContentDefineModal } from "./components/ContentDefineModal";
import { useHandlerContentDefine } from "./handler/useHandlerContentDefine";
// import { StaffModal } from "./components/StaffModal";

const { Column } = Table;
const { Option } = Select;

interface BannerQuery extends QueryParam {
  areaId: null | null;
}

export const ContentDefinePage = observer(({ title = "" }) => {
  const { loading, data, total, query, fetchData } = useHandlerContentDefine({
    initQuery: {
      page: 1,
      limit: 50,
      search: "",
    },
  });
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedContentDefine, setSelectedContentDefine] = useState<
    Partial<ContentDefine>
  >({});
  const [modalStatus, setModalStatus] = useState<ModalStatus>("create");

  useEffect(() => {
    document.title = getTitle(title);
    fetchData();
  }, []);

  return (
    <div>
      <Spin spinning={loading}>
        <Table pagination={false} rowKey="id" dataSource={data}>
          <Column
            title="Tên nội dung"
            dataIndex="title"
            key="title"
            render={(text, record: ContentDefine) => (
              <span className="font-medium text-secondary">
                {record?.title}
              </span>
            )}
          />
          {/* <Column
            title="Loại"
            dataIndex="type"
            key="type"
            render={(text, record: ContentDefine) => (
              <span className="font-medium text-secondary">
                {ContentDefineTypeTrans[record.type]}
              </span>
            )}
          /> */}

          <Column
            width={120}
            title="Thao tác"
            key="action"
            render={(text, record: ContentDefine) => (
              <span>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      setSelectedContentDefine(record);
                      setVisibleModal(true);
                      setModalStatus("update");
                    }}
                  >
                    Cập nhật
                  </Button>
                </Space>
              </span>
            )}
          />
        </Table>

        <Pagination
          total={total}
          currentPage={query.page}
          onChange={fetchData}
        />
      </Spin>

      <ContentDefineModal
        onSubmitOk={fetchData}
        onClose={() => setVisibleModal(false)}
        visible={visibleModal}
        contentDefine={selectedContentDefine}
        status={modalStatus}
      />
    </div>
  );
});
