import { Form, Input, message, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { employeeApi } from "api/employee.api";
import { useEffect, useState } from "react";
import { Staff } from "types/staff";
import { minRule, requiredRule } from "utils/validate";

export const ResetPassModal = ({
  visible,
  staff,
  closeModal,
}: {
  visible: boolean;
  staff: Partial<Staff>;
  closeModal: () => void;
}) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [staff]);

  const handleResetPass = async () => {
    await form.validateFields();
    setLoading(true);
    const dataPost = form.getFieldValue("newPassword");

    try {
      await employeeApi.resetPassword(staff.id || 0, { password: dataPost });
      message.success("Thao tác thành công");
      closeModal();
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      onOk={() => handleResetPass()}
      title={`Cập lại mật khẩu (${staff.username})`}
      visible={visible}
      confirmLoading={loading}
      onCancel={closeModal}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          name="newPassword"
          label="Mật khẩu mới"
          required
          rules={[requiredRule, minRule]}
        >
          <Input.Password placeholder="Nhập mật khẩu mới" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
