import { Avatar, Button, Popconfirm, Space, Spin, Switch, Table } from "antd";
import { useEffect, useRef } from "react";
import { getTitle } from "utils";
import { PaymentTypeModal } from "./components/PaymentTypeModal";
import usePaymentType from "hooks/usePaymentType";
import { PaymentType } from "types/paymentType";
import { PaymentTypeDndModal } from "./components/PaymentTypeDnd";
import { MdOutlineChangeCircle } from "react-icons/md";
const { Column } = Table;

export const PaymentTypePage = ({ title = "" }) => {
  const {
    fetchPaymentType,
    isFetchPaymentTypeLoading,
    paymentTypes,
    toggleEnable,
    changePosition,
    toggleDefault,
  } = usePaymentType({
    initialQuery: {
      page: 1,
      limit: 20,
    },
  });
  const modalRef = useRef<PaymentTypeModal>(null);
  const paymentTypeDndModalRef = useRef<PaymentTypeDndModal>(null);

  useEffect(() => {
    document.title = getTitle(title);
    fetchPaymentType();
  }, []);

  return (
    <section className="box">
      <Spin spinning={isFetchPaymentTypeLoading}>
        <Table pagination={false} rowKey="id" dataSource={paymentTypes}>
          <Column
            title={
              <Button
                onClick={() =>
                  paymentTypeDndModalRef.current?.handleOpen(paymentTypes)
                }
                type="primary"
                icon={
                  <MdOutlineChangeCircle className="translate-y-0.5 mr-1" />
                }
              >
                Vị trí
              </Button>
            }
            align="center"
            width={40}
            className="drag-visible drag-icon"
            dataIndex={"no"}
            render={() => <></>}
          />
          <Column
            width={60}
            align="center"
            title="Ảnh"
            dataIndex="icon"
            key="icon"
            render={(text) => (
              <Avatar
                // className="border border-solid"
                src={text || "/logo.png"}
                size={40}
                shape="square"
              />
            )}
          />
          <Column
            title="Hình thức thanh toán"
            dataIndex="name"
            width={300}
            key="name"
            render={(text, record: PaymentType) => {
              return (
                <span
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                  className="text-secondary cursor-pointer hover:underline font-medium"
                >
                  {text}
                </span>
              );
            }}
          />
          <Column
            title="Vị trí hiển thị"
            dataIndex="position"
            width={300}
            key="position"
            render={(text) => {
              return <span>{text}</span>;
            }}
          />
          <Column
            title="Hoạt động"
            align="center"
            width={150}
            dataIndex="isEnabled"
            key="isEnabled"
            render={(text, record: PaymentType) => (
              <span>
                <Popconfirm
                  onConfirm={async () => {
                    await toggleEnable(record.id, !record.isEnabled);
                  }}
                  title="Bạn có chắc chắn muốn thực hiện thao tác này?"
                >
                  <Switch
                    checked={record.isEnabled}
                    checkedChildren="Bật"
                    unCheckedChildren="Tắt"
                  ></Switch>
                </Popconfirm>
              </span>
            )}
          />
          <Column
            title="Mặc định"
            align="center"
            width={150}
            dataIndex="isDefault"
            key="isDefault"
            render={(text, record: PaymentType) => (
              <span>
                <Popconfirm
                  onConfirm={async () => {
                    await toggleDefault(record.id, !record.isDefault);
                  }}
                  title="Bạn có chắc chắn muốn thực hiện thao tác này?"
                >
                  <Switch
                    checked={record.isDefault}
                    checkedChildren="Mặc định"
                    unCheckedChildren="Mặc định"
                  ></Switch>
                </Popconfirm>
              </span>
            )}
          />
          {/* <Column
            title="Mặc định"
            align="center"
            width={150}
            dataIndex="isDefault"
            key="isDefault"
            render={(text) => (
              <span className="text-primary font-semibold">
                {text ? "Mặc định" : ""}
              </span>
            )}
          /> */}

          <Column
            width={150}
            title="Hành động"
            key="action"
            render={(text, record: PaymentType) => (
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                >
                  Cập nhật
                </Button>
              </Space>
            )}
          />
        </Table>
      </Spin>

      <PaymentTypeDndModal
        ref={paymentTypeDndModalRef}
        onSubmitOk={fetchPaymentType}
        changePosition={changePosition}
      />

      <PaymentTypeModal
        onSubmitOk={fetchPaymentType}
        onClose={() => {}}
        ref={modalRef}
      />
    </section>
  );
};
