import { AxiosPromise } from "axios";
import { request } from "utils/request";

export const oneSignalApi = {
  sub: (data?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/oneSignal/sub",
      data,
      method: "post",
    }),
  unSub: (data: any, token: string = ""): AxiosPromise<any> =>
    request({
      url: "/v1/admin/oneSignal/unSub",
      data,
      method: "delete",
      headers: {
        token,
      },
    }),
};
