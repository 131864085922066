import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { CommissionConfig } from "types/commissionConfig";
import { commissionConfigApi } from "api/commissionConfig.api";
import { message } from "antd";
interface CommissionConfigQuery extends QueryParam {}
type Props = {
  initialQuery: CommissionConfigQuery;
};

const useCommissionConfig = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<CommissionConfig, CommissionConfigQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await commissionConfigApi
        .findAll(query)
        .then(({ data }) => data);
      return {
        data: data.commissionConfigs,
        total: data.total,
      };
    },
    deleteFunc: async (id) => {
      await commissionConfigApi.delete(id);
      message.success("Thao tác thành công!");
    },
    editFunc: async (id, data) => {
      await commissionConfigApi.update(id, data);
    },
    createFunc: async (data) => {
      await commissionConfigApi.create(data);
    },
  });

  return { ...fetch };
};

export default useCommissionConfig;
