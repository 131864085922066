import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { Role } from "types/role";
import { roleApi } from "api/role.api";

interface RoleQuery extends QueryParam {}
type Props = {
  initialQuery: RoleQuery;
};

const useRole = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<Role, RoleQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await roleApi.findAll(query).then(({ data }) => data);
      return {
        data,
        total: 0,
      };
    },
    deleteFunc: async (id) => {
      await roleApi.delete(id);
    },
    editFunc: async (id, data) => {
      await roleApi.update(id, data);
    },
    createFunc: async (data) => {
      await roleApi.create(data);
    },
  });

  return { ...fetch };
};

export default useRole;
