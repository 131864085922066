import {
  DatePicker,
  Image,
  Input,
  Select,
  Spin,
  Table,
  Tag,
  message,
} from "antd";
import { Pagination } from "components/Pagination";
import { useEffect, useRef, useState } from "react";
import { getTitle } from "utils";
import useServicePackOrder from "hooks/useServicePackOrder";
import { debounce } from "lodash";
import {
  ServicePackOrder,
  ServicePackOrderStatus,
  ServicePackOrderStatusTrans,
} from "types/servicePackOrder";
import { unixToFullTime } from "utils/formatDate";
import { formatVND } from "utils/formatVND";
import { PreviewImage } from "components/PreviewImage/PreviewImage";
import { ServicePackOrderDrawer } from "./components/ServicePackOrderDrawer";
import { servicePackOrderApi } from "api/servicePackOrder.api";
import "./styles/styles.scss";
import { EPaymentTypeTrans } from "types/paymentType";
import ServicePackOrderExportExcel from "./components/ServicePackOrderExportExcel";
import usePaymentType from "hooks/usePaymentType";
import dayjs from "dayjs";
import settings from "settings";
const { Column } = Table;

interface IProps {
  title?: string;
  description?: string;
  merchantId?: number;
  customerId?: number;
}
export const ServicePackOrderPage = ({
  title = "",
  description,
  merchantId,
  customerId,
}: IProps) => {
  const {
    fetchServicePackOrder,
    isFetchServicePackOrderLoading,
    queryServicePackOrder,
    totalServicePackOrder,
    ServicePackOrders,
  } = useServicePackOrder({
    initialQuery: {
      page: 1,
      limit: 10,
    },
  });
  const { fetchPaymentType, isFetchPaymentTypeLoading, paymentTypes } =
    usePaymentType({
      initialQuery: {
        page: 1,
        limit: 20,
        isEnabled: false,
      },
    });
  const servicePackOrderDrawerRef = useRef<ServicePackOrderDrawer>(null);
  const [isCheckoutServicePackOrder, setIsCheckoutServicePackOrder] =
    useState(false);
  const [isCancelServicePackOrder, setIsCancelServicePackOrder] =
    useState(false);

  const handleCheckoutServicePackOrder = async (
    servicePackOrder: ServicePackOrder
  ) => {
    setIsCheckoutServicePackOrder(true);
    try {
      await servicePackOrderApi.paid(servicePackOrder.id);
      message.success("Thanh toán thành công");
      fetchServicePackOrder();
    } finally {
      setIsCheckoutServicePackOrder(false);
    }
  };

  const handleCancelServicePackOrder = async (
    servicePackOrder: ServicePackOrder
  ) => {
    setIsCancelServicePackOrder(true);
    try {
      await servicePackOrderApi.cancel(servicePackOrder.id);
      message.success("Hủy thanh toán thành công");
      fetchServicePackOrder();
    } finally {
      setIsCancelServicePackOrder(false);
    }
  };

  useEffect(() => {
    document.title = getTitle(title);
    // fetchServicePackOrder();
    fetchPaymentType();
  }, []);

  useEffect(() => {
    queryServicePackOrder.merchantId = merchantId;
    queryServicePackOrder.customerId = customerId;
    fetchServicePackOrder();
  }, [merchantId, customerId]);

  const searchDebounce = debounce((search) => {
    queryServicePackOrder.search = search;
    fetchServicePackOrder();
  }, 300);

  const onChangeRangePicker = (date: any, dateFormat: string[]) => {
    if (date) {
      queryServicePackOrder.toAt = dayjs(date[1]).endOf("day").unix();
      queryServicePackOrder.fromAt = dayjs(date[0]).startOf("day").unix();
    } else {
      queryServicePackOrder.toAt = undefined;
      queryServicePackOrder.fromAt = undefined;
    }
    fetchServicePackOrder();
  };

  return (
    <section>
      <div className="filter-container">
        {/* <div className="filter-item btn">
            <Button
              onClick={() => fetchServicePackOrder()}
              type="primary"
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </div> */}
        <div className="flex gap-2 items-center flex-wrap">
          <div className="filter-item w-full sm:w-[200px]">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              size="middle"
              onChange={(e) => {
                let text = e.target.value;
                searchDebounce(text);
              }}
              placeholder="Tìm kiếm theo mã"
            />
          </div>
          <div className="filter-item !w-fit max-w-[300px] ">
            <label htmlFor="">Khoảng thời gian</label>
            <DatePicker.RangePicker
              presets={[
                { label: "Hôm nay", value: [dayjs(), dayjs().add(1, "day")] },
                {
                  label: "Tuần này",
                  value: [dayjs().startOf("week"), dayjs().endOf("week")],
                },
                {
                  label: "Tháng này",
                  value: [dayjs().startOf("month"), dayjs().endOf("month")],
                },
                {
                  label: "Tháng trước",
                  value: [
                    dayjs().subtract(1, "month").startOf("month"),
                    dayjs().subtract(1, "month").endOf("month"),
                  ],
                },
              ]}
              onChange={onChangeRangePicker}
              className="custom-filter !w-full  max-h-[32px]  max-w-[300px]"
              size="small"
              format={settings.dateFormat}
            />
          </div>
          <div className="filter-item mt-1">
            <label>Trạng thái</label>
            <br />
            <Select
              size="middle"
              className="custom-filter focus-within:!border-primary hover:!border-primary focus-within:!shadow-none"
              style={{ width: 200 }}
              placeholder="Chọn trạng thái"
              allowClear
              onChange={(value) => {
                queryServicePackOrder.status = value;
                fetchServicePackOrder();
              }}
            >
              <Select.Option value={undefined}>Tất cả</Select.Option>
              {Object.values(ServicePackOrderStatus)?.map((item) => (
                <Select.Option value={item}>
                  {ServicePackOrderStatusTrans[item]?.label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="filter-item  mt-1">
            <label>Hình thức thanh toán</label>
            <br />
            <Select
              size="middle"
              className="custom-filter focus-within:!border-primary hover:!border-primary focus-within:!shadow-none"
              style={{ width: 250 }}
              placeholder="Chọn hình thức thanh toán"
              allowClear
              loading={isFetchPaymentTypeLoading}
              onChange={(value) => {
                if (value) {
                  queryServicePackOrder.paymentTypeId = value;
                } else {
                  queryServicePackOrder.paymentTypeId = undefined;
                }
                // if(value)

                fetchServicePackOrder();
              }}
            >
              <Select.Option value={undefined}>Tất cả</Select.Option>
              {paymentTypes?.map((item) => (
                <Select.Option value={item.id}>{item?.name}</Select.Option>
              ))}
            </Select>
          </div>
          <div className="filter-item btn mt-1">
            <ServicePackOrderExportExcel
              queryServicePackOrder={queryServicePackOrder}
              summaries={ServicePackOrders}
            />
          </div>
        </div>
      </div>

      <Spin spinning={isFetchServicePackOrderLoading}>
        <Table
          pagination={false}
          scroll={{ x: "max-content", y: "calc(100vh - 280px)" }}
          rowKey="id"
          dataSource={ServicePackOrders}
        >
          <Column
            align="center"
            width={70}
            title="Mã"
            key="point"
            render={(text, record: ServicePackOrder) => (
              <div className="flex flex-col gap-1">
                <span
                  className="font-semibold text-primary cursor-pointer"
                  onClick={
                    () => servicePackOrderDrawerRef.current?.handleOpen(record)
                    // PaymentOrderDrawerRef.current?.handleOpen(record)
                  }
                >
                  #{record?.code}
                </span>
              </div>
            )}
          />
          <Column
            width={250}
            title="Tên khách hàng"
            key="name"
            render={(text, record: ServicePackOrder) => (
              <div className="flex gap-3 items-center">
                <Image
                  src={record.customerProfile?.avatar || settings.avatarDefault}
                  preview={false}
                  className="rounded-full shadow-md !w-[40px] !h-[40px] object-cover"
                />
                <span className="text-sm font-semibold">
                  {record?.customerProfile?.fullName || "Khách hàng ẩn danh"}
                </span>
              </div>
            )}
          />

          <Column
            align="center"
            width={120}
            title="Số điện thoại"
            key="point"
            render={(text, record: ServicePackOrder) => (
              <div className="flex flex-col gap-1">
                <span className="font-medium">
                  {record?.customerProfile?.contactPhone}
                </span>
              </div>
            )}
          />

          {/* <Column
            align="center"
            width={120}
            title="Dịch vụ"
            key="point"
            render={(text, record: ServicePackOrder) => (
              <>
                <div className="flex flex-col gap-1">
                  <span className="font-medium">{record?.totalSession}</span>
                </div>
              </>
            )}
          /> */}
          <Column
            align="center"
            width={150}
            title="Tên gói dịch vụ"
            key="point"
            render={(text, record: ServicePackOrder) => (
              <>
                {/* <Popover
                  content={
                    <div className="list flex flex-col gap-3 min-w-[300px]">
                      <div className="item">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span
                            className="service-name font-semibold max-w-[200px]"
                          >
                            {record?.servicePack?.name}
                          </span>
                          <span style={{ fontWeight: 500, fontSize: 14 }}>
                            {1} x {formatVND(record?.servicePack?.finalPrice)}
                          </span>
                        </div>
                      </div>
                    </div>
                  }
                  title={
                    <h1 className="text-lg font-semibold">
                      Thông tin gói dịch vụ
                    </h1>
                  }
                  trigger="hover"
                >
                  <Tooltip
                  // title={
                  //   record.appointmentDetails.length > 2
                  //     ? "Bấm vào để xem chi tiết"
                  //     : ""
                  // }
                  >
                    <div className="flex flex-col gap-1">
                      <span className="font-medium">
                        {record?.servicePack?.name}
                      </span>
                    </div>
                  </Tooltip>
                </Popover> */}
                <div className="flex flex-col gap-1">
                  <span className="font-medium">
                    {record?.servicePack?.name}
                  </span>
                </div>
              </>
            )}
          />

          <Column
            align="center"
            width={150}
            title="Tổng giá (VNĐ)"
            key="point"
            render={(text, record: ServicePackOrder) => (
              <>
                <div className="flex flex-col gap-1">
                  <span className="font-medium">
                    {formatVND(record?.moneyFinal)}
                  </span>
                </div>
              </>
            )}
          />
          <Column
            align="center"
            width={170}
            title="Hình thức thanh toán"
            key="point"
            render={(text, record: ServicePackOrder) => (
              <>
                <div className="flex flex-col gap-1">
                  <span className="font-medium">
                    {EPaymentTypeTrans[record?.paymentType?.type]}
                  </span>
                </div>
              </>
            )}
          />

          <Column
            align="center"
            width={120}
            title="Trạng thái"
            key="point"
            render={(text, record: ServicePackOrder) => (
              <div className="flex flex-col gap-1">
                <span className="font-medium">
                  <Tag color={ServicePackOrderStatusTrans[record.status].color}>
                    {ServicePackOrderStatusTrans[record.status].label}
                  </Tag>
                </span>
              </div>
            )}
          />
          {/* <Column
            align="center"
            width={120}
            title="Lý do hủy"
            key="point"
            render={(text, record: ServicePackOrder) => (
              <>
                <div className="flex flex-col gap-1">
                  <span className="font-medium">{record?.cancelReason}</span>
                </div>
              </>
            )}
          /> */}
          <Column
            align="center"
            width={140}
            title="Ngày tạo"
            key="point"
            render={(text, record: ServicePackOrder) => (
              <>
                <div className="flex flex-col gap-1">
                  <span className="font-medium">
                    {unixToFullTime(record?.createdAt)}
                  </span>
                </div>
              </>
            )}
          />
        </Table>

        <Pagination
          currentPage={queryServicePackOrder.page}
          total={totalServicePackOrder}
          // pageSize={queryServicePackOrder.limit}
          onChange={({ limit, page }) => {
            queryServicePackOrder.page = page;
            queryServicePackOrder.limit = limit;
            fetchServicePackOrder();
          }}
        />
      </Spin>
      <ServicePackOrderDrawer ref={servicePackOrderDrawerRef} />
    </section>
  );
};
