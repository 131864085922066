import { Flex, Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Route, adminRoutes } from "router";
import { permissionStore } from "store/permissionStore";

const deviceWidth = window.innerWidth;

export const Sidebar = React.memo(
  ({ collapsed, toggle }: { collapsed: boolean; toggle: () => void }) => {
    const location = useLocation();

    const handleCloseSidebar = () => {
      if (!collapsed && deviceWidth <= 821) {
        toggle();
      }
    };

    return (
      <Sider trigger={null} collapsible collapsed={collapsed} width={250}>
        <Flex justify="center" className="logo text-center p-2">
          <img
            width={100}
            src={require("../../assets/images/logo.png")}
            alt=""
          />
        </Flex>

        <Menu
          className="custom-scrollbar"
          style={{
            maxHeight: "calc(100vh - 100px)",
          }}
          theme="dark"
          mode="inline"
          inlineCollapsed={collapsed}
          //   activeKey={selectedKey}
          //   defaultSelectedKeys={[selectedKey]}
          selectedKeys={[location.pathname]}
        >
          {permissionStore.accessRoutes
            .filter(
              (router) =>
                router.isAccess ||
                router.children?.some((route: any) => route.isAccess)
            )
            .map((route) => {
              if (route.children?.length) {
                return (
                  <SubMenu
                    key={route.path}
                    icon={route.icon}
                    title={route.title}
                  >
                    {route.children
                      ?.filter((item: Route) => item.isAccess)
                      ?.map((item: Route) => (
                        <Menu.Item
                          icon={item.icon}
                          key={route.path + "/" + item.path}
                          onClick={handleCloseSidebar}
                        >
                          {route.path && item.path && (
                            <Link to={route.path + "/" + item.path}>
                              {item?.title}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                  </SubMenu>
                );
              }
              return (
                <Menu.Item
                  icon={route.icon}
                  key={route.path}
                  onClick={handleCloseSidebar}
                >
                  <Link to={route.path || ""}>{route.title}</Link>
                </Menu.Item>
              );
            })}
        </Menu>
      </Sider>
    );
  }
);
