import { ExportOutlined } from "@ant-design/icons";
import { Popconfirm, Button } from "antd";
import { merchantApi } from "api/merchant.api";
import { MerchantQuery } from "hooks/useMerchant";
import moment from "moment";
import { useState, useMemo, useEffect } from "react";
import { Merchant, merchantStatusTrans } from "types/merchant";
import { formatVND } from "utils";
import { MyExcelColumn, handleExport } from "utils/MyExcel";

interface PropsType {
  Merchants: Merchant[];
  queryMerchant: MerchantQuery;
}

const MerchantExportExcel = ({ Merchants, queryMerchant }: PropsType) => {
  const [percentExportComplete, setPercentExportComplete] = useState<number>(0);

  const [visible, setVisible] = useState<boolean>(false);

  const exportColumns: MyExcelColumn<Merchant>[] = useMemo(() => {
    return [
      {
        width: 30,
        header: "Tên cửa hàng",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "name",
        render: (record: Merchant) => {
          return record?.name;
        },
      },
      {
        width: 20,
        header: "Trạng thái",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "status",
        render: (record: Merchant) => {
          return merchantStatusTrans[record?.status].label;
        },
      },
      {
        width: 30,
        header: "Email",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "email",
        render: (record: Merchant) => {
          return record?.email;
        },
      },
      {
        width: 15,
        header: "Số điện thoại",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "phone",
        render: (record: Merchant) => {
          return record?.phone;
        },
      },
      {
        width: 20,
        header: "Điểm",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "balance",
        render: (record: Merchant) => {
          return formatVND(record?.balance);
        },
      },
      {
        width: 20,
        header: "Địa chỉ",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "address",
      },
      {
        width: 20,
        header: "Hiển thị",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "isVisible",
        render: (record: Merchant) => {
          return record?.isVisible ? "Hiện" : "Ẩn";
        },
      },
      {
        width: 20,
        header: "Kích hoạt",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "isBlocked",
        render: (record: Merchant) => {
          return record?.isBlock ? "Hoạt động" : "Đã khóa";
        },
      },
    ];
  }, []);

  useEffect(() => {
    if (percentExportComplete >= 100) {
      setTimeout(() => {
        setVisible(false);
      }, 500);
    }
  }, [percentExportComplete]);
  const handleExportExcel = async () => {
    setVisible(true);
    setPercentExportComplete(0);
    try {
      await handleExport({
        onProgress(percent) {
          setPercentExportComplete(percent);
        },
        exportColumns: exportColumns,
        fileType: "xlsx",
        dataField: "merchants",
        query: queryMerchant,
        api: merchantApi.findAll,
        fileName: "Danh sách cửa hàng",
        sheetName: "Danh sách cửa hàng",
        isGetFullData: false,
      });
    } finally {
      setPercentExportComplete(0);
      setVisible(false);
    }
  };

  return (
    <div>
      <Popconfirm
        title={`Bạn có muốn xuất file excel`}
        onConfirm={handleExportExcel}
        okText={"Xuất excel"}
        cancelText={"Huỷ"}
      >
        <Button
          type="primary"
          loading={false}
          icon={<ExportOutlined />}
          size="middle"
          disabled={!Merchants.length}
        >
          Xuất file excel
        </Button>
      </Popconfirm>
    </div>
  );
};

export default MerchantExportExcel;
