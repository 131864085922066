import {
  Button,
  Card,
  Col,
  DatePicker,
  Image,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { formatVND, getTitle } from "utils";
import StatsPage, { SummaryData } from "./components/StatsPage";
import { dashboardApi } from "api/dashboard.api";
import { QueryParam } from "types/query";
import useRecordSale, { RecordSaleQuery } from "hooks/useRecordSale";
import Column from "antd/es/table/Column";
import {
  RecordSale,
  RecordSaleDetails,
  RecordSalePaymentStatus,
  RecordSalePaymentStatusTrans,
  RecordSalePaymentType,
  RecordSalePaymentTypeTrans,
} from "types/recordSale";
import { unixToFullDate } from "utils/dateFormat";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Pagination } from "components/Pagination";
import { formattedFullDate } from "utils/formatDate";
import dayjs from "dayjs";
import RecordSaleExportExcel from "./components/RecordSaleExportExcel";
import { DashboardQuery, useDashboard } from "hooks/useDashboard";
import { SummarySaleTab } from "views/Merchant/components/TabPane/Stats";
import { recordSaleApi } from "api/recordSale.api";
import { RecordSalePage } from "views/RecordSale/RecordSalePage";
import CommissionList from "views/RecordSale/components/CommissionList";

interface DashboardData {
  summary: SummaryData;
}

export const DashboardPage = ({ title = "" }) => {
  const [loading, setLoading] = useState(false);
  const [loadingSummaryRecordSale, setLoadingSummaryRecordSale] =
    useState(false);
  const [data, setData] = useState<DashboardData>();
  const [query, setQuery] = useState<QueryParam>({
    page: 1,
    limit: 20,
  });
  const currDay = dayjs();
  const initialQuery = {
    fromAt: currDay.startOf("day").startOf("month"),
    toAt: currDay.endOf("day").endOf("month"),
  };
  const [querySummarySale, setQuerySummarySale] = useState<any>({
    fromAt: dayjs().startOf("month").unix(),
    toAt: dayjs().endOf("month").unix(),
    page: 1,
    limit: 50,
  });
  const [querySummaryCommission, setQuerySummaryCommission] = useState<any>({
    fromAt: dayjs().startOf("month").unix(),
    toAt: dayjs().endOf("month").unix(),
    page: 1,
    limit: 50,
  });
  const currentMonthString = dayjs().format("YYYY-MM");
  // const { fetchSummarySale, summarySale, query, loading, fetchSummary, summaryData } = useDashboard({
  //   initQuery: {
  //     fromAt: initialQuery.fromAt.unix(),
  //     toAt: initialQuery.toAt.unix(),
  //     // merchantId: merchant?.id,
  //   },
  // });

  useEffect(() => {
    document.title = getTitle(title);
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [summaryData] = await Promise.all([
        dashboardApi.summary(query).then(({ data }) => data),
      ]);

      setData({
        summary: summaryData,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const fetchSummaryRecordSale = async () => {
    setLoadingSummaryRecordSale(true);
    try {
      const data = await dashboardApi.summaryRecordSale(query);

      // setData({
      //   summary: summaryData,
      // });
    } catch (error) {
    } finally {
      setLoadingSummaryRecordSale(false);
    }
  };

  const {
    fetchData: fetchRecordSale,
    query: queryRecordSale,
    setQuery: setQueryRecordSale,
    data: recordSales,
    loading: loadingRecordSales,
    total: totalRecordSales,
  } = useRecordSale({
    initialQuery: {
      page: 1,
      limit: 50,
      // month: currentMonthString,
      fromAt: initialQuery.fromAt.unix(),
      toAt: initialQuery.toAt.unix(),
    },
  });

  useEffect(() => {
    fetchData();
    fetchRecordSale();
    fetchSummaryRecordSale();
    return () => {};
  }, []);

  const onChangeMonthPickerAll = (date: any) => {
    if (date) {
      // Lấy tháng được chọn
      const selectedMonth = dayjs(date).startOf("month");

      // Thiết lập giá trị fromAt là đầu tháng và toAt là cuối tháng được chọn
      const fromAt = selectedMonth.unix();
      const toAt = selectedMonth.endOf("month").unix();
      // queryRecordSale.month = selectedMonth.format("YYYY-MM");
      queryRecordSale.fromAt = fromAt;
      queryRecordSale.toAt = toAt;
      querySummarySale.fromAt = fromAt;
      querySummarySale.toAt = toAt;
      querySummaryCommission.fromAt = fromAt;
      querySummaryCommission.toAt = toAt;
      setQuerySummarySale({ ...querySummarySale });
      setQueryRecordSale({ ...queryRecordSale });
      setQuerySummaryCommission({ ...querySummaryCommission });
    } else {
      query.fromAt = dayjs().subtract(1, "month").startOf("month").unix();
      query.toAt = dayjs().endOf("month").unix();
    }
  };

  return (
    <>
      <Row gutter={[24, 24]}>
        <div className="flex flex-col gap-2 px-[14px]">
          <h3>Bộ lọc chung</h3>
          <div className="flex gap-3 flex-wrap">
            <div className="filter-item mb-5">
              <DatePicker.MonthPicker
                onChange={onChangeMonthPickerAll}
                className="custom-filter w-[300px]"
                size="large"
                format={"MM-YYYY"}
                // defaultValue={moment()}
                // inputReadOnly={isMobile}
                allowClear
              />
            </div>
          </div>
        </div>
        <Col span={24}>
          <StatsPage data={data?.summary} />
        </Col>
        <Col span={24}>
          <div className="flex flex-col ">
            <h3 className="my-2">Tổng doanh thu</h3>
            <SummarySaleTab querySummarySale={querySummarySale} />
          </div>
        </Col>
        <Col span={24}>
          <div className="flex flex-col ">
            <h3>Doanh thu tổng hợp của Joybookie</h3>
            {/* <CommissionList querySummaryCommission={querySummaryCommission} /> */}
            <CommissionList />
          </div>
        </Col>
        <Col span={24}>
          <div className="flex flex-col ">
            <Tooltip
              className="!w-fit !flex"
              title={"Bao gồm tiền gói cố định và hoa hồng hàng tháng"}
            >
              <h3>Danh sách phí Joy Bookie và tình trạng đối tác thanh toán cho Joy Bookie</h3>
              <InfoCircleOutlined className="translate-x-1 translate-y-0.5" />
            </Tooltip>

            <RecordSalePage
              isShowAction={false}
              querySummarySale={queryRecordSale}
              // totalSummaryRef={totalSummaryRef}
            />
          </div>
        </Col>
        {/* <Col span={24}>
          <Card
            style={{ height: "100%" }}
            bordered={false}
            size="small"
            className="dashboard-card"
          >
            <div className="filter-container">
              <Space wrap>
                <div className="filter-item">
                  <label htmlFor="">Khoảng thời gian</label>
                  <div>
                    <DatePicker.MonthPicker
                      defaultValue={dayjs(currentMonthString)}
                      onChange={(data) => {
                        if (data) {
                          queryRecordSale.month = dayjs(data).format("YYYY-MM");
                        } else {
                          queryRecordSale.month = undefined;
                        }
                        fetchRecordSale();
                      }}
                      format={"MM-YYYY"}
                      style={{ height: 32 }}
                    />
                  </div>
                </div>
                <div className="filter-item">
                  <label htmlFor="">Trạng thái thanh toán</label>
                  <div>
                    <Select
                      placeholder="Chọn trạng thái"
                      allowClear
                      className="!h-auto"
                      onChange={(val) => {
                        queryRecordSale.paymentStatus = val;
                        fetchRecordSale();
                      }}
                      options={Object.values(RecordSalePaymentStatusTrans).map(
                        (item) => {
                          return {
                            label: item.label,
                            value: item.value,
                          };
                        }
                      )}
                      style={{ width: 200 }}
                    ></Select>
                  </div>
                </div>
                <div className="filter-item">
                  <label htmlFor="">Phương thức thanh toán</label>
                  <div>
                    <Select
                      placeholder="Chọn phương thức"
                      allowClear
                      className="!h-auto"
                      onChange={(val) => {
                        queryRecordSale.paymentType = val;
                        fetchRecordSale();
                      }}
                      options={Object.values(RecordSalePaymentTypeTrans).map(
                        (item) => {
                          return {
                            label: item.label,
                            value: item.value,
                          };
                        }
                      )}
                      style={{ width: 200 }}
                    ></Select>
                  </div>
                </div>
                <div className="filter-item btn">
                  <RecordSaleExportExcel
                    summaries={recordSales}
                    queryRecordSale={queryRecordSale}
                  />
                </div>
              </Space>
            </div>
            <Table
              loading={loadingRecordSales}
              pagination={false}
              rowKey="id"
              dataSource={recordSales}
              scroll={{
                x: "max-content",
              }}
            >
              <Column
                width={60}
                title="Code"
                render={(text, record: RecordSale) => (
                  <span className="text-secondary font-medium">
                    {record.code}
                  </span>
                )}
              />
              <Column
                width={90}
                title="Tháng"
                render={(text, record: RecordSale) => (
                  <span className="font-medium">
                    {dayjs(record.month).format("MM-YYYY")}
                  </span>
                )}
              ></Column>
              <Column
                width={300}
                title="Cửa hàng"
                render={(text, record: RecordSale) => (
                  <div className="flex items-center gap-3">
                    <Image
                      src={record.merchant.avatar}
                      preview={false}
                      className="rounded-full !w-[40px] !h-[40px] object-cover shadow-md"
                    />
                    <div className="flex flex-col flex-1">
                      <span className="font-medium">
                        {record.merchant.name}
                      </span>
                      <Tooltip title={record.merchant.address}>
                        <span className="text-[#8f8b8b] line-clamp-2">
                          {record.merchant.address}
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                )}
              />
              <Column
                width={200}
                title="Liên hệ"
                render={(text, record: RecordSale) => (
                  <div className="flex items-center gap-2">
                    <div className="flex flex-col">
                      <span className="text-[#8f8b8b]">
                        {record.merchant.email}
                      </span>
                      <span className="">{record.merchant.phone}</span>
                    </div>
                  </div>
                )}
              />
              <Column
                width={110}
                title="Tổng lịch đặt"
                align="center"
                dataIndex={"totalAppointment"}
              />
              <Column
                width={140}
                title="Tổng lịch đặt xác nhận trễ"
                align="center"
                dataIndex={"totalInvalidConfirmAppointment"}
              />
              <Column
                width={150}
                title="Tổng đánh giá tốt"
                align="center"
                dataIndex={"totalGoodReview"}
              />
              <Column
                width={150}
                title="Tổng đánh giá xấu"
                align="center"
                dataIndex={"totalBadReview"}
              />
              <Column
                align="right"
                width={200}
                title="Doanh thu lịch đặt"
                render={(text, record: RecordSale) => (
                  <div className="flex flex-col gap-1">
                    <span className="text-primary font-medium cursor-pointer">
                      {formatVND(record.moneyAppointment)}
                    </span>
                  </div>
                )}
              ></Column>
              <Column
                align="right"
                width={200}
                title="Doanh thu voucher"
                render={(text, record: RecordSale) => (
                  <div className="flex flex-col gap-1">
                    <span className="text-primary font-medium cursor-pointer">
                      {formatVND(record.moneyVoucher)}
                    </span>
                  </div>
                )}
              ></Column>
              <Column
                align="right"
                width={200}
                title="Doanh thu gói dịch vụ"
                render={(text, record: RecordSale) => (
                  <div className="flex flex-col gap-1">
                    <span className="text-primary font-medium cursor-pointer">
                      {formatVND(record.moneyServicePack)}
                    </span>
                  </div>
                )}
              ></Column>

              <Column
                align="right"
                width={180}
                title="Tiền hoa hồng"
                render={(text, record: RecordSale) => {
                  const details = JSON.parse(record?.details || "[]") as RecordSaleDetails[]
                  return(
                  <div className="flex gap-1 items-center justify-end">
                    <span className="text-primary font-medium ">
                      {formatVND(record.moneyCommission)} (
                      {record?.commissionPercent}
                      %)
                    </span>
                    <Popover
                        content={
                          <div className="flex flex-col gap-2">
                            {details?.map(item => <div className="flex items-center w-[300px] justify-between">
                              <span className="font-medium whitespace-nowrap">{item.content}</span>
                              <span className="font-medium text-primary whitespace-nowrap">
                                {item.commission}%
                              </span>
                            </div>)}
                          </div>
                        }
                      >
                        <InfoCircleOutlined className="text-primary" />
                      </Popover>
                  </div>
                )}}
              ></Column>

              <Column
                align="right"
                width={150}
                title="Tiền trả theo gói"
                render={(text, record: RecordSale) => (
                  <div className="flex justify-end text-primary gap-1">
                    <span className="text-primary font-medium cursor-pointer">
                      {formatVND(record.moneyRenew)}
                    </span>
                    {record.renewPackage && (
                      <Popover
                        content={
                          <div className="flex flex-col gap-2">
                            <div className="flex items-center">
                              <span className="font-medium w-[50px]">Gói:</span>
                              <span className="font-medium text-primary">
                                {record.renewPackage?.name}
                              </span>
                            </div>
                            <div className="flex items-center">
                              <span className="font-medium w-[50px]">
                                Mô tả:
                              </span>
                              <span className="font-medium text-primary">
                                {record.renewPackage?.desc}
                              </span>
                            </div>
                            <div className="flex items-center">
                              <span className="font-medium w-[50px]">Giá:</span>
                              <span className="font-medium text-primary">
                                {formatVND(record.renewPackage?.price)}
                              </span>
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className="text-primary" />
                      </Popover>
                    )}
                  </div>
                )}
              ></Column>

          
              <Column
                align="center"
                width={200}
                title="Thanh toán"
                fixed={window.screen.width < 600 ? undefined : "right"}
                render={(text, record: RecordSale) => (
                  <div className="flex flex-col gap-1 items-center">
                    {record.paymentStatus ===
                      RecordSalePaymentStatus.Pending && (
                      <div>
                        <Tag
                          color={
                            RecordSalePaymentStatusTrans[record.paymentStatus]
                              .color
                          }
                          className="font-medium w-fit align-center"
                        >
                          {
                            RecordSalePaymentStatusTrans[record.paymentStatus]
                              .label
                          }
                        </Tag>
                        <br />
                        Cần thanh toán số tiền:{" "}
                        <span className="text-pink font-medium">
                          {formatVND(record.amount)}{" "}
                        </span>
                        <Popover
                          content={
                            <div className="flex flex-col gap-2">
                              <div className="flex items-center gap-2">
                                <span className="font-medium w-[130px]">
                                  Tổng tiền hoa hồng:
                                </span>
                                <span className="font-medium text-primary">
                                  {formatVND(record.moneyCommission)}
                                </span>
                              </div>
                              <div className="flex items-center">
                                <span className="font-medium w-[130px]">
                                  Tiền trả theo gói:
                                </span>
                                <span className="font-medium text-primary">
                                  {formatVND(record.moneyRenew)}
                                </span>
                              </div>
                            </div>
                          }
                        >
                          <InfoCircleOutlined className="!text-pink" />
                        </Popover>
                      </div>
                    )}
                    {record.paymentStatus ===
                      RecordSalePaymentStatus.Complete && (
                      <>
                        <Tag
                          color={
                            RecordSalePaymentStatusTrans[record.paymentStatus]
                              .color
                          }
                          className="font-medium w-fit align-center"
                        >
                          {
                            RecordSalePaymentStatusTrans[record.paymentStatus]
                              .label
                          }
                        </Tag>
                        <div>
                          Bằng{" "}
                          <span className="font-medium">
                            {
                              RecordSalePaymentTypeTrans[record.paymentType]
                                ?.label
                            }
                          </span>{" "}
                          {record.paymentType ===
                            RecordSalePaymentType.Bank && (
                            <Popover
                              content={
                                <div className="flex flex-col gap-2">
                                  <div className="flex items-center">
                                    <span className="font-medium w-[130px]">
                                      Số tài khoản:
                                    </span>
                                    <span className="font-medium text-primary">
                                      {record.bankAccountNumber}
                                    </span>
                                  </div>
                                  <div className="flex items-center">
                                    <span className="font-medium w-[130px]">
                                      Tên chủ tài khoản:
                                    </span>
                                    <span className="font-medium text-primary">
                                      {record.bankAccountName}
                                    </span>
                                  </div>
                                  <div className="flex items-center">
                                    <span className="font-medium w-[130px]">
                                      Tên ngân hàng:
                                    </span>
                                    <span className="font-medium text-primary">
                                      {record.bankName}
                                    </span>
                                  </div>
                                </div>
                              }
                            >
                              <InfoCircleOutlined className="text-primary" />
                            </Popover>
                          )}{" "}
                          :{" "}
                          <span className="text-primary font-medium">
                            {formatVND(record.amount)}
                          </span>{" "}
                          <Popover
                            content={
                              <div className="flex flex-col gap-2">
                                <div className="flex items-center">
                                  <span className="font-medium w-[130px]">
                                    Tiền hoa hồng:
                                  </span>
                                  <span className="font-medium text-primary">
                                    {formatVND(record.moneyCommission)}
                                  </span>
                                </div>
                                <div className="flex items-center">
                                  <span className="font-medium w-[130px]">
                                    Tiền trả theo gói:
                                  </span>
                                  <span className="font-medium text-primary">
                                    {formatVND(record.moneyRenew)}
                                  </span>
                                </div>
                              </div>
                            }
                          >
                            <InfoCircleOutlined className="!text-primary" />
                          </Popover>
                        </div>
                        <div>Lúc {formattedFullDate(record.updatedAt)}</div>
                      </>
                    )}
                  </div>
                )}
              />

            </Table>
            <Pagination
              currentPage={queryRecordSale.page}
              total={totalRecordSales}
              pageSize={queryRecordSale.limit}
              onChange={({ limit, page }) => {
                fetchRecordSale({ limit, page });
              }}
            />
          </Card>
        </Col> */}
      </Row>
    </>
  );
};
