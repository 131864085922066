import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Space, Spin, Table } from "antd";
import { Pagination } from "components/Pagination";
import useRank from "hooks/useRank";
import { useEffect, useRef } from "react";
import { Rank } from "types/rank";
import { formatVND, getTitle } from "utils";
import { RankModal } from "./components/RankModal";
import { News } from "types/news";
import { PreviewImage } from "components/PreviewImage/PreviewImage";
import { FaCircle } from "react-icons/fa";

const { Column } = Table;

export const RankPage = ({ title = "" }) => {
  const { fetchData, query, data, loading, filterData, total, deleteData } =
    useRank({
      initialQuery: {
        page: 1,
        limit: 20,
      },
    });
  const modalRef = useRef<RankModal>(null);

  useEffect(() => {
    document.title = getTitle(title);
    fetchData();
  }, []);

  return (
    <section className="box">
      <div className="filter-container">
        <Space>
          <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              size="middle"
              onChange={(ev) => {
                filterData({ search: ev.currentTarget.value, page: 1 });
              }}
              placeholder="Tìm kiếm"
            />
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => fetchData()}
              type="primary"
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => {
                modalRef.current?.handleCreate();
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              Thêm mới
            </Button>
          </div>
        </Space>
      </div>

      <Spin spinning={loading}>
        <Table
          scroll={{
            x: 1000,
          }}
          pagination={false}
          rowKey="id"
          dataSource={data}
        >
          {/* <Column
            title="Icon"
            dataIndex="icon"
            key="icon"
            width={50}
            align="center"
            render={(text) => <PreviewImage image={text} />}
          /> */}
          <Column
            title="Tên"
            dataIndex="name"
            key="name"
            render={(text, record: Rank) => {
              return (
                <span
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                  className="text-secondary cursor-pointer hover:underline font-medium"
                >
                  {text}
                </span>
              );
            }}
          />

          <Column
            align="center"
            width={100}
            title="Màu nền"
            dataIndex="color"
            key="color"
            render={(text) => {
              return text ? <FaCircle color={text} size={24} /> : <span></span>;
            }}
          />
          <Column
            title="Số điểm cần"
            dataIndex="needPoint"
            align="right"
            render={(text) => formatVND(text)}
            key="needPoint"
          />
          <Column
            title="Tỉ lệ hoàn điểm"
            dataIndex="refundPercent"
            align="right"
            render={(text) => formatVND(text) + "%"}
            key="refundPercent"
          />

          <Column
            width={120}
            align="center"
            title="Hành động"
            key="action"
            render={(text, record: Rank) => (
              <Space>
                <Popconfirm
                  title="Bạn có chắc chắn muốn thực hiện thao tác?"
                  onConfirm={async () => {
                    await deleteData(record.id);
                  }}
                >
                  <Button type="primary" danger>
                    Xóa
                  </Button>
                </Popconfirm>
                <Button
                  type="primary"
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                >
                  Cập nhật
                </Button>
              </Space>
            )}
          />
        </Table>

        <Pagination
          currentPage={query.page}
          total={total}
          pageSize={query.limit}
          onChange={({ limit, page }) => {
            filterData({ limit, page });
          }}
        />
      </Spin>

      <RankModal onSubmitOk={fetchData} onClose={() => {}} ref={modalRef} />
    </section>
  );
};
