import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { CouponCampaign } from "types/couponCampaign";
import { couponCampaignApi } from "api/couponCampaign.api";
import { message } from "antd";
interface CouponCampaignQuery extends QueryParam {
  CouponCampaignTopicId?: number;
}
type Props = {
  initialQuery: CouponCampaignQuery;
};

const useCouponCampaign = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<CouponCampaign, CouponCampaignQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await couponCampaignApi
        .findAll(query)
        .then(({ data }) => data);
      return {
        data: data.couponCampaigns,
        total: data.total,
      };
    },
    editFunc: async (id, data) => {
      await couponCampaignApi.update(id, data);
    },
    deleteFunc: async (id) => {
      await couponCampaignApi.delete(id);
    },
  });
  const toggleEnable = async (id: number, isEnabled?: boolean) => {
    await couponCampaignApi.update(id, { couponCampaign: { isEnabled } });
    message.success("Thao tác thành công");
    fetch.fetchData();
  };
  const {
    query: queryCouponCampaign,
    fetchData: fetchCouponCampaign,
    total: totalCouponCampaign,
    loading: isFetchCouponCampaignLoading,
    data: couponCampaigns,
    deleteData: deleteCouponCampaign,
    editData: resolveCouponCampaign,
    filterData: filterCouponCampaign,
  } = fetch;

  return {
    fetchCouponCampaign,
    totalCouponCampaign,
    queryCouponCampaign,
    isFetchCouponCampaignLoading,
    couponCampaigns,
    deleteCouponCampaign,
    toggleEnable,
    resolveCouponCampaign,
    filterCouponCampaign,
  };
};

export default useCouponCampaign;
