import { customerApi } from "api/customer.api";
import { Customer } from "types/customer";
import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { MerchantTransaction } from "types/merchantTransaction";
import { merchantTransactionApi } from "api/merchantTransaction.api";
interface MerchantTransactionQuery extends QueryParam {
  merchantId?: number;
}
type Props = {
  initialQuery: MerchantTransactionQuery;
};

const useMerchantTransaction = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<
    MerchantTransaction,
    MerchantTransactionQuery
  >({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await merchantTransactionApi
        .findAll(query)
        .then(({ data }) => data);
      return {
        data: data.merchantTransactions,
        total: data.total,
      };
    },
    deleteFunc: async (id) => {},
    editFunc: async (id, data) => {
      await merchantTransactionApi.update(id, data);
    },
    createFunc: async (data) => {},
  });

  return { ...fetch };
};

export default useMerchantTransaction;
