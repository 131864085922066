import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { CustomerReview } from "types/customerReview";
import { customerReviewApi } from "api/customerReview.api";
interface CustomerReviewQuery extends QueryParam {
  CustomerReviewTopicId?: number;
}
type Props = {
  initialQuery: CustomerReviewQuery;
};

const useCustomerReview = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<CustomerReview, CustomerReviewQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await customerReviewApi
        .findAll(query)
        .then(({ data }) => data);
      return {
        data: data.customerReviews,
        total: data.total,
      };
    },
    editFunc: async (id, data) => {
      await customerReviewApi.update(id, data);
    },
    deleteFunc: async (id) => {
      await customerReviewApi.delete(id);
    },
  });
  const {
    query: queryCustomerReview,
    fetchData: fetchCustomerReview,
    total: totalCustomerReview,
    loading: isFetchCustomerReviewLoading,
    data: customerReviews,
    deleteData: deleteCustomerReview,
    editData: resolveCustomerReview,
    filterData: filterCustomerReview,
  } = fetch;

  return {
    fetchCustomerReview,
    totalCustomerReview,
    queryCustomerReview,
    isFetchCustomerReviewLoading,
    customerReviews,
    deleteCustomerReview,
    resolveCustomerReview,
    filterCustomerReview,
  };
};

export default useCustomerReview;
