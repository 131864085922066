import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Input,
  Modal,
  Space,
  Spin,
  Table,
  message,
} from "antd";
import { staffApi } from "api/staff.api";
import { Pagination } from "components/Pagination";
import useStaff from "hooks/useStaff";
import { useEffect, useState } from "react";
import { ModalStatus } from "types/modal";
import { Staff } from "types/staff";
import { getTitle } from "utils";
import { ResetPasswordModal } from "./components/ResetPasswordModal";
import { StaffModal } from "./components/StaffModal";

const { Column } = Table;

export const StaffPage = ({ title = "Users", description="" }) => {
  const { fetchData, data, query, total, loading, filterData } = useStaff({
    initialQuery: {
      page: 1,
      limit: 20,
    },
  });
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleResetPassModal, setVisibleResetPassModal] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState<Partial<Staff>>({});
  const [modalStatus, setModalStatus] = useState<ModalStatus>("create");

  useEffect(() => {
    document.title = getTitle(title);
    fetchData();
  }, []);

  const confirmDelete = (staff: Staff) => {
    Modal.confirm({
      title: "Xác nhận thao tác",
      icon: <ExclamationCircleOutlined />,
      content: `Bạn có muốn xóa ${staff.name} ?`,
      okText: "Xóa",
      okButtonProps: { danger: true },
      cancelText: "Hủy",
      onOk: () => handleDelete(staff.id),
    });
  };

  const handleDelete = async (id: number) => {
    await staffApi.delete(id);
    message.success("Thao tác thành công");

    await fetchData();
  };

  return (
    <section className="box">
      <div className="flex flex-col gap-1 mb-5">
        <h1 className="text-xl m-0 ">{title}</h1>
        <span className="font-medium">{description}</span>
      </div>
      <div className="filter-container">
        <Space wrap>
          <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              onChange={(e) => {
                let text = e.target.value;
                filterData({
                  search: text,
                });
              }}
              size="middle"
              placeholder="Tìm kiếm theo tên, số điện thoại"
            />
          </div>

          <div className="filter-item btn mobile-no-top">
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => {
                query.page = 1;
                fetchData();
              }}
            >
              Tìm
            </Button>
          </div>

          <div className="filter-item btn mobile-no-top">
            <Button
              onClick={() => {
                setVisibleModal(true);
                setModalStatus("create");
                setSelectedStaff({});
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              Thêm mới
            </Button>
          </div>
        </Space>
      </div>

      <Spin spinning={loading}>
        <Table
          pagination={false}
          rowKey="id"
          dataSource={data}
          scroll={{ x: 1000 }}
        >
          <Column
            width={150}
            title="Username"
            dataIndex="username"
            key="username"
            align="center"
            render={(text, record: Staff) => {
              return (
                <div>
                  <Avatar
                    src={record.avatar}
                    size={40}
                    icon={<UserOutlined />}
                  />
                  <br />
                  <label style={{ marginTop: "10px" }} htmlFor="">
                    {text}
                  </label>
                </div>
              );
            }}
          />
          <Column
            title="Tên"
            dataIndex="name"
            width={300}
            key="name"
            render={(text, record: Staff) => {
              return (
                <span
                  onClick={() => {
                    setSelectedStaff(record);
                    setVisibleModal(true);
                    setModalStatus("update");
                  }}
                  className="text-secondary cursor-pointer hover:underline font-medium"
                >
                  {text}
                </span>
              );
            }}
          />
          <Column title="Điện thoại" dataIndex="phone" key="phone" />
          <Column title="Email" dataIndex="email" key={"email"} />
          <Column
            width={150}
            align="center"
            title="Quyền"
            dataIndex="role"
            key={"role"}
            render={(_, record: Staff) => record.role?.name}
          />

          <Column
            align="center"
            width={300}
            title="Hành động"
            key="action"
            render={(text, record: Staff) => (
              <Space>
                <Button
                  type="link"
                  onClick={() => {
                    setVisibleResetPassModal(true);
                    setSelectedStaff(record);
                  }}
                >
                  Khôi phục mật khẩu
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setSelectedStaff(record);
                    setVisibleModal(true);
                    setModalStatus("update");
                  }}
                >
                  Cập nhật
                </Button>
                <Button
                  type="primary"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    confirmDelete(record);
                  }}
                >
                  Xóa
                </Button>
              </Space>
            )}
          />
        </Table>

        <Pagination
          total={total}
          currentPage={query.page}
          defaultPageSize={query.limit}
          onChange={({ limit, page }) => {
            filterData({ limit, page });
          }}
        />
      </Spin>

      <StaffModal
        onSubmitOk={fetchData}
        onClose={() => setVisibleModal(false)}
        visible={visibleModal}
        staff={selectedStaff}
        status={modalStatus}
      />
      <ResetPasswordModal
        staff={selectedStaff}
        visible={visibleResetPassModal}
        closeModal={() => setVisibleResetPassModal(false)}
      />
    </section>
  );
};
