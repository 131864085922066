import { leadApi } from "api/lead.api";
import { action, configure, makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

configure({
  enforceActions: "never",
});

class ConversationStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "ConversationStore",
      properties: ["totalNewConversation", "isGetTotalConversation"],
      storage: window.localStorage,
    });
  }

  totalNewConversation: number = 0;
  isGetTotalConversation = false;

  @action
  async countNewConversation() {
    const res = await leadApi.countNew();
    this.totalNewConversation = res.data.total;
    this.isGetTotalConversation = true;
  }
}

const conversationStore = new ConversationStore();

export { conversationStore };
