import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Spin, Table, Tabs, TabsProps } from "antd";
import { Pagination } from "components/Pagination";
import useCommissionConfig from "hooks/useCommissionConfig";
import useConfiguration from "hooks/useConfiguration";
import useRenewPackage from "hooks/useRenewPackage";
import { useEffect, useMemo, useRef, useState } from "react";
import { CommissionConfig, CommissionConfigType } from "types/commissionConfig";
import { ConfigurationParam } from "types/configuration";
import { getTitle } from "utils";
import { CommissionConfigModal } from "./components/CommissionConfigModal";
import { RenewPackageModal } from "./components/RenewPackageModal";
import { RenewPackage } from "types/renewPackage";
import { formatVND } from "utils/formatVND";

const { Column } = Table;

export const CommissionConfigPage = ({ title = "", description = "" }) => {
  const { data: configurations, fetchData: fetchConfig } = useConfiguration({
    initialQuery: {
      page: 1,
      limit: 100,
      param: ConfigurationParam.RemindConfirmAppMinute,
    },
  });

  const remindConfig = useMemo(
    () =>
      configurations.find(
        (it) => it.param === ConfigurationParam.RemindConfirmAppMinute
      ),
    [configurations]
  );

  const { fetchData, query, data, loading, filterData, total, deleteData } =
    useCommissionConfig({
      initialQuery: {
        page: 1,
        limit: 20,
      },
    });

  const {
    data: renewPackages,
    total: totalRenewPackages,
    query: queryRenewPackages,
    fetchData: fetchRenewPackage,
    loading: loadingRenewPackage,
    filterData: filterRenewPackage,
  } = useRenewPackage({ initialQuery: { limit: 100, page: 1 } });

  const modalRef = useRef<CommissionConfigModal>(null);
  const renewPackageModalRef = useRef<RenewPackageModal>(null);

  const [currTab, setCurrTab] = useState("1");

  useEffect(() => {
    document.title = getTitle(title);
    fetchData();
    fetchRenewPackage();
    fetchConfig();
  }, []);

  const onTabChange = (activeKey: string) => {
    setCurrTab(activeKey);
  };

  const tabs: TabsProps["items"] = [
    {
      key: "1",
      label: "% Booking",
    },
    {
      key: "2",
      label: "Loại phí",
    },
  ];

  return (
    <section className="box">
      <div className="flex flex-col gap-1 mb-5">
        <h1 className="text-xl m-0 ">{title}</h1>
        <span className="font-medium">{description}</span>
      </div>
      <div className="filter-container">
        <Tabs items={tabs} onChange={onTabChange} activeKey={currTab} />
      </div>

      {currTab === "1" ? (
        <Spin spinning={loading}>
          <Space className="filter-container">
            <div className="filter-item ">
              <label htmlFor="">Tìm kiếm</label>
              <Input
                size="middle"
                onChange={(ev) => {
                  filterData({ search: ev.currentTarget.value, page: 1 });
                }}
                placeholder="Tìm kiếm"
              />
            </div>
            <div className="filter-item btn">
              <Button
                onClick={() => fetchData()}
                type="primary"
                icon={<SearchOutlined />}
              >
                Tìm kiếm
              </Button>
            </div>
          </Space>
          <Table pagination={false} rowKey="id" dataSource={data}>
            <Column
              title="Tên"
              dataIndex="name"
              key="name"
              render={(name: string, record: CommissionConfig) => {
                if (record.type === CommissionConfigType.Guarantee) {
                  return (
                    <span className="font-medium text-secondary">
                      {name.replace(
                        "15 phút",
                        `${remindConfig?.value || 2}
                      phút`
                      )}
                    </span>
                  );
                }
                return (
                  <span className="font-medium text-secondary">{name}</span>
                );
              }}
            />
            <Column
              width={200}
              align="right"
              title="Tiền hoa hồng (%)"
              dataIndex="percent"
              key="percent"
            />

            <Column
              width={150}
              title="Hành động"
              key="action"
              render={(text, record: CommissionConfig) => (
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      modalRef.current?.handleUpdate(
                        record,
                        remindConfig?.value
                      );
                    }}
                  >
                    Cập nhật
                  </Button>
                </Space>
              )}
            />
          </Table>

          <Pagination
            currentPage={query.page}
            total={total}
            pageSize={query.limit}
            onChange={({ limit, page }) => {
              filterData({ limit, page });
            }}
          />
        </Spin>
      ) : (
        <Spin spinning={loading}>
          <Space className="filter-container">
            <div className="filter-item ">
              <label htmlFor="">Tìm kiếm</label>
              <Input
                size="middle"
                onChange={(ev) => {
                  filterRenewPackage({
                    search: ev.currentTarget.value,
                    page: 1,
                  });
                }}
                placeholder="Tìm kiếm"
              />
            </div>
            <div className="filter-item btn">
              <Button
                onClick={() => fetchRenewPackage()}
                type="primary"
                icon={<SearchOutlined />}
              >
                Tìm kiếm
              </Button>
            </div>
          </Space>
          <Table pagination={false} rowKey="id" dataSource={renewPackages}>
            <Column
              width={200}
              title="Tên"
              dataIndex="name"
              key="name"
              render={(name: string, record: RenewPackage) => {
                return (
                  <span className="font-medium text-secondary">{name}</span>
                );
              }}
            />
            <Column
              width={100}
              align="center"
              title="Giá tiền"
              dataIndex="price"
              key="price"
              render={(price: number, record: RenewPackage) => {
                return price > 0 ? (
                  <span className="font-medium text-secondary">
                    {formatVND(price)}
                  </span>
                ) : (
                  <>--</>
                );
              }}
            />
            <Column  width={500} title="Mô tả" dataIndex="desc" key="desc" />

            <Column
              width={150}
              title="Hành động"
              key="action"
              render={(text, record: RenewPackage) => (
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      renewPackageModalRef.current?.handleUpdate(record);
                    }}
                  >
                    Cập nhật
                  </Button>
                </Space>
              )}
            />
          </Table>

          <Pagination
            currentPage={queryRenewPackages.page}
            total={totalRenewPackages}
            pageSize={queryRenewPackages.limit}
            onChange={({ limit, page }) => {
              queryRenewPackages.limit = limit;
              queryRenewPackages.page = page;
              fetchRenewPackage();
            }}
          />
        </Spin>
      )}

      <CommissionConfigModal
        onSubmitOk={fetchData}
        onClose={() => {}}
        ref={modalRef}
      />

      <RenewPackageModal
        onSubmitOk={fetchRenewPackage}
        onClose={() => {}}
        ref={renewPackageModalRef}
      />
    </section>
  );
};
