import { BannerLayout } from "types/banner";

export type Size = {
  width: number;
  height: number;
};

export const RECOMMENDED_SIZE = {
  merchantAvatar: {
    width: 140,
    height: 140,
  },
  coverImage: {
    width: 960,
    height: 600,
  },
  bioImage: {
    width: 550,
    height: 400,
  },
  serviceImage: {
    width: 600,
    height: 450,
  },
  treatmentImage: {
    width: 250,
    height: 150,
  },

  [BannerLayout.Layout1]: {
    width: 500,
    height: 250,
  },
  [BannerLayout.Layout2]: {
    width: 700,
    height: 250,
  },
  [BannerLayout.Layout3]: {
    width: 400,
    height: 250,
  },
  [BannerLayout.Layout4]: {
    width: 300,
    height: 250,
  },
  [BannerLayout.Layout5]: {
    width: 500,
    height: 250,
  },

  newsImage: {
    width: 500,
    height: 300,
  },
};
