import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { RenewPackage } from "types/renewPackage";
import { renewPackageApi } from "api/renewPackage.api";
interface RenewPackageQuery extends QueryParam {}
type Props = {
  initialQuery: RenewPackageQuery;
};

const useRenewPackage = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<RenewPackage, RenewPackageQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await renewPackageApi
        .findAll(query)
        .then(({ data }) => data);
      return {
        data: data.renewPackages,
        total: data.total,
      };
    },
    deleteFunc: async (id) => {
      await renewPackageApi.delete(id);
    },
    editFunc: async (id, data) => {
      await renewPackageApi.update(id, data);
    },
    createFunc: async (data) => {
      await renewPackageApi.create(data);
    },
  });

  return { ...fetch };
};

export default useRenewPackage;
