import { Form, Modal } from "antd";
import { Rule } from "antd/lib/form";
import React, { useImperativeHandle, useState } from "react";
import { ModalStatus } from "types/modal";
import { RecordSalePage } from "views/RecordSale/RecordSalePage";

export interface RecordSaleModal {
  handleCreate: () => void;
}
interface RecordSaleModalProps {
  //   onClose: () => void;
  //   onSubmitOk: () => void;
}

export const RecordSaleModal = React.forwardRef(
  (
    {}: //   onClose,
    //   onSubmitOk,
    RecordSaleModalProps,
    ref
  ) => {
    const [visible, setVisible] = useState(false);

    useImperativeHandle<any, RecordSaleModal>(
      ref,
      () => ({
        handleCreate() {
          setVisible(true);
        },
      }),
      []
    );

    const handleClose = () => {
      setVisible(false);
    };
    return (
      <Modal
        onCancel={handleClose}
        visible={visible}
        title={"Thanh toán tiền cho Admin"}
        style={{ top: 20 }}
        width={1400}
        footer={null}
      >
        <RecordSalePage isBoxStyle={false} isShowAction={false} />
      </Modal>
    );
  }
);
