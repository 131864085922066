import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { Rank } from "types/rank";
import { rankApi } from "api/rank.api";
import { message } from "antd";
interface RankQuery extends QueryParam {}
type Props = {
  initialQuery: RankQuery;
};

const useRank = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<Rank, RankQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await rankApi.findAll(query).then(({ data }) => data);
      return {
        data: data.ranks,
        total: data.total,
      };
    },
    deleteFunc: async (id) => {
      await rankApi.delete(id);
      message.success("Thao tác thành công!");
    },
    editFunc: async (id, data) => {
      await rankApi.update(id, data);
    },
    createFunc: async (data) => {
      await rankApi.create(data);
    },
  });

  return { ...fetch };
};

export default useRank;
