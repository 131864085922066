export function formatPhoneNumber(phoneNumber: string): string {
  // Xóa tất cả các ký tự không phải số từ chuỗi số điện thoại
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

  // // Kiểm tra xem số điện thoại có đủ số hay không
  // if (cleanedPhoneNumber.length !== 10) {
  //   throw new Error("Số điện thoại không hợp lệ.");
  // }
  // Định dạng số điện thoại
  const formattedPhoneNumber = `+84 ${cleanedPhoneNumber.slice(
    1,
    5
  )} ${cleanedPhoneNumber.slice(5, 8)} ${cleanedPhoneNumber.slice(8)}`;
  return formattedPhoneNumber;
}
