import { customerApi } from "api/customer.api";
import { Customer } from "types/customer";
import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { Withdraw } from "types/withdraw";
import { withdrawApi } from "api/withdraw.api";
export interface WithdrawQuery extends QueryParam {}
type Props = {
  initialQuery: WithdrawQuery;
};

const useWithdraw = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<Withdraw, WithdrawQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await withdrawApi.findAll(query).then(({ data }) => data);
      return {
        data: data.withdraws,
        total: data.total,
      };
    },
    deleteFunc: async (id) => {},
    editFunc: async (id, data) => {
      await customerApi.update(id, data);
    },
    createFunc: async (data) => {},
  });

  return { ...fetch };
};

export default useWithdraw;
