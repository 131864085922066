import { Merchant } from "./merchant";

export enum EPaymentType {
  Momo = "MOMO",
  VNPAY = "VNPAY",
  Point = "POINT", //điểm
  ZaloPay = "ZALO_PAY", // Zalopay
  Cash = "CASH", // tại spa
}
export const EPaymentTypeTrans = {
  [EPaymentType.Momo]: "Momo",
  [EPaymentType.VNPAY]: "VNPay",
  [EPaymentType.Point]: "Điểm",
  [EPaymentType.Cash]: "Tiền mặt",
  [EPaymentType.ZaloPay]: "Zalopay",
};
export interface PaymentType {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  name: string;
  type: EPaymentType;
  icon: string;
  position: number;
  isEnabled: boolean;
  isDefault: boolean;
  merchantPaymentTypes: MerchantPaymentType[];
}

export interface MerchantPaymentType {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  isEnabled: boolean;
  position: number;
  paymentType: PaymentType;
  merchant: Merchant;
}
