import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { Dictionary, DictionaryType } from "types/dictionary";
import { dictionaryApi } from "api/dictionary.api";
interface DictionaryQuery extends QueryParam {
  type: DictionaryType;
}
type Props = {
  initialQuery: DictionaryQuery;
};

const useDictionary = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<Dictionary, DictionaryQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await dictionaryApi.findAll(query).then(({ data }) => data);
      return {
        data: data.dictionaries,
        total: data.total,
      };
    },
    editFunc: async (id, data) => {
      await dictionaryApi.update(id, data);
    },
    deleteFunc: async (id) => {
      await dictionaryApi.delete(id);
    },
  });
  const {
    query: queryDictionary,
    fetchData: fetchDictionary,
    total: totalDictionary,
    loading: isFetchDictionaryLoading,
    data: dictionaries,
    deleteData: deleteDictionary,
    filterData: filterDictionary,
  } = fetch;

  return {
    fetchDictionary,
    totalDictionary,
    queryDictionary,
    isFetchDictionaryLoading,
    dictionaries,
    deleteDictionary,
    filterDictionary,
  };
};

export default useDictionary;
