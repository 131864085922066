import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { Banner } from "types/banner";
import { bannerApi } from "api/banner.api";
import { message } from "antd";
interface BannerQuery extends QueryParam {}
type Props = {
  initialQuery: BannerQuery;
};

const useBanner = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<Banner, BannerQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await bannerApi.findAll(query).then(({ data }) => data);
      return {
        data: data.banners,
        total: data.total,
      };
    },
    deleteFunc: async (id) => {
      await bannerApi.delete(id);
      message.success("Thao tác thành công!");
    },
    editFunc: async (id, data) => {
      await bannerApi.update(id, data);
    },
    createFunc: async (data) => {
      await bannerApi.create(data);
    },
  });

  return { ...fetch };
};

export default useBanner;
