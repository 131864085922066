/**
 * Format number to VND.
 * Example: formatVND(10000);           // '10,000'
 */
export function formatVND(num: number = 0) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
export function formatCurrency(n: number = 0) {
  return new Intl.NumberFormat("Vi", {
    style: "currency",
    currency: "VND",
  }).format(n);
}

export const formatMoneyWithMinusSymbol = (
  money: number = 0,
  hasPrefix = false
) => {
  return money === 0
    ? `0${hasPrefix ? "đ" : ""}`
    : `- ${formatVND(money)}${hasPrefix ? "đ" : ""}`;
};
