import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import { voucherTemplateApi } from "api/voucherTemplate.api";
import { FormInputNumber } from "components/Input/FormInputNumber";
import dayjs, { Dayjs } from "dayjs";
import React, { useImperativeHandle, useMemo, useState } from "react";
import { CirclePicker, SketchPicker } from "react-color";
import { MdOutlineChangeCircle } from "react-icons/md";
import { ModalStatus } from "types/modal";
import { VoucherTemplate } from "types/voucher";
import { disabledPast } from "utils/date-picker";
import { requiredRule } from "utils/validate";

export interface VoucherModal {
  handleCreate: () => void;
  handleUpdate: (voucherTemplate: VoucherTemplate) => void;
}
interface VoucherModalProps {
  onClose: () => void;
  onSubmitOk: () => void;
}

const { TextArea } = Input;

interface IVoucherDurationUsage {
  expirationPeriod: any;
  startAt: any;
}

const enum TypeOfExpirationDate {
  NoLimitedTime = "NO_LIMITED_TIME",
  LimitedTime = "LIMITED_TIME",
}
const enum AllowPurchasingType {
  NoLimited = "NO_LIMITED",
  Limited = "LIMITED",
}
interface FormTypes extends VoucherTemplate {
  date: Dayjs;
  typeOfExpirationDate: TypeOfExpirationDate;
  allowPurchasingType: AllowPurchasingType;
}

const expirationDate = [
  {
    label: "Không có thời hạn",
    value: TypeOfExpirationDate.NoLimitedTime,
  },
  {
    label: "Có thời hạn",
    value: TypeOfExpirationDate.LimitedTime,
  },
];
const allowPurchasingTypeList = [
  {
    label: "Không giới hạn",
    value: AllowPurchasingType.NoLimited,
  },
  {
    label: "Giới hạn",
    value: AllowPurchasingType.Limited,
  },
];

export const VoucherModal = React.forwardRef(
  ({ onClose, onSubmitOk }: VoucherModalProps, ref) => {
    const [form] = Form.useForm<FormTypes>();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<ModalStatus>("create");
    const [voucherTemplateSelected, setVoucherTemplateSelected] =
      useState<VoucherTemplate>();
    const [voucherDurationUsage, setVoucherDurationUsage] =
      useState<IVoucherDurationUsage>();
    const [initialVoucherDurationUsage, setInitialVoucherDurationUsage] =
      useState<IVoucherDurationUsage>();
    const [color, setColor] = useState<any>("#8bc34a");
    const [colorPickerType, setColorPickerType] = useState<"circle" | "custom">(
      "circle"
    );

    const typeOfExpirationDate = Form.useWatch("typeOfExpirationDate", form);
    const allowPurchasingType = Form.useWatch("allowPurchasingType", form);

    useImperativeHandle<any, VoucherModal>(
      ref,
      () => ({
        handleCreate() {
          form.resetFields();
          setVisible(true);
          setStatus("create");
        },
        handleUpdate(voucherTemplate: VoucherTemplate) {
          form.setFieldsValue({
            ...voucherTemplate,
            date: dayjs.unix(voucherTemplate.startAt),
            typeOfExpirationDate:
              voucherTemplate.expirationPeriod === 0
                ? TypeOfExpirationDate.NoLimitedTime
                : TypeOfExpirationDate.LimitedTime,
            allowPurchasingType:
              voucherTemplate.numOfPerson === 0
                ? AllowPurchasingType.NoLimited
                : AllowPurchasingType.Limited,
            // expirationPeriod:
            //   voucherTemplate.expirationPeriod === 0
            //     ? 0
            //     : voucherTemplate.expirationPeriod,
          });
          setVisible(true);
          setStatus("update");
          setColor(voucherTemplate.color);
          setVoucherTemplateSelected(voucherTemplate);
          setVoucherDurationUsage({
            expirationPeriod: dayjs.unix(voucherTemplate.expirationPeriod),
            startAt: dayjs.unix(voucherTemplate.startAt),
          });
          setInitialVoucherDurationUsage({
            expirationPeriod: dayjs.unix(voucherTemplate.expirationPeriod),
            startAt: dayjs.unix(voucherTemplate.startAt),
          });
        },
      }),
      []
    );

    const onCloseModal = () => {
      setVisible(false);
      setColorPickerType("circle");
      setColor(undefined);
      setVoucherDurationUsage({
        expirationPeriod: undefined,
        startAt: undefined,
      });
      setInitialVoucherDurationUsage({
        expirationPeriod: undefined,
        startAt: undefined,
      });
    };

    // Kiểm tra nếu đang trong tg diễn ra voucher thì không được update
    const isInDurationOfEvent = useMemo(() => {
      return voucherTemplateSelected
        ? voucherTemplateSelected?.startAt < dayjs().unix()
        : false;
      // if (!initialVoucherDurationUsage?.expirationPeriod) return false;
      // const result = checkCurrentTimeIsBetweenUnix(
      //   dayjs(initialVoucherDurationUsage?.startAt).startOf("day").unix(),
      //   dayjs(initialVoucherDurationUsage?.expirationPeriod).endOf("day").unix()
      // );
      // return result;
    }, [voucherTemplateSelected]);

    const handleChangeDurationUsage = (values: any) => {
      setVoucherDurationUsage({
        expirationPeriod: values?.[1],
        startAt: values?.[0],
      });
    };

    const onSubmit = async () => {
      try {
        setLoading(true);
        console.log(form.getFieldsValue());
        await form.validateFields();
        const { id, expirationPeriod, date, ...formData } =
          form.getFieldsValue();
        // if (
        //   !voucherDurationUsage?.expirationPeriod ||
        //   !voucherDurationUsage?.startAt
        // ) {
        //   message.error("Thời gian sử dụng là bắt buộc");
        //   return;
        // }
        if (formData.price >= formData.value) {
          message.error("Giá bán cần nhỏ hơn giá trị của voucher !");
          return;
        }
        const payload = {
          voucherTemplate: {
            ...formData,
            numberSale: formData.limitSales ? formData.numberSale : undefined,
            expirationPeriod: expirationPeriod ? expirationPeriod : 0,
            startAt: dayjs(date).startOf("day").unix(),
            color,
          },
        };

        console.log(payload);
        if (status === "update") {
          await voucherTemplateApi.update(id || 0, payload);
        } else {
          await voucherTemplateApi.create(payload);
        }
        message.success("Thao tác thành công!");
        form.resetFields();
        onClose?.();
        onSubmitOk();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        onCancel={onCloseModal}
        visible={visible}
        title={
          <h1 className="text-secondary text-lg font-bold">
            {status === "create"
              ? "Thêm mới voucher"
              : `Cập nhật voucher - ${voucherTemplateSelected?.name || ""}`}
          </h1>
        }
        style={{ top: 20 }}
        width={900}
        // confirmLoading={loading}
        // onOk={onSubmit}
        footer={
          // isInDurationOfEvent ? (
          //   <span className="font-bold" style={{ color: "#f44336" }}>
          //     Đang trong thời gian mở bán
          //   </span>
          // ) : (
          <Space>
            <Button onClick={onCloseModal}>Hủy</Button>
            <Button loading={loading} onClick={onSubmit} type="primary">
              Lưu
            </Button>
          </Space>
          // )
        }
      >
        <Form
          layout="vertical"
          form={form}
          // initialValues={{
          //   typeOfExpirationDate: TypeOfExpirationDate.NoLimitedTime,
          // }}
        >
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Row gutter={[16, 32]}>
            <Col span={12}>
              <Form.Item label="Tên Voucher" name="name" rules={[requiredRule]}>
                <Input placeholder="Nhập tên voucher" />
              </Form.Item>

              <Form.Item
                label="Thời hạn sử dụng"
                name="typeOfExpirationDate"
                rules={[requiredRule]}
              >
                <Select
                  options={expirationDate}
                  fieldNames={{ label: "label", value: "value" }}
                  placeholder="Chọn loại"
                />
              </Form.Item>
              <Form.Item
                label="Giới hạn số lần mua cho 1 người"
                name="allowPurchasingType"
                rules={[requiredRule]}
              >
                <Select
                  options={allowPurchasingTypeList}
                  fieldNames={{ label: "label", value: "value" }}
                  placeholder="Chọn loại"
                />
              </Form.Item>
              <Form.Item
                name="price"
                label="Giá bán"
                required
                rules={[requiredRule]}
              >
                <FormInputNumber
                  placeholder="Nhập vào Giá bán"
                  addonAfter="đ"
                />
              </Form.Item>
              <Form.Item label="Ngày mở bán" name={"date"}>
                <DatePicker
                  allowClear={true}
                  // disabled={isInDurationOfEvent}
                  disabledDate={(current) => disabledPast(current)}
                  style={{ width: "100%", borderRadius: 8 }}
                  format="DD/MM/YYYY"
                  value={voucherDurationUsage?.startAt}
                  onChange={handleChangeDurationUsage}
                />
              </Form.Item>
              <Form.Item name="note" label="Ghi chú">
                <TextArea
                  rows={3}
                  placeholder="Nhập vào Ghi chú"
                  showCount
                  maxLength={300}
                />
              </Form.Item>

              <Form.Item
                initialValue={true}
                label="Trạng thái"
                name="isEnabled"
                valuePropName="checked"
                // extra={
                //   <span className="text-red-500">
                //     Đang trong thời gian bán voucher
                //   </span>
                // }
              >
                <Switch
                  checkedChildren="Mở bán"
                  unCheckedChildren="Ngưng bán"
                />
              </Form.Item>
            </Col>
            <Col span={12} className="text-center">
              <Form.Item
                name="value"
                label="Giá trị voucher"
                required
                rules={[requiredRule]}
              >
                <FormInputNumber
                  placeholder="Nhập vào Giá trị"
                  addonAfter="đ"
                />
              </Form.Item>
              {typeOfExpirationDate === "LIMITED_TIME" && (
                <Form.Item
                  name="expirationPeriod"
                  label="Hạn sử dụng (ngày)"
                  required
                  rules={[requiredRule]}
                >
                  <FormInputNumber placeholder="Nhập vào số ngày hết hạn" />
                </Form.Item>
              )}
              {allowPurchasingType === AllowPurchasingType.Limited && (
                <Form.Item
                  name="numOfPerson"
                  label="Giới hạn số lần mua"
                  required
                  rules={[requiredRule]}
                >
                  <FormInputNumber placeholder="Nhập vào số lần giới hạn mua" />
                </Form.Item>
              )}

              <Form.Item
                label="Mô tả"
                name="description"
                rules={[requiredRule]}
              >
                <TextArea placeholder="Nhập mô tả" rows={3} />
              </Form.Item>
              <div className="flex flex-col">
                <Form.Item label="Chọn màu">
                  {colorPickerType === "circle" && (
                    <CirclePicker
                      color={color}
                      onChangeComplete={(color) => setColor(color.hex)}
                    />
                  )}
                  {colorPickerType === "custom" && (
                    <SketchPicker
                      width="300px"
                      color={color}
                      onChangeComplete={(color) => setColor(color.hex)}
                    />
                  )}
                </Form.Item>
                <Button
                  icon={
                    <MdOutlineChangeCircle className="translate-y-0.5 mr-0" />
                  }
                  // style={{ width: "90%" }}
                  onClick={() => {
                    setColorPickerType(
                      colorPickerType === "circle" ? "custom" : "circle"
                    );
                  }}
                  style={{ marginBottom: 15 }}
                >
                  {colorPickerType === "circle" ? "Tùy chỉnh" : "Đơn giản"}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
);
