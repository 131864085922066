import { Avatar, DatePicker, Input, Popover, Table, Tag, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useRef, useState } from "react";
import { Pagination } from "components/Pagination";
import { useFetchCheckout } from "hooks/useFetchCheckout";
import { debounce } from "lodash";
import { useSearchParams } from "react-router-dom";
// import { appointmentApi } from "api/appointment.api";
import Column from "antd/lib/table/Column";
// import { settings } from "settings";
import {
  Checkout,
  CheckoutDetail,
  CheckoutStatus,
  CheckoutStatusTrans,
} from "types/checkout";
import { unixToFullTime } from "utils/formatDate";
import { formatVND } from "utils/formatVND";
import { checkoutApi } from "api/checkout.api";
// import { CompleteCheckoutDrawerDetail } from "./components/Drawer/CompleteCheckoutDrawerDetail";
// import { ProcessCheckoutDrawer } from "./components/Drawer/ProcessCheckoutDrawer";
import { getTitle } from "utils";
import { ServiceOptionCustom } from "types/appointment";
import CheckoutExportExcel from "./components/CheckoutExportExcel";
import settings from "settings";
import { CompleteCheckoutDrawerDetail } from "./components/Drawer/CompleteCheckoutDrawerDetail";
import dayjs from "dayjs";

interface IProps {
  title?: string;
  description?: string;
  merchantId?: number;
  customerId?: number;
}

export const CheckoutPage = ({
  title = "",
  description,
  merchantId,
  customerId,
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const [isFetchAppointmentDetailLoading, setisFetchAppointmentDetailLoading] =
    useState(false);
  // const history = useHistory()

  const [form] = useForm();
  const [queryParameters, setQueryParameters] = useSearchParams();

  const {
    checkouts,
    fetchCheckout,
    loadingCheckouts,
    queryCheckouts,
    totalCheckouts,
  } = useFetchCheckout({
    initQuery: {
      page: 1,
      limit: 10,
    },
  });
  // const processCheckoutDrawer = useRef<ProcessCheckoutDrawer>();
  const completeCheckoutDrawerDetailRef =
    useRef<CompleteCheckoutDrawerDetail>();

  // useEffect(() => {
  //   queryCheckouts.customerId = customerId;
  //   fetchCheckout();
  //   document.title = getTitle(title);

  //   // //*Xét urlParam nếu có appointmentId thì mở drawer để checkout
  //   // const appointmentId = queryParameters.get("appointmentId");
  //   // appointmentId && handleFetchAppointmentDetail(+appointmentId);
  // }, []);

  useEffect(() => {
    document.title = getTitle(title);
    queryCheckouts.merchantId = merchantId;
    queryCheckouts.customerId = customerId;
    fetchCheckout();
  }, [merchantId, customerId]);

  //* Xử lý fetch chi tiết appointment
  // const handleFetchAppointmentDetail = async (appointmentId: number) => {
  //   try {
  //     setisFetchAppointmentDetailLoading(true);
  //     const result = (await appointmentApi.detail(appointmentId))
  //       .data as Appointment;
  //     processCheckoutDrawer.current?.handleCreate(result);
  //   } finally {
  //     setisFetchAppointmentDetailLoading(false);
  //   }
  // };

  // const handleSubmitForm = async () => {
  //   await form.validateFields();

  //   const formData = form.getFieldsValue();
  //   const { images, techNickname, ...rest } = formData;
  //   const dataPost = {
  //     merchant: {
  //       ...rest,
  //       taxFactor: form.getFieldValue("taxFactor") / 100,
  //       exchangePointRationFactor:
  //         form.getFieldValue("exchangePointRationFactor") / 100,
  //       techNickname: techNickname.toLowerCase(),
  //     },
  //     images: formData.images,
  //   };
  //   setLoading(true);
  //   await merchantApi.update(dataPost);
  //   setLoading(false);
  //   message.success("Saved!");
  // };

  // const handleDeleteEmployee = async (employeeId: number) => {
  //   try {
  //     const res = await employeeApi.delete(employeeId);
  //     message.success("Xóa nhân viên thành công");
  //     fetchCheckout();
  //   } catch (error) {}
  // };

  const onChangeRangePicker = (date: any, dateFormat: string[]) => {
    if (date) {
      queryCheckouts.toAt = dayjs(date[1]).endOf("day").unix();
      queryCheckouts.fromAt = dayjs(date[0]).startOf("day").unix();
    } else {
      queryCheckouts.toAt = undefined;
      queryCheckouts.fromAt = undefined;
    }
    fetchCheckout();
  };

  const debounceSearch = debounce(() => {
    fetchCheckout();
  }, 300);

  const handleFindOneCheckout = async (checkoutId: number) => {
    try {
      //   setLoadingInvoices(true);
      const res = await checkoutApi.detail(checkoutId);
      //   setVisibleInvoicesDetailsDrawer(true);
      //   setSelectedInvoices(res.data);
      //   setLoadingInvoices(false);
    } catch (error) {}
  };

  const renderServiceDetail = (record: Checkout) => {
    let countServices = 0; //* Tính tổng dich vụ còn lại chưa show trong chi tiết
    const element = (
      <Popover
        content={
          <div className="list flex flex-col gap-3 min-w-[300px]">
            {record?.checkoutDetails.map((detail) => {
              const serviceOptions = (
                JSON.parse(
                  detail?.serviceOptionJson || "[]"
                ) as ServiceOptionCustom[]
              ).filter((item) => item.isChecked);
              countServices += serviceOptions.length + 1;
              return (
                <div className="flex flex-col">
                  <div className="item">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span
                        // style={{  }}
                        className="service-name font-semibold max-w-[200px]"
                      >
                        {/* {detail.serviceOption.pricingName ||
              detail.service.name} */}
                        {detail.service.name}
                      </span>
                      <span style={{ fontWeight: 500, fontSize: 14 }}>
                        {detail.servicePackOrderDetail
                          ? "Thanh toán gói"
                          : `${formatVND(detail.price)}`}
                      </span>
                    </div>
                  </div>
                  {serviceOptions?.length != 0 &&
                    serviceOptions?.map((option) => (
                      <div className="item">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span className="service-name font-semibold max-w-[180px]">
                            + {option.pricingName} (Dịch vụ kèm)
                          </span>
                          <span style={{ fontWeight: 500, fontSize: 14 }}>
                            {detail.servicePackOrderDetail
                              ? "Thanh toán gói"
                              : `${formatVND(option.price)}`}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              );
            })}
          </div>
        }
        title={<h1 className="text-lg font-semibold">Danh sách dịch vụ</h1>}
        trigger="click"
      >
        <Tooltip
          title={
            record.checkoutDetails.length > 2 ? "Bấm vào để xem chi tiết" : ""
          }
        >
          <span
            className="text-primary cursor-pointer"
            style={{ marginBottom: 0 }}
          >
            {/* //* Vì có 1 dịch vụ show ở ngoài sẵn nên trừ 1 đi chi tiết */}+
            {countServices - 1} dịch vụ khác
          </span>
        </Tooltip>
      </Popover>
    );
    return countServices == 1 ? <></> : element;
  };

  return (
    <div className="m-auto ">
      <div className="filter-container">
        <div className="flex gap-2 items-center flex-wrap">
          <div className="filter-item w-full sm:w-fit">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              size="middle"
              onChange={(e) => {
                let text = e.target.value;
                queryCheckouts.search = text;
                queryCheckouts.page = 1;
                debounceSearch();
              }}
              placeholder="Tìm kiếm theo mã"
            />
          </div>

          <div className="filter-item !w-fit max-w-[300px] ">
            <label htmlFor="">Khoảng thời gian</label>
            <DatePicker.RangePicker
              presets={[
                { label: "Hôm nay", value: [dayjs(), dayjs().add(1, "day")] },
                {
                  label: "Tuần này",
                  value: [dayjs().startOf("week"), dayjs().endOf("week")],
                },
                {
                  label: "Tháng này",
                  value: [dayjs().startOf("month"), dayjs().endOf("month")],
                },
                {
                  label: "Tháng trước",
                  value: [
                    dayjs().subtract(1, "month").startOf("month"),
                    dayjs().subtract(1, "month").endOf("month"),
                  ],
                },
              ]}
              onChange={onChangeRangePicker}
              className="custom-filter !w-full  max-h-[32px]  max-w-[300px]"
              size="small"
              format={settings.dateFormat}
            />
          </div>
          <div className="filter-item w-full sm:w-fit btn">
            <CheckoutExportExcel
              queryCheckout={queryCheckouts}
              summaries={checkouts}
            />
          </div>
          {/* <div className="filter-item w-full sm:w-fit">
            <Select
              size="large"
              className="custom-filter focus-within:!border-primary hover:!border-primary focus-within:!shadow-none"
              style={{ width: isMobile ? "100%" : 200 }}
              placeholder="Chọn trạng thái"
              allowClear
              onChange={(value) => {
                queryCheckouts.status = value;
                fetchCheckout();
              }}
            >
              <Select.Option value={undefined}>Tất cả</Select.Option>
              {Object.values(CheckoutStatus)?.map((item) => (
                <Select.Option value={item}>
                  {CheckoutStatusTrans[item]?.label}
                </Select.Option>
              ))}
            </Select>
          </div> */}

          {/* <div className="filter-item btn">
            <Button
              onClick={() => {
                // employeeModalRef.current?.handleCreate();
              }}
              type="primary"
              size="large"
            >
              Thêm nhân viên
            </Button>
          </div> */}
        </div>
      </div>

      <Table
        sticky
        dataSource={checkouts}
        pagination={false}
        loading={loadingCheckouts}
        size="small"
        scroll={{ x: "max-content", y: "calc(100vh - 280px)" }}
      >
        <Column
          align="center"
          width={70}
          title="Mã"
          key="point"
          render={(text, record: Checkout) => (
            <div className="flex flex-col gap-1">
              <span
                className="font-semibold text-primary cursor-pointer"
                onClick={() => {
                  // handleFindOneCheckout(record.id);
                  completeCheckoutDrawerDetailRef.current?.handleOpen(record);
                }}
              >
                #{record?.code}
              </span>
            </div>
          )}
        />
        <Column
          width={250}
          title="Tên khách hàng"
          key="name"
          render={(text, record: Checkout) => (
            <div className="flex gap-3 items-center">
              <Avatar
                className="!w-[40px] !h-[40px]"
                src={record.customerProfile?.avatar || settings.avatarDefault}
              />
              <span className="text-sm font-semibold">
                {record?.customerProfile?.fullName || "Khách hàng ẩn danh"}
              </span>
            </div>
          )}
        />

        <Column
          width={250}
          title="Tên nhân viên"
          key="name"
          render={(text, record: Checkout) => (
            <div className="flex gap-3 items-center">
              <span className="text-sm font-semibold">
                {record?.employee?.name}
              </span>
            </div>
          )}
        />

        {/* <Column
          align="center"
          width={120}
          title="Số điện thoại"
          key="point"
          render={(text, record: Checkout) => (
            <div className="flex flex-col gap-1">
              <span className="font-medium">{record?.customer?.phone}</span>
            </div>
          )}
        /> */}

        <Column
          width={150}
          title="Dịch vụ"
          key="point"
          render={(text, record: Checkout) => (
            <div>
              {record.checkoutDetails
                .slice(0, 1)
                .map((item: CheckoutDetail, index: number) => (
                  <div key={index}>
                    <span> {item?.service?.name}</span>
                  </div>
                ))}

              {/* {record.checkoutDetails.length > 1 &&
                <Popover
                  content={
                    <div className="list flex flex-col gap-3 min-w-[300px]">
                      {record?.checkoutDetails.map((detail) => {
                        const serviceOptions = (
                          JSON.parse(
                            detail?.serviceOptionJson || "[]"
                          ) as ServiceOptionCustom[]
                        ).filter((item) => item.isChecked);
                        return (
                          <div className="flex flex-col">
                            <div className="item">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  // style={{  }}
                                  className="service-name font-semibold max-w-[200px]"
                                >
                                  {detail.service.name}
                                </span>
                                <span style={{ fontWeight: 500, fontSize: 14 }}>
                                  {detail.servicePackOrderDetail
                                    ? "Thanh toán gói"
                                    : `${formatVND(detail.price)}`}
                                </span>
                              </div>
                            </div>
                            {serviceOptions?.length != 0 &&
                              serviceOptions?.map((option) => (
                                <div className="item">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span className="service-name font-semibold max-w-[180px]">
                                      + {option.pricingName} (Dịch vụ kèm)
                                    </span>
                                    <span
                                      style={{ fontWeight: 500, fontSize: 14 }}
                                    >
                                      {detail.servicePackOrderDetail
                                        ? "Thanh toán gói"
                                        : `${formatVND(option.price)}`}
                                    </span>
                                  </div>
                                </div>
                              ))}
                          </div>
                        );
                      })}
                    </div>
                  }
                  title={
                    <h1 className="text-lg font-semibold">Danh sách dịch vụ</h1>
                  }
                  trigger="click"
                >
                  <Tooltip
                    title={
                      record.checkoutDetails.length > 2
                        ? "Bấm vào để xem chi tiết"
                        : ""
                    }
                  >
                    <span
                      className="text-primary cursor-pointer"
                      style={{ marginBottom: 0 }}
                    >
                      +{record.checkoutDetails.length - 1} dịch vụ khác
                    </span>
                  </Tooltip>
                </Popover>} */}
              {renderServiceDetail(record)}
            </div>
          )}
        />

        <Column
          align="right"
          width={150}
          title="Tổng giá (VNĐ)"
          key="point"
          render={(text, record: Checkout) => {
            const hasSingleServiceOption = record.checkoutDetails.find(
              (item) => !item.servicePackOrderDetail
            );

            return (
              <>
                <div className="flex flex-col gap-1">
                  <span className="font-medium">
                    {!hasSingleServiceOption
                      ? "Thanh toán gói"
                      : `${formatVND(record?.moneyTotal)}`}
                  </span>
                </div>
              </>
            );
          }}
        />

        <Column
          align="center"
          width={120}
          title="Trạng thái"
          key="point"
          render={(text, record: Checkout) => (
            <div className="flex flex-col gap-1">
              <span className="font-medium">
                <Tag
                  color={
                    (record.status === CheckoutStatus.Complete && "green") ||
                    (record.status === CheckoutStatus.Voided && "orange") ||
                    "red"
                  }
                >
                  {CheckoutStatusTrans[record.status].label}
                </Tag>
              </span>
            </div>
          )}
        />

        <Column
          align="center"
          width={140}
          title="Ngày tạo"
          key="point"
          render={(text, record: Checkout) => (
            <>
              <div className="flex flex-col gap-1">
                <span className="font-medium">
                  {unixToFullTime(record?.createdAt)}
                </span>
              </div>
            </>
          )}
        />
        {/* <Column
          align="center"
          width={120}
          key="point"
          render={(text, record: Appointment) => (
            <Dropdown
              menu={{
                items: [
                  {
                    label: "Cập nhật",
                    key: "1",
                    icon: <EditOutlined />,
                    // onClick: () =>
                    //   employeeModalRef.current?.handleUpdate(record),
                  },
                  {
                    label: <span className="text-red-500">Xóa khách hàng</span>,
                    key: "2",
                    icon: <DeleteOutlined className="!text-red-500" />,
                    onClick: () => onDelete(record.id),
                  },
                ],
              }}
              trigger={["click"]}
            >
              <Button
                style={{ borderRadius: 9999 }}
                className="hover:!border-[#000000] focus:!border-[#000000] hover:!bg-[#000000] hover:!text-white"
              >
                <Space>
                  Thao tác
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          )}
        /> */}
      </Table>

      {/* {selectedInvoices && (
        <InvoicesDetailsDrawer
          loading={loadingInvoices}
          onClose={() => {
            setVisibleInvoicesDetailsDrawer(false);
            setSelectedInvoices(undefined);
            setStatus("create");
          }}
          visible={visibleInvoicesDetailsDrawer}
          selectedInvoices={selectedInvoices}
        />
      )} */}

      <Pagination
        total={totalCheckouts}
        currentPage={queryCheckouts.page}
        onChange={({ limit, page }) => {
          queryCheckouts.page = page;
          queryCheckouts.limit = limit;
          fetchCheckout();
        }}
      />
      <CompleteCheckoutDrawerDetail
        ref={completeCheckoutDrawerDetailRef}
        // onSubmitOk={fetchCheckout}
      />
    </div>
  );
};
