import { Badge, Tabs } from "antd";
import { MerchantStatus, merchantStatusTrans } from "types/merchant";
import { MerchantTab } from "./MerchantTab";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { merchantApi } from "api/merchant.api";
import {
  OneSignalDataType,
  useOneSignalContext,
} from "context/OneSignalContext";
import { getTitle } from "utils";
import usePaymentOrder from "hooks/usePaymentOrder";

interface IMerchantSummaryStatus {
  status: MerchantStatus;
  total: number;
}

export const MerchantPage = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  const [lastUpdated, setLastUpdated] = useState(0);
  const [merchantStatusSummary, setMerchantStatusSummary] = useState<
    IMerchantSummaryStatus[]
  >([]);

  const { addListCallback, removeListCallback } = useOneSignalContext();
  const handleFetchMerchantSummary = useCallback(async () => {
    const res = await merchantApi.summary();
    setMerchantStatusSummary(res.data);
  }, []);
  useEffect(() => {
    handleFetchMerchantSummary();
  }, [lastUpdated]);

  useEffect(() => {
    const ev = addListCallback?.(
      OneSignalDataType.NEW_MERCHANT,
      (data: any) => {
        setLastUpdated(moment().unix());
      }
    );
    document.title = getTitle(title);

    return () => {
      removeListCallback?.(ev);
    };
  }, []);
  return (
    // <section className="box">
    <>
      <div className="flex flex-col gap-1 mb-1.5">
        <span className="font-medium">{description}</span>
      </div>
      <Tabs>
        {Object.values(merchantStatusTrans).map((status) => (
          <Tabs.TabPane
            key={status.value}
            tabKey={status.value}
            tab={
              <span>
                {status.label}
                <Badge
                  count={
                    merchantStatusSummary?.find(
                      (stat) => stat.status === status.value
                    )?.total || 0
                  }
                  showZero
                  size={"small"}
                  color={status.color}
                  style={{ fontSize: 10, marginLeft: 5, marginBottom: 10 }}
                ></Badge>
              </span>
            }
          >
            <MerchantTab
              lastUpdated={lastUpdated}
              onStatusChanged={() => setLastUpdated(moment().unix())}
              initialQuery={{
                status: status.value,
              }}
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </>
    // </section>
  );
};
