import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { City } from "types/address";
import { cityApi } from "api/city.api";
import { message } from "antd";
interface cityQuery extends QueryParam {}
type Props = {
  initialQuery: cityQuery;
};

const useCity = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<City, cityQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await cityApi.findAll(query).then(({ data }) => data);
      return {
        data: data.cities,
        total: data.total,
      };
    },
    editFunc: async (id, data) => {
      await cityApi.update(id, data);
    },
  });

  const toggleHighlight = async (id: number, isHighlight?: boolean) => {
    await cityApi.update(id, { city: { isHighlight } });
    message.success("Thao tác thành công");
    fetch.fetchData();
  };

  return { toggleHighlight, ...fetch };
};

export default useCity;
