export enum CouponDiscountType {
  Fixed = "FIXED",
  Percent = "PERCENT",
}

export const CouponDiscountTypeTrans = {
  [CouponDiscountType.Fixed]: "VNĐ",
  [CouponDiscountType.Percent]: "%",
};

export enum CouponCampaignType {
  NewMemberShip = "NEW_MEMBER_SHIP", //thành viên mới
  DOB = "DOB",
  FirstRegister = "FIRST_REGISTER",
}

export const CouponCampaignTypeTrans = {
  [CouponCampaignType.NewMemberShip]: "Thành viên mới",
  [CouponCampaignType.DOB]: "Sinh nhật",
  [CouponCampaignType.FirstRegister]: "Tài khoản mới",
};

export interface CouponCampaign {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  code: string;
  name: string; //tên chiến dịch
  type: CouponCampaignType;
  discountType: CouponDiscountType;
  conditionValue: number; //giá trị đơn hàng tối thiếu có để áp dụng mã. Đối với CouponDiscountType.Fixed || CouponDiscountType.Percent.
  discountValue: number; //giá trị giảm (tiền). Đối với CouponDiscountType.Fixed || CouponDiscountType.Percent.
  discountMaxValue: number; //giá trị giảm tối đa. Đối với CouponDiscountType.Percent. 0: k giới hạn
  isEnabled: boolean; //true: chiến dịch đc bật
}
