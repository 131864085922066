import { Employee } from "./employee";
import { Merchant } from "./merchant";
import { Staff } from "./staff";

export enum WithdrawStatus {
  Pending = "PENDING",
  Cancel = "CANCEL",
  Complete = "COMPLETE",
}

export const WithdrawStatusTrans = {
  [WithdrawStatus.Pending]: {
    label: "Chờ xử lý",
    value: WithdrawStatus.Pending,
    color: "orange",
  },
  [WithdrawStatus.Cancel]: {
    label: "Bị từ chối",
    value: WithdrawStatus.Cancel,
    color: "red",
  },
  [WithdrawStatus.Complete]: {
    label: "Đã duyệt",
    value: WithdrawStatus.Complete,
    color: "green",
  },
};
export interface Withdraw {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  code: string;
  amount: number;
  status: WithdrawStatus;
  note: string;
  canceledAt: number;
  completedAt: number;
  merchant: Merchant;
  employee: Employee;
  staff: Staff;
}
