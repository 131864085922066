export interface Configuration {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  title: string;
  param: ConfigurationParam;
  value: string;
  dataType: ConfigurationDataType;
}

export enum ConfigurationDataType {
  Number = "NUMBER",
  String = "STRING",
}

export enum ConfigurationParam {
  RemindAppMinute = "REMIND_APP_MINUTE", //số phút nhắc hẹn đặt lịch
  RemindConfirmAppMinute = "REMIND_CONFIRM_APP_MINUTE", //số phút xác nhận lịch hẹn
  RewardPointReview = "REWARD_POINT_REVIEW", //số điểm thưởng khi review
}

export const configurationParamsObject = {
  [ConfigurationParam.RemindAppMinute]: {
    value: ConfigurationParam.RemindAppMinute,
    label: "Số phút nhắc hẹn đặt lịch",
  },
  [ConfigurationParam.RemindConfirmAppMinute]: {
    value: ConfigurationParam.RemindConfirmAppMinute,
    label: "Số phút xác nhận lịch hẹn",
  },
  [ConfigurationParam.RewardPointReview]: {
    value: ConfigurationParam.RewardPointReview,
    label: "Số điểm thưởng sau khi đánh giá",
  },
};
