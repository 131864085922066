import { customerApi } from "api/customer.api";
import { Customer } from "types/customer";
import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { recordSaleApi } from "api/recordSale.api";
import { RecordSale } from "types/recordSale";
import { useRef, useState } from "react";
import { SumaryRecordSale, SummarySale } from "types/dashboard";
import { DashboardQuery } from "./useDashboard";
export interface RecordSaleQuery extends QueryParam {}
type Props = {
  initialQuery: RecordSaleQuery;
};

const useRecordSale = ({ initialQuery }: Props) => {
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [summary, setSummary] = useState<SumaryRecordSale[]>([]);
  const [totalSummary, setTotalSummary] = useState(0);
  const query = useRef<DashboardQuery>(initialQuery);
  const fetch = useFetchTableData<RecordSale, RecordSaleQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await recordSaleApi.findAll(query).then(({ data }) => data);
      return {
        data: data.recordSales,
        total: data.total,
      };
    },
    deleteFunc: async (id) => {},
    editFunc: async (id, data) => {
      await customerApi.update(id, data);
    },
    createFunc: async (data) => {},
  });
  const fetchSummary = async () => {
    setLoadingSummary(true);
    try {
      const { data } = await recordSaleApi.summary(query.current);

      setSummary(data);
      setTotalSummary(data.total);
    } finally {
      setLoadingSummary(false);
    }
  };
  return { ...fetch, fetchSummary, summary, loadingSummary };
};

export default useRecordSale;
