import { Appointment } from "./appointment";
import { CheckoutPaymentType } from "./checkout";
import { Customer } from "./customer";
import { Merchant } from "./merchant";
import { ServicePackOrder } from "./servicePackOrder";
// import { ServicePackOrder } from "./";
import { Voucher, VoucherTemplate } from "./voucher";

export interface PaymentType {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  name: string;
  position: number;
  type: CheckoutPaymentType;
}
export enum PaymentOrderStatus {
  Pending = "PENDING",
  Complete = "COMPLETE",
  Fail = "FAIL",
}

export const PaymentOrderStatusTrans: any = {
  [PaymentOrderStatus.Fail]: {
    value: PaymentOrderStatus.Fail,
    label: "Thất bại",
    color: "red",
  },
  [PaymentOrderStatus.Complete]: {
    value: PaymentOrderStatus.Complete,
    label: "Hoàn tất",
    color: "green",
  },
  [PaymentOrderStatus.Pending]: {
    value: PaymentOrderStatus.Pending,
    label: "Đang xử lý",
    color: "blue",
  },
};

export interface PaymentOrderDetail {
  id: number;
  createdAt: number;
  quantity: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  amount: number;
  merchant: Merchant;
  appointment: Appointment;
  servicePackOrder: ServicePackOrder;

  paymentOrder: PaymentOrder;
  voucherTemplate: VoucherTemplate;
  voucher: Voucher;
}

export interface PaymentOrder {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  publicCode: string;
  code: string;
  status: PaymentOrderStatus;
  amount: number; //tổng tiền
  quantity: number; //sl details
  paymentType: PaymentType;
  paymentOrderDetails: PaymentOrderDetail[];
  customer: Customer;
}
