export enum FAQType {
  FAQ = "FAQ", //câu hỏi thường gặp
  Booking = "BOOKING", //đặt lịch
  RefundPolicy = "REFUND_POLICY", //hủy, hoàn và thay đổi
  Partner = "PARTNER", //đăng ký đối tác
  UseApp = "USE_APP", //tôi và sử dụng app
  Payment = "PAYMENT", //thanh toán
  Account = "ACCOUNT", //tài khoản
}

export const FAQTypeTrans = {
  [FAQType.FAQ]: "Câu hỏi thường gặp",
  [FAQType.Booking]: "Đặt lịch",
  [FAQType.RefundPolicy]: "Hủy, hoàn tiền và thay đổi",
  [FAQType.Partner]: "Đăng ký đối tác",
  [FAQType.UseApp]: "Tải và sử dụng app",
  [FAQType.Payment]: "Thanh toán",
  [FAQType.Account]: "Tài khoản",
};

export interface Faq {
  id: number;
  position: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  title: string;
  content: string;
  type: FAQType;
}
