import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Space, Spin, Table, TagType } from "antd";
import { Pagination } from "components/Pagination";
import useTag from "hooks/useTag";
import { useEffect, useRef } from "react";
import { Tag, tagTypeObject } from "types/tag";
import { getTitle } from "utils";
import { TagModal } from "./components/TagModal";

const { Column } = Table;

export const TagPage = ({ title = "" }) => {
  const { fetchData, query, data, loading, filterData, total, deleteData } =
    useTag({
      initialQuery: {
        page: 1,
        limit: 20,
      },
    });
  const modalRef = useRef<TagModal>(null);

  useEffect(() => {
    document.title = getTitle(title);
    fetchData();
  }, []);

  return (
    <section className="box">
      <div className="filter-container">
        <Space>
          <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              size="middle"
              onChange={(ev) => {
                filterData({ search: ev.currentTarget.value, page: 1 });
              }}
              placeholder="Tìm kiếm"
            />
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => fetchData()}
              type="primary"
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => {
                modalRef.current?.handleCreate();
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              Thêm mới
            </Button>
          </div>
        </Space>
      </div>

      <Spin spinning={loading}>
        <Table pagination={false} rowKey="id" dataSource={data}>
          <Column
            title="Tên"
            dataIndex="name"
            key="name"
            render={(text: string) => (
              <span className="font-medium text-secondary">{text}</span>
            )}
          />

          <Column
            title="Loại"
            dataIndex="type"
            key="type"
            render={(text: TagType) =>
              //@ts-ignore
              tagTypeObject[text]?.label
            }
          />

          <Column
            align="center"
            width={150}
            title="Hành động"
            key="action"
            render={(text, record: Tag) => (
              <Space>
                <Popconfirm
                  title="Bạn có chắc chắn muốn thực hiện thao tác?"
                  onConfirm={async () => {
                    await deleteData(record.id);
                  }}
                >
                  <Button type="primary" danger>
                    Xóa
                  </Button>
                </Popconfirm>
                <Button
                  type="primary"
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                >
                  Cập nhật
                </Button>
              </Space>
            )}
          />
        </Table>

        <Pagination
          currentPage={query.page}
          total={total}
          pageSize={query.limit}
          onChange={({ limit, page }) => {
            filterData({ limit, page });
          }}
        />
      </Spin>

      <TagModal onSubmitOk={fetchData} onClose={() => {}} ref={modalRef} />
    </section>
  );
};
