import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { Tag } from "types/tag";
import { tagApi } from "api/tag.api";
import { message } from "antd";
interface TagQuery extends QueryParam {}
type Props = {
  initialQuery: TagQuery;
};

const useTag = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<Tag, TagQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await tagApi.findAll(query).then(({ data }) => data);
      return {
        data: data.tags,
        total: data.total,
      };
    },
    deleteFunc: async (id) => {
      await tagApi.delete(id);
      message.success("Thao tác thành công!");
    },
    editFunc: async (id, data) => {
      await tagApi.update(id, data);
    },
    createFunc: async (data) => {
      await tagApi.create(data);
    },
  });

  return { ...fetch };
};

export default useTag;
