import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Input, Modal, Space, Table, Tag, message } from "antd";
import Column from "antd/lib/table/Column";
import { employeeApi } from "api/employee.api";
import { Pagination } from "components/Pagination";
import useEmployee from "hooks/useEmployee";
import { useEffect, useState } from "react";
import { Merchant } from "types/merchant";
import { ModalStatus } from "types/modal";
import { Staff } from "types/staff";
import { EmployeeModal } from "../EmployeeModal";
import { ResetPassModal } from "../ResetPassModal";
import { Employee } from "types/employee";

export const StaffTab = ({ merchant }: { merchant?: Merchant }) => {
  const { fetchData, data, total, query, filterData, loading } = useEmployee({
    initialQuery: {
      page: 1,
      limit: 20,
      merchantId: merchant?.id,
    },
  });
  const [ModalVisible, setIsModalVisible] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState({});
  const [status, setStatus] = useState<ModalStatus>("create");
  const [visibleResetPassModal, setVisibleResetPassModal] = useState(false);

  useEffect(() => {
    if (merchant?.id) {
      query.merchantId = merchant.id;
      fetchData();
    }
  }, [merchant?.id]);

  const confirmDelete = (staff: Employee) => {
    Modal.confirm({
      title: "Xác nhận thao tác",
      icon: <ExclamationCircleOutlined />,
      content: `Bạn có muốn xóa ${staff.name} ?`,
      okText: "Xóa",
      okButtonProps: { danger: true },
      cancelText: "Hủy",
      onOk: () => handleDelete(staff.id),
    });
  };

  // const confirm = (staff: Staff, action: string) => {
  //   Modal.confirm({
  //     title: "Xác nhận thao tác",
  //     icon: <ExclamationCircleOutlined />,
  //     content: `Bạn có muốn ${action} ${staff.name} ?`,
  //     okText: "Thực hiện",
  //     cancelText: "Hủy",
  //     onOk: () => handleBlockEmployee(staff.id, action),
  //   });
  // };

  // const handleBlockEmployee = async (id: number, action: string) => {
  //   switch (action) {
  //     case "block":
  //       await employeeApi.updateEmployee(id,{

  //       });
  //       break;

  //     case "unblock":
  //       await employeeApi.unblockEmployee(id);
  //       break;
  //   }
  //   message.success("Thao tác thành công");
  //   fetchData();
  // };

  const handleDelete = async (id: number) => {
    await employeeApi.delete(id);
    message.success("Thao tác thành công");
    fetchData();
  };

  return (
    <>
      {" "}
      <div className="filter-container">
        <Space wrap>
          <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              onChange={(ev) => {
                let text = ev.target.value;
                filterData({ search: text });
              }}
              size="middle"
              placeholder="Tìm kiếm nhân viên"
            />
          </div>

          <div className="filter-item btn mt-1">
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => {
                fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </div>

          <div className="filter-item btn mt-1">
            <Button
              onClick={() => {
                setIsModalVisible(true);
                setStatus("create");
                setSelectedStaff({});
                setStatus("create");
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              Thêm nhân viên
            </Button>
          </div>
        </Space>
      </div>
      <Table
        loading={loading}
        dataSource={data}
        pagination={false}
        scroll={{ x: 720 }}
      >
        <Column
          width={200}
          title="Ảnh đại diện"
          key="avatar"
          render={(text, record: Employee) => (
            <>
              <Avatar src={record.avatar} size={50} icon={<UserOutlined />} />
              <p
                className="my-2 font-medium hover:underline text-primary cursor-pointer"
                onClick={() => {
                  setSelectedStaff(record);
                  setIsModalVisible(true);
                  setStatus("update");
                }}
              >
                {record.name}
              </p>
              {record.isOwner ? (
                <Tag color="blue-inverse">Chủ shop</Tag>
              ) : undefined}
            </>
          )}
        />
        <Column
          width={100}
          title="Trạng thái"
          key="status"
          render={(text, record: Employee) => (
            <>
              {record.isBlocked ? (
                <Tag color="red">Đã khóa</Tag>
              ) : (
                <Tag color="green">Hoạt động</Tag>
              )}
            </>
          )}
        />
        <Column title="Username" dataIndex="username" key="username" />
        <Column title="Điện thoại" dataIndex="phone" key="phone" />
        <Column title="Email" dataIndex="email" key="email" />

        <Column
          width={300}
          title="Hành động"
          key="action"
          render={(text, record: Employee) => (
            <Space>
              <Button
                type="default"
                onClick={() => {
                  setVisibleResetPassModal(true);
                  setSelectedStaff(record);
                }}
              >
                Khôi phục mật khẩu
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setSelectedStaff(record);
                  setIsModalVisible(true);
                  setStatus("update");
                }}
              >
                Cập nhật
              </Button>
              {/* {record.isBlocked ? (
                <Button
                  type="default"
                  danger
                  onClick={() => {
                    confirm(record, "unblock");
                  }}
                >
                  Bỏ chặn
                </Button>
              ) : (
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    confirm(record, "block");
                  }}
                  icon={<StopOutlined />}
                >
                  Chặn
                </Button>
              )} */}
              {!record?.isOwner && (
                <Button
                  icon={<DeleteOutlined />}
                  type="primary"
                  danger
                  onClick={() => {
                    confirmDelete(record);
                  }}
                >
                  Xóa
                </Button>
              )}
            </Space>
          )}
        />
      </Table>
      <Pagination
        total={total}
        defaultPageSize={query.limit}
        currentPage={query.page}
        onChange={({ limit, page }) => {
          filterData({ limit, page });
        }}
      />
      <EmployeeModal
        onSubmitOk={fetchData}
        onClose={() => setIsModalVisible(false)}
        visible={ModalVisible}
        staff={selectedStaff}
        status={status}
        merchant={merchant}
      />
      <ResetPassModal
        visible={visibleResetPassModal}
        staff={selectedStaff}
        closeModal={() => setVisibleResetPassModal(false)}
      />
    </>
  );
};
