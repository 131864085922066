import { DatePicker, Space, Spin, Table } from "antd";
import Column from "antd/es/table/Column";
import dayjs, { Dayjs, OpUnitType } from "dayjs";
import { DashboardQuery, useDashboard } from "hooks/useDashboard";
import { useEffect } from "react";
import { SummarySale } from "types/dashboard";
import { Merchant } from "types/merchant";
import { formatVND } from "utils";
import { unixToDate } from "utils/dateFormat";
import StatsSalePanelGroup from "./StatsSalePanelGroup";
import SummarySaleExportExcel from "views/Dashboard/components/SaleExportExcel";

const currDay = dayjs();
const initialQuery = {
  fromAt: currDay.startOf("day").startOf("month"),
  toAt: currDay.endOf("day").endOf("month"),
};

const getDateQuery = (unit: OpUnitType, date?: Dayjs) => {
  if (!date) {
    return {
      fromAt: undefined,
      toAt: undefined,
    };
  }
  return {
    fromAt: date.startOf("day").startOf(unit).unix(),
    toAt: date.endOf("day").endOf(unit).unix(),
  };
};

interface IProps {
  merchant?: Merchant;
  querySummarySale?: DashboardQuery;
}

export const SummarySaleTab = ({ merchant, querySummarySale }: IProps) => {
  useEffect(() => {}, []);

  const {
    fetchSummarySale,
    summarySale,
    query,
    loading,
    fetchSummary,
    summaryData,
    fetchSummaryRecordSale,
    summaryRecordSale,
    loadingRecordSale,
  } = useDashboard({
    initQuery: querySummarySale || {
      fromAt: initialQuery.fromAt.unix(),
      toAt: initialQuery.toAt.unix(),
    },
  });

  useEffect(() => {
    query.merchantId = merchant?.id;
    Object.assign(query, {
      ...querySummarySale,
    });
    fetchSummarySale();
    fetchSummary();
    fetchSummaryRecordSale();
    return () => {};
  }, [merchant, querySummarySale]);

  return (
    <Spin spinning={loading}>
      <div className="m-auto">
        <div className="filter-container">
          <Space wrap>
            <div className="filter-item">
              <DatePicker.MonthPicker
                defaultValue={initialQuery.fromAt}
                size="small"
                onChange={(data) => {
                  const newQuery = getDateQuery("month", data as Dayjs);
                  Object.assign(query, {
                    ...newQuery,
                  });
                  fetchSummarySale();
                  fetchSummaryRecordSale();
                }}
                className="max-h-[36px]"
                format={"MM-YYYY"}
                allowClear={false}
                value={dayjs.unix(query.fromAt!)}
              />
            </div>
            <div className="filter-item ">
              <SummarySaleExportExcel
                queryDashboard={query}
                summarySales={summarySale}
                commission={summaryRecordSale}
              />
            </div>
          </Space>
        </div>
        {summaryData && (
          <StatsSalePanelGroup
            {...summaryData}
            commission={summaryRecordSale}
          />
        )}
        <Table
          dataSource={summarySale}
          size="small"
          scroll={{ x: "max-content", y: "500px" }}
          pagination={false}
        >
          <Column
            width={150}
            title="Ngày"
            render={(text, record: SummarySale) => (
              <span className="font-medium">
                {unixToDate(dayjs(record.date).unix())}
              </span>
            )}
          ></Column>
          <Column
            align="right"
            width={100}
            title="Doanh thu dịch vụ"
            render={(text, record: SummarySale) => (
              <div className="flex flex-col gap-1">
                <span className="text-secondary font-medium cursor-pointer">
                  {formatVND(record.appointmentTotalSale)}
                </span>
              </div>
            )}
          ></Column>

          <Column
            align="right"
            width={100}
            title="Doanh thu gói dịch vụ"
            render={(text, record: SummarySale) => (
              <div className="flex flex-col gap-1">
                <span className="text-secondary font-medium cursor-pointer">
                  {formatVND(record.servicePackTotalSale)}
                </span>
              </div>
            )}
          ></Column>

          <Column
            align="right"
            width={100}
            title="Doanh thu voucher"
            render={(text, record: SummarySale) => (
              <div>
                <span className="text-secondary font-medium cursor-pointer">
                  {formatVND(record.voucherTotalSale)}
                </span>
              </div>
            )}
          ></Column>
          {/* <Column
            align="right"
            width={100}
            title="Tổng doanh thu"
            render={(text, {appointmentTotalSale, servicePackTotalSale, voucherTotalSale}: SummarySale) => (
              <div>
                <span className="text-secondary font-medium cursor-pointer">
                  {formatVND(voucherTotalSale + appointmentTotalSale + servicePackTotalSale)}
                </span>
              </div>
            )}
          ></Column> */}
        </Table>
      </div>
    </Spin>
  );
};
