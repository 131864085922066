import { Form, message } from "antd";
import { contentDefineApi } from "api/contentDefine.api";
import { useFetchTableData } from "hooks/useFetchTableData";
import { ContentDefine } from "types/contentDefine";
import { QueryParam } from "types/query";

interface Props {
  initQuery?: QueryParam;
}

export function useHandlerContentDefine({
  initQuery = {} as QueryParam,
}: Props) {
  const [form] = Form.useForm<ContentDefine>();
  const fetch = useFetchTableData<ContentDefine>({
    initQuery,
    queryFunc: async (query) => {
      const res = await contentDefineApi.findAll(query);
      return { data: res.data.contentDefines, total: res.data.total };
    },
    createFunc: async () => {
      await form!.validateFields();
      const data = {
        contentDefine: form!.getFieldsValue(),
      };
      await contentDefineApi.create(data);
      message.success("Tạo mới thành công!");
    },
    editFunc: async (id) => {
      await form!.validateFields();
      const data = {
        contentDefine: form!.getFieldsValue(),
      };
      await contentDefineApi.update(id, data);
      message.success("Cập nhật thành công!");
    },
  });

  return { ...fetch, form };
}
