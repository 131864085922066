import { request } from "utils/request";
import { AxiosPromise } from "axios";
import settings from "settings";

export const merchantApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/merchant",
      params,
    }),
  summary: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/merchant/summary/status",
      params,
    }),
  detail: (id: number, params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/merchant/" + id,
      params,
    }),
  create: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/merchant",
      data,
      method: "post",
    }),
  update: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/merchant/${id}`,
      method: "patch",
      data,
    }),
  block: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/merchant/${id}/block`,
      method: "patch",
      data,
    }),
  visible: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/merchant/${id}/visible`,
      method: "patch",
      data,
    }),
  approve: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/merchant/${id}/approve`,
      method: "patch",
      data,
    }),
  reject: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/merchant/${id}/reject`,
      method: "delete",
      data,
    }),

  delete: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/merchant/${id}`,
      method: "delete",
    }),
  uploadUrl: settings.apiUrl + "/v1/admin/merchant/upload",
};
