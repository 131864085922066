import { Popover, Row, Col, Typography, Divider, Button } from "antd";
import React, { useEffect, useState } from "react";
import {
  Appointment,
  AppointmentDetail,
  ServiceOptionCustom,
} from "types/appointment";
import { Checkout } from "types/checkout";
import { ServicePackOrder } from "types/servicePackOrder";
import { formattedFullDate } from "utils/formatDate";
import { formatVND, formatMoneyWithMinusSymbol } from "utils/formatVND";

interface IProps {
  appointment: Appointment;
  hasSelectOnlyServicePackOrder: boolean;
}

export const AppointmentHistoryPopover = ({
  hasSelectOnlyServicePackOrder,
  appointment,
}: IProps) => {
  //* Danh sách dịch vụ lẻ, gom chung dịch vụ với nhau từ checkoutDetail
  const [
    appointmentDetailWithSingleServiceOptions,
    setAppointmentDetailWithSingleServiceOptions,
  ] = useState<AppointmentDetail[]>([]);

  //* Danh sách Gói dịch vụ, gom chung gói với nhau từ checkoutDetail
  const [servicePackOrderList, setServicePackOrderList] = useState<
    ServicePackOrder[]
  >([]);

  useEffect(() => {
    if (!appointment) return;
    // handleInit(appointment);
  }, [appointment]);

  // const handleInit = (appointmentInput: Appointment) => {
  //   setAppointmentDetailWithSingleServiceOptions(
  //     appointmentInput?.appointmentDetails?.filter(
  //       (item) => !item.servicePackOrderDetail
  //     )
  //   );

  //   let servicePackOrders: ServicePackOrder[] = [];
  //   appointmentInput?.appointmentDetails?.reduce(
  //     (prevValue: ServicePackOrder[], currValue: AppointmentDetail) => {
  //       if (!currValue?.servicePackOrderDetail) return prevValue;

  //       const hasExistServicePackOrder = prevValue?.find(
  //         (item) =>
  //           item.id === currValue?.servicePackOrderDetail?.servicePackOrder?.id
  //       );
  //       const serviceOptions = JSON.parse(
  //         currValue.serviceOptionJson || "[]"
  //       ) as ServiceOptionCustom[];

  //       if (!hasExistServicePackOrder) {
  //         servicePackOrders.push({
  //           ...currValue.servicePackOrderDetail?.servicePackOrder,
  //           servicePackOrderDetails: [
  //             {
  //               ...currValue.servicePackOrderDetail,
  //               serviceOption: currValue.serviceOption,
  //               serviceOptions,
  //             },
  //           ],
  //           servicePack:
  //             currValue.servicePackOrderDetail.servicePackOrder.servicePack,
  //         });
  //       } else {
  //         hasExistServicePackOrder.servicePackOrderDetails.push({
  //           ...currValue.servicePackOrderDetail,
  //           serviceOption: currValue.serviceOption,
  //           serviceOptions,
  //         });
  //       }

  //       return prevValue;
  //     },
  //     servicePackOrders
  //   );
  //   setServicePackOrderList(servicePackOrders);
  // };

  console.log({ servicePackOrderList });
  console.log({ appointmentDetailWithSingleServiceOptions });

  return (
    <div className="flex justify-end items-center mb-2">
      <Popover
        title="Chi tiết lịch khách đã đặt"
        placement="bottomRight"
        content={
          <Row gutter={12} style={{ width: 300, fontWeight: 600 }}>
            {/* //* Danh sách gói */}
            {servicePackOrderList.length !== 0 && (
              <>
                <Col span={24}>
                  <Typography.Title
                    level={5}
                    style={{ fontSize: 13 }}
                    className="underline"
                  >
                    Gói dịch vụ:
                  </Typography.Title>
                </Col>
                {servicePackOrderList?.map((item) => {
                  return (
                    <>
                      <Col
                        span={15}
                        style={{
                          fontWeight: 550,
                        }}
                      >
                        {item.servicePack.name}
                      </Col>
                      <Col
                        span={9}
                        style={{
                          fontWeight: 500,
                          textAlign: "end",
                        }}
                      >
                        Thanh toán gói
                      </Col>
                      {item?.servicePackOrderDetails.length !== 0 &&
                        item?.servicePackOrderDetails?.map((detail) => (
                          <>
                            <Col span={24} className="italic font-medium">
                              + {detail?.service?.name}
                            </Col>
                          </>
                        ))}
                    </>
                  );
                })}
                <Col span={24}>
                  <Divider style={{ margin: "5px 0" }} />
                </Col>
              </>
            )}

            {/* //*Dịch vụ lẻ */}
            {appointmentDetailWithSingleServiceOptions.length !== 0 && (
              <>
                <Col span={24}>
                  <Typography.Title
                    level={5}
                    style={{ fontSize: 13 }}
                    className="underline"
                  >
                    Dịch vụ đơn lẻ:
                  </Typography.Title>
                </Col>
                {appointmentDetailWithSingleServiceOptions?.map((detail) => {
                  const serviceOptions = (
                    JSON.parse(
                      detail.serviceOptionJson || "[]"
                    ) as ServiceOptionCustom[]
                  )?.filter((item) => item?.isChecked);
                  return (
                    <>
                      <Col
                        span={15}
                        style={{
                          fontWeight: 550,
                        }}
                      >
                        {detail.service.name}
                      </Col>
                      <Col
                        span={9}
                        style={{
                          fontWeight: 500,
                          textAlign: "end",
                        }}
                      >
                        {formatVND(detail.service.price)}
                        <span className="text-xs text-gray-500">
                          {" "}
                          x{detail.quantity || 1}
                        </span>
                      </Col>
                      {serviceOptions.length !== 0 &&
                        serviceOptions.map((option) => (
                          <>
                            <Col span={15} className="italic font-medium">
                              + {option.pricingName} (Dịch vụ kèm)
                            </Col>
                            <Col
                              span={9}
                              className="italic text-end font-medium"
                            >
                              {formatVND(option.price)}
                              <span className="text-xs text-gray-500">
                                {" "}
                                x{option.quantity || 1}
                              </span>
                            </Col>
                          </>
                        ))}
                    </>
                  );
                })}

                {!hasSelectOnlyServicePackOrder && (
                  <>
                    <Col span={24}>
                      <Divider style={{ margin: "5px 0" }} />
                    </Col>

                    <Col span={17}>Tổng tiền</Col>
                    <Col
                      span={7}
                      style={{
                        textAlign: "end",
                        fontWeight: 500,
                      }}
                    >
                      {formatVND(appointment.moneyTotal)}
                    </Col>

                    {appointment?.moneyVoucher !== 0 && (
                      <>
                        <Col span={17}>Voucher</Col>
                        <Col
                          span={7}
                          style={{
                            textAlign: "end",
                            fontWeight: 500,
                            color: "red",
                          }}
                        >
                          {formatMoneyWithMinusSymbol(
                            appointment?.moneyVoucher
                          )}
                        </Col>
                      </>
                    )}
                    {appointment?.moneyCoupon !== 0 && (
                      <>
                        <Col span={17}>Coupon</Col>
                        <Col
                          span={7}
                          style={{
                            textAlign: "end",
                            fontWeight: 500,
                            color: "red",
                          }}
                        >
                          {formatMoneyWithMinusSymbol(appointment?.moneyCoupon)}
                        </Col>
                      </>
                    )}
                    <Col span={17}>Đã thanh toán</Col>
                    <Col
                      span={7}
                      style={{
                        textAlign: "end",
                        fontWeight: 500,
                        color: "red",
                      }}
                    >
                      {formatMoneyWithMinusSymbol(appointment?.customerPaid)}
                    </Col>

                    <Col span={17}>Còn lại</Col>
                    <Col
                      span={7}
                      style={{
                        textAlign: "end",
                        fontWeight: 600,
                      }}
                    >
                      {formatVND(appointment.moneyFinal)}
                    </Col>
                  </>
                )}

                <Col span={24}>
                  <Divider style={{ margin: "5px 0" }} />
                </Col>
              </>
            )}

            <Col span={24} style={{ fontWeight: 500 }}>
              Đã đặt lúc: {formattedFullDate(appointment.createdAt)}
            </Col>
          </Row>
        }
      >
        {/* <Button
          style={{
            padding: "5px 0",
            fontWeight: 600,
            color: "#abca74",
          }}
          type="text"
        >
          Xem lịch đã đặt
        </Button> */}
      </Popover>
    </div>
  );
};
