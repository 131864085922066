import settings from "settings";

export const GenerateObjectUrl = ({
  link,
  subLink = "merchant",
}: {
  link: string;
  subLink?: string;
}) => {
  return (
    <a
      target="_blank"
      className="text-primary hover:underline "
      href={`${settings.clientUrl}/${subLink}/${link || ""}`}
    >
      {`${settings.clientUrl}/${subLink}/${link || ""}`}
    </a>
  );
};
