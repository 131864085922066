import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Space, Spin, Table } from "antd";
import { Pagination } from "components/Pagination";
import useCategory from "hooks/useCategory";
import { useEffect, useRef } from "react";
import { Category } from "types/category";
import { getTitle } from "utils";
import { CategoryModal } from "./components/CategoryModal";
import settings from "settings";
import { FaCircle } from "react-icons/fa";

const { Column } = Table;

export const CategoryPage = ({ title = "", description="" }) => {
  const {
    fetchCategory,
    categories,
    deleteCategory,
    filterCategory,
    isFetchCategoryLoading,
    queryCategory,
    totalCategory,
  } = useCategory({
    initialQuery: {
      page: 1,
      limit: 20,
    },
  });
  const modalRef = useRef<CategoryModal>(null);

  useEffect(() => {
    document.title = getTitle(title);
    fetchCategory();
  }, []);

  return (
    <section className="box">
       <div className="flex flex-col gap-1 mb-5">
        <h1 className="text-xl m-0 ">{title}</h1>
        <span className="font-medium">{description}</span>
      </div>
      <div className="filter-container">
        <Space>
          <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              size="middle"
              onChange={(ev) => {
                filterCategory({ search: ev.currentTarget.value, page: 1 });
              }}
              placeholder="Tìm kiếm"
            />
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => fetchCategory()}
              type="primary"
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </div>

          {settings.isDev && (
            <div className="filter-item btn">
              <Button
                onClick={() => {
                  modalRef.current?.handleCreate();
                }}
                type="primary"
                icon={<PlusOutlined />}
              >
                Thêm mới
              </Button>
            </div>
          )}
        </Space>
      </div>

      <Spin spinning={isFetchCategoryLoading}>
        <Table pagination={false} rowKey="id" dataSource={categories}>
          <Column
            title="Tên ngành dịch vụ"
            dataIndex="name"
            key="name"
            render={(name: string) => (
              <span className="font-medium text-secondary">{name}</span>
            )}
          />
          <Column
            title="Màu icon"
            dataIndex="color"
            key="color"
            render={(text) => {
              return text ? <FaCircle color={text} size={24} /> : <span></span>;
            }}
          />

          <Column
            width={150}
            title="Hành động"
            key="action"
            render={(text, record: Category) => (
              <Space>
                <Popconfirm
                  title="Bạn có chắc chắn muốn thực hiện thao tác?"
                  onConfirm={async () => {
                    await deleteCategory(record.id);
                  }}
                >
                  <Button type="primary" danger>
                    Xóa
                  </Button>
                </Popconfirm>
                <Button
                  type="primary"
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                >
                  Cập nhật
                </Button>
              </Space>
            )}
          />
        </Table>

        <Pagination
          currentPage={queryCategory.page}
          total={totalCategory}
          pageSize={queryCategory.limit}
          onChange={({ limit, page }) => {
            filterCategory({ limit, page });
          }}
        />
      </Spin>

      <CategoryModal
        onSubmitOk={fetchCategory}
        onClose={() => {}}
        ref={modalRef}
      />
    </section>
  );
};
