import { Col, Form, Input, message, Modal, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { faqApi } from "api/faq.api";
import { RichTextEditorV2 } from "components/Editor/components/RichTextEditorV2";
import { InputNumber } from "components/Input/InputNumber";
import { InputNumberVN } from "components/Input/InputNumberVN";
// import { FaqApi } from "api/lea";
import React, { useImperativeHandle, useState } from "react";
import { Faq, FAQType, FAQTypeTrans } from "types/faq";
import { ModalStatus } from "types/modal";
// import { Faq } from "types/address";
import { requiredRule } from "utils/validate";

export interface FaqModal {
  handleCreate: () => void;
  handleUpdate: (Faq: Faq) => void;
}
interface FaqModalProps {
  onClose: () => void;
  onSubmitOk: () => void;
  FaqList: Faq[];
}

interface IForm {
  name?: string;
  type?: FAQType;
  id?: number;
  content: string;
  position?: number;
}

export const FaqModal = React.forwardRef(
  ({ onClose, onSubmitOk, FaqList }: FaqModalProps, ref) => {
    const [form] = Form.useForm<IForm>();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<ModalStatus>("create");
    // const [topicId, setTopicId] = useState<number>()

    useImperativeHandle<any, FaqModal>(
      ref,
      () => ({
        handleCreate() {
          form.resetFields();
          setVisible(true);
          setStatus("create");
        },
        handleUpdate(Faq: Faq) {
          console.log({ Faq });
          form.setFieldsValue({ ...Faq });
          setVisible(true);
          setStatus("update");
          // setTopicId(Faq.id)
        },
      }),
      []
    );

    // const getFormData = () => {
    //   const { id, ...data } = form.getFieldsValue();
    //   return {
    //     id,
    //     Faq: data,
    //   };
    // };

    const onSubmit = async () => {
      console.log(form.getFieldsValue());
      await form.validateFields();
      const { id, ...formData } = form.getFieldsValue();
      setLoading(true);
      try {
        if (status === "create") {
          await faqApi.create({
            faq: formData,
          });
        } else {
          await faqApi.update(id || 0, {
            faq: formData,
          });
        }
        message.success("Thao tác thành công!");
        onClose();
        onSubmitOk();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        onCancel={() => {
          form.resetFields();
          onClose?.();
          setVisible(false);
        }}
        visible={visible}
        title={status == "create" ? "Tạo dữ liệu" : "Chỉnh sửa dữ liệu"}
        style={{ top: 20 }}
        width={900}
        confirmLoading={loading}
        onOk={onSubmit}
      >
        <Form layout="vertical" form={form}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="title" label="Câu hỏi" rules={[requiredRule]}>
                <Input placeholder="Nhập câu hỏi" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="type"
                label="Loại câu hỏi"
                rules={[requiredRule]}
              >
                <Select placeholder="Chọn loại câu hỏi">
                  {Object.values(FAQType)?.map((item) => (
                    <Select.Option key={item} value={item}>
                      {FAQTypeTrans[item]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="position"
                label="Thứ tự câu hỏi"
                rules={[requiredRule]}
              >
                <InputNumberVN placeholder="Nhập thứ tự câu hỏi" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item required label="Nội dung" name="content">
                      <RichTextEditorV2
                        onChange={(content) => {
                          form.setFieldsValue({ content });
                        }}
                        content={form.getFieldValue("content")}
                        maxHeight={300}
                        minHeight={300}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
);
