import { message } from "antd";
import { toJS } from "mobx";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Route } from "router";
import { permissionStore } from "store/permissionStore";
import { getToken } from "utils/auth";

const whileList = ["/login"];

export const useRouter = (isLoaded: boolean) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    const token = getToken();

    if (!token) {
      return navigate("/login");
    }
    if (location.pathname == "/login" && token) {
      return navigate("/");
    }

    if (whileList.includes(location.pathname)) {
      return;
    }

    if (permissionStore.accessRoutes.length) {
      const accessRouters = permissionStore.accessRoutes.flatMap((router) =>
        router.children?.length
          ? router.children.map((child: Route) => ({
              ...child,
              path: router.path + "/" + child.path,
            }))
          : router
      );
      const accessRouter = accessRouters.find((r: Route) => r.isAccess);

      if (location.pathname == "/") {
        if (!accessRouter) {
          message.error("Bạn chưa có quyền truy cập");
          navigate("/login");
          return;
        }
        if (token && accessRouter) {
          navigate(accessRouter.path);
        } else {
          navigate("/login");
        }
      } else {
        const isHasPermission = accessRouters.some((p) => {
          return `${p.path}` == location.pathname && (p.isAccess || p.isPublic);
        });
        if (!isHasPermission) {
          navigate("/404");
        }

        handleScrollToTop();
      }
    }
  }, [location.pathname, isLoaded]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return location;
};
