// import { productTypeList } from "../storePage";

import { ExportOutlined } from "@ant-design/icons";
import { Popconfirm, Button } from "antd";
import { customerApi } from "api/customer.api";
import { CustomerQuery } from "hooks/useCustomer";
import moment from "moment";
import { useState, useMemo, useEffect } from "react";
import { Customer, genderTypeObject } from "types/customer";
import { formatVND } from "utils";
import { MyExcelColumn, handleExport } from "utils/MyExcel";

interface PropsType {
  customers: Customer[];
  queryCustomer: CustomerQuery;
}

const CustomerExportExcel = ({ customers, queryCustomer }: PropsType) => {
  const [percentExportComplete, setPercentExportComplete] = useState<number>(0);

  const [visible, setVisible] = useState<boolean>(false);

  const exportColumns: MyExcelColumn<Customer>[] = useMemo(() => {
    return [
      {
        width: 30,
        header: "Tên khách hàng",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "fullName",
        render: (record: Customer) => {
          return record?.fullName;
        },
      },
      {
        width: 30,
        header: "Ngày tham gia/đăng ký",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "createdAt",
        render: (record: Customer) => {
          return record?.createdAt
            ? moment.unix(record.createdAt).format("HH:mm DD/MM/YYYY")
            : "--";
        },
      },
      {
        width: 15,
        header: "Số điện thoại",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "phone",
        render: (record: Customer) => {
          return record?.phone;
        },
      },
      {
        width: 30,
        header: "Email",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "email",
        render: (record: Customer) => {
          return record?.email;
        },
      },
      {
        width: 30,
        header: "Điểm",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "point",
        render: (record: Customer) => {
          return formatVND(record?.point);
        },
      },
    ];
  }, []);

  useEffect(() => {
    if (percentExportComplete >= 100) {
      setTimeout(() => {
        setVisible(false);
      }, 500);
    }
  }, [percentExportComplete]);
  const handleExportExcel = async () => {
    setVisible(true);
    setPercentExportComplete(0);
    try {
      await handleExport({
        onProgress(percent) {
          setPercentExportComplete(percent);
        },
        exportColumns: exportColumns,
        fileType: "xlsx",
        dataField: "customers",
        query: queryCustomer,
        api: customerApi.findAll,
        fileName: "Danh sách khách hàng",
        sheetName: "Danh sách khách hàng",
        isGetFullData: false,
      });
    } finally {
      setPercentExportComplete(0);
      setVisible(false);
    }
  };

  return (
    <div>
      <Popconfirm
        title={`Bạn có muốn xuất file excel`}
        onConfirm={handleExportExcel}
        okText={"Xuất excel"}
        cancelText={"Huỷ"}
      >
        <Button
          type="primary"
          loading={false}
          icon={<ExportOutlined />}
          size="middle"
          disabled={!customers.length}
        >
          Xuất file excel
        </Button>
      </Popconfirm>
    </div>
  );
};

export default CustomerExportExcel;
