import { Col, Form, Input, message, Modal, Row } from "antd";
import { categoryApi } from "api/category.api";
import React, { useImperativeHandle, useState } from "react";
import { CirclePicker, SketchPicker } from "react-color";
import { Category } from "types/category";
import { ModalStatus } from "types/modal";
import { requiredRule } from "utils/validate";

export interface CategoryModal {
  handleCreate: () => void;
  handleUpdate: (Category: Category) => void;
}
interface CategoryModalProps {
  onClose: () => void;
  onSubmitOk: () => void;
}

export const CategoryModal = React.forwardRef(
  ({ onClose, onSubmitOk }: CategoryModalProps, ref) => {
    const [form] = Form.useForm<Category>();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<ModalStatus>("create");
    const [color, setColor] = useState<any>(undefined);

    useImperativeHandle<any, CategoryModal>(
      ref,
      () => ({
        handleCreate() {
          form.resetFields();
          setVisible(true);
          setStatus("create");
          setColor("");
        },
        handleUpdate(Category: Category) {
          form.setFieldsValue({ ...Category });
          setColor(Category.color || "");
          setVisible(true);
          setStatus("update");
        },
      }),
      []
    );

    const onSubmit = async () => {
      await form.validateFields();
      const formData = form.getFieldsValue();
      setLoading(true);
      try {
        const payload = {
          category: {
            name: formData.name,
            color,
          },
        };
        if (status === "update") {
          await categoryApi.update(formData.id || 0, payload);
        } else {
          await categoryApi.create(payload);
        }
        message.success("Thao tác thành công!");
        onClose();
        onSubmitOk();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        onCancel={() => {
          form.resetFields();
          onClose?.();
          setVisible(false);
        }}
        visible={visible}
        title={status == "create" ? "Tạo dữ liệu" : "Chỉnh sửa dữ liệu"}
        style={{ top: 20 }}
        width={700}
        confirmLoading={loading}
        onOk={onSubmit}
      >
        <Form layout="vertical" form={form}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Tên ngành dịch vụ"
                name="name"
                rules={[requiredRule]}
              >
                <Input placeholder="Nhập tên ngành dịch vụ" />
              </Form.Item>
              <Form.Item label="Chọn màu icon">
                <SketchPicker
                  width="50%"
                  // className="w-96"
                  color={color}
                  // styles={{
                  //   default: { picker: { width: 300, height: 300 } },
                  // }}
                  onChangeComplete={(color) => setColor(color.hex)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
);
