import { ExportOutlined } from "@ant-design/icons";
import { Popconfirm, Button } from "antd";
import { withdrawApi } from "api/withdraw.api";
import { WithdrawQuery } from "hooks/useWithdraw";
import { useState, useMemo, useEffect } from "react";
import { Withdraw, WithdrawStatusTrans } from "types/withdraw";
import { MyExcelColumn, handleExport } from "utils/MyExcel";
import { unixToFullTime } from "utils/formatDate";
import { formatVND } from "utils/formatVND";

interface PropsType {
  summaries: Withdraw[];
  queryWithdraw: WithdrawQuery;
}

const WithdrawExportExcel = ({ summaries, queryWithdraw }: PropsType) => {
  const [percentExportComplete, setPercentExportComplete] = useState<number>(0);

  const [visible, setVisible] = useState<boolean>(false);

  const exportColumns: MyExcelColumn<Withdraw>[] = useMemo(() => {
    return [
      {
        width: 30,
        header: "Mã",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "code",
        render: (record: Withdraw) => {
          return `#${record?.code}`;
        },
      },
      {
        width: 30,
        header: "Tên cửa hàng",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "store",
        render: (record: Withdraw) => {
          return record?.merchant?.name;
        },
      },
      {
        width: 30,
        header: "Địa chỉ",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "address",
        render: (record: Withdraw) => {
          return record?.merchant?.address;
        },
      },
      {
        width: 30,
        header: "Email",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "email",
        render: (record: Withdraw) => {
          return record?.merchant?.email;
        },
      },
      {
        width: 30,
        header: "Số điện thoại",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "phone",
        render: (record: Withdraw) => {
          return record?.merchant?.phone;
        },
      },
      {
        width: 30,
        header: "Số điểm rút",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "month",
        render: (record: Withdraw) => {
          return formatVND(record?.amount);
        },
      },
      // {
      //   width: 30,
      //   header: "Cửa hàng",
      //   headingStyle: {
      //     font: {
      //       bold: true,
      //     },
      //   },
      //   style: {
      //     alignment: {
      //       vertical: "middle",
      //     },
      //   },

      //   key: "name",
      //   render: (record: Withdraw) => {
      //     return record?.merchant?.name;
      //   },
      // },
      // {
      //   width: 15,
      //   header: "Liên hệ",
      //   headingStyle: {
      //     font: {
      //       bold: true,
      //     },
      //   },
      //   style: {
      //     alignment: {
      //       vertical: "middle",
      //     },
      //   },
      //   key: "phone",
      //   render: (record: Withdraw) => {
      //     return `${record?.merchant?.email} - ${record?.merchant?.phone}`;
      //   },
      // },
      {
        width: 30,
        header: "Trạng thái",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "totalAppointment",
        render: (record: Withdraw) => {
          return WithdrawStatusTrans[record?.status].label;
        },
      },
      {
        width: 40,
        header: "Ngày thực hiện",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            wrapText: true,
            vertical: "middle",
          },
        },
        key: "createdAt",
        render: (record: Withdraw) => {
          return unixToFullTime(record?.createdAt);
        },
      },
      // {
      //   width: 70,
      //   header: "Thời gian giao dịch",
      //   headingStyle: {
      //     font: {
      //       bold: true,
      //     },
      //   },
      //   style: {
      //     alignment: {
      //       wrapText: true,
      //       vertical: "middle",
      //     },
      //   },
      //   key: "moneyVoucher",
      //   render: (record: Withdraw) => {
      //     return unixToFullTime(record?.completedAt);
      //   },
      // },
    ];
  }, []);

  useEffect(() => {
    if (percentExportComplete >= 100) {
      setTimeout(() => {
        setVisible(false);
      }, 500);
    }
  }, [percentExportComplete]);
  const handleExportExcel = async () => {
    setVisible(true);
    setPercentExportComplete(0);
    try {
      await handleExport({
        onProgress(percent) {
          setPercentExportComplete(percent);
        },
        exportColumns: exportColumns,
        fileType: "xlsx",
        dataField: "withdraws",
        query: queryWithdraw,
        api: withdrawApi.findAll,
        fileName: "Danh sách lịch sử rút điểm",
        sheetName: "Danh sách lịch sử rút điểm",
        isGetFullData: true,
      });
    } finally {
      setPercentExportComplete(0);
      setVisible(false);
    }
  };

  return (
    <div>
      <Popconfirm
        title={`Bạn có muốn xuất file excel`}
        onConfirm={handleExportExcel}
        okText={"Xuất excel"}
        cancelText={"Huỷ"}
      >
        <Button
          type="primary"
          loading={false}
          icon={<ExportOutlined />}
          size="middle"
          disabled={!summaries.length}
        >
          Xuất file excel
        </Button>
      </Popconfirm>
    </div>
  );
};

export default WithdrawExportExcel;
