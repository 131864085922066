import { Appointment } from "./appointment";
import { Merchant } from "./merchant";
import { Withdraw } from "./withdraw";

export enum MerchantTransactionType {
  Booking = "BOOKING", //tiền booking
  UseCoupon = "USE_COUPON", //sử dụng giảm giá = coupon
  RefundBooking = "REFUND_BOOKING", //hoàn tiền booking (cancel lịch)
  Voucher = "VOUCHER", //tiền mua voucher từ khách
  ServicePack = "SERVICE_PACK", //tiền mua gói dịch vụ
  Withdraw = "WITHDRAW",
  CancelWithdraw = "CANCEL_WITHDRAW",
  RecordSale = "RECORD_SALE",
}

export interface MerchantTransaction {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  code: string;
  desc: string;
  beforeChange: number;
  afterChange: number;
  amount: number;
  type: MerchantTransactionType;
  merchant: Merchant;
  appointment: Appointment;
  //   servicePackOrder: ServicePackOrder;
  //   voucherTemplate: VoucherTemplate;
  //   customerCoupon: CustomerCoupon;
  //   paymentOrderDetail: PaymentOrderDetail;
  withdraw: Withdraw;
}

export const MerchantTransactionTypeTrans = {
  [MerchantTransactionType.UseCoupon]: {
    title: "Sử dụng Coupon",
    value: MerchantTransactionType.UseCoupon,
    color: "#FFC107",
  },
  [MerchantTransactionType.Booking]: {
    title: "Tiền Booking",
    value: MerchantTransactionType.Booking,
    color: "#4CAF50",
  },
  [MerchantTransactionType.Voucher]: {
    title: "Khách mua Voucher",
    value: MerchantTransactionType.Voucher,
    color: "#2196F3",
  },
  [MerchantTransactionType.ServicePack]: {
    title: "Khách mua Gói dịch vụ",
    value: MerchantTransactionType.Voucher,
    color: "#4ad9d6",
  },
  [MerchantTransactionType.RefundBooking]: {
    title: "Hoàn tiền Booking",
    value: MerchantTransactionType.RefundBooking,
    color: "red",
  },
  [MerchantTransactionType.Withdraw]: {
    title: "Rút điểm",
    value: MerchantTransactionType.Withdraw,
    color: "#e3a232",
  },
  [MerchantTransactionType.CancelWithdraw]: {
    title: "Rút điểm",
    value: MerchantTransactionType.CancelWithdraw,
    color: "#ed670e",
  },
  [MerchantTransactionType.RecordSale]: {
    title: "Thanh toán tiền hoa hồng",
    value: MerchantTransactionType.RecordSale,
    color: "#ed670e",
  },
};
