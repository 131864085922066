import { authApi } from "api/auth.api";
import { setToken } from "utils/auth";
import { action, makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { Staff } from "types/staff";
import { oneSignalApi } from "api/oneSignal.api";

class UserStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "UserStore",
      properties: ["info", "token"],
      storage: window.localStorage,
    });
  }

  info: Partial<Staff> = {};
  token = "";
  isLoggedIn: boolean = false;

  @action
  async login(username: string, password: string) {
    const res = await authApi.login({ username, password });
    setToken(res.data.token);
  }

  @action
  async getProfile() {
    this.isLoggedIn = true;
    const res = await authApi.profile();
    this.info = res.data;
  }

  @action
  logout = async () => {
    const oneSignalId = localStorage.getItem("oneSignalId");
    if (oneSignalId && this.token) {
      await oneSignalApi.unSub({ oneSignalId: oneSignalId });
    }
    this.isLoggedIn = false;
    setToken("");
    this.token = "";
  };
}

const userStore = new UserStore();

export { userStore };
