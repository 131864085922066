import { customerApi } from "api/customer.api";
import { Customer } from "types/customer";
import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
export interface CustomerQuery extends QueryParam {}
type Props = {
  initialQuery: CustomerQuery;
};

const useCustomer = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<Customer, CustomerQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await customerApi.findAll(query).then(({ data }) => data);
      return {
        data: data.customers,
        total: data.total,
      };
    },
    deleteFunc: async (id) => {
      await customerApi.delete(id);
    },
    editFunc: async (id, data) => {
      await customerApi.update(id, data);
    },
    createFunc: async (data) => {},
  });

  return { ...fetch };
};

export default useCustomer;
