import { ExportOutlined } from "@ant-design/icons";
import { Popconfirm, Button } from "antd";
import { recordSaleApi } from "api/recordSale.api";
import { servicePackOrderApi } from "api/servicePackOrder.api";
import dayjs from "dayjs";
import { ServicePackOrderQuery } from "hooks/useServicePackOrder";
import moment from "moment";
import { useState, useMemo, useEffect } from "react";
import { EPaymentTypeTrans } from "types/paymentType";
import {
  ServicePackOrder,
  ServicePackOrderStatusTrans,
} from "types/servicePackOrder";
import { MyExcelColumn, handleExport } from "utils/MyExcel";
import { unixToFullTime } from "utils/formatDate";
import { formatVND } from "utils/formatVND";

interface PropsType {
  summaries: ServicePackOrder[];
  queryServicePackOrder: ServicePackOrderQuery;
}

const ServicePackOrderExportExcel = ({
  summaries,
  queryServicePackOrder,
}: PropsType) => {
  const [percentExportComplete, setPercentExportComplete] = useState<number>(0);

  const [visible, setVisible] = useState<boolean>(false);

  const exportColumns: MyExcelColumn<ServicePackOrder>[] = useMemo(() => {
    return [
      {
        width: 30,
        header: "Mã",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "code",
        render: (record: ServicePackOrder) => {
          return record?.code;
        },
      },
      {
        width: 30,
        header: "Tên khách hàng",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "month",
        render: (record: ServicePackOrder) => {
          return record?.customerProfile?.fullName;
        },
      },
      // {
      //   width: 30,
      //   header: "Cửa hàng",
      //   headingStyle: {
      //     font: {
      //       bold: true,
      //     },
      //   },
      //   style: {
      //     alignment: {
      //       vertical: "middle",
      //     },
      //   },

      //   key: "name",
      //   render: (record: ServicePackOrder) => {
      //     return record?.merchant?.name;
      //   },
      // },
      // {
      //   width: 15,
      //   header: "Liên hệ",
      //   headingStyle: {
      //     font: {
      //       bold: true,
      //     },
      //   },
      //   style: {
      //     alignment: {
      //       vertical: "middle",
      //     },
      //   },
      //   key: "phone",
      //   render: (record: ServicePackOrder) => {
      //     return `${record?.merchant?.email} - ${record?.merchant?.phone}`;
      //   },
      // },
      {
        width: 30,
        header: "Số điện thoại",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "totalAppointment",
        render: (record: ServicePackOrder) => {
          return record?.customerProfile?.contactPhone;
        },
      },
      {
        width: 20,
        header: "Tên gói dịch vụ",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "totalInvalidConfirmAppointment",
        render: (record: ServicePackOrder) => {
          return record?.servicePack?.name;
        },
      },
      {
        width: 20,
        header: "Tổng giá (VNĐ)",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "totalGoodReview",
        render: (record: ServicePackOrder) => {
          return formatVND(record?.moneyFinal);
        },
      },
      {
        width: 20,
        header: "Hình thức thanh toán",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "totalBadReview",
        render: (record: ServicePackOrder) => {
          return record?.paymentType
            ? EPaymentTypeTrans[record?.paymentType?.type]
            : "--";
        },
      },
      {
        width: 20,
        header: "Trạng thái",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "moneyAppointment",
        render: (record: ServicePackOrder) => {
          return ServicePackOrderStatusTrans[record.status].label;
        },
      },
      {
        width: 70,
        header: "Ngày tạo",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            wrapText: true,
            vertical: "middle",
          },
        },
        key: "moneyVoucher",
        render: (record: ServicePackOrder) => {
          return unixToFullTime(record?.createdAt);
        },
      },
    ];
  }, []);

  useEffect(() => {
    if (percentExportComplete >= 100) {
      setTimeout(() => {
        setVisible(false);
      }, 500);
    }
  }, [percentExportComplete]);
  const handleExportExcel = async () => {
    setVisible(true);
    setPercentExportComplete(0);
    try {
      await handleExport({
        onProgress(percent) {
          setPercentExportComplete(percent);
        },
        exportColumns: exportColumns,
        fileType: "xlsx",
        dataField: "servicePackOrders",
        query: queryServicePackOrder,
        api: servicePackOrderApi.findAll,
        fileName: "Danh sách lịch sử mua gói",
        sheetName: "Danh sách lịch sử mua gói",
        isGetFullData: false,
      });
    } finally {
      setPercentExportComplete(0);
      setVisible(false);
    }
  };

  return (
    <div>
      <Popconfirm
        title={`Bạn có muốn xuất file excel`}
        onConfirm={handleExportExcel}
        okText={"Xuất excel"}
        cancelText={"Huỷ"}
      >
        <Button
          type="primary"
          loading={false}
          icon={<ExportOutlined />}
          size="middle"
          disabled={!summaries.length}
        >
          Xuất file excel
        </Button>
      </Popconfirm>
    </div>
  );
};

export default ServicePackOrderExportExcel;
