import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { paymentSourceApi } from "api/paymentSource.api";
import { message } from "antd";
import { PaymentType } from "types/paymentType";
interface paymentTypeQuery extends QueryParam {}
type Props = {
  initialQuery: paymentTypeQuery;
};

const usePaymentSouce = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<PaymentType, paymentTypeQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await paymentSourceApi.findAll(query).then(({ data }) => data);
      return {
        data: data.paymentSources,
        total: data.total,
      };
    },
    editFunc: async (id, data) => {
      await paymentSourceApi.update(id, data);
    },
  });
  const toggleEnable = async (id: number, isEnabled?: boolean) => {
    await paymentSourceApi.update(id, { paymentType: { isEnabled } });
    message.success("Thao tác thành công");
    fetch.fetchData();
  };
  const toggleDefault = async (id: number, isDefault?: boolean) => {
    await paymentSourceApi.update(id, { paymentType: { isDefault } });
    message.success("Thao tác thành công");
    fetch.fetchData();
  };

  const {
    query: queryPaymentSouce,
    fetchData: fetchPaymentSouce,
    total: totalPaymentSouce,
    loading: isFetchPaymentSouceLoading,
    data: paymentSouces,
  } = fetch;

  return {
    fetchPaymentSouce,
    totalPaymentSouce,
    queryPaymentSouce,
    isFetchPaymentSouceLoading,
    paymentSouces,
    toggleEnable,
    toggleDefault,
  };
};

export default usePaymentSouce;
