import { Col, Form, Input, message, Modal, Radio, Row, Tabs } from "antd";
import { DatePicker } from "antd";
import { customerApi } from "api/customer.api";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import dayjs from "dayjs";
import moment from "moment";
import React, { useImperativeHandle, useState } from "react";
import { Customer, GenderType, genderTypeObject } from "types/customer";
import { ModalStatus } from "types/modal";
import { disableFuture } from "utils/date-picker";
import { getCustomerAddress, getMerchantAddress } from "utils/url";
import { emailRule, requiredRule } from "utils/validate";
import { CheckoutPage } from "views/CheckoutPage/CheckoutPage";
import { CustomerTransactionPage } from "views/CustomerTransaction/CustomerTransaction";
import { ServicePackOrderPage } from "views/ServicePackOrderPage/ServicePackOrderPage";

export interface CustomerModal {
  handleCreate: () => void;
  handleUpdate: (Customer: Customer) => void;
}
interface CustomerModalProps {
  onClose: () => void;
  onSubmitOk: () => void;
}

export const CustomerModal = React.forwardRef(
  ({ onClose, onSubmitOk }: CustomerModalProps, ref) => {
    const [form] = Form.useForm<Customer>();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<ModalStatus>("create");
    const [customerSelected, setCustomerSelected] = useState<Customer>();
    const customerId = Form.useWatch("id", form);

    useImperativeHandle<any, CustomerModal>(
      ref,
      () => ({
        handleCreate() {
          form.resetFields();
          setVisible(true);
          setStatus("create");
        },
        handleUpdate(Customer: Customer) {
          form.setFieldsValue({
            ...Customer,
            address: getCustomerAddress(Customer),
            dob: Customer?.dob ? dayjs(Customer.dob) : null,
          });
          setCustomerSelected(Customer);
          setVisible(true);
          setStatus("update");
        },
      }),
      []
    );

    const getFormData = () => {
      const { id, address, ...data } = form.getFieldsValue();
      return {
        id,
        customer: data as Customer,
      };
    };

    const onSubmit = async () => {
      await form.validateFields();
      setLoading(true);
      try {
        const { id, ...restData } = getFormData();
        if (id) {
          await customerApi.update(id || 0, {
            customer: {
              ...restData.customer,
              dob: dayjs(restData.customer.dob).format("YYYY-MM-DD"),
            },
          });
        } else {
          await customerApi.create(restData);
        }
        message.success("Thao tác thành công!");
        onClose();
        onSubmitOk();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        onCancel={() => {
          form.resetFields();
          onClose?.();
          setVisible(false);
        }}
        visible={visible}
        title={
          <h1 className="text-secondary text-lg font-bold">
            {status === "create"
              ? "Thêm khách hàng"
              : `Cập nhật khách hàng - ${customerSelected?.fullName || ""}`}
          </h1>
        }
        style={{ top: 20 }}
        width={1000}
        confirmLoading={loading}
        destroyOnClose
        onOk={onSubmit}
      >
        <Tabs>
          <Tabs.TabPane tab="Thông tin" key={"info"} tabKey="info">
            <Form layout="vertical" form={form}>
              <Form.Item hidden name="id">
                <Input />
              </Form.Item>
              <Row gutter={[32, 0]}>
                <Col span={24} className="text-center">
                  <Form.Item noStyle shouldUpdate={true}>
                    {() => {
                      return (
                        <Form.Item label="Ảnh đại diện" name="avatar">
                          <SingleImageUpload
                            uploadUrl={customerApi.uploadUrl}
                            onUploadOk={(path: string) => {
                              form.setFieldsValue({
                                avatar: path,
                              });
                            }}
                            imageUrl={form.getFieldValue("avatar")}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={12} className="text-center">
                  <Form.Item
                    label="Tên khách hàng"
                    name="fullName"
                    rules={[requiredRule]}
                  >
                    <Input placeholder="Nhập tên" />
                  </Form.Item>
                  <Form.Item
                    label="Điện thoại"
                    name="phone"
                    rules={[requiredRule]}
                  >
                    <Input disabled placeholder="Nhập số điện thoại" />
                  </Form.Item>
                  <Form.Item label="Email" name="email" rules={[emailRule]}>
                    <Input placeholder="Nhập email" />
                  </Form.Item>
                </Col>

                <Col span={12} className="">
                  <Form.Item
                    initialValue={GenderType.Male}
                    label="Giới tính"
                    name="gender"
                  >
                    <Radio.Group
                      className="flex items-center"
                      options={Object.values(genderTypeObject)}
                    ></Radio.Group>
                  </Form.Item>
                  <Form.Item label="Ngày sinh" name="dob">
                    <DatePicker
                      // size="middle"
                      disabledDate={(current) => disableFuture(current)}
                      allowClear={true}
                      style={{ width: "100%", borderRadius: 8 }}
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                  <Form.Item label="Địa chỉ" name="address">
                    <Input disabled placeholder="Nhập địa chỉ" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Thứ hạng" name="rank">
                    <span>
                      {customerSelected?.rank?.name || "Thành viên mới"}
                    </span>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Tabs.TabPane>
          {status == "update" && (
            <Tabs.TabPane
              tab="Lịch sử giao dịch"
              key={"transaction"}
              tabKey="transaction"
            >
              <CustomerTransactionPage
                initialQuery={{
                  customerId,
                }}
              ></CustomerTransactionPage>
            </Tabs.TabPane>
          )}
          {status == "update" && (
            <Tabs.TabPane
              tab="Lịch sử mua gói"
              key={"servicePackOrder"}
              tabKey="servicePackOrder"
            >
              <ServicePackOrderPage
                customerId={customerId}
              ></ServicePackOrderPage>
            </Tabs.TabPane>
          )}
          {status == "update" && (
            <Tabs.TabPane
              tab="Lịch sử checkout"
              key={"checkout"}
              tabKey="checkout"
            >
              <CheckoutPage customerId={customerId}></CheckoutPage>
            </Tabs.TabPane>
          )}
        </Tabs>
      </Modal>
    );
  }
);
