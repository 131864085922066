import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const recordSaleApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/recordSale",
      params,
    }),
  trigger: (params: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/recordSale/trigger",
      params,
      method: "post",
    }),
  lastUpdate: (params: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/recordSale/lastUpdate",
      params,
      method: "get",
    }),
  paid: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/recordSale/${id}/paid`,
      method: "patch",
      data,
    }),
  debt: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/recordSale/${id}/debt`,
      method: "patch",
      data,
    }),
  delete: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/recordSale/${id}`,
      method: "delete",
    }),
  summary: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/recordSale/summary",
      params,
    }),
};
