import { TimeRangePickerProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import duration from "dayjs/plugin/duration";
import moment from "moment";
import settings from "settings";

export const unixToFullDate = (unix: number) => {
  if (unix) {
    return dayjs.unix(unix).format(settings.fullDateFormat);
  } else {
    return "--";
  }
};

export const unixToDate = (
  unix: number,
  format: string = settings.dateFormat
) => {
  if (!unix) return;
  return dayjs.unix(unix).format(format);
};

export const stringToDate = (
  string: string,
  format: string = settings.dateFormat
) => {
  if (!dayjs(string).isValid()) return;
  return dayjs(string).format(format);
};

export const rangePresets: TimeRangePickerProps["presets"] = [
  { label: "Hôm nay", value: [dayjs(), dayjs()] },
  { label: "7 Ngày gần nhất", value: [dayjs().add(-7, "d"), dayjs()] },
  { label: "2 Tuần trước", value: [dayjs().add(-14, "d"), dayjs()] },
  { label: "30 ngày trước", value: [dayjs().add(-30, "d"), dayjs()] },
  { label: "90 Ngày trước", value: [dayjs().add(-90, "d"), dayjs()] },
];

export const onRangeChange = (dates: null | (Dayjs | null)[]) => {
  if (dates) {
    return [
      dates[0]?.startOf("day")?.format("YYYY-MM-DD"),
      dates[1]?.endOf("day")?.format("YYYY-MM-DD"),
    ];
  } else {
    return [undefined, undefined];
  }
};

export const onRangeChangeUnix = (dates: null | (Dayjs | null)[]) => {
  if (dates) {
    return [dates[0]?.startOf("day")?.unix(), dates[1]?.endOf("day")?.unix()];
  } else {
    return [undefined, undefined];
  }
};

export const getExpiredDate = (expriedDate: number) => {
  let diffDate = dayjs.unix(expriedDate).diff(dayjs(), "days");

  if (diffDate > 1) {
    return diffDate + " ngày";
  }
  diffDate = dayjs.unix(expriedDate).diff(dayjs(), "hour");

  if (diffDate >= 1) {
    return diffDate + " giờ";
  }

  diffDate = dayjs.unix(expriedDate).diff(dayjs(), "minute");

  return diffDate + " phút";
};

export const getTimeAgo = (unixTime: number) => {
  const now = dayjs(); // Get the current date and time
  const pastDate = dayjs.unix(unixTime); // Convert Unix time to Day.js object

  const diff = now.diff(pastDate); // Get the time difference in milliseconds

  if (diff < 86400000) {
    // Less than one day

    const duration = dayjs.duration(diff);
    const hours = duration.hours();
    const minutes = duration.minutes();

    let timeDifference = "";
    if (hours > 0) {
      timeDifference += `${hours} giờ `;
    }
    if (minutes > 0) {
      timeDifference += `${minutes} phút`;
    }

    return `Cách đây ${timeDifference}`;
  } else {
    // More than one day
    const formattedDate = pastDate.format("DD [tháng] MM [năm] YYYY");
    return `Ngày ${formattedDate}`;
  }
};

export const getCustomFieldData = (value: number) => {
  try {
    var unixTimestamp = (+value - 25569) * 86400; //as per the post above, convert Excel date to unix timestamp, assuming Mac/Windows Excel 2011 onwards
    if (unixTimestamp) {
      const timeValue = moment.unix(unixTimestamp);
      return timeValue;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const getDateExcel = (value: number | string) => {
  if (typeof value == "string") {
    return dayjs(value, "DD/MM/YYYY").isValid()
      ? dayjs(value, "DD/MM/YYYY")
      : undefined;
  }

  if (typeof value == "number") {
    return getCustomFieldData(value);
  }

  return undefined;
};
