export interface AppLogoProps {
  width?: number;
  height?: number;
}

export const AppLogo = ({ width, height = 100 }: AppLogoProps) => {
  return (
    <div
      style={{
        borderRadius: 10,
        display: "block",
        height,
        width,
      }}
    >
      <img
        style={{
          height: height,
        }}
        className={["object-cover"].join(" ")}
        src={"/logo.png"}
      />
    </div>
  );
};
