import { checkoutApi } from "api/checkout.api";
import { useRef, useState } from "react";
import { Checkout, CheckoutStatus } from "types/checkout";
import { QueryParam } from "types/query";

export interface CheckoutQuery extends QueryParam {
  fromAt?: number;
  toAt?: number;
  status?: CheckoutStatus;
  customerProfileId?: number;
}

interface CheckoutProps {
  initQuery: CheckoutQuery;
}

export const useFetchCheckout = ({ initQuery }: CheckoutProps) => {
  const [data, setData] = useState<Checkout[]>([]);
  const query = useRef<CheckoutQuery>(initQuery);
  const [loading, setLoading] = useState<boolean>(false);

  const [total, setTotal] = useState(0);

  const fetchCheckout = async (newQuery?: CheckoutQuery) => {
    try {
      setLoading(true);
      const { data } = await checkoutApi.findAll({
        ...query.current,
        ...newQuery,
      });
      setTotal(data.total);
      setData(data.checkouts);
      setLoading(false);
    } catch (error) {}
  };

  return {
    checkouts: data,
    totalCheckouts: total,
    fetchCheckout,
    queryCheckouts: query.current,
    loadingCheckouts: loading,
  };
};
