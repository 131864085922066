import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { Lead } from "types/lead";
import { leadApi } from "api/lead.api";
interface LeadQuery extends QueryParam {
  leadTopicId?: number;
  id?: number;
}
type Props = {
  initialQuery: LeadQuery;
};

const useLead = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<Lead, LeadQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await leadApi.findAll(query).then(({ data }) => data);
      return {
        data: data.leads,
        total: data.total,
      };
    },
    editFunc: async (id, data) => {
      await leadApi.resolve(id, data);
    },
    deleteFunc: async (id) => {
      await leadApi.delete(id);
    },
  });
  const {
    query: queryLead,
    fetchData: fetchLead,
    total: totalLead,
    loading: isFetchLeadLoading,
    data: leads,
    deleteData: deleteLead,
    editData: resolveLead,
    filterData: filterLead,
  } = fetch;

  return {
    fetchLead,
    totalLead,
    queryLead,
    isFetchLeadLoading,
    leads,
    deleteLead,
    resolveLead,
    filterLead,
  };
};

export default useLead;
