import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { Category } from "types/category";
import { categoryApi } from "api/category.api";
interface CategoryQuery extends QueryParam {
  //   CategoryTopicId?: number;
}
type Props = {
  initialQuery: CategoryQuery;
};

const useCategory = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<Category, CategoryQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await categoryApi.findAll(query).then(({ data }) => data);
      return {
        data: data.categories,
        total: data.total,
      };
    },
    // editFunc: async (id, data) => {
    //   await CategoryApi.resolve(id, data);
    // },
    deleteFunc: async (id) => {
      await categoryApi.delete(id);
    },
  });
  const {
    query: queryCategory,
    fetchData: fetchCategory,
    total: totalCategory,
    loading: isFetchCategoryLoading,
    data: categories,
    deleteData: deleteCategory,
    // editData: resolveCategory,
    filterData: filterCategory,
  } = fetch;

  return {
    fetchCategory,
    totalCategory,
    queryCategory,
    isFetchCategoryLoading,
    categories,
    deleteCategory,
    // resolveCategory,
    filterCategory,
  };
};

export default useCategory;
