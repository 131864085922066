import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Dropdown,
  MenuProps,
  Popover,
  Row,
  Spin,
  Tag,
  Typography,
} from "antd";
import React, { useImperativeHandle, useMemo, useState } from "react";
import {
  Checkout,
  CheckoutDetail,
  CheckoutPaymentType,
  CheckoutPaymentTypeTrans,
  CheckoutStatusTrans,
} from "types/checkout";
import { formatMoneyWithMinusSymbol, formatVND } from "utils/formatVND";
//@ts-ignore
import Lottie from "react-lottie";
import checkIconAnimation from "../../../../assets/animation/check.json";
import { formattedDate, formattedFullDate } from "utils/formatDate";
import { checkoutApi } from "api/checkout.api";
import { GoQuestion } from "react-icons/go";
import PrintBillBtn from "../PrintBill";
import { useSearchParams } from "react-router-dom";
import { ServicePackOrder } from "types/servicePackOrder";
import { BiPackage } from "react-icons/bi";
import { Appointment, ServiceOptionCustom } from "types/appointment";
// import { PrintPage } from "../PrintExample";
import { appointmentApi } from "api/appointment.api";
import { AppointmentHistoryPopover } from "../AppointmentHistoryPopover";

export interface CompleteCheckoutDrawerDetail {
  handleOpen: (checkout: Checkout) => void;
}

interface CompleteCheckoutDrawerDetailProps {}

export const CompleteCheckoutDrawerDetail = React.forwardRef(
  ({}: CompleteCheckoutDrawerDetailProps, ref) => {
    const [isVisibleCheckoutModal, setIsVisibleCheckoutModal] = useState(false);
    const [selectedCheckout, setSelectedCheckout] = useState<Checkout>();

    //* Danh sách dịch vụ lẻ, gom chung dịch vụ với nhau từ checkoutDetail
    const [
      checkoutDetailWithSingleServiceOptions,
      setCheckoutDetailWithSingleServiceOptions,
    ] = useState<CheckoutDetail[]>([]);

    //* Danh sách Gói dịch vụ, gom chung gói với nhau từ checkoutDetail
    const [servicePackOrderList, setServicePackOrderList] = useState<
      ServicePackOrder[]
    >([]);
    const [isFetchCheckoutDetailLoading, setisFetchCheckoutDetailLoading] =
      useState(false);
    const [queryParameters, setQueryParameters] = useSearchParams();
    const [appointment, setAppointment] = useState<Appointment>();

    useImperativeHandle<any, CompleteCheckoutDrawerDetail>(
      ref,
      () => ({
        handleOpen: async (checkout: Checkout) => {
          handleSetupDataInitially(checkout);
        },
      }),
      []
    );

    //* Thể hiện ds chọn dịch vụ chỉ có gói dùng hiển thị/ ẩn content giá tiền
    const hasSelectOnlyServicePackOrder = useMemo(() => {
      if (
        checkoutDetailWithSingleServiceOptions.length === 0 &&
        selectedCheckout
      )
        return true;
      return false;
    }, [checkoutDetailWithSingleServiceOptions, selectedCheckout]);

    const handleSetupDataInitially = async (checkoutInput: Checkout) => {
      setisFetchCheckoutDetailLoading(true);
      setIsVisibleCheckoutModal(true);
      try {
        const res = await checkoutApi.detail(checkoutInput.id);
        const appointmentFindOne = (
          await appointmentApi.findOne(res.data.appointment.id)
        ).data as Appointment;
        setAppointment(appointmentFindOne);
        const checkoutFindOne = res.data as Checkout;
        setSelectedCheckout({
          ...checkoutFindOne,
          checkoutDetails: checkoutFindOne?.checkoutDetails?.map((item) => {
            const serviceOptions = JSON.parse(
              item.serviceOptionJson || "[]"
            ) as ServiceOptionCustom[];
            return {
              ...item,
              serviceOptions:
                serviceOptions.length !== 0
                  ? serviceOptions?.filter((item) => item.isChecked)
                  : [],
            } as CheckoutDetail;
          }),
        });
        setCheckoutDetailWithSingleServiceOptions(
          checkoutFindOne?.checkoutDetails
            ?.filter((item) => !item.servicePackOrderDetail)
            ?.map((item) => {
              const serviceOptions = JSON.parse(
                item.serviceOptionJson || "[]"
              ) as ServiceOptionCustom[];
              return {
                ...item,
                serviceOptions:
                  serviceOptions.length !== 0
                    ? serviceOptions?.filter((item) => item.isChecked)
                    : [],
              } as CheckoutDetail;
            })
        );

        let servicePackOrders: ServicePackOrder[] = [];
        checkoutFindOne?.checkoutDetails?.reduce(
          (prevValue: ServicePackOrder[], currValue: CheckoutDetail) => {
            if (!currValue?.servicePackOrderDetail) return prevValue;

            const hasExistServicePackOrder = prevValue?.find(
              (item) =>
                item.id ===
                currValue?.servicePackOrderDetail?.servicePackOrder?.id
            );
            const serviceOptions = JSON.parse(
              currValue.serviceOptionJson || "[]"
            ) as ServiceOptionCustom[];

            if (!hasExistServicePackOrder) {
              servicePackOrders.push({
                ...currValue.servicePackOrderDetail?.servicePackOrder,
                servicePackOrderDetails: [
                  {
                    ...currValue.servicePackOrderDetail,
                    serviceOption: currValue.serviceOption,
                    serviceOptions,
                  },
                ],
                servicePack:
                  currValue.servicePackOrderDetail.servicePackOrder.servicePack,
              });
            } else {
              hasExistServicePackOrder.servicePackOrderDetails.push({
                ...currValue.servicePackOrderDetail,
                serviceOption: currValue.serviceOption,
                serviceOptions,
              });
            }

            return prevValue;
          },
          servicePackOrders
        );
        setServicePackOrderList(servicePackOrders);
      } finally {
        setisFetchCheckoutDetailLoading(false);
      }
    };

    const onCloseModal = () => {
      setIsVisibleCheckoutModal(false);
      setSelectedCheckout(undefined);
      queryParameters.delete("appointmentId");
      setQueryParameters(queryParameters);
    };

    const handleMenuClick: MenuProps["onClick"] = (e) => {
      // console.log(e);
    };

    const items: MenuProps["items"] = [
      {
        label: selectedCheckout ? (
          <PrintBillBtn
            data={selectedCheckout}
            checkoutDetailWithSingleServiceOptions={
              checkoutDetailWithSingleServiceOptions
            }
            hasSelectOnlyServicePackOrder={hasSelectOnlyServicePackOrder}
            servicePackOrderList={servicePackOrderList}
            appointment={appointment}
          />
        ) : (
          <></>
        ),
        key: "print",
      },
    ];

    const menuProps = {
      items,
      onClick: handleMenuClick,
    };

    // console.log({ checkoutDetailWithSingleServiceOptions });

    // console.log(servicePackOrderList);

    return (
      <Drawer
        bodyStyle={{ padding: 0 }}
        closable={false}
        width={800}
        onClose={onCloseModal}
        open={isVisibleCheckoutModal}
      >
        <div
          className={`cursor-pointer absolute -left-[50px] bg-white top-[20px]"
           w-10 h-10 rounded-full flex items-center justify-center`}
          onClick={onCloseModal}
        >
          <CloseOutlined className={`text-center ${"!text-black"}`} />
        </div>

        {/* {selectedCheckout && <PrintPage data={selectedCheckout} />} */}

        <div className="flex md:flex-row flex-col h-full">
          <div className="w-full md:max-w-[330px] p-6 border border-solid border-[#e7e8e9]">
            <div className="text-center mb-5">
              <Lottie
                options={{
                  fill: "green",
                  loop: false,
                  autoplay: true,
                  animationData: checkIconAnimation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={80}
                width={80}
                className="!text-green-500 text-8xl"
              />
              <h1 className="text-lg font-semibold">Hoàn thành</h1>
            </div>

            <div className="flex flex-col gap-3">
              {/* <Button
                type="primary"
                size="large"
                onClick={() => message.info("Chức năng đang phát triển")}
              >
                Đặt lại lịch hẹn
              </Button> */}
              <Dropdown menu={menuProps} trigger={["click"]}>
                <Button className="!rounded-lg" size="middle">
                  Thao tác
                </Button>
              </Dropdown>
            </div>
          </div>
          <div className="w-full  bg-[#f2f2f7] p-6 overflow-auto">
            <h1 className="text-xl font-semibold !mb-6">Chi tiết checkout</h1>

            <Spin
              spinning={isFetchCheckoutDetailLoading}
              className="w-full justify-center items-center h-full"
            >
              {selectedCheckout && (
                <div className="flex flex-col gap-3 pb-3">
                  <div className="flex flex-col gap-1 items-center bg-white rounded-lg border border-solid border-[#d3d3d3] p-6 ">
                    <h1 className="text-lg font-semibold">
                      Đơn hàng #{selectedCheckout?.code}
                    </h1>
                    <span className="text-[#757676]">
                      {formattedDate(selectedCheckout?.createdAt)}
                    </span>
                    <span className="text-[#757676]">
                      Hóa đơn cho{" "}
                      <span className="text-primary">
                        {selectedCheckout?.appointment?.customerProfile
                          ?.fullName || "Khách hàng ẩn danh"}
                      </span>
                    </span>
                    <Tag color={"green"}>
                      {CheckoutStatusTrans[selectedCheckout.status].label}
                    </Tag>
                  </div>

                  {servicePackOrderList?.map((selectedServicePackOrder) => {
                    return (
                      <div className="rounded-lg border border-solid border-[#d3d3d3] p-6 bg-white">
                        <div className="title font-bold text-xl flex flex-col gap-2">
                          <div className="flex justify-between ">
                            <span className="text-base">
                              <div className="flex items-center">
                                <BiPackage className="text-2xl mr-2"></BiPackage>
                                Gói dịch vụ
                              </div>
                              <div className="font-normal text-sm text-gray-400 mt-1">
                                Danh sách gói dịch vụ
                              </div>
                            </span>
                          </div>

                          <div className="flex justify-between">
                            <div>
                              {/* <span className="mr-1">{item.quantity || 1}x</span> */}
                              {selectedServicePackOrder.servicePack?.name}
                            </div>
                          </div>
                          <Divider className="!my-1" />

                          <div>
                            {selectedServicePackOrder.servicePackOrderDetails?.map(
                              (detail) => (
                                <div className="flex flex-col">
                                  <div className="item flex justify-between">
                                    <div className="flex gap-2 items-center">
                                      {/* <span className="text-xs text-gray-500">
                                        1 x
                                      </span> */}
                                      <div className="text-sm leading-normal font-semibold !mb-0">
                                        {detail.service.name}
                                      </div>
                                    </div>

                                    <div className="flex items-center gap-2">
                                      <span className="font-medium text-[15px]">
                                        Thanh toán gói
                                      </span>
                                    </div>
                                  </div>
                                  {detail?.serviceOptions?.length !== 0 &&
                                    detail?.serviceOptions?.map((item) => (
                                      <div className="item flex justify-between italic">
                                        <div className="flex gap-2 items-center">
                                          <div className="text-sm leading-normal font-medium !mb-0">
                                            + {item.pricingName} (dịch vụ kèm)
                                          </div>
                                        </div>

                                        {/* <div className="flex items-center gap-2">
                                          <span className="font-medium text-[15px]">
                                            Thanh toán gói
                                          </span>
                                        </div> */}
                                      </div>
                                    ))}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                      // <Card className="!border-slate-300 !border !rounded-lg">
                      //   <div className="flex justify-between font-bold text-[20px]">
                      //     <div className="">Tổng:</div>
                      //     <div>
                      //       {formatVND(selectedServicePackOrder?.moneyTotal || 0) + "đ"}
                      //     </div>
                      //   </div>
                      // </Card>
                    );
                  })}

                  {checkoutDetailWithSingleServiceOptions.length !== 0 && (
                    <div className="rounded-lg border border-solid border-[#d3d3d3] p-6 bg-white">
                      <div className="title font-bold text-xl flex flex-col gap-2">
                        <div className="flex justify-between ">
                          <span className="text-base">
                            <div className="flex items-center">
                              <BiPackage className="text-2xl mr-2"></BiPackage>
                              Dịch vụ
                            </div>
                            <div className="font-normal text-sm text-gray-400 mt-1">
                              Danh sách dịch vụ lẻ
                            </div>
                          </span>
                        </div>

                        <Divider className="!my-1" />

                        <div>
                          {checkoutDetailWithSingleServiceOptions?.map(
                            (detail) => (
                              <div className="flex flex-col">
                                <div className="item flex justify-between">
                                  <div className="flex gap-2 items-center">
                                    <div className="text-sm leading-normal font-semibold !mb-0">
                                      {detail.service.name}
                                    </div>
                                  </div>

                                  <div className="flex items-center gap-2">
                                    <span className="font-medium text-[15px]">
                                      {formatVND(detail?.service.price)}
                                    </span>
                                    <span className="text-xs text-gray-500">
                                      x{detail.quantity || 1}
                                    </span>
                                  </div>
                                </div>
                                {detail?.serviceOptions?.length !== 0 &&
                                  detail?.serviceOptions?.map((item) => (
                                    <div className="item flex justify-between italic">
                                      <div className="flex gap-2 items-center">
                                        <div className="text-sm leading-normal font-medium !mb-0">
                                          + {item.pricingName} (dịch vụ kèm)
                                        </div>
                                      </div>

                                      <div className="flex items-center gap-2">
                                        <span className="font-medium text-[15px]">
                                          {formatVND(item.price)}
                                        </span>
                                        <span className="text-xs text-gray-500">
                                          x{item.quantity || 1}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="rounded-lg border border-solid border-[#d3d3d3] p-6 bg-white">
                    <div>
                      {!hasSelectOnlyServicePackOrder && (
                        <>
                          <div className="flex justify-between items-center mb-2">
                            <h1 className="text-base font-semibold my-0">
                              Tổng tiền
                            </h1>
                            <span className="font-medium">
                              {formatVND(selectedCheckout?.moneyTotal)}
                            </span>
                          </div>

                          {selectedCheckout?.moneyVoucher !== 0 && (
                            <div className="flex justify-between items-center mb-2">
                              <h1 className="text-base font-semibold my-0">
                                Voucher
                              </h1>
                              <span className="font-medium text-red-500">
                                {formatMoneyWithMinusSymbol(
                                  selectedCheckout?.moneyVoucher
                                )}
                              </span>
                            </div>
                          )}

                          {selectedCheckout?.moneyCoupon !== 0 && (
                            <div className="flex justify-between items-center mb-2">
                              <h1 className="text-base font-semibold my-0">
                                Coupon
                              </h1>
                              <span className="font-medium text-red-500">
                                {formatMoneyWithMinusSymbol(
                                  selectedCheckout?.moneyCoupon
                                )}
                              </span>
                            </div>
                          )}

                          {selectedCheckout?.appointment.customerPaid !== 0 && (
                            <div className="flex justify-between items-center mb-2">
                              <h1 className="text-base font-semibold my-0">
                                Thanh toán trước
                              </h1>
                              <span
                                className={`font-medium ${
                                  selectedCheckout?.customer
                                    ? "text-red-500"
                                    : ""
                                } `}
                              >
                                {formatMoneyWithMinusSymbol(
                                  selectedCheckout?.appointment.customerPaid
                                )}
                              </span>
                            </div>
                          )}
                          {/* {selectedCheckout.checkoutPayments?.[0]
                            ?.paymentType === CheckoutPaymentType.Point && (
                            <div className="flex justify-between items-center mb-2">
                              {selectedCheckout.checkoutPayments?.[0].amount >
                                0 && (
                                <>
                                  <h1 className="text-base font-semibold my-0">
                                    Khách thanh toán online
                                  </h1>
                                  <span
                                    className={`font-medium ${
                                      selectedCheckout?.customer
                                        ? "text-red-500"
                                        : ""
                                    } `}
                                  >
                                    {formatMoneyWithMinusSymbol(
                                      selectedCheckout.checkoutPayments?.[0]
                                        .amount
                                    )}
                                  </span>
                                </>
                              )}
                              {selectedCheckout.checkoutPayments?.[0].amount <
                                0 && (
                                <>
                                  <h1 className="text-base font-semibold my-0">
                                    Hoàn tiền
                                  </h1>
                                  <span
                                    className={`font-medium ${
                                      selectedCheckout?.customer
                                        ? "text-red-500"
                                        : ""
                                    } `}
                                  >
                                    {formatVND(
                                      selectedCheckout.checkoutPayments?.[0]
                                        .amount
                                    )
                                      .toString()
                                      .replace("-", "")}
                                  </span>
                                </>
                              )}
                            </div>
                          )} */}

                          <Divider style={{ margin: "5px 0" }} />
                        </>
                      )}

                      {selectedCheckout?.paymentType && (
                        <div className="flex justify-between items-center mb-2">
                          <h1 className="text-base font-semibold my-0">
                            Hình thức thanh toán
                          </h1>
                          <span className="font-semibold">
                            <span>
                              {
                                CheckoutPaymentTypeTrans[
                                  selectedCheckout?.paymentType.type
                                ].label
                              }
                            </span>
                          </span>
                        </div>
                      )}
                      <div className="flex justify-between items-center mb-2">
                        <h1 className="text-base font-semibold">
                          {selectedCheckout?.checkoutPayments?.[0]?.amount < 0
                            ? "Tiền hoàn lại"
                            : "Tổng thanh toán"}
                        </h1>
                        <div>
                          <span className="font-bold">
                            {hasSelectOnlyServicePackOrder
                              ? "Đã thanh toán gói"
                              : `${formatVND(
                                  selectedCheckout?.checkoutPayments?.[0]
                                    ?.amount
                                )
                                  .toString()
                                  .replace("-", "")}`}
                          </span>
                        </div>
                      </div>

                      {/* <div className="flex justify-end items-center mb-2">
                        <Popover
                          title="Chi tiết lịch khách đã đặt"
                          placement="bottomRight"
                          content={
                            <Row
                              gutter={12}
                              style={{ width: 300, fontWeight: 600 }}
                            >
                              <Col span={24}>
                                <Typography.Title
                                  level={5}
                                  style={{ fontSize: 13 }}
                                >
                                  Dịch vụ
                                </Typography.Title>
                              </Col>
                              <Col span={24}>
                                <Divider style={{ margin: "5px 0" }} />
                              </Col>
                              {selectedCheckout?.checkoutDetails?.map(
                                (detail) => {
                                  return (
                                    <>
                                      <Col
                                        span={15}
                                        style={{
                                          fontWeight: 550,
                                        }}
                                      >
                                        {detail.service.name}
                                      </Col>
                                      <Col
                                        span={9}
                                        style={{
                                          fontWeight: 500,
                                          textAlign: "end",
                                        }}
                                      >
                                        {!!detail.servicePackOrderDetail
                                          ? "Thanh toán gói"
                                          : `${formatVND(
                                              detail?.service.price
                                            )}`}
                                      </Col>
                                    </>
                                  );
                                }
                              )}

                              {!hasSelectOnlyServicePackOrder && (
                                <>
                                  <Col span={24}>
                                    <Divider style={{ margin: "5px 0" }} />
                                  </Col>

                                  <Col span={17}>Tổng tiền</Col>
                                  <Col
                                    span={7}
                                    style={{
                                      textAlign: "end",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {formatVND(
                                      selectedCheckout?.appointment.moneyTotal
                                    )}
                                  </Col>

                                  {selectedCheckout?.appointment
                                    ?.moneyVoucher !== 0 && (
                                    <>
                                      <Col span={17}>Voucher</Col>
                                      <Col
                                        span={7}
                                        style={{
                                          textAlign: "end",
                                          fontWeight: 500,
                                          color: "red",
                                        }}
                                      >
                                        {formatMoneyWithMinusSymbol(
                                          selectedCheckout?.appointment
                                            ?.moneyVoucher
                                        )}
                                      </Col>
                                    </>
                                  )}
                                  {selectedCheckout?.appointment
                                    ?.moneyCoupon !== 0 && (
                                    <>
                                      <Col span={17}>Coupon</Col>
                                      <Col
                                        span={7}
                                        style={{
                                          textAlign: "end",
                                          fontWeight: 500,
                                          color: "red",
                                        }}
                                      >
                                        {formatMoneyWithMinusSymbol(
                                          selectedCheckout?.appointment
                                            ?.moneyCoupon
                                        )}
                                      </Col>
                                    </>
                                  )}
                                  <Col span={17}>Đã thanh toán</Col>
                                  <Col
                                    span={7}
                                    style={{
                                      textAlign: "end",
                                      fontWeight: 500,
                                      color: "red",
                                    }}
                                  >
                                    {formatMoneyWithMinusSymbol(
                                      selectedCheckout?.appointment
                                        ?.customerPaid
                                    )}
                                  </Col>

                                  <Col span={17}>Còn lại</Col>
                                  <Col
                                    span={7}
                                    style={{
                                      textAlign: "end",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {formatVND(
                                      selectedCheckout?.appointment.moneyFinal
                                    )}
                                  </Col>
                                </>
                              )}

                              <Col span={24}>
                                <Divider style={{ margin: "5px 0" }} />
                              </Col>
                              <Col span={24} style={{ fontWeight: 500 }}>
                                Đã đặt lúc:{" "}
                                {formattedFullDate(
                                  selectedCheckout?.appointment.createdAt
                                )}
                              </Col>
                            </Row>
                          }
                        >
                          <Button
                            style={{
                              padding: "5px 0",
                              fontWeight: 600,
                              color: "#abca74",
                            }}
                            type="text"
                          >
                            Xem lịch đã đặt
                          </Button>
                        </Popover>
                      </div> */}

                      {appointment && (
                        <AppointmentHistoryPopover
                          appointment={appointment}
                          hasSelectOnlyServicePackOrder={
                            hasSelectOnlyServicePackOrder
                          }
                        />
                      )}

                      <div>
                        <span>
                          Thanh toán lúc{" "}
                          {formattedFullDate(selectedCheckout.createdAt)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Spin>
          </div>
        </div>
      </Drawer>
    );
  }
);
