import { roleApi } from "api/role.api";
import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import settings from "settings";
import { Permission } from "types/role";
// import { adminRoutes, Route } from "router";
// import { settings } from "settings";

class PermissionStore {
  permissions: Permission[] = [];
  // accessRoutes: Route[] = [];
  accessRoutes: any[] = [];

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "PermissionStore",
      properties: ["permissions"],
      storage: localStorage,
    });
  }

  fetchPermissions = async (roleId: number) => {
    const res = await roleApi.findOne(roleId);
    this.permissions = res.data.permissions;
  };

  setAccessRoutes = () => {
    if (this.permissions.length) {
      this.accessRoutes
        .flatMap((router) =>
          router.children?.length ? router.children : router
        )
        .forEach((route) => {
          const find = permissionStore.permissions.find(
            (e) => e.name == route.name
          );
          route.isAccess = !!find || settings.isDev;
        });
    }
  };
}

const permissionStore = new PermissionStore();

export { permissionStore };
