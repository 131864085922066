import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Spin,
  Tabs,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { authApi } from "api/auth.api";
import { staffApi } from "api/staff.api";
import React, { useEffect, useState } from "react";
import { userStore } from "store/userStore";
import { getTitle } from "utils";
import { emailRule, requiredRule } from "utils/validate";

export const ProfilePage = ({ title }: { title: string }) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue(userStore.info);
    document.title = getTitle(title);
  }, []);

  //handle submit form
  const onFinish = async (values: any) => {
    setLoading(true);
    await form.validateFields();

    try {
      const oldPassword = form.getFieldValue("oldPassword");
      const newPassword = form.getFieldValue("newPassword");
      if (oldPassword && newPassword) {
        await authApi.passwordUpdate({
          oldPassword,
          newPassword,
        });

        form.resetFields();
      }
      message.success("Thao tác thành công");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row gutter={16}>
        <Col
          className="gutter-row"
          lg={6}
          xs={24}
          style={{ marginBottom: "2em" }}
        >
          <Card title="Thông tin" className="box">
            <div
              className="card-avatar"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Avatar
                icon={userStore.info.name?.[0]}
                style={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                  verticalAlign: "middle",
                  margin: "auto",
                }}
                size={100}
              />
            </div>

            <Divider orientation="left" orientationMargin="0">
              Thông tin liên hệ
            </Divider>
            <p>
              <b>Tên:</b> {userStore.info.name}
            </p>
            <p>
              <b>Số điện thoại:</b> {userStore.info.phone}
            </p>
            <p>
              <b>Email: </b> {userStore.info.email}
            </p>
          </Card>
        </Col>
        <Col className="gutter-row" lg={18} xs={24}>
          <Card title="Đổi thông tin" className="box">
            <Tabs>
              {/* <Tabs.TabPane tab="Cá nhân" key={"info"} tabKey="info">
                <Form form={form} onFinish={onFinish} layout="vertical">
                  <Form.Item label="Tên" name="name" rules={[requiredRule]}>
                    <Input placeholder="Nhập họ và tên" />
                  </Form.Item>
                  <Form.Item
                    label="Điện thoại"
                    name="phone"
                    rules={[requiredRule]}
                  >
                    <Input placeholder="Nhập số điện thoại" />
                  </Form.Item>
                  <Form.Item label="Email" name="email" rules={[emailRule]}>
                    <Input placeholder="Nhập email" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      loading={loading}
                      type="primary"
                      style={{ background: "black", borderColor: "black" }}
                      htmlType="submit"
                    >
                      Cập nhật
                    </Button>
                  </Form.Item>
                </Form>
              </Tabs.TabPane> */}
              <Tabs.TabPane
                tab="Đổi mật khẩu"
                key={"password"}
                tabKey="password"
              >
                <Form form={form} onFinish={onFinish} layout="vertical">
                  <Form.Item
                    label="Mật khẩu cũ"
                    name="oldPassword"
                    required
                    rules={[requiredRule]}
                  >
                    <Input.Password placeholder="Nhập mật khẩu cũ" />
                  </Form.Item>
                  <Form.Item
                    name="newPassword"
                    label="Mật khẩu mới"
                    required
                    rules={[requiredRule]}
                  >
                    <Input.Password placeholder="Nhập mật khẩu mới" />
                  </Form.Item>
                  <Form.Item
                    name="renewPassword"
                    label="Nhập lại mật khẩu"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please enter re-new password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("newPassword") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Nhập lại mật khẩu không khớp")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="Nhập lại mật khẩu mới" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      loading={loading}
                      type="primary"
                      htmlType="submit"
                    >
                      Cập nhật
                    </Button>
                  </Form.Item>
                </Form>
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </>
  );
};
