import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Popconfirm,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  message,
} from "antd";
import { bannerApi } from "api/banner.api";
import { Pagination } from "components/Pagination";
import { PreviewImage } from "components/PreviewImage/PreviewImage";
import useVoucherTemplate from "hooks/useVoucherTemplate";
import { useEffect, useRef } from "react";
import { Banner } from "types/banner";
import { formatVND, getTitle } from "utils";
import { VoucherModal } from "./components/VoucherModal";
import { VoucherTemplate } from "types/voucher";
import { unixToDate, unixToFullDate } from "utils/dateFormat";

const { Column } = Table;

export const VoucherPage = ({ title = "", description="" }) => {
  const {
    fetchData,
    query,
    data,
    loading,
    filterData,
    total,
    deleteData,
    toggleEnabled,
  } = useVoucherTemplate({
    initialQuery: {
      page: 1,
      limit: 20,
    },
  });
  const modalRef = useRef<VoucherModal>(null);

  useEffect(() => {
    document.title = getTitle(title);
    fetchData();
  }, []);

  return (
    <section className="box">
      <div className="flex flex-col gap-1 mb-5">
        <h1 className="text-xl m-0 ">{title}</h1>
        <span className="font-medium">{description}</span>
      </div>
      <div className="filter-container">
        <Space>
          <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              size="middle"
              onChange={(ev) => {
                filterData({ search: ev.currentTarget.value, page: 1 });
              }}
              placeholder="Tìm kiếm"
            />
          </div>

          <div className="filter-item btn mb-1">
            <Button
              onClick={() => fetchData()}
              type="primary"
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </div>

          <div className="filter-item btn mb-1">
            <Button
              onClick={() => {
                modalRef.current?.handleCreate();
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              Thêm mới
            </Button>
          </div>
        </Space>
      </div>

      <Spin spinning={loading}>
        <Table
          scroll={{
            x: 1000,
          }}
          pagination={false}
          rowKey="id"
          dataSource={data}
        >
          <Column
            title="Tên"
            dataIndex="name"
            key="name"
            render={(name, voucher: VoucherTemplate) => {
              return (
                <span className="font-bold" style={{ color: voucher.color }}>
                  {name}
                </span>
              );
            }}
          />
          <Column title="Mô tả" dataIndex="description" key="description" />
          <Column
            align="right"
            title="Giá bán"
            dataIndex="price"
            key="price"
            render={(price) => <span>{formatVND(price)}đ</span>}
          />

          <Column
            align="right"
            title="Giá trị"
            dataIndex="value"
            key="value"
            render={(value) => <span>{formatVND(value)}đ</span>}
          />
          <Column
            align="right"
            title="Ngày mở bán"
            dataIndex="startAt"
            key="value"
            render={(value) => <span>{unixToFullDate(value)}</span>}
          />
          <Column
            align="center"
            title="Trạng thái"
            dataIndex="isEnabled"
            key="isEnabled"
            render={(isEnabled) =>
              isEnabled ? (
                <Tag color="green">Mở bán</Tag>
              ) : (
                <Tag color="red">Ngưng bán</Tag>
              )
            }
          />
          <Column
            width={300}
            align="center"
            title="Hành động"
            key="action"
            render={(text, record: VoucherTemplate) => (
              <Space>
                <Popconfirm
                  title="Bạn có chắc chắn muốn thực hiện thao tác?"
                  onConfirm={async () => {
                    await toggleEnabled(record.id, !record.isEnabled);
                  }}
                >
                  {record.isEnabled ? (
                    <Button type="primary" danger>
                      Ngưng bán
                    </Button>
                  ) : (
                    <Button type="primary">Mở bán</Button>
                  )}
                </Popconfirm>
                <Button
                  type="primary"
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                >
                  Chi tiết
                </Button>
                <Popconfirm
                  title="Bạn có chắc chắn muốn thực hiện thao tác?"
                  onConfirm={async () => {
                    await deleteData(record.id);
                  }}
                >
                  <Button type="primary" danger>
                    Xóa
                  </Button>
                </Popconfirm>
              </Space>
            )}
          />
        </Table>

        <Pagination
          currentPage={query.page}
          total={total}
          pageSize={query.limit}
          onChange={({ limit, page }) => {
            filterData({ limit, page });
          }}
        />
      </Spin>

      <VoucherModal onSubmitOk={fetchData} onClose={() => {}} ref={modalRef} />
    </section>
  );
};
