import {
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Switch,
} from "antd";
import { Rule } from "antd/lib/form";
import { cityApi } from "api/city.api";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import React, { useImperativeHandle, useState } from "react";
import { ModalStatus } from "types/modal";
import { City } from "types/address";
import { requiredRule } from "utils/validate";

export interface CityModal {
  // handleCreate: () => void;
  handleUpdate: (City: City) => void;
}
interface CityModalProps {
  onClose: () => void;
  onSubmitOk: () => void;
}

export const CityModal = React.forwardRef(
  ({ onClose, onSubmitOk }: CityModalProps, ref) => {
    const [form] = Form.useForm<City>();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<ModalStatus>("create");

    useImperativeHandle<any, CityModal>(
      ref,
      () => ({
        // handleCreate() {
        //   form.resetFields();
        //   setVisible(true);
        //   setStatus("create");
        // },
        handleUpdate(City: City) {
          form.setFieldsValue({ ...City });
          setVisible(true);
          setStatus("update");
        },
      }),
      []
    );

    const getFormData = () => {
      const { id, ...data } = form.getFieldsValue();
      return {
        id,
        city: data,
      };
    };

    const onSubmit = async () => {
      await form.validateFields();
      setLoading(true);
      try {
        const { id, ...city } = getFormData();
        await cityApi.update(id || 0, city);

        message.success("Thao tác thành công!");
        onClose();
        onSubmitOk();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        onCancel={() => {
          form.resetFields();
          onClose?.();
          setVisible(false);
        }}
        visible={visible}
        title={status == "create" ? "Tạo dữ liệu" : "Chỉnh sửa dữ liệu"}
        style={{ top: 20 }}
        width={700}
        confirmLoading={loading}
        onOk={onSubmit}
      >
        <Form layout="vertical" form={form}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>

          <Row gutter={16}>
            <Col span={24} className="text-center">
              <Form.Item noStyle shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item
                      className="text-center"
                      rules={[requiredRule]}
                      label="Hình"
                      name="image"
                    >
                      <SingleImageUpload
                        uploadUrl={cityApi.uploadUrl}
                        onUploadOk={(path: string) => {
                          form.setFieldsValue({
                            image: path,
                          });
                        }}
                        imageUrl={form.getFieldValue("image")}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              {/* <Form.Item noStyle shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item
                      className="text-center"
                      rules={[requiredRule]}
                      label="Icon"
                      name="icon"
                    >
                      <SingleImageUpload
                        uploadUrl={cityApi.uploadUrl}
                        onUploadOk={(path: string) => {
                          form.setFieldsValue({
                            icon: path,
                          });
                        }}
                        imageUrl={form.getFieldValue("icon")}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item> */}
            </Col>
            <Col span={24}>
              <Form.Item name="name" label="Tên tỉnh/ thành">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Thứ tự" name="priority" rules={[requiredRule]}>
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="Nhập thứ tự hiển thị"
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                initialValue={true}
                label="Nổi bật"
                name="isHighlight"
                valuePropName="checked"
              >
                <Switch checkedChildren="Bật" unCheckedChildren="Tắt" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
);
