import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { servicePackOrderApi } from "api/servicePackOrder.api";
import { message } from "antd";
import {
  ServicePackOrder,
  ServicePackOrderStatus,
} from "types/servicePackOrder";
import { PaymentType } from "types/paymentType";
export interface ServicePackOrderQuery extends QueryParam {
  customerProfileId?: number;
  fromAt?: number;
  toAt?: number;
  status?: ServicePackOrderStatus;
  paymentTypeId?: number;
  merchantId?: number;
}
type Props = {
  initialQuery: ServicePackOrderQuery;
};

const useServicePackOrder = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<ServicePackOrder, ServicePackOrderQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      console.log({ query });
      const data = await servicePackOrderApi
        .findAll(query)
        .then(({ data }) => data);
      return {
        data: data.servicePackOrders,
        total: data.total,
      };
    },

    // editFunc: async (id, data) => {
    //   await servicePackOrderApi.update(id, data);
    // },
  });

  const {
    query: queryServicePackOrder,
    fetchData: fetchServicePackOrder,
    total: totalServicePackOrder,
    loading: isFetchServicePackOrderLoading,
    data: ServicePackOrders,
    setData,
  } = fetch;

  return {
    fetchServicePackOrder,
    totalServicePackOrder,
    queryServicePackOrder,
    isFetchServicePackOrderLoading,
    ServicePackOrders,
    setServicePackOrder: setData,
  };
};

export default useServicePackOrder;
