import { Appointment, ServiceOptionCustom } from "./appointment";
import { Customer } from "./customer";
import { CustomerProfile } from "./customerProfile";
import { Employee } from "./employee";
import { Merchant } from "./merchant";
import { Promotion } from "./promotion";
import { Service, ServiceOption } from "./service";
import CashIcon from "../assets/images/cash.png";
import PaymentPointIcon from "../assets/images/payment-point.png";
import { ServicePackOrderDetail } from "./servicePackOrder";

export enum CheckoutPaymentType {
  Cash = "CASH", //tiền mặt
  Point = "POINT", //điểm
}

export const CheckoutPaymentTypeTrans = {
  [CheckoutPaymentType.Cash]: {
    label: "Tiền mặt",
    icon: CashIcon,
  },
  [CheckoutPaymentType.Point]: {
    label: "Điểm",
    icon: PaymentPointIcon,
  },
};
export interface Checkout {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  status: CheckoutStatus;
  emailSentInvoice: string;
  note: string;
  refundPoint: number; //Số điểm khách dc nhận khi checkout
  code: string;
  completeAt: number; // Hoan thanh hoa don luc
  voidedAt: number; // Huy hoa don luc
  voucherAmount: number; // Thanh toan bang voucher bao nhieu cho checkout nay
  moneyTotal: number; //  Tiền tạm tính(dịch vụ)  -> 1
  tax: number; // Thuế -> 2
  moneyDiscount: number; // Tiền giảm -> 3
  customerPaid: number; // Tiền giảm -> 3
  moneyFinal: number; // Tong tien invoice -> 4: 1 + 2 - 3
  isSendReview: boolean;
  checkoutDetails: CheckoutDetail[];
  employee: Employee;
  merchant: Merchant;
  appointment: Appointment;
  checkoutPayments: CheckoutPayment[];
  promotion: Promotion;
  customer: Customer;
  moneyVoucher: number;
  moneyCoupon: number;
  paymentType: PaymentType;
  customerProfile: CustomerProfile;
}

export interface PaymentType {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  name: string;
  position: number;
  type: CheckoutPaymentType;
}

export interface CheckoutPayment {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  amount: number;
  paymentType: CheckoutPaymentType;
  checkout: Checkout;
}

export enum CheckoutStatus {
  Complete = "COMPLETE",
  Voided = "VOIDED",
  Refunded = "REFUNDED",
}

export const CheckoutStatusTrans = {
  [CheckoutStatus.Complete]: {
    label: "Hoàn thành",
    value: CheckoutStatus.Complete,
  },
  [CheckoutStatus.Voided]: {
    label: "Vô hiệu",
    value: CheckoutStatus.Voided,
  },
  [CheckoutStatus.Refunded]: {
    label: "Đã hoàn tiền",
    value: CheckoutStatus.Refunded,
  },
};

export const CheckoutStatusBgColors = {
  [CheckoutStatus.Voided]: "#ff4d4f",
  [CheckoutStatus.Complete]: "#52c41a",
  [CheckoutStatus.Refunded]: "#e7e8ff",
};

export interface CheckoutDetail {
  id: number;
  createdAt: number;
  updatedAt: number;
  price: number; // So tien thanh toan
  quantity: number; // So luong
  checkout: Checkout;
  service: Service;
  serviceOption: ServiceOption;
  servicePackOrderDetail: ServicePackOrderDetail;
  serviceOptionJson: string;
  //* Lấy từ Json.parse(serviceOptionJson)
  serviceOptions?: ServiceOptionCustom[];
}
