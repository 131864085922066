import { Col, Form, Input, message, Modal, Row, Select, Switch } from "antd";
import { Rule } from "antd/lib/form";
import { treatmentApi } from "api/treatment.api";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import useCategory from "hooks/useCategory";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { ModalStatus } from "types/modal";
import { Treatment } from "types/treatment";
import { requiredRule } from "utils/validate";

export interface TreatmentModal {
  handleCreate: () => void;
  handleUpdate: (Treatment: Treatment) => void;
}
interface TreatmentModalProps {
  onClose: () => void;
  onSubmitOk: () => void;
}

export const TreatmentModal = React.forwardRef(
  ({ onClose, onSubmitOk }: TreatmentModalProps, ref) => {
    const [form] = Form.useForm<Treatment & { categoryId: number }>();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<ModalStatus>("create");
    const { categories, fetchCategory, isFetchCategoryLoading } = useCategory({
      initialQuery: {
        page: 1,
        limit: 20,
      },
    });
    useImperativeHandle<any, TreatmentModal>(
      ref,
      () => ({
        handleCreate() {
          form.resetFields();
          setVisible(true);
          setStatus("create");
        },
        handleUpdate(Treatment: Treatment) {
          form.setFieldsValue({
            ...Treatment,
            categoryId: Treatment.category.id,
          });
          setVisible(true);
          setStatus("update");
        },
      }),
      []
    );

    useEffect(() => {
      fetchCategory();
    }, []);

    const getFormData = () => {
      const { id, categoryId, ...data } = form.getFieldsValue();
      return {
        id,
        categoryId,
        treatment: data,
      };
    };

    const onSubmit = async () => {
      await form.validateFields();
      setLoading(true);
      try {
        const { id, categoryId, ...treatment } = getFormData();
        const payload = {
          ...treatment,
          categoryId,
        };
        if (id) {
          await treatmentApi.update(id || 0, payload);
        } else {
          await treatmentApi.create(payload);
        }
        message.success("Thao tác thành công!");
        onClose();
        onSubmitOk();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        onCancel={() => {
          form.resetFields();
          onClose?.();
          setVisible(false);
        }}
        visible={visible}
        title={status == "create" ? "Tạo dữ liệu" : "Chỉnh sửa dữ liệu"}
        style={{ top: 20 }}
        width={700}
        confirmLoading={loading}
        onOk={onSubmit}
      >
        <Form layout="vertical" form={form}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>

          <Row gutter={16}>
            <Col span={24} className="text-center">
              <Form.Item noStyle shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item
                      className="text-center"
                      rules={[requiredRule]}
                      label="Hình nền"
                      name="image"
                    >
                      <SingleImageUpload
                        uploadUrl={treatmentApi.uploadUrl}
                        onUploadOk={(path: string) => {
                          form.setFieldsValue({
                            image: path,
                          });
                        }}
                        imageUrl={form.getFieldValue("image")}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item noStyle shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item
                      className="text-center"
                      rules={[requiredRule]}
                      label="Icon"
                      name="icon"
                    >
                      <SingleImageUpload
                        uploadUrl={treatmentApi.uploadUrl}
                        onUploadOk={(path: string) => {
                          form.setFieldsValue({
                            icon: path,
                          });
                        }}
                        imageUrl={form.getFieldValue("icon")}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Tên" name="name" rules={[requiredRule]}>
                <Input placeholder="Nhập tên" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                // initialValue={true}
                label="Ngành dịch vụ"
                name="categoryId"
                rules={[requiredRule]}
              >
                <Select
                  options={categories}
                  fieldNames={{
                    label: "name",
                    value: "id",
                  }}
                  placeholder="Chọn ngành dịch vụ"
                  loading={isFetchCategoryLoading}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                initialValue={true}
                label="Nổi bật"
                name="isHighlight"
                valuePropName="checked"
              >
                <Switch checkedChildren="Bật" unCheckedChildren="Tắt" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
);
