import { Col, Form, Input, message, Modal, Row } from "antd";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import React, { useImperativeHandle, useState } from "react";
import { PaymentType } from "types/paymentType";
import { requiredRule } from "utils/validate";
import { paymentSourceApi } from "api/paymentSource.api";
import { PaymentSource } from "types/paymentSource";
import { ModalStatus } from "types/modal";

export interface PaymentSourceModal {
  handleCreate: () => void;
  handleUpdate: (PaymentSource: PaymentSource) => void;
}
interface PaymentSourceModalProps {
  onClose: () => void;
  onSubmitOk: () => void;
}

export const PaymentSourceModal = React.forwardRef(
  ({ onClose, onSubmitOk }: PaymentSourceModalProps, ref) => {
    const [form] = Form.useForm<PaymentType>();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<ModalStatus>("create");

    useImperativeHandle<any, PaymentSourceModal>(
      ref,
      () => ({
        handleCreate() {
          form.resetFields();
          setVisible(true);
          setStatus("create");
        },
        handleUpdate(PaymentSource: PaymentSource) {
          form.setFieldsValue({ ...PaymentSource });
          setVisible(true);
          setStatus("update");
        },
      }),
      []
    );

    const getFormData = () => {
      const { id, ...data } = form.getFieldsValue();
      return {
        id,
        paymentSource: data,
      };
    };

    const onSubmit = async () => {
      await form.validateFields();
      setLoading(true);
      const { id, ...paymentSource } = getFormData();
      try {
        if (status === "create") {
          await paymentSourceApi.create(paymentSource);
        } else {
          await paymentSourceApi.update(id || 0, paymentSource);
        }
        message.success("Thao tác thành công!");
        onClose();
        onSubmitOk();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        onCancel={() => {
          form.resetFields();
          onClose?.();
          setVisible(false);
        }}
        visible={visible}
        title={status == "create" ? "Tạo dữ liệu" : "Chỉnh sửa dữ liệu"}
        style={{ top: 20 }}
        width={700}
        confirmLoading={loading}
        onOk={onSubmit}
      >
        <Form layout="vertical" form={form}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>

          <Col span={24} className="text-center">
            <Form.Item noStyle shouldUpdate={true}>
              {() => {
                return (
                  <Form.Item
                    className="text-center"
                    rules={[requiredRule]}
                    label="Hình đại diện"
                    name="icon"
                  >
                    <SingleImageUpload
                      // uploadUrl={LeadTopicApi.uploadUrl}
                      onUploadOk={(path: string) => {
                        form.setFieldsValue({
                          icon: path,
                        });
                      }}
                      imageUrl={form.getFieldValue("icon")}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="name" label="Nguồn thanh toán">
                {status == "create" ? (
                  <Input placeholder="Nhập nguồn thanh toán" />
                ) : (
                  <Input />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
);
