import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Popconfirm,
  Space,
  Spin,
  Switch,
  Table,
  message,
  Image,
} from "antd";
import { Pagination } from "components/Pagination";
import useBanner from "hooks/useBanner";
import { useEffect, useRef } from "react";
import { Banner } from "types/banner";
import { formatVND, getTitle } from "utils";
import { BannerModal } from "./components/BannerModal";
import { bannerApi } from "api/banner.api";
import { PreviewImage } from "components/PreviewImage/PreviewImage";

const { Column } = Table;

export const BannerPage = ({ title = "", description="" }) => {
  const { fetchData, query, data, loading, filterData, total, deleteData } =
    useBanner({
      initialQuery: {
        page: 1,
        limit: 20,
      },
    });
  const modalRef = useRef<BannerModal>(null);

  useEffect(() => {
    document.title = getTitle(title);
    fetchData();
  }, []);

  const hanldeVisibleBanner = async (id: number, isVisible: boolean) => {
    await bannerApi.update(id, { banner: { isVisible } });
    message.success("Thao tác thành công");
    fetchData();
  };

  return (
    <section className="box">
      <div className="flex flex-col gap-1 mb-5">
        <h1 className="text-xl m-0 ">{title}</h1>
        <span className="font-medium">{description}</span>
      </div>
      <div className="filter-container">
        <Space>
          <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              size="middle"
              onChange={(ev) => {
                filterData({ search: ev.currentTarget.value, page: 1 });
              }}
              placeholder="Tìm kiếm"
            />
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => fetchData()}
              type="primary"
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => {
                modalRef.current?.handleCreate();
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              Thêm mới
            </Button>
          </div>
        </Space>
      </div>

      <Spin spinning={loading}>
        <Table
          scroll={{
            x: 1000,
          }}
          pagination={false}
          rowKey="id"
          dataSource={data}
        >
          <Column
            width={80}
            align="center"
            title="Vị trí"
            dataIndex="position"
            key="position"
          />

          <Column
            title="Hình ảnh"
            dataIndex="image"
            key="image"
            width={100}
            align="center"
            render={(text) => (
              <PreviewImage
                image={text}
                styles={{ width: 70, height: "auto", maxHeight: 30 }}
              />
            )}
          />
          <Column
            title="Tên"
            dataIndex="name"
            key="name"
            render={(text, record: Banner) => {
              return (
                <span
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                  className="text-secondary cursor-pointer hover:underline font-medium"
                >
                  {text}
                </span>
              );
            }}
          />

          <Column title="Layout" dataIndex="layout" key="layout" />

          <Column
            align="center"
            width={120}
            title="Trạng thái"
            dataIndex="isVisible"
            key="isVisible"
            render={(text, record: Banner) => {
              return (
                <Popconfirm
                  onConfirm={async () => {
                    await hanldeVisibleBanner(record.id, !record.isVisible);
                  }}
                  title="Bạn có muốn tiếp tục thực hiện thao tác?"
                >
                  <Switch
                    checked={text}
                    checkedChildren="Hiện"
                    unCheckedChildren="Ẩn"
                  />
                </Popconfirm>
              );
            }}
          />

          <Column
            width={120}
            align="center"
            title="Hành động"
            key="action"
            render={(text, record: Banner) => (
              <Space>
                <Popconfirm
                  title="Bạn có chắc chắn muốn thực hiện thao tác?"
                  onConfirm={async () => {
                    await deleteData(record.id);
                  }}
                >
                  <Button type="primary" danger>
                    Xóa
                  </Button>
                </Popconfirm>
                <Button
                  type="primary"
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                >
                  Cập nhật
                </Button>
              </Space>
            )}
          />
        </Table>

        <Pagination
          currentPage={query.page}
          total={total}
          pageSize={query.limit}
          onChange={({ limit, page }) => {
            filterData({ limit, page });
          }}
        />
      </Spin>

      <BannerModal onSubmitOk={fetchData} onClose={() => {}} ref={modalRef} />
    </section>
  );
};
