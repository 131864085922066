import {
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Switch,
} from "antd";
import { useWatch } from "antd/es/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { couponCampaignApi } from "api/couponCampaign.api";
import { FormInputNumber } from "components/Input/FormInputNumber";
import { InputNumberVN } from "components/Input/InputNumberVN";
// import { CouponCampaignApi } from "api/lea";
import React, { useImperativeHandle, useState, Validator } from "react";
import {
  CouponCampaign,
  CouponCampaignType,
  CouponCampaignTypeTrans,
  CouponDiscountType,
  CouponDiscountTypeTrans,
} from "types/couponCampaign";
import { ModalStatus } from "types/modal";
// import { CouponCampaign } from "types/address";
import { requiredRule } from "utils/validate";

export interface CouponCampaignModal {
  handleCreate: () => void;
  handleUpdate: (CouponCampaign: CouponCampaign) => void;
}
interface CouponCampaignModalProps {
  onClose: () => void;
  onSubmitOk: () => void;
  // CouponCampaignList: CouponCampaign[];
}

interface IForm {
  name?: string;
  type?: CouponCampaignType;
  id?: number;
}

export const CouponCampaignModal = React.forwardRef(
  ({ onClose, onSubmitOk }: CouponCampaignModalProps, ref) => {
    const [form] = Form.useForm<CouponCampaign>();
    const discountType = useWatch<CouponDiscountType>("discountType", form);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [campaignType, setCampaignType] = useState(
      CouponCampaignType.NewMemberShip
    );
    const [status, setStatus] = useState<ModalStatus>("create");

    const [couponCampaignSelected, setCouponCampaignSelected] =
      useState<CouponCampaign>();
    useImperativeHandle<any, CouponCampaignModal>(
      ref,
      () => ({
        handleCreate() {
          form.resetFields();
          form.setFieldValue("type", CouponCampaignType.NewMemberShip);
          form.setFieldValue("expiredInDay", 7);
          setCampaignType(CouponCampaignType.NewMemberShip);
          setVisible(true);
          setStatus("create");
        },
        handleUpdate(couponCampaign: CouponCampaign) {
          setCouponCampaignSelected(couponCampaign);
          form.setFieldsValue({ ...couponCampaign });
          setCampaignType(couponCampaign.type);
          setVisible(true);
          setStatus("update");
        },
      }),
      []
    );

    const onUpdate = async () => {
      try {
        console.log(form.getFieldsValue());
        await form.validateFields();
        const { id, ...formData } = form.getFieldsValue();
        // const { conditionValue, discountValue, discountType } = formData;

        // //* Nếu loại giảm giá là VNĐ thì giá trị giảm ko được bé hơn giá trị áp dụng
        // if (
        //   discountType === CouponDiscountType.Fixed &&
        //   discountValue > conditionValue
        // ) {
        //   message.error(
        //     "Giá trị áp dụng tối thiểu không được nhỏ hơn giá trị giảm"
        //   );
        //   return;
        // }
        setLoading(true);
        await couponCampaignApi.update(id || 0, {
          couponCampaign: {
            ...formData,
            discountMaxValue:
              formData.discountType === CouponDiscountType.Fixed
                ? 0
                : formData.discountMaxValue,
          } as CouponCampaign,
        });
        message.success("Thao tác thành công!");
        onClose();
        onSubmitOk();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    const onCreate = async () => {
      try {
        setLoading(true);
        await form.validateFields();
        const formData = form.getFieldsValue();

        await couponCampaignApi.create({
          couponCampaign: {
            ...formData,
            discountMaxValue:
              formData.discountType === CouponDiscountType.Fixed
                ? 0
                : formData.discountMaxValue,
          },
        });
        message.success("Thao tác thành công!");

        onClose();
        onSubmitOk();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    const onSubmit = async () => {
      if (status === "update") onUpdate();
      else onCreate();
    };

    return (
      <Modal
        onCancel={() => {
          form.resetFields();
          onClose?.();
          setVisible(false);
        }}
        visible={visible}
        title={
          <h1 className="text-secondary text-lg font-bold">
            {status === "create"
              ? "Thêm mới coupon"
              : `Cập nhật coupon - ${couponCampaignSelected?.name || ""}`}
          </h1>
        }
        style={{ top: 20 }}
        width={900}
        confirmLoading={loading}
        onOk={onSubmit}
      >
        <Form layout="vertical" form={form}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Tên chiến dịch khuyến mãi"
                rules={[requiredRule]}
              >
                <Input
                  disabled={campaignType !== CouponCampaignType.NewMemberShip}
                  placeholder="Nhập Tên chiến dịch"
                />
              </Form.Item>
            </Col>
            <Col span={discountType === CouponDiscountType.Percent ? 12 : 24}>
              <Form.Item
                name="discountType"
                label="Giảm giá theo"
                rules={[requiredRule]}
              >
                <Select
                  placeholder="Chọn Giảm giá theo"
                  onChange={(value) =>
                    form.setFieldsValue({
                      discountValue: 0,
                      discountMaxValue: 0,
                    })
                  }
                >
                  {Object.values(CouponDiscountType)?.map((item) => (
                    <Select.Option key={item} value={item}>
                      {CouponDiscountTypeTrans[item]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="conditionValue"
                label="Giá trị áp dụng tối thiểu"
                rules={[requiredRule]}
              >
                <FormInputNumber
                  step={10000}
                  placeholder="Nhập Giá trị"
                  addonAfter="đ"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="discountValue"
                label="Giá trị giảm"
                dependencies={["conditionValue"]}
                rules={[
                  requiredRule,
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        getFieldValue("conditionValue") > value ||
                        getFieldValue("discountType") ===
                          CouponDiscountType.Percent
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "Giá trị áp dụng tối thiểu không được nhỏ hơn giá trị giảm"
                        )
                      );
                    },
                  }),
                ]}
              >
                <FormInputNumber
                  max={
                    discountType === CouponDiscountType.Percent
                      ? 100
                      : undefined
                  }
                  min={discountType === CouponDiscountType.Fixed ? 1000 : 0}
                  step={10000}
                  placeholder="Nhập Giá trị"
                  addonAfter={
                    discountType === CouponDiscountType.Percent ? "%" : "đ"
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="minBalance"
                label="Số điểm cần có để nhận"
                rules={[requiredRule]}
              >
                <FormInputNumber
                  min={0}
                  step={10000}
                  placeholder="Nhập Giá trị"
                  addonAfter={"điểm"}
                />
              </Form.Item>
            </Col>
            {discountType === CouponDiscountType.Percent && (
              <Col span={12}>
                <Form.Item
                  name="discountMaxValue"
                  label="Giá trị giảm tối đa"
                  rules={[requiredRule]}
                >
                  <FormInputNumber
                    step={10000}
                    placeholder="Nhập Giá trị"
                    addonAfter="đ"
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                name="type"
                label="Loại chiến dịch"
                rules={[requiredRule]}
              >
                <Select placeholder="Chọn loại chiến dịch" disabled={true}>
                  {Object.values(CouponCampaignType)?.map((item) => (
                    <Select.Option key={item} value={item}>
                      {CouponCampaignTypeTrans[item]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="expiredInDay"
                label="Khả dụng trong vòng (ngày)"
                rules={[requiredRule]}
              >
                <InputNumberVN />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="type" label="Hoạt động" rules={[requiredRule]}>
                <Switch
                  // checked={record.isEnabled}
                  defaultChecked={true}
                  checkedChildren="Hoạt động"
                  unCheckedChildren="Khóa"
                ></Switch>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
);
