import { Col, Form, Input, InputNumber, message, Modal, Row } from "antd";
import { commissionConfigApi } from "api/commissionConfig.api";
import React, { useImperativeHandle, useState } from "react";
import { CommissionConfig, CommissionConfigType } from "types/commissionConfig";
import { ModalStatus } from "types/modal";
import { requiredRule } from "utils/validate";

export interface CommissionConfigModal {
  handleCreate: () => void;
  handleUpdate: (
    CommissionConfig: CommissionConfig,
    remindConfigValue?: string
  ) => void;
}
interface CommissionConfigModalProps {
  onClose: () => void;
  onSubmitOk: () => void;
}

export const CommissionConfigModal = React.forwardRef(
  ({ onClose, onSubmitOk }: CommissionConfigModalProps, ref) => {
    const [form] = Form.useForm<CommissionConfig>();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<ModalStatus>("create");

    useImperativeHandle<any, CommissionConfigModal>(
      ref,
      () => ({
        handleCreate() {
          form.resetFields();
          setVisible(true);
          setStatus("create");
        },
        handleUpdate(CommissionConfig: CommissionConfig, remindConfigValue) {
          form.setFieldsValue({ ...CommissionConfig });
          if (CommissionConfig.type === CommissionConfigType.Guarantee)
            form.setFieldValue(
              "name",
              CommissionConfig.name.replaceAll(
                "15 phút",
                `${remindConfigValue} phút`
              )
            );
          setVisible(true);
          setStatus("update");
        },
      }),
      []
    );

    const getFormData = () => {
      const { id, ...data } = form.getFieldsValue();
      return {
        id,
        commissionConfig: data,
      };
    };

    const onSubmit = async () => {
      await form.validateFields();
      setLoading(true);
      try {
        const { id, ...commissionConfig } = getFormData();
        if (id) {
          await commissionConfigApi.update(id || 0, commissionConfig);
        } else {
          await commissionConfigApi.create(commissionConfig);
        }
        message.success("Thao tác thành công!");
        onClose();
        onSubmitOk();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        onCancel={() => {
          form.resetFields();
          onClose?.();
          setVisible(false);
        }}
        visible={visible}
        title={status == "create" ? "Tạo dữ liệu" : "Chỉnh sửa dữ liệu"}
        style={{ top: 20 }}
        width={700}
        confirmLoading={loading}
        onOk={onSubmit}
      >
        <Form layout="vertical" form={form}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Tên" name="name" rules={[requiredRule]}>
                <Input disabled={status == "update"} placeholder="Nhập tên" />
              </Form.Item>
              <Form.Item
                label="Chiết khẩu"
                name="percent"
                rules={[
                  requiredRule,
                  {
                    type: "number",
                  },
                ]}
              >
                <InputNumber
                  controls={false}
                  suffix="%"
                  className="w-full "
                  placeholder="Nhập tiền hoa hồng"
                ></InputNumber>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
);
