import { Button, Spin, Table } from "antd";
import { configurationApi } from "api/configuration.api";
import { useEffect, useState } from "react";
import { Configuration, configurationParamsObject } from "types/configuration";
import { ModalStatus } from "types/modal";
import { QueryParam } from "types/query";
import { formatVND, getTitle } from "utils";
import { ConfigurationModal } from "./components/ConfigurationModal";
import useConfiguration from "hooks/useConfiguration";

const { ColumnGroup, Column } = Table;

export const ConfigurationPage = ({ title = "" }) => {
  const { fetchData, query, loading, total, data } = useConfiguration({
    initialQuery: {
      page: 1,
      limit: 100,
    },
  });
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedConfiguration, setSelectedConfiguration] = useState<
    Partial<Configuration>
  >({});
  const [modalStatus, setModalStatus] = useState<ModalStatus>("create");

  useEffect(() => {
    document.title = getTitle(title);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="box">
      <Spin spinning={loading}>
        <Table pagination={false} rowKey="id" dataSource={data}>
          <Column
            title="Loại"
            dataIndex="param"
            key="param"
            render={(text, record: Configuration) => (
              <span className="font-medium text-secondary">
                {configurationParamsObject[record.param]?.label}
              </span>
            )}
          />
          <Column
            align="center"
            width={200}
            title="Giá trị"
            dataIndex="value"
            key="value"
            render={(text, record: Configuration) => (
              <span>
                {Number(record.value) >= 0
                  ? formatVND(Number(record.value))
                  : record.value}
              </span>
            )}
          />

          <Column
            align="center"
            width={120}
            title="Hành động"
            key="action"
            render={(text, record: Configuration) => (
              <span>
                <Button
                  type="primary"
                  onClick={() => {
                    setSelectedConfiguration(record);
                    setVisibleModal(true);
                    setModalStatus("update");
                  }}
                >
                  Cập nhật
                </Button>
              </span>
            )}
          />
        </Table>
      </Spin>

      <ConfigurationModal
        onSubmitOk={fetchData}
        onClose={() => setVisibleModal(false)}
        visible={visibleModal}
        configuration={selectedConfiguration}
        status={modalStatus}
      />
    </section>
  );
};
