import { Button, Col, Form, Input, message, Modal, Row, Select } from "antd";
import { Rule } from "antd/lib/form";
import { rankApi } from "api/rank.api";
import { InputNumber } from "components/Input/InputNumber";
import { InputNumberVN } from "components/Input/InputNumberVN";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import React, { useImperativeHandle, useState } from "react";
import { CirclePicker, SketchPicker } from "react-color";
import { MdOutlineChangeCircle } from "react-icons/md";
import { ModalStatus } from "types/modal";
import { Rank } from "types/rank";
import { betweenValueRule, requiredRule } from "utils/validate";

export interface RankModal {
  handleCreate: () => void;
  handleUpdate: (Rank: Rank) => void;
}
interface RankModalProps {
  onClose: () => void;
  onSubmitOk: () => void;
}

export const RankModal = React.forwardRef(
  ({ onClose, onSubmitOk }: RankModalProps, ref) => {
    const [form] = Form.useForm<Rank>();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<ModalStatus>("create");
    const [colorPickerType, setColorPickerType] = useState<"circle" | "custom">(
      "circle"
    );
    const [color, setColor] = useState<any>(undefined);
    const [rankSelected, setRankSelected] = useState<Rank>();

    useImperativeHandle<any, RankModal>(
      ref,
      () => ({
        handleCreate() {
          form.resetFields();
          setVisible(true);
          setStatus("create");
        },
        handleUpdate(Rank: Rank) {
          setRankSelected(Rank);
          form.setFieldsValue({ ...Rank });
          setVisible(true);
          setStatus("update");
        },
      }),
      []
    );

    const getFormData = () => {
      const { id, ...data } = form.getFieldsValue();
      return {
        id,
        rank: { ...data, color },
      };
    };

    const onSubmit = async () => {
      await form.validateFields();
      setLoading(true);
      try {
        const { id, ...rank } = getFormData();
        if (id) {
          await rankApi.update(id || 0, rank);
        } else {
          await rankApi.create(rank);
        }
        message.success("Thao tác thành công!");
        onClose();
        onSubmitOk();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        onCancel={() => {
          form.resetFields();
          onClose?.();
          setVisible(false);
        }}
        visible={visible}
        title={
          <h1 className="text-secondary text-lg font-bold">
            {status === "create"
              ? "Thêm mới thứ hạng"
              : `Cập nhật thứ hạng - ${rankSelected?.name || ""}`}
          </h1>
        }
        style={{ top: 20 }}
        width={700}
        confirmLoading={loading}
        onOk={onSubmit}
      >
        <Form layout="vertical" form={form}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Row gutter={[32, 32]}>
            <Col span={12}>
              <Form.Item label="Tên" name="name" rules={[requiredRule]}>
                <Input placeholder="Nhập tên" />
              </Form.Item>
              <Form.Item
                label="Số điểm cần đạt"
                name="needPoint"
                rules={[requiredRule]}
              >
                <InputNumberVN placeholder="Nhập số điểm cần đạt" />
              </Form.Item>
              <Form.Item
                label="Tỉ lệ hoàn điểm"
                name="refundPercent"
                rules={[requiredRule, betweenValueRule({ max: 100, min: 0 })]}
              >
                <InputNumberVN
                  placeholder="Nhập tỉ lệ hoàn điểm"
                  //@ts-ignore
                  addonAfter="%"
                />
              </Form.Item>
              <Form.Item label="Mô tả" name="desc">
                <Input.TextArea
                  rows={3}
                  placeholder="Nhập mô tả"
                ></Input.TextArea>
              </Form.Item>
            </Col>
            <Col span={12} className="text-center">
              {/* <Form.Item noStyle shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item
                      className="text-center"
                      rules={[requiredRule]}
                      label="Hình nền"
                      name="thumbnail"
                    >
                      <SingleImageUpload
                        uploadUrl={rankApi.uploadUrl}
                        onUploadOk={(path: string) => {
                          form.setFieldsValue({
                            thumbnail: path,
                          });
                        }}
                        imageUrl={form.getFieldValue("thumbnail")}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item noStyle shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item
                      className="text-center"
                      rules={[requiredRule]}
                      label="Icon"
                      name="icon"
                    >
                      <SingleImageUpload
                        uploadUrl={rankApi.uploadUrl}
                        onUploadOk={(path: string) => {
                          form.setFieldsValue({
                            icon: path,
                          });
                        }}
                        imageUrl={form.getFieldValue("icon")}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item> */}
              <div className="flex flex-col">
                <Form.Item label="Chọn màu">
                  {colorPickerType === "circle" && (
                    <CirclePicker
                      color={color}
                      onChangeComplete={(color) => setColor(color.hex)}
                    />
                  )}
                  {colorPickerType === "custom" && (
                    <SketchPicker
                      width="300px"
                      color={color}
                      onChangeComplete={(color) => setColor(color.hex)}
                    />
                  )}
                </Form.Item>
                <Button
                  icon={
                    <MdOutlineChangeCircle className="translate-y-0.5 mr-0" />
                  }
                  // style={{ width: "90%" }}
                  onClick={() => {
                    setColorPickerType(
                      colorPickerType === "circle" ? "custom" : "circle"
                    );
                  }}
                  style={{ marginBottom: 15 }}
                >
                  {colorPickerType === "circle" ? "Tùy chỉnh" : "Đơn giản"}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
);
