import { Col, Form, Input, message, Modal, Row, Select } from "antd";
import { InputNumber } from "antd/lib";
import TextArea from "antd/lib/input/TextArea";
import { customerReviewApi } from "api/customerReview.api";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
// import { customerReviewApi } from "api/lea";
import React, { useImperativeHandle, useState } from "react";
import { CustomerReview } from "types/customerReview";
import { ModalStatus } from "types/modal";
// import { CustomerReview } from "types/address";
import { requiredRule } from "utils/validate";

export interface CustomerReviewModal {
  handleCreate: () => void;
  handleUpdate: (CustomerReview: CustomerReview) => void;
}
interface CustomerReviewModalProps {
  onClose: () => void;
  onSubmitOk: () => void;
}

interface ICustomerReviewForm {
  avatar: string;
  no: number;
  name: string;
  address: string;
  content: string;
  id: number;
}

export const CustomerReviewModal = React.forwardRef(
  ({ onClose, onSubmitOk }: CustomerReviewModalProps, ref) => {
    const [form] = Form.useForm<ICustomerReviewForm>();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<ModalStatus>("create");
    // const [topicId, setTopicId] = useState<number>()

    useImperativeHandle<any, CustomerReviewModal>(
      ref,
      () => ({
        handleCreate() {
          form.resetFields();
          setVisible(true);
          setStatus("create");
        },
        handleUpdate(customerReview: CustomerReview) {
          form.setFieldsValue({ ...customerReview });
          setVisible(true);
          setStatus("update");
          // setTopicId(CustomerReview.id)
        },
      }),
      []
    );

    const onSubmit = async () => {
      console.log(form.getFieldsValue());
      await form.validateFields();
      const formData = form.getFieldsValue();

      setLoading(true);
      try {
        if (status === "create") {
          await customerReviewApi.create({
            customerReview: formData,
          });
        } else {
          await customerReviewApi.update(+formData.id || 0, {
            customerReview: formData,
          });
        }
        message.success("Thao tác thành công!");
        onClose();
        onSubmitOk();
        setVisible(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        onCancel={() => {
          form.resetFields();
          onClose?.();
          setVisible(false);
        }}
        visible={visible}
        title={
          <h1 className="text-secondary text-lg font-bold">
            {status === "create" ? "Thêm mới đánh giá" : `Cập nhật đánh giá`}
          </h1>
        }
        style={{ top: 20 }}
        width={900}
        confirmLoading={loading}
        onOk={onSubmit}
      >
        <Form layout="vertical" form={form}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>

          <Row gutter={16}>
            <Col span={24} className="text-center">
              <Form.Item noStyle shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item
                      className="text-center"
                      rules={[requiredRule]}
                      label="Hình đại diện"
                      name="avatar"
                    >
                      <SingleImageUpload
                        // uploadUrl={LeadTopicApi.uploadUrl}
                        onUploadOk={(path: string) => {
                          form.setFieldsValue({
                            avatar: path,
                          });
                        }}
                        imageUrl={form.getFieldValue("avatar")}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="id" hidden></Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Tên người review"
                rules={[requiredRule]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="Nhập Tên người review"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="address" label="Địa chỉ" rules={[requiredRule]}>
                <Input style={{ width: "100%" }} placeholder="Nhập Địa chỉ" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="no"
                label="Thứ tự hiển thị"
                rules={[requiredRule]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="Nhập thứ tự hiển thị"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="content"
                label="Nội dung review"
                rules={[requiredRule]}
              >
                <TextArea
                  rows={10}
                  style={{ width: "100%" }}
                  placeholder="Nhập nội dung review"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
);
