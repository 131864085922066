import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Space, Spin, Table, Tooltip } from "antd";
import Column from "antd/es/table/Column";
import dayjs, { Dayjs, OpUnitType } from "dayjs";
import { useServices } from "hooks/useServices";
import { useEffect } from "react";
import { Merchant } from "types/merchant";
import { Service } from "types/treatment";
import { formatVND } from "utils";
import { unixToDate } from "utils/dateFormat";

const currDay = dayjs();
const initialQuery = {
  fromAt: currDay.startOf("day").startOf("month"),
  toAt: currDay.endOf("day").endOf("month"),
};

const getDateQuery = (unit: OpUnitType, date?: Dayjs) => {
  if (!date) {
    return {
      fromAt: undefined,
      toAt: undefined,
    };
  }
  return {
    fromAt: date.startOf("day").startOf(unit).unix(),
    toAt: date.endOf("day").endOf(unit).unix(),
  };
};

export const ServicesTab = ({ merchant }: { merchant: Merchant }) => {
  useEffect(() => {}, []);

  const { services, fetchServices, loadingService, queryService } = useServices(
    { initQuery: { page: 1, limit: 50, merchantId: merchant.id } }
  );

  useEffect(() => {
    fetchServices();

    return () => {};
  }, []);

  return (
    <Spin spinning={loadingService}>
      <div className="m-auto">
        <div className="filter-container">
          <Space wrap>
            <div className="filter-item">
              <label htmlFor="">Tìm kiếm</label>
              <Input
                onChange={(ev) => {
                  let text = ev.target.value;
                  queryService.search = text;
                  fetchServices();
                  // filterData({ search: text });
                }}
                size="middle"
                placeholder="Tìm kiếm theo tên dịch vụ"
              />
            </div>
            <div className="filter-item btn mt-1">
              <Button
                type="primary"
                icon={<SearchOutlined />}
                onClick={() => {
                  fetchServices();
                }}
              >
                Tìm kiếm
              </Button>
            </div>
          </Space>
        </div>

        <Table
          dataSource={services}
          size="small"
          scroll={{ x: "max-content", y: "500px" }}
          pagination={false}
        >
          <Column
            width={150}
            title="Tên dịch vụ"
            render={(text, record: Service) => (
              <div className="flex items-center justify-between">
                <span className="font-medium">{record.name}</span>

                <span className="font-medium text-secondary">
                  {formatVND(record.price)}đ
                </span>
              </div>
            )}
          ></Column>
          <Column
            width={150}
            title="Mô tả dịch vụ"
            render={(text, record: Service) => (
              <Tooltip title={record.description}>
                <span className="font-medium line-clamp-2">
                  {record.description}
                </span>
              </Tooltip>
            )}
          ></Column>
          <Column
            width={100}
            title="Dịch vụ đi kèm"
            render={(text, record: Service) => (
              <div className="flex flex-col gap-1">
                {record.serviceOptions.map((item) => {
                  return (
                    <div className="flex items-center justify-between">
                      <span className="font-medium">{item.pricingName}</span>

                      <span className="font-medium text-secondary">
                        {formatVND(item.price)}đ
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          ></Column>
        </Table>
      </div>
    </Spin>
  );
};
