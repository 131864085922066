import { Input, message, Select } from "antd";
import { Pagination } from "components/Pagination";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import {
  Appointment,
  AppointmentStatus,
  AppointmentStatusTrans,
} from "types/appointment";
import { getTitle } from "utils";
import { OneSignal } from "utils/oneSignal";
import { QueryParams, useFetchAppointment } from "hooks/useFetchAppointment";
import { AppointmentList } from "./Table/AppointmentList";
import useMerchant from "hooks/useMerchant";
import { SearchOutlined } from "@ant-design/icons";
import { appointmentApi } from "api/appointment.api";
import {
  PaymentOrderStatus,
  PaymentOrderStatusTrans,
} from "types/paymentOrder";

export const AppointmentsPage = ({
  title,
  initQuery,
  onSelectAppointment,
  description,
}: {
  title: string;
  initQuery?: QueryParams;
  onSelectAppointment?: (appointment: Appointment) => void;
  description: string;
}) => {
  const [selectedMerchantLocationId, setSelectedMerchantLocationId] = useState<
    number | null
  >(null);

  const {
    appointments,
    fetchAppointment,
    loadingAppointments,
    queryAppointments,
    totalAppointments,
  } = useFetchAppointment({
    initQuery: {
      page: 1,
      limit: 50,
      ...initQuery,
    },
  });
  const {
    query: queryMerchant,
    fetchData: fetchMerchant,
    loading: loadingMerchant,
    data: dataMerchants,
  } = useMerchant({
    initialQuery: {
      page: 1,
      limit: 20,
    },
  });
  useEffect(() => {
    fetchMerchant();
    fetchAppointment();
    document.title = getTitle(title);
    OneSignal.notificationDisplay((event) => {
      const id = event?.data?.appointmentId || 0;
      fetchAppointment();
    });

    return () => {
      OneSignal.removeNotificationDisplay();
    };
  }, []);

  const debounceFetchMerchant = debounce((value) => {
    queryMerchant.search = value;
    fetchMerchant();
  }, 300);
  const debounceSearch = debounce(() => {
    fetchAppointment();
  }, 300);
  const handleCancelAppointment = async (
    appointmentId: number,
    reason: string
  ) => {
    try {
      const res = await appointmentApi.cancel(appointmentId, { reason });
      message.success("Hủy lịch đặt thành công");
      fetchAppointment();
    } catch (error) {}
  };
  return (
    <div className="m-auto box">
      <div className="filter-container">
        <div className="flex justify-between py-3 items-center flex-wrap">
          <div className="flex flex-col gap-1 mb-1.5">
            <h1 className="text-xl font-bold m-0">{title}</h1>
            <span className="font-medium">{description}</span>
          </div>
        </div>
        <div className="flex gap-4 items-center flex-wrap">
          <div className="filter-item w-full sm:w-[330px]">
            <label htmlFor="">Tìm kiếm</label>
            <br />
            <Input
              allowClear={true}
              prefix={
                <SearchOutlined
                  className="cursor-pointer"
                  onClick={() => fetchAppointment()}
                />
              }
              placeholder="Tìm kiếm theo mã lịch hẹn, tên khách hàng"
              onChange={(e) => {
                let text = e.target.value;
                queryAppointments.search = text;
                queryAppointments.page = 1;
                debounceSearch();
              }}
            />
          </div>
          <div className="filter-item w-full sm:w-fit">
            <label htmlFor="">Cửa hàng</label>
            <br />
            <Select
              filterOption={false}
              showSearch
              size="middle"
              className="custom-filter focus-within:!border-primary hover:!border-primary focus-within:!shadow-none"
              style={{ width: 250 }}
              placeholder="Chọn cửa hàng"
              allowClear
              loading={loadingMerchant}
              onSearch={(value) => debounceFetchMerchant(value)}
              onChange={(value) => {
                if (value) {
                  queryAppointments.merchantId = value;
                } else {
                  queryAppointments.merchantId = undefined;
                  queryMerchant.search = "";
                  fetchMerchant();
                }
                fetchAppointment();
              }}
            >
              <Select.Option value={undefined}>Tất cả</Select.Option>
              {dataMerchants?.map((item) => (
                <Select.Option value={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <AppointmentList
        onCancel={(appointmentId, reason) =>
          handleCancelAppointment(appointmentId, reason)
        }
        refreshData={fetchAppointment}
        dataSource={appointments}
        loading={loadingAppointments}
        onSelect={onSelectAppointment}
        fetchAppointment={fetchAppointment}
        selectedMerchantLocationId={selectedMerchantLocationId}
        // employeeModalRef={employeeModalRef}
      />
      <Pagination
        total={totalAppointments}
        currentPage={queryAppointments.page}
        onChange={({ limit, page }) => {
          queryAppointments.page = page;
          queryAppointments.limit = limit;
          fetchAppointment();
        }}
      />
    </div>
  );
};
