import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  DatePicker,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import Column from "antd/lib/table/Column";
import { employeeApi } from "api/employee.api";
import { Pagination } from "components/Pagination";
import useEmployee from "hooks/useEmployee";
import { useEffect, useState } from "react";
import { Merchant } from "types/merchant";
import { ModalStatus } from "types/modal";
import { Staff } from "types/staff";
import { EmployeeModal } from "../EmployeeModal";
import { ResetPassModal } from "../ResetPassModal";
import { Employee } from "types/employee";
import useMerchantTransaction from "hooks/useMerchantTransaction";
import {
  MerchantTransaction,
  MerchantTransactionType,
  MerchantTransactionTypeTrans,
} from "types/merchantTransaction";
import { AppointmentStatus } from "types/appointment";
import { unixToFullDate } from "utils/dateFormat";
import { formatVND } from "utils";
import dayjs from "dayjs";
import settings from "settings";

interface PropsType {
  merchant?: Merchant;
}
export const MerchantTransactionTab = ({ merchant }: PropsType) => {
  const [status, setStatus] = useState<ModalStatus>("create");

  const {
    fetchData: fetchMerchantTransaction,
    data: merchantTransactions,
    loading: loadingMerchantTransaction,
    query: queryMerchantTransaction,
    total: totalMerchantTransaction,
  } = useMerchantTransaction({
    initialQuery: {
      merchantId: merchant?.id,
      page: 1,
      limit: 50,
    },
  });

  useEffect(() => {
    fetchMerchantTransaction();
  }, []);

  console.log(merchantTransactions);

  const onChangeRangePicker = (date: any, dateFormat: string[]) => {
    if (date) {
      queryMerchantTransaction.toAt = dayjs(date[1]).endOf("day").unix();
      queryMerchantTransaction.fromAt = dayjs(date[0]).startOf("day").unix();
    } else {
      queryMerchantTransaction.toAt = undefined;
      queryMerchantTransaction.fromAt = undefined;
    }
    fetchMerchantTransaction();
  };

  return (
    <>
      {" "}
      <div className="filter-container">
        <Space wrap>
          <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              onChange={(ev) => {
                let text = ev.target.value;
                queryMerchantTransaction.search = text;
                fetchMerchantTransaction();
                // filterData({ search: text });
              }}
              size="middle"
              placeholder="Tìm kiếm theo mã"
            />
          </div>
          <div className="filter-item !w-fit max-w-[300px] ">
            <label htmlFor="">Khoảng thời gian</label>
            <DatePicker.RangePicker
              presets={[
                { label: "Hôm nay", value: [dayjs(), dayjs().add(1, "day")] },
                {
                  label: "Tuần này",
                  value: [dayjs().startOf("week"), dayjs().endOf("week")],
                },
                {
                  label: "Tháng này",
                  value: [dayjs().startOf("month"), dayjs().endOf("month")],
                },
                {
                  label: "Tháng trước",
                  value: [
                    dayjs().subtract(1, "month").startOf("month"),
                    dayjs().subtract(1, "month").endOf("month"),
                  ],
                },
              ]}
              onChange={onChangeRangePicker}
              className="custom-filter !w-full  max-h-[32px]  max-w-[300px]"
              size="small"
              format={settings.dateFormat}
            />
          </div>
          <div className="filter-item mt-2">
            <label htmlFor="">Loại giao dịch</label>
            <br />
            <Select
              size="large"
              className="custom-filter focus-within:!border-primary hover:!border-primary focus-within:!shadow-none"
              style={{ width: 200 }}
              placeholder="Chọn loại giao dịch"
              allowClear
              onChange={(value) => {
                queryMerchantTransaction.type = value;
                fetchMerchantTransaction();
              }}
            >
              {Object.values(MerchantTransactionType)
                // ?.filter(
                //   (item) =>
                //     !(
                //       item === MerchantTransactionType.PendingPayment ||
                //       item === MerchantTransactionType.Cancel
                //     )
                // )
                .map((item) => (
                  <Select.Option value={item}>
                    {MerchantTransactionTypeTrans[item]?.title}
                  </Select.Option>
                ))}
            </Select>
          </div>

          <div className="filter-item btn mt-1">
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => {
                fetchMerchantTransaction();
              }}
            >
              Tìm kiếm
            </Button>
          </div>
        </Space>
      </div>
      <Table
        loading={loadingMerchantTransaction}
        pagination={false}
        size="small"
        rowKey="id"
        scroll={{ x: "max-content", y: "500px" }}
        dataSource={merchantTransactions}
      >
        <Column
          width={70}
          title="Mã"
          key="code"
          dataIndex="code"
          render={(text, record: MerchantTransaction) => (
            <div className="flex flex-col">
              <span className="font-semibold text-secondary">
                #{record?.code}
              </span>
              <span className="text-gray-500">
                {unixToFullDate(record.createdAt)}
              </span>
            </div>
          )}
        />
        <Column
          width={150}
          title="Loại giao dịch"
          key="type"
          dataIndex="type"
          render={(text, record: MerchantTransaction) => (
            <div className="flex flex-col">
              <Tag
                style={{ marginBottom: "5px" }}
                className="w-fit"
                color={MerchantTransactionTypeTrans?.[record?.type]?.color}
              >
                {MerchantTransactionTypeTrans?.[record?.type]?.title}
              </Tag>
            </div>
          )}
        />
        <Column
        width={200}
          title="Mô tả"
          key="desc"
          dataIndex="desc"
          render={(text) => <span className="font-medium">{text}</span>}
        />

        <Column
         width={150}
          align="right"
          title="Trước giao dịch"
          key="beforeChange"
          dataIndex="beforeChange"
          render={(amount) => (
            <>
              <div className="flex flex-col gap-1">
                <span className="font-medium">{formatVND(amount)}đ</span>
              </div>
            </>
          )}
        />
        <Column
          width={150}
          align="right"
          title="Điểm thay đổi"
          key="amount"
          dataIndex="amount"
          render={(amount, record: MerchantTransaction) => (
            <>
              {record.beforeChange - record.afterChange > 0 ? (
                <span className="font-medium text-[#cf1322]">
                  {formatVND(amount)}đ
                </span>
              ) : (
                <span className="font-medium text-[#4caf50]">
                  +{formatVND(amount)}đ
                </span>
              )}
            </>
          )}
        />

        <Column
        width={150}
          align="right"
          title="Sau giao dịch"
          key="afterChange"
          dataIndex="afterChange"
          render={(amount) => (
            <span className="font-medium">{formatVND(amount)}đ</span>
          )}
        />
      </Table>
      <Pagination
        currentPage={queryMerchantTransaction.page}
        total={totalMerchantTransaction}
        // pageSize={query.limit}
        onChange={({ limit, page }) => {
          queryMerchantTransaction.page = page;
          queryMerchantTransaction.limit = limit;
          fetchMerchantTransaction();
        }}
      />
      {/* <Pagination
        total={total}
        defaultPageSize={query.limit}
        currentPage={query.page}
        onChange={({ limit, page }) => {
          filterData({ limit, page });
        }}
      /> */}
    </>
  );
};
