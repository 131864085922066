import { ExportOutlined } from "@ant-design/icons";
import { Popconfirm, Button } from "antd";
import { paymentOrderApi } from "api/paymentOrder.api";
import { paymentOrderQuery } from "hooks/usePaymentOrder";
import moment from "moment";
import { useState, useMemo, useEffect } from "react";
import { PaymentOrder, PaymentOrderStatusTrans } from "types/paymentOrder";
import { EPaymentTypeTrans } from "types/paymentType";
import { MyExcelColumn, handleExport } from "utils/MyExcel";
import { unixToFullTime } from "utils/formatDate";
import { formatVND } from "utils/formatVND";

interface PropsType {
  summaries: PaymentOrder[];
  queryPaymentOrder: paymentOrderQuery;
}
const HistoryCheckoutExportExcel = ({
  summaries,
  queryPaymentOrder,
}: PropsType) => {
  const [percentExportComplete, setPercentExportComplete] = useState<number>(0);

  const [visible, setVisible] = useState<boolean>(false);

  const exportColumns: MyExcelColumn<PaymentOrder>[] = useMemo(() => {
    return [
      {
        width: 30,
        header: "Mã",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "code",
        render: (record: PaymentOrder) => {
          return `#${record?.code}`;
        },
      },
      {
        width: 30,
        header: "Tên khách hàng",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "month",
        render: (record: PaymentOrder) => {
          return record?.customer?.fullName || "Khách hàng ẩn danh";
        },
      },
      {
        width: 30,
        header: "Cửa hàng",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },

        key: "name",
        render: (record: PaymentOrder) => {
          return record?.paymentOrderDetails?.[0]?.merchant?.name;
        },
      },
      // {
      //   width: 15,
      //   header: "Liên hệ",
      //   headingStyle: {
      //     font: {
      //       bold: true,
      //     },
      //   },
      //   style: {
      //     alignment: {
      //       vertical: "middle",
      //     },
      //   },
      //   key: "phone",
      //   render: (record: PaymentOrder) => {
      //     return `${record?.merchant?.email} - ${record?.merchant?.phone}`;
      //   },
      // },
      {
        width: 30,
        header: "Số điện thoại",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "totalAppointment",
        render: (record: PaymentOrder) => {
          return record?.customer?.phone;
        },
      },
      {
        width: 20,
        header: "Tổng giá (VNĐ)",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "totalGoodReview",
        render: (record: PaymentOrder) => {
          return formatVND(record?.amount);
        },
      },
      {
        width: 20,
        header: "Hình thức thanh toán",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "paymentType",
        render: (record: PaymentOrder) => {
          return EPaymentTypeTrans[record.paymentType?.type];
        },
      },
      {
        width: 20,
        header: "Trạng thái",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            vertical: "middle",
          },
        },
        key: "moneyAppointment",
        render: (record: PaymentOrder) => {
          return PaymentOrderStatusTrans[record.status]?.label;
        },
      },
      {
        width: 70,
        header: "Ngày tạo",
        headingStyle: {
          font: {
            bold: true,
          },
        },
        style: {
          alignment: {
            wrapText: true,
            vertical: "middle",
          },
        },
        key: "moneyVoucher",
        render: (record: PaymentOrder) => {
          return unixToFullTime(record?.createdAt);
        },
      },
    ];
  }, []);

  useEffect(() => {
    if (percentExportComplete >= 100) {
      setTimeout(() => {
        setVisible(false);
      }, 500);
    }
  }, [percentExportComplete]);
  const handleExportExcel = async () => {
    setVisible(true);
    setPercentExportComplete(0);
    try {
      await handleExport({
        onProgress(percent) {
          setPercentExportComplete(percent);
        },
        exportColumns: exportColumns,
        fileType: "xlsx",
        dataField: "paymentOrders",
        query: queryPaymentOrder,
        api: paymentOrderApi.findAll,
        fileName: "Danh sách lịch sử thanh toán",
        sheetName: "Danh sách lịch sử thanh toán",
        isGetFullData: true,
      });
    } finally {
      setPercentExportComplete(0);
      setVisible(false);
    }
  };

  return (
    <div>
      <Popconfirm
        title={`Bạn có muốn xuất file excel`}
        onConfirm={handleExportExcel}
        okText={"Xuất excel"}
        cancelText={"Huỷ"}
      >
        <Button
          type="primary"
          loading={false}
          icon={<ExportOutlined />}
          size="middle"
          disabled={!summaries.length}
        >
          Xuất file excel
        </Button>
      </Popconfirm>
    </div>
  );
};

export default HistoryCheckoutExportExcel;
