export enum ContentDefineType {
  About = "ABOUT", //về chúng tôi
  FAQ = "FAQ", //câu hỏi thường gặp (app khách)
  FAQDriver = "FAQ_DRIVER", //câu hỏi thường gặp (app t.xế)
  Security = "SECURITY", //chính sách bảo mật
  IntroContent2 = "INTRO_CONTENT_2",
  IntroContent1 = "INTRO_CONTENT_1",
  Policy = "POLICY",
  BookingQRTutorial = "BOOKING_QR_TUTORIAL", //hướng dẫn sử dụng mã QR booking
  PaymentSuccess = "PAYMENT_SUCCESS", //nội dung khi thanh toán thành công
}

export const ContentDefineTypeTrans = {
  [ContentDefineType.About]: "Về chúng tôi",
  [ContentDefineType.FAQ]: "Câu hỏi thường gặp (App người dùng)",
  [ContentDefineType.FAQDriver]: "Câu hỏi thường gặp (App tài xế)",
  [ContentDefineType.Security]: "Chính sách bảo mật",
  [ContentDefineType.IntroContent2]: "Mô tả về ứng dụng Joy Bookie",
  [ContentDefineType.IntroContent1]: "Về Joy Bookie",
  [ContentDefineType.Policy]: "Chính sách",
  [ContentDefineType.BookingQRTutorial]: "Hướng dẫn sử dụng mã QR đặt lịch",
  [ContentDefineType.PaymentSuccess]: "Nội dung thanh toán thành công",
};

export interface ContentDefine {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  icon: string;
  title: string;
  body: string;
  type: ContentDefineType;
}
