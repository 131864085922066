import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { Configuration } from "types/configuration";
import { configurationApi } from "api/configuration.api";
import { message } from "antd";
interface ConfigurationQuery extends QueryParam {}
type Props = {
  initialQuery: ConfigurationQuery;
};

const useConfiguration = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<Configuration, ConfigurationQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await configurationApi
        .findAll(query)
        .then(({ data }) => data);
      return {
        data: data.configurations,
        total: data.total,
      };
    },
    deleteFunc: async (id) => {
      await configurationApi.delete(id);
      message.success("Thao tác thành công!");
    },
    editFunc: async (id, data) => {
      await configurationApi.update(id, data);
    },
    createFunc: async (data) => {
      await configurationApi.create(data);
    },
  });

  return { ...fetch };
};

export default useConfiguration;
