import { Col, Row } from "antd";

import { useMemo, useRef } from "react";
import { BiSolidDiscount } from "react-icons/bi";
import {
  FaCalendarCheck,
  FaMotorcycle,
  FaStore,
  FaUserFriends,
} from "react-icons/fa";
import { GrMoney } from "react-icons/gr";
import { LuPackage } from "react-icons/lu";
import { RecordSaleModal } from "views/Dashboard/components/RecordSaleModal";
import {
  ISummaryItem,
  SummaryItem,
} from "views/Dashboard/components/SummaryItem";

export interface SummaryData {
  appointments: number;
  servicePack: number;
  voucher: number;
}

const StatsSalePanelGroup = ({
  appointments,
  commission,
  servicePack,
  voucher,
}: SummaryData & {
  commission: number;
}) => {
  const recordSaleModal = useRef<RecordSaleModal>();
  const generateSummary = () => {
    const summary: ISummaryItem[] = [
      {
        colSpan: 12,
        title: "Doanh thu lịch đặt",
        icon: <FaCalendarCheck size={45} className="text-primary" />,
        total: appointments || 0,
      },
      {
        colSpan: 12,
        title: "Doanh thu gói dịch vụ",
        icon: <LuPackage size={45} className="text-primary" />,
        total: servicePack || 0,
      },
      {
        colSpan: 12,
        title: "Doanh thu Voucher",
        icon: <BiSolidDiscount size={45} className="text-primary" />,
        total: voucher || 0,
      },
      {
        colSpan: 12,
        title: "Tổng tiền hoa hồng",
        icon: <GrMoney size={45} className="text-primary" />,
        total: commission || 0,
        tooltip: "Bấm để xem",
        tooltipOnClickEvent: () => recordSaleModal?.current?.handleCreate(),
      },
    ];
    return summary;
  };

  const summaryData = useMemo(() => {
    return generateSummary();
  }, [appointments, voucher, commission, servicePack]);

  return (
    <>
      <Row gutter={[24, 24]} className="mb-10 mt-5">
        {summaryData.map((item, index) => (
          <Col span={item.colSpan} key={index}>
            <SummaryItem data={item} key={index} />
          </Col>
        ))}
      </Row>
      <RecordSaleModal ref={recordSaleModal} />
    </>
  );
};

export default StatsSalePanelGroup;
