import { City, District, Ward } from "./address";
import { Customer } from "./customer";
import { CustomerProfile } from "./customerProfile";
import { Employee } from "./employee";
import { Merchant } from "./merchant";
import { MerchantLocation } from "./merchantLocation";
import { Service } from "./service";
import { ServicePack } from "./servicePack";
import { ServicePackOrder, ServicePackOrderDetail } from "./servicePackOrder";

export enum AppointmentStatus {
  New = "NEW",
  Confirm = "CONFIRM",
  PendingPayment = "PENDING_PAYMENT", //đợi thanh toán
  // CheckIn = "CHECK_IN", //đã check in
  NoShow = "NO_SHOW",
  Cancel = "CANCEL",
  Completed = "COMPLETED",
  //* custom
}

export interface AppointmentHistory {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  type: string;
  content: string;
  appointment: Appointment;
  employee: Employee;
}

export const AppointmentStatusTrans: any = {
  [AppointmentStatus.Cancel]: {
    value: AppointmentStatus.Cancel,
    label: "Đã hủy",
    color: "red",
  },
  [AppointmentStatus.Completed]: {
    value: AppointmentStatus.Completed,
    label: "Đã thanh toán",
    color: "green",
  },
  [AppointmentStatus.New]: {
    value: AppointmentStatus.New,
    label: "Mới",
    color: "blue",
  },
  [AppointmentStatus.Confirm]: {
    value: AppointmentStatus.Confirm,
    label: "Đã xác nhận",
    color: "orange",
  },
  [AppointmentStatus.NoShow]: {
    value: AppointmentStatus.NoShow,
    label: "Vắng mặt",
    color: "gray",
  },
  [AppointmentStatus.PendingPayment]: {
    value: AppointmentStatus.PendingPayment,
    label: "Chờ thanh toán",
    color: "gray",
  },
};


export interface AppointmentDetail {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  no: number; //stt của dịch vụ
  start: number;
  service: Service;
  end: number;
  duration: number;
  quantity: number;
  isFavorite: boolean;
  price: number;
  isInDoor: boolean; //true: book về nhà làm
  note: string;
  isPaid: boolean;
  //custom
  appointment: Appointment;
  servicePack: ServicePack; //*giá của dịch vụ
  servicePrice: number;
  servicePackOrder: ServicePackOrder;
  serviceOptionJson: string; //* ServiceOptionCustom
  //   serviceOption: ServiceOption;
  //   service: Service;
  employee: Employee;
    servicePackOrderDetail: ServicePackOrderDetail;
}

export interface Appointment {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  deletedAt: number;
  deletedBy: string;
  note: string;
  code: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  address: string; //địa chỉ nhà
  status: AppointmentStatus;
  serviceStartAt: number;
  serviceEndAt: number;
  point: number;
  reasonCancel: string;
  canceledAt: number;
  isRated: boolean;
  merchantLocation: MerchantLocation
  moneyTotal: number; //Tiền dịch vụ (+)
  moneyDiscount: number; //Tiền giảm giá
  moneyCoupon: number; //tiền sử dụng coupon (-)
  moneyVoucher: number; //tiền sử dụng voucher (-)
  moneyFinal: number; //Tiền khác phải trả
  customerPaid: number; //tiền khách đã thanh toán
  publicCode: string; //Code dùng để tra cứu lại qua link gửi trong sms, email
  checkInAt: number; //thời gian check-in tại tiệm
  totalDetail: number; //sl detail
  paidAt: number; //thời gian thanh toán
  isConfirmed: boolean; //spa đã xác nhận
  confirmedAt: number; //thời gian xác nhận
  //   cancelBy: AppointmentCancelBy;
  expiredRemindAt: number; //hạn nhắc xác nhận lịch
  //custom
  appointmentDetails: AppointmentDetail[];
  merchant: Merchant;
  customer: Customer;
  servicePack: ServicePack; //*giá của dịch vụ
  servicePrice: number;
  servicePackOrder: ServicePackOrder;
  //   checkout: Checkout;
  createdEmployee: Employee; //nv tạo
  canceledEmployee: Employee;
  //   rates: Rate[];
  //   promotion: Promotion;
  //   voucher: Voucher; //sử dụng voucher
  //   customerCoupon: CustomerCoupon; //sử dụng coupon
  appointmentHistories: AppointmentHistory[];
  customerProfile: CustomerProfile;
  //   merchantLocation: MerchantLocation;
  //   paymentOrderDetails: PaymentOrderDetail[];
  city: City; //dành cho book về nhà làm
  district: District; //dành cho book về nhà làm
  ward: Ward; //dành cho book về nhà làm
  //   servicePackOrder: ServicePackOrder;
  // if (this.promotion) {
  //     this.moneyDiscount = this.promotion.calcMoneyDiscount(this.moneyTotal);
  // }
  //details.length = 0 do skip k chon service
  //dùng hết phần còn lại của voucher
  //* k có lỗi coupon
  //case tiền giảm lớn hơn tiền dịch vụ
}

export interface ServiceOptionCustom {
  id: number;
  price: number;
  duration: number;
  pricingName: string;
  isChecked: boolean;
  quantity: number;
}
