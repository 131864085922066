import { request } from "utils/request";
import { AxiosPromise } from "axios";
import settings from "settings";

export const customerApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/customer",
      params,
    }),

  update: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/customer/${id}`,
      method: "patch",
      data,
    }),
  create: (data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/customer`,
      method: "post",
      data,
    }),

  block: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/customer/${id}/block`,
      method: "patch",
    }),

  unblock: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/customer/${id}/unblock`,
      method: "patch",
    }),
  delete: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/customer/${id}`,
      method: "delete",
    }),
  uploadUrl: settings.apiUrl + "/v1/admin/customer/upload",
};
