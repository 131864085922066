import {
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Image, message, Spin, Tooltip, Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { Size } from "constant/size";
import React, { useMemo, useState } from "react";
import settings from "settings";
import { getToken } from "utils/auth";
import { $url } from "utils/url";

interface SingleImageUploadProps {
  uploadUrl?: string;
  imageUrl: string;
  onUploadOk: (path: string) => void;
  uploadTitleBtn?: string;
  recommendedSize?: Size;
}

export const SingleImageUpload = ({
  recommendedSize,
  uploadUrl = process.env.REACT_APP_API_URL + "/v1/admin/merchant/upload",
  imageUrl,
  onUploadOk,
  uploadTitleBtn = "Upload",
}: SingleImageUploadProps) => {
  const [loading, setLoading] = useState(false);

  const beforeUpload = (file: File) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Bạn chỉ có thể upload file PNG hoặc JPG!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Kích thước hình ảnh phải nhỏ hơn 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      onUploadOk(process.env.REACT_APP_API_URL + info.file.response.data.path);
      setLoading(false);
    }
    if (info.file.status === "error") {
      message.error(info.file.response.message || "");
      setLoading(false);
    }
  };

  const uploadButton = useMemo(
    () => (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8, fontSize: 13 }}>{uploadTitleBtn}</div>
      </div>
    ),
    [loading]
  );

  return (
    <Spin spinning={loading}>
      <Upload
        name="file"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={uploadUrl}
        headers={{
          token: getToken() || "",
          version: settings.version,
        }}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? (
          <Spin spinning={loading}>
            <Image
              src={$url(imageUrl)}
              alt="avatar"
              preview={false}
              // className="!object-contain"
            />
          </Spin>
        ) : (
          uploadButton
        )}
      </Upload>
      {recommendedSize && <RecommendedSize size={recommendedSize} />}
    </Spin>
  );
};

export const RecommendedSize = ({
  size,
  content = "Kích thước đề nghị",
  tooltip = "Kích thước khuyến nghị cho hình ảnh được hiển thị tốt nhất",
  className,
  iconClassName,
}: {
  size: Size;
  content?: string;
  tooltip?: string;
  className?: string;
  iconClassName?: string;
}) => {
  return (
    <div
      className={[
        "recommended-size opacity-70 mt-1 font-medium",
        className,
      ].join(" ")}
    >
      <Tooltip title={tooltip}>
        <QuestionCircleOutlined
          className={["mr-1 text-[14px]", iconClassName].join(" ")}
        ></QuestionCircleOutlined>
      </Tooltip>
      {content} {[size.width, size.height].join(" x ")}
    </div>
  );
};
