import { message, Modal } from "antd";
import { Rule } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import { merchantApi } from "api/merchant.api";
import { GoogleMaps } from "components/Map/GoogleMaps";

import React, { useState } from "react";
import "../styles/addressModal.scss";
import { Merchant } from "types/merchant";

interface CoordAddress {
  address: string;
  lat: number;
  lng: number;
}

export const AddressModal = ({
  visible,
  merchantId,
  merchant,
  onClose,
  onSubmitOk,
}: {
  visible: boolean;
  merchantId: number;
  merchant?: Merchant;
  onClose: () => void;
  onSubmitOk: (address: string, lat: number, long: number) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [addressInfo, setAddressInfo] = useState<CoordAddress>();

  const handleSubmitForm = async () => {
    if (addressInfo) {
      onSubmitOk(addressInfo.address, addressInfo.lat, addressInfo.lng);
      onClose();
    }
  };

  console.log(merchant);

  return (
    <Modal
      className="address-modal"
      onCancel={onClose}
      visible={visible}
      title={"Cập nhật địa chỉ"}
      confirmLoading={loading}
      onOk={handleSubmitForm}
      width={600}
      okText={"Lưu"}
      cancelText={"Hủy"}
    >
      <GoogleMaps
        coord={{
          lat: merchant?.lat || 0,
          lng: merchant?.long || 0,
          address: merchant?.address || "",
        }}
        onPlaceSelected={(address) => setAddressInfo(address)}
      />
    </Modal>
  );
};
