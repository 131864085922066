import { TimeRangePickerProps } from "antd";
import dayjs, { Dayjs } from "dayjs";

export const rangePresets: TimeRangePickerProps["presets"] = [
  {
    label: "Hôm nay",
    value: [dayjs().startOf("day"), dayjs().endOf("day")],
  },
  {
    label: "Tuần này",
    value: [dayjs().startOf("week"), dayjs().endOf("week")],
  },
  {
    label: "Tháng này",
    value: [dayjs().startOf("month"), dayjs().endOf("month")],
  },
  {
    label: "Tháng trước",
    value: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
  },
];

export const disableFuture = (current: Dayjs) => {
  // Disable dates after today
  return current && current > dayjs().endOf("day");
};

export const disabledPast = (current: Dayjs) => {
  // Disable past dates
  return current && current < dayjs().startOf("day");
};
