import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { paymentOrderApi } from "api/paymentOrder.api";
import { message } from "antd";
import { PaymentOrder } from "types/paymentOrder";
export interface paymentOrderQuery extends QueryParam {}
type Props = {
  initialQuery: paymentOrderQuery;
};

const usePaymentOrder = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<PaymentOrder, paymentOrderQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await paymentOrderApi
        .findAll(query)
        .then(({ data }) => data);
      return {
        data: data.paymentOrders,
        total: data.total,
      };
    },
    // editFunc: async (id, data) => {
    //   await paymentOrderApi.update(id, data);
    // },
  });

  const {
    query: queryPaymentOrder,
    fetchData: fetchPaymentOrder,
    total: totalPaymentOrder,
    loading: isFetchPaymentOrderLoading,
    data: paymentOrders,
  } = fetch;

  return {
    fetchPaymentOrder,
    totalPaymentOrder,
    queryPaymentOrder,
    isFetchPaymentOrderLoading,
    paymentOrders,
  };
};

export default usePaymentOrder;
